<template>
  <div class="create-workbook container">
    <BreadCrumbs :trail="breadcrumbTrail" />
    <TrainingNavigation />
    <router-view />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import BreadCrumbs from '../components/BreadCrumbs.vue';
import TrainingNavigation from '../components/TrainingNavigation.vue';

export default {
  name: 'default',
  components: {
    BreadCrumbs,
    TrainingNavigation,
  },
  props: {},
  data() {
    return {};
  },
  created() {
    if (this.$route.name === 'create-workbook') {
      this.$router.push({ name: 'workbook' });
    }
  },
  beforeRouteLeave(to, from, next) {
    // leaving workbook, reset state
    if (!to.params.workbookId) {
      this.RESET_WORKBOOK();
      next();
      return;
    }
    // navigating within workbook, proceed to next route
    next();
  },
  computed: {
    breadcrumbTrail() {
      return [
        { name: 'workbooks', route: 'workbooks' },
        { name: this.$route.name, route: this.$route.name },
      ];
    },
  },
  watch: {},
  methods: {
    ...mapMutations('workbookBuilder', ['RESET_WORKBOOK']),
  },
};
</script>
