<template>
  <div>
    <template v-if="showTrainingLibrary">
      <div class="container training view-wrap">
        <Modal
          v-if="deleteModal"
          modalType="delete"
          :workbook="selectedWorkbook"
          @close-modal="closeDeleteModal"
          @delete="deleteWorkbook"
        />
        <Modal
          v-if="duplicateWorkbookModal"
          modalType="popup"
          :modalContent="duplicateWorkbookModalContent"
          @close-modal="closeDuplicateWorkbookModal"
        />
        <div class="view-heading">
          <h1 v-if="isFavorites">Favorites</h1>
          <h1 v-else>Workbooks</h1>
          <transition-group name="progress-update">
            <span :key="1" class="status-msg error" v-show="error">
              <Cancel class="cancel-icon" /> Error: {{ statusMsg }}
            </span>
            <span :key="2" class="status-msg success" v-show="success">
              <Check class="check-icon" /> Success: {{ statusMsg }}
            </span>
          </transition-group>
        </div>
        <div class="view-content">
          <div class="view-filter training-filter" :class="{ 'training-filter-mobile': isLaptop }">
            <!-- Desktop Filter Options -->
            <div v-if="!isLaptop" class="filter-options">
              <div class="vue-multiselect display-count">
                <span class="label">Show:</span>
                <VueMultiselect
                  @input="getFilteredWorkbooks"
                  class="single"
                  v-model.lazy="displayCount"
                  :options="displayCountOptions"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder=""
                />
              </div>
              <div class="vue-multiselect sort-by">
                <span class="label">Sort by:</span>
                <VueMultiselect
                  @input="getFilteredWorkbooks"
                  class="single"
                  v-model.lazy="sortBy"
                  label="title"
                  :options="sortByOptions"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder=""
                />
              </div>
              <div @click="toggleFilterMenu" class="filter" :class="{ 'filter-menu-active': filterMenu }">
                <p>Filter</p>
                <FilterIcon class="filter-icon" />
              </div>
              <div class="filtered-options">
                <span v-if="category" class="tag">
                  <Cancel @click="removeFilteredItem('category')" class="icon" />
                  {{ this.category }}
                </span>
                <span v-if="carline" class="tag">
                  <Cancel @click="removeFilteredItem('carline')" class="icon" />
                  {{ this.carline }}
                </span>
                <span v-if="modelYear" class="tag">
                  <Cancel @click="removeFilteredItem('modelYear')" class="icon" />
                  {{ this.modelYear }}
                </span>
              </div>
            </div>

            <!-- Filter Menu For Desktop & Mobile -->
            <div v-show="filterMenu" class="filter-menu" :class="{ 'filter-menu-mobile': isLaptop }">
              <div class="vue-multiselect">
                <label for="">Category</label>
                <VueMultiselect
                  class="single"
                  v-model.lazy="category"
                  :options="categoryOptions"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="All categories"
                />
              </div>
              <div class="vue-multiselect">
                <label for="">Carline</label>
                <VueMultiselect
                  class="single"
                  v-model.lazy="carline"
                  :options="carlineOptions"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="All carlines"
                />
              </div>
              <div class="vue-multiselect">
                <label for="">Model Year</label>
                <VueMultiselect
                  class="single"
                  v-model.lazy="modelYear"
                  :options="modelYearOptions"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="All model years"
                />
              </div>

              <!-- WAITING ON CONFIRM IF NEEDED -->
              <!-- <div class="vue-multiselect">
            <label for="">Vehicle Trim</label>
            <Multiselect

              class="single"
              v-model.lazy="vehicleTrim"
              :options="vehicleTrimOptions"
              :searchable="false"
              :close-on-select="true"
              :show-labels="false"
              placeholder=""
            >
            </Multiselect>
            <ArrowDown class="arrow-down" />
          </div> -->
              <div class="filter-actions">
                <PorscheButton @click="getFilteredWorkbooks">
                  <Arrow class="arrow-icon icon" />
                  <span>Apply filters</span>
                </PorscheButton>
                <PorscheButton @click="clearFilters" :light="true">
                  <Arrow class="arrow-icon icon" />
                  <span>Clear filters</span>
                </PorscheButton>
              </div>
            </div>

            <!-- Filtered Options Mobile -->
            <div v-if="isLaptop" class="filter-options-mobile">
              <div @click="toggleFilterMenu" class="filter">
                <p>Filter</p>
                <FilterIcon class="filter-icon" />
              </div>
              <div class="flex">
                <div class="vue-multiselect display-count">
                  <span class="label">Show:</span>
                  <VueMultiselect
                    @input="getFilteredWorkbooks"
                    class="single"
                    v-model.lazy="displayCount"
                    :options="displayCountOptions"
                    :searchable="false"
                    :close-on-select="true"
                    :show-labels="false"
                  />
                </div>
                <div class="vue-multiselect sort-by">
                  <span class="label">Sort by:</span>
                  <VueMultiselect
                    @input="getFilteredWorkbooks"
                    class="single"
                    v-model.lazy="sortBy"
                    :options="sortByOptions"
                    label="title"
                    :searchable="false"
                    :close-on-select="true"
                    :show-labels="false"
                  />
                </div>
              </div>
            </div>

            <!-- Create Workbook Button -->
            <PorscheButton v-if="!isLaptop && (admin || designer)" @click="goToRoute('workbook setup')">
              <Arrow class="arrow-icon icon" />
              <span>Create workbook</span>
            </PorscheButton>

            <div v-if="isFavorites" class="favorites-navigation">
              <router-link class="nav-link" :to="{ name: 'favorites-content-library' }">
                <FavoritesIcon class="fav-icon" />
                <span>Content Library</span>
              </router-link>
              <router-link class="nav-link" :to="{ name: 'favorites-workbooks' }">
                <FavoritesIcon class="fav-icon" />
                <span>Workbooks</span>
              </router-link>
            </div>
          </div>
          <div class="training-content-wrap">
            <Loading v-if="!dataAvailable" :type="'section'" />
            <div v-if="dataAvailable && !serverError && paginatedWorkbooks.length > 0" class="training-content">
              <div class="workbooks">
                <TrainingWorkbook
                  v-for="(workbook, index) in paginatedWorkbooks"
                  :key="index"
                  @favoriteWorkbook="favoriteWorkbook"
                  @delete-workbook="promptDelete"
                  @duplicate-workbook="duplicateWorkbook"
                  :workbook="workbook"
                  :index="index"
                />
              </div>
              <Pagination :pageCount="pageCount" @page-change="pageChange" :currentPage="activePage" />
            </div>
            <div v-if="dataAvailable && !serverError && paginatedWorkbooks.length === 0" class="training-no-results">
              <h2>You're not currently enrolled in any workbooks or no workbooks match your filtered criteria</h2>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <router-view :key="$route.params.workbookId" />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { uid } from 'uid';
import FavoritesIcon from '@/assets/images/Icons/heart_outline.svg';
import ApiService from '../utilities/ApiService';
import TrainingWorkbook from '../components/TrainingWorkbook.vue';
import Arrow from '../assets/images/Icons/arrow_right_white.svg';
import FilterIcon from '../assets/images/Icons/filter.svg';
import Cancel from '../assets/images/Icons/cancel.svg';
import PorscheButton from '../components/PorscheButton.vue';
import Pagination from '../components/Pagination.vue';
import Modal from '../components/Modal.vue';
import Check from '../assets/images/Icons/check.svg';
import Loading from '../components/Loading.vue';
import VueMultiselect from '../components/VueMultiselect.vue';

export default {
  name: 'training',
  components: {
    Modal,
    TrainingWorkbook,
    Check,
    Arrow,
    FilterIcon,
    Cancel,
    PorscheButton,
    Pagination,
    Loading,
    VueMultiselect,
    FavoritesIcon,
  },
  props: {},
  data() {
    return {
      serverError: false,
      dataAvailable: null,
      error: null,
      success: null,
      statusMsg: null,
      deleteModal: null,
      duplicateWorkbookModal: null,
      duplicateWorkbookModalContent: null,
      selectedWorkbook: null,
      workbookPages: {},
      activePage: 1,
      offset: 0,
      paginatedWorkbooks: [],
      filterOptions: null,
      filterCarline: null,
      filterCarlineYear: null,
      displayCount: 8,
      displayCountOptions: [8, 16, 24],
      sortBy: {
        title: 'Date (Newest First)',
        value: 'createdAt',
        order: 'DESC',
      },
      sortByOptions: [
        {
          title: 'Date (Newest First)',
          value: 'createdAt',
          order: 'DESC',
        },
        {
          title: 'Date (Oldest First)',
          value: 'createdAt',
          order: 'ASC',
        },
        {
          title: 'Title (A-Z)',
          value: 'title',
          order: 'ASC',
        },
        {
          title: 'Title (Z-A)',
          value: 'title',
          order: 'DESC',
        },
      ],
      category: null,
      categoryOptions: [],
      carline: null,
      carlineOptions: [],
      modelYear: null,
      modelYearOptions: [],
      vehicleTrim: null,
      vehicleTrimOptions: [],
      filterMenu: null,
      selectedFilterOptions: [],
      pageCount: null,
    };
  },
  async created() {
    await this.getWorkbooks();
  },
  computed: {
    ...mapState('userInformation', ['admin', 'designer', 'instructor', 'participant']),

    isLaptop() {
      return this.$mq === 'tablet' || this.$mq === 'tabletLarge' || this.$mq === 'mobile' || this.$mq === 'laptop';
    },

    showTrainingLibrary() {
      return this.$route.name === 'workbooks' || this.$route.name === 'favorites-workbooks';
    },

    isFavorites() {
      return this.$route.name === 'favorites-workbooks';
    },
  },
  methods: {
    async getWorkbooks() {
      try {
        // Get Filter Options
        this.filterOptions = await ApiService.get(`${process.env.VUE_APP_API_URL}/filter/workbook`);

        // Set Category options
        this.categoryOptions = this.filterOptions.data.data.categories;
        // Need to remove "No Category" & replace it with "" to retrieve workbooks with no categories
        const requestCategoryOptions = this.categoryOptions.filter((item) => item !== 'No Category');
        requestCategoryOptions.push('');

        this.carlineOptions = this.filterOptions.data.data.vehicleModels;
        this.modelYearOptions = this.filterOptions.data.data.vehicleYears;

        this.filterCarline = this.filterOptions.data.data.vehicleModels;
        this.filterCarline.push(null);
        this.filterCarline = this.filterCarline.filter((item) => item !== 'None');

        this.filterCarlineYear = this.filterOptions.data.data.vehicleYears;
        this.filterCarlineYear.push(null);
        this.filterCarlineYear = this.filterCarlineYear.filter((item) => item !== 'None');

        // Get All Workbooks
        const trainingData = await ApiService.post(`${process.env.VUE_APP_API_URL}/workbook/filter`, {
          vehicleModel: this.filterCarline,
          vehicleYear: this.filterCarlineYear,
          categories: requestCategoryOptions,
          eventPalmsId: null,
          startDate: null,
          endDate: null,
          orderBy: this.sortBy.value,
          order: this.sortBy.order,
          limit: this.displayCount,
          offset: this.offset,
          onlyFavorites: this.isFavorites,
        });

        this.pageCount = Math.ceil(trainingData.data.data.count / this.displayCount);
        this.workbookPages[this.activePage] = trainingData.data.data.rows;
        this.paginatedWorkbooks = this.workbookPages[this.activePage];

        this.dataAvailable = true;
      } catch (error) {
        this.dataAvailable = true;
        this.serverError = true;
        this.error = true;
        this.statusMsg = error.message;
      }
    },

    async getFilteredWorkbooks() {
      try {
        this.dataAvailable = null;
        this.error = false;
        this.filterMenu = false;

        // set category options. API only accepts array
        const categoryArray = [];
        categoryArray.push(this.category === 'No Category' ? '' : this.category);
        // need to remove "No Category" & replace it with "" to retrieve workbooks with no categories if there is not a single category chosen
        const requestCategoryOptions = this.categoryOptions.filter((item) => item !== 'No Category');
        requestCategoryOptions.push('');

        const carline = this.carline === 'None' ? null : this.carline;
        const modelYear = this.modelYear === 'None' ? null : this.modelYear;

        const trainingData = await ApiService.post(`${process.env.VUE_APP_API_URL}/workbook/filter`, {
          vehicleModel: this.carline ? carline : this.filterCarline,
          vehicleYear: this.modelYear ? modelYear : this.filterCarlineYear,
          categories: this.category ? categoryArray : requestCategoryOptions,
          eventPalmsId: null,
          startDate: null,
          endDate: null,
          orderBy: this.sortBy.value,
          order: this.sortBy.order,
          limit: this.displayCount,
          offset: this.offset,
          onlyFavorites: this.isFavorites,
        });
        this.pageCount = Math.ceil(trainingData.data.data.count / this.displayCount);
        this.workbookPages[this.activePage] = trainingData.data.data.rows;
        this.paginatedWorkbooks = this.workbookPages[this.activePage];
        this.dataAvailable = true;
      } catch (error) {
        this.error = true;
        this.statusMsg = error;
        this.dataAvailable = true;
      }
    },

    clearFilters() {
      this.category = null;
      this.carline = null;
      this.modelYear = null;
      this.getFilteredWorkbooks();
    },

    async pageChange(page) {
      // Check if we already have page
      if (this.workbookPages[page]) {
        this.paginatedWorkbooks = this.workbookPages[page];
        this.activePage = page;
        window.scrollTo(0, 0);
        return;
      }

      // Get new page
      this.offset = (page - 1) * this.displayCount;
      this.activePage = page;
      await this.getFilteredWorkbooks();
      this.paginatedWorkbooks = this.workbookPages[page];
      window.scrollTo(0, 0);
    },

    promptDelete(workbook) {
      this.deleteModal = true;
      this.selectedWorkbook = workbook;
    },

    closeDeleteModal() {
      this.deleteModal = false;
      this.selectedWorkbook = null;
    },

    async deleteWorkbook() {
      try {
        await ApiService.delete(`${process.env.VUE_APP_API_URL}/workbook/${this.selectedWorkbook.id}`);
        this.closeDeleteModal();
        this.getFilteredWorkbooks();
        window.scrollTo({ top: 0 });
        this.success = true;
        this.statusMsg = 'Workbook Deleted';
        setTimeout(() => {
          this.success = false;
        }, 5000);
      } catch (err) {
        this.closeDeleteModal();
        this.error = true;
        this.statusMsg = `${err.message}`;
        setTimeout(() => {
          this.error = false;
        }, 5000);
      }
    },

    async duplicateWorkbook(workbookId) {
      try {
        await ApiService.post(`${process.env.VUE_APP_API_URL}/workbook/duplicate`, {
          workbookId,
        });
        this.duplicateWorkbookModalContent = {
          title: 'Duplicate Workbook',
          message: 'Workbook duplicated successfully.',
        };
      } catch (err) {
        this.duplicateWorkbookModalContent = {
          title: 'Duplicate Workbook',
          message: 'Error, please try again.',
        };

        console.warn(err);
      }

      this.duplicateWorkbookModal = true;
    },

    closeDuplicateWorkbookModal() {
      this.duplicateWorkbookModal = null;
      // refetch workbooks
      this.getFilteredWorkbooks();
    },

    toggleFilterMenu() {
      this.filterMenu = !this.filterMenu;
    },

    async removeFilteredItem(filteredItem) {
      this[filteredItem] = null;
      await this.getFilteredWorkbooks();
    },

    goToRoute(route) {
      this.$router.push({ name: route, params: { workbookId: uid() } });
    },

    async favoriteWorkbook(index) {
      try {
        // remove from favorites
        if (this.paginatedWorkbooks[index].favorite) {
          await ApiService.delete(`${process.env.VUE_APP_API_URL}/favorite`, {
            assetId: this.paginatedWorkbooks[index].id,
          });
          this.paginatedWorkbooks[index].favorite = 0;
          return;
        }

        // add to favorites
        await ApiService.post(`${process.env.VUE_APP_API_URL}/favorite`, {
          assetId: this.paginatedWorkbooks[index].id,
          type: 'workbook',
        });
        this.paginatedWorkbooks[index].favorite = 1;
      } catch (error) {
        console.warn(error.message);
      }
    },
  },
  watch: {
    // watching for route to change & checking if on the training route
    // to recall the filter endpoint to refresh the displayed workbooks.
    $route() {
      if (this.$route.name === 'workbooks') {
        this.dataAvailable = false;
        this.getWorkbooks();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.training {
  .arrow-down {
    width: 16px;
    height: auto;
    margin-left: 18px;
    transform: rotate(-90deg);
  }

  .view-content {
    position: relative;
    min-height: 70vh;

    .training-filter {
      position: relative;
      display: flex;
      margin: 25px 0;

      // Shared Styling
      .filter-options,
      .filter-options-mobile {
        z-index: 99;
        display: flex;
        flex: 1;
        gap: 10px;

        .filter {
          position: relative;
          z-index: 99;
          display: flex;
          align-items: center;
          padding: 10px 12px;
          cursor: pointer;
          background-color: #fff;
          border: 1px solid #626669;
          .filter-icon {
            height: 16px;
            margin-left: 18px;
          }
        }

        .filter-menu-active {
          border-bottom-color: transparent;
        }
      }

      // Filter Options for mobile

      .filter-options-mobile {
        flex-direction: column;
        .filter {
          .filter-icon {
            margin-left: auto;
          }
        }
        div:nth-child(2) {
          column-gap: 24px;
          .display {
            flex: 1;
          }

          .sort-by {
            flex: 2;
          }
        }
      }

      .filtered-options {
        display: flex;
        gap: 8px;
        align-items: center;
        .tag {
          display: flex;
          align-items: center;
          padding: 4px 8px;
          font-size: 12px;
          background-color: #e3e4e5;
          border-radius: 2px;
          .icon {
            height: 16px;
            margin-right: 7px;
            cursor: pointer;
          }
        }
      }

      .filter-menu {
        position: absolute;
        top: 48px;
        left: 349px;
        z-index: 98;
        display: flex;
        flex-direction: column;
        max-width: max-content;
        padding: 24px 26px;
        background-color: #fff;
        border: 1px solid #626669;

        .porsche-button {
          align-self: flex-start;
        }
      }

      .filter-menu-mobile {
        left: 0;
        z-index: 100;
        max-width: 100%;
      }

      .favorites-navigation {
        display: flex;
        column-gap: 20px;
        align-items: center;
        justify-self: flex-end;

        a {
          display: flex;
          column-gap: 4px;
          align-items: center;
          color: initial;
          text-decoration: none;
        }

        .router-link-active {
          color: $primary;
          .fav-icon {
            g {
              fill: $primary;
            }
          }
        }

        .fav-icon {
          width: 16px;
          height: auto;
        }
      }
    }

    .training-filter-mobile {
      flex-direction: column;
      row-gap: 20px;

      .favorites-navigation {
        order: 1;
      }

      .filter-options-mobile {
        order: 2;
      }
    }

    .training-content-wrap {
      position: relative;
      .training-content {
        position: relative;
        display: flex;
        flex: 1;
        flex-direction: column;
        min-height: 70vh;

        .workbooks {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
</style>
