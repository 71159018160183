import Vue from 'vue';
import Vuex from 'vuex';
import userInformation from './modules/userInformation';
import workbookBuilder from './modules/workbookBuilder';
import workbookViewer from './modules/workbookViewer';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    userInformation,
    workbookBuilder,
    workbookViewer,
  },
});
