<template>
  <div class="pagination flex">
    <Arrow @click="backPage" class="icon" />
    <paginate
      v-model="localPage"
      :page-range="4"
      :page-count="pageCount"
      :prev-text="''"
      :next-text="''"
      :container-class="'paginate'"
    >
    </paginate>
    <Arrow @click="forwardPage" class="icon" />
  </div>
</template>

<script>
import Arrow from '../assets/images/Icons/arrow_left.svg';

export default {
  name: 'default',
  components: {
    Arrow,
  },
  props: ['pageCount', 'currentPage'],
  data() {
    return {
      localPage: this.currentPage ? this.currentPage : 1,
    };
  },
  created() {},
  computed: {},
  watch: {
    localPage() {
      this.$emit('page-change', this.localPage);
    },
  },
  methods: {
    forwardPage() {
      if (this.localPage < this.pageCount) {
        this.localPage += 1;
      }
    },

    backPage() {
      if (this.localPage > 1) {
        this.localPage += -1;
      }
    },
  },
};
</script>

<style lang="scss">
.pagination {
  gap: 24px;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  margin: auto 0 60px 0;

  .paginate {
    display: flex;
    gap: 20px;
    list-style: none;

    li {
      a {
        padding: 0 6px;
        font-size: 16px;
        cursor: pointer;
        border-bottom: 1px solid transparent;
      }

      &:first-child,
      &:last-child {
        display: none;
      }
    }

    .active {
      a {
        border-bottom-color: #d5001c;
      }
    }
  }

  .icon {
    height: 21px;
    cursor: pointer;
  }

  .icon:last-child {
    transform: rotate(-180deg);
  }
}
</style>
