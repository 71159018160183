<template>
  <div>
    <template v-if="type === 'absolute'">
      <div class="favorite-toggle-absolute" :class="{ 'favorite-item-active': favorite }" @click="favoriteItem">
        <FavoriteIconActive class="fav-icon" v-if="favorite" />
        <FavoriteIcon class="fav-icon" v-else />
      </div>
    </template>
    <template v-if="type === 'relative'">
      <div class="favorite-toggle" @click="favoriteItem">
        <FavoriteIconActive class="fav-icon" v-if="favorite" />
        <FavoriteIcon class="fav-icon" v-else />
        <p v-if="favorite">Remove from favorites</p>
        <p v-else>Add to favorites</p>
      </div>
    </template>
  </div>
</template>

<script>
import FavoriteIconActive from '@/assets/images/Icons/heart_filled_white.svg';
import FavoriteIcon from '@/assets/images/Icons/heart_outline.svg';

export default {
  name: 'favorite-toggler',
  components: { FavoriteIconActive, FavoriteIcon },
  props: {
    index: {
      type: Number,
    },
    type: {
      type: String,
    },
    favorite: {
      type: [Boolean, Number],
    },
  },
  data() {
    return {};
  },
  created() {},
  computed: {},
  watch: {},
  methods: {
    favoriteItem() {
      this.$emit('favoriteItem', this.index);
    },
  },
};
</script>

<style lang="scss" scoped>
.fav-icon {
  width: 20px;
  height: auto;
}

.favorite-toggle-absolute {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: $charcoal;
  border-radius: 50%;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

  .fav-icon {
    g {
      path {
        fill: #fff;
      }
    }
  }
}

.favorite-toggle {
  display: flex;
  column-gap: 8px;

  .fav-icon {
    g {
      path {
        fill: $primary;
      }
    }
  }
}

.favorite-item-active {
  background-color: $primary;
}
</style>
