<template>
  <div class="view-workbook">
    <div v-if="dataAvailable" class="workbook-wrap">
      <!-- Workbook Preview Banner -->
      <div v-if="$route.query.preview === 'true' && !userInformation.participant" class="workbook-preview">
        <div class="preview-content container">
          <p>
            You are previewing this workbook as a participant. Interactions within this workbook are limited. You must
            exit the preview in order to continue building the workbook.
          </p>
          <PorscheButton @click="exitPreview">
            <Arrow class="arrow-icon icon" />
            <span>Exit Preview</span>
          </PorscheButton>
        </div>
      </div>
      <BreadCrumbs class="container" :trail="breadcrumbTrail" />
      <div class="workbook" :class="{ 'mobile-workbook': isLaptop }">
        <ul class="workbook-nav">
          <MenuIcon class="menu-lines icon" @click="toggleWbNav" v-if="isLaptop" />
          <div class="workbook-nav-items" v-show="!isLaptop || mobileNavItems">
            <li
              @click="changeView($event, 'setup')"
              :id="localWorkbook.workbook.id"
              class="nav-title nav-item"
              :class="{ 'nav-active': workbookIntro }"
            >
              {{ localWorkbook.workbook.title }}
            </li>
            <li class="nav-modules" v-for="workbookModule in workbookViewer.moduleNavigation" :key="workbookModule.id">
              <div
                @click="changeView($event, 'module', workbookModule)"
                class="nav-module nav-item"
                :class="{ 'nav-active': currentModuleId === workbookModule.id && moduleView }"
                :id="workbookModule.id"
              >
                {{ workbookModule.title }}
                <div
                  v-if="new Date().toISOString() <= workbookModule.unlockTime || workbookModule.accessCode"
                  class="lock-icon-wrap"
                >
                  <IconLock class="lock-icon" />
                </div>
              </div>
              <ul class="nav-sections">
                <li
                  class="nav-section nav-item"
                  v-for="workbookSection in workbookModule.sections"
                  :class="{
                    'nav-active': currentSectionId === workbookSection.id && section,
                    'nav-disabled':
                      (!workbookModule.accessCodeValue && workbookModule.accessCode) ||
                      new Date().toISOString() <= workbookModule.unlockTime,
                  }"
                  :key="workbookSection.id"
                  :id="workbookSection.id"
                  @click="changeView($event, 'section', workbookSection)"
                >
                  {{ workbookSection.title }}
                </li>
              </ul>
            </li>
          </div>
        </ul>
        <div class="workbook-content" :class="{ container: isLaptop }">
          <Loading :type="'section'" v-if="!dataAvailable" />

          <div v-if="workbookIntro" class="wb-intro">
            <div v-if="!isTablet" class="image">
              <img
                v-if="localWorkbook.workbook.coverImageUrl !== ''"
                :src="localWorkbook.workbook.coverImageUrl"
                alt=""
              />
              <img v-else class="default-img" src="@/assets/images/Workbook/workbook_thumb@2x.png" alt="" />
            </div>
            <div class="content">
              <h1>{{ localWorkbook.workbook.title }}</h1>
              <div class="event-details">
                <span class="tag">{{ localWorkbook.event.palmsId }}</span>
                <span class="tag">{{ localWorkbook.event.title }}</span>
                <span class="tag">{{ localWorkbook.event.category }}</span>
              </div>
              <FavoriteToggle
                v-if="userInformation.participant"
                :favorite="localWorkbook.workbook.isFavorite"
                @favoriteItem="favoriteWorkbook"
                type="relative"
              />
              <hr />
              <div v-if="localWorkbook.workbook.vehicleModel" class="vehicle-info">
                <div class="vehicle-tag">
                  <p class="label">Vehicle Model:</p>
                  <span class="tag">{{ localWorkbook.workbook.vehicleModel }}</span>
                </div>
                <div class="vehicle-tag">
                  <p class="label">Vehicle Year:</p>
                  <span class="tag">{{ localWorkbook.workbook.vehicleYear }}</span>
                </div>
              </div>

              <div class="description">
                <p class="label">Description:</p>
                <p class="value" v-html="localWorkbook.workbook.description"></p>
              </div>
              <div class="description" v-if="userInformation.instructor || workbookBuilder.workbookPreviewMode">
                <p class="label">Instructor Description:</p>
                <p class="value" v-html="localWorkbook.workbook.instructorDescription"></p>
              </div>
              <!-- Temp removing this feature -->
              <PorscheButton :light="true" @click="nextModule">
                <Arrow class="arrow-icon icon" />
                <span>Next Module</span>
              </PorscheButton>
            </div>
          </div>

          <template v-for="(moduleInfo, index) in workbookViewer.moduleNavigation">
            <ViewModule
              @nextSection="nextSection"
              @goToWorkbookOverview="goToWorkbookOverview"
              v-if="currentModuleId === moduleInfo.id && moduleView"
              :currentModule="currentModule"
              :index="index"
              :key="moduleInfo.id"
            />

            <template v-for="(sectionInfo, secondaryIndex) in moduleInfo.sections">
              <ViewSection
                v-if="currentSectionId === sectionInfo.id && section"
                :currentSection="currentSection"
                :index="index"
                :secondaryIndex="secondaryIndex"
                @nextSection="nextSection"
                :key="sectionInfo.id"
              />
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ApiService from '@/utilities/ApiService';
import BreadCrumbs from '@/components/BreadCrumbs.vue';
import PorscheButton from '@/components/PorscheButton.vue';
import FavoriteToggle from '@/components/FavoriteToggle.vue';
import MenuIcon from '@/assets/images/Icons/menu-lines.svg';
import Arrow from '@/assets/images/Icons/arrow_right_white.svg';
import ViewModule from '@/components/ViewModule.vue';
import ViewSection from '@/components/ViewSection.vue';
import Loading from '@/components/Loading.vue';
import IconLock from '../assets/images/Icons/lock.svg';

export default {
  name: 'view-workbook',
  components: {
    BreadCrumbs,
    PorscheButton,
    Loading,
    MenuIcon,
    FavoriteToggle,
    Arrow,
    IconLock,
    ViewModule,
    ViewSection,
  },
  props: {},
  data() {
    return {
      mobileNavItems: null,
      localWorkbook: {},
      dataAvailable: null,
      workbookIntro: true,
      moduleView: null,
      currentModule: null,
      currentModuleId: null,
      section: null,
      currentSection: null,
      currentSectionId: null,
      breadcrumbTrail: [
        { name: 'workbooks', route: 'workbooks' },
        { name: 'workbook view', route: 'workbook view' },
      ],
    };
  },
  async created() {
    await this.getWorkbook();
  },
  computed: {
    ...mapState({
      userInformation: (state) => state.userInformation,
      workbookViewer: (state) => state.workbookViewer,
      workbookBuilder: (state) => state.workbookBuilder,
    }),

    isLaptop() {
      return this.$mq === 'tablet' || this.$mq === 'mobile' || this.$mq === 'tabletLarge';
    },

    isTablet() {
      return this.$mq === 'tablet' || this.$mq === 'mobile';
    },
  },
  watch: {},
  beforeDestroy() {
    if (this.$route.query.preview) {
      this.$router.replace({ query: { preview: null } });
    }
  },
  methods: {
    ...mapActions('workbookViewer', ['GET_WORKBOOK']),
    async getWorkbook() {
      try {
        await this.GET_WORKBOOK(this.$route.params.workbookId);
        this.localWorkbook = this.workbookViewer.workbook;
        this.dataAvailable = true;
      } catch (error) {
        console.warn(error);
      }
    },

    // go to workbook overview / setup (runs from $emit on viewModule)
    goToWorkbookOverview() {
      this.resetViews();
      this.workbookIntro = !this.workbookIntro;
    },

    // reset all views
    resetViews() {
      this.workbookIntro = false;
      this.moduleView = false;
      this.section = false;
    },

    changeView(e, type, content) {
      this.resetViews();
      this.toggleWbNav();

      // setup
      if (type === 'setup') {
        this.workbookIntro = !this.workbookIntro;
        return;
      }

      // module
      if (type === 'module') {
        this.currentModule = {
          ...content,
          workbookId: this.localWorkbook.workbookId,
        };
        this.moduleView = !this.moduleView;
        this.currentModuleId = content.id;
        return;
      }

      // else section
      this.currentSection = content;
      this.section = !this.section;
      this.currentSectionId = content.id;
    },

    toggleWbNav() {
      this.mobileNavItems = !this.mobileNavItems;
    },

    async favoriteWorkbook() {
      try {
        // remove from favorites
        if (this.localWorkbook.workbook.isFavorite) {
          await ApiService.delete(`${process.env.VUE_APP_API_URL}/favorite`, {
            assetId: this.localWorkbook.workbook.id,
          });
          this.localWorkbook.workbook.isFavorite = null;
          return;
        }

        // add to favorites
        await ApiService.post(`${process.env.VUE_APP_API_URL}/favorite`, {
          assetId: this.localWorkbook.workbook.id,
          type: 'workbook',
        });
        this.localWorkbook.workbook.isFavorite = true;
      } catch (error) {
        console.warn(error.message);
      }
    },

    nextModule() {
      this.resetViews();
      this.moduleView = true;
      this.currentModule = this.workbookViewer.moduleNavigation[0];
      this.currentModuleId = this.workbookViewer.moduleNavigation[0].id;
    },

    nextSection(type, moduleIndex, sectionIndex) {
      this.resetViews();
      // coming from a module
      if (type === 'module') {
        // check if another section exists
        if (this.workbookViewer.moduleNavigation[moduleIndex].sections[0]) {
          this.currentSection = this.workbookViewer.moduleNavigation[moduleIndex].sections[0];
          this.currentSectionId = this.workbookViewer.moduleNavigation[moduleIndex].sections[0].id;
          this.section = true;
        } else {
          this.moduleView = true;
        }
      }

      // coming from a section
      if (type === 'section') {
        // check see if another section exists, go to section
        if (sectionIndex + 1 < this.workbookViewer.moduleNavigation[moduleIndex].sections.length) {
          this.currentSection = this.workbookViewer.moduleNavigation[moduleIndex].sections[sectionIndex + 1];
          this.currentSectionId = this.workbookViewer.moduleNavigation[moduleIndex].sections[sectionIndex + 1].id;
          this.section = true;
        } else if (moduleIndex + 1 < this.workbookViewer.moduleNavigation.length) {
          // check if another module exists go to module
          this.currentModule = this.workbookViewer.moduleNavigation[moduleIndex + 1];
          this.currentModuleId = this.workbookViewer.moduleNavigation[moduleIndex + 1].id;
          this.moduleView = true;
        } else {
          this.section = true;
        }
      }
    },

    exitPreview() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.workbook-wrap {
  .workbook-preview {
    padding: 38px 0;
    background-color: #c9cacb;

    .preview-content {
      display: flex;
      column-gap: 32px;

      p {
        flex: 1;
        max-width: 630px;
      }

      .porsche-button {
        display: flex;
        align-self: center;
        margin-left: auto;
      }
    }
  }

  .workbook {
    display: flex;
    column-gap: 32px;
    min-height: 600px;
    margin: 22px auto 80px auto;

    ul {
      list-style: none;
    }

    .workbook-nav {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 300px;

      .menu-lines {
        width: 36px;
        min-height: 36px;
        margin-left: 10px;
      }

      .workbook-nav-items {
        height: 100%;
        border-top: 1px solid $grey-extra-light;
        border-right: 1px solid $grey-extra-light;
      }

      .nav-title {
        background-color: $white-smoke;
      }

      .nav-item {
        display: flex;
        align-items: center;
        height: 52px;
        padding: 0 0 0 28px;
        cursor: pointer;
        border-bottom: 1px solid $grey-extra-light;

        .lock-icon-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 35px;
          height: 35px;
          margin-right: 16px;
          margin-left: auto;
          background-color: $white-smoke;
          border-radius: 50%;

          .lock-icon {
            width: 24px;
            height: 24px;
            path {
              fill: #000;
            }
          }
        }
      }

      .nav-active {
        color: #fff;
        background-color: #323639 !important;
        border: 1px solid #c9cacb;

        .lock-icon {
          path {
            fill: white;
          }
        }
      }

      .nav-disabled {
        pointer-events: none;
      }

      .nav-modules {
        .nav-sections {
          .nav-section {
            padding-left: 48px;
          }
        }
      }
    }

    .workbook-content {
      position: relative;
      display: flex;
      flex: 1;
      flex-direction: column;

      .wb-intro {
        display: flex;
        column-gap: 32px;

        .image {
          .default-img {
            border: 1px solid $grey;
          }

          img {
            display: block;
            width: 250px;
            height: 250px;
            object-fit: cover;
          }
        }

        .content {
          flex: 1;

          h1 {
            font-size: 36px;
            font-weight: 500;
          }

          .event-details {
            display: flex;
            column-gap: 6px;
            margin: 8px 0 32px;
          }

          .favorites {
            display: flex;
            column-gap: 8px;
            margin-top: 32px;
          }

          .vehicle-info {
            display: flex;
            column-gap: 60px;
            margin-bottom: 32px;
          }

          .wb-req {
            display: flex;
            column-gap: 16px;
            margin-bottom: 32px;

            .req-column {
              display: flex;
              flex: 1;
              flex-direction: column;

              .values {
                display: flex;
                flex-wrap: wrap;
                column-gap: 8px;

                span {
                  position: relative;
                  &::after {
                    position: absolute;
                    right: -4px;
                    content: ',';
                  }

                  &:last-child {
                    &::after {
                      content: '';
                    }
                  }
                }
              }

              .no-result {
                color: $grey-light;
              }
            }

            .c-designer {
              flex: initial;
              flex-basis: 45%;
            }
          }
        }
      }
    }
  }

  .mobile-workbook {
    position: relative;
    flex-direction: column;
    margin-top: 30px;

    .workbook-nav {
      max-width: 100%;
      height: 100%;

      .workbook-nav-items {
        position: absolute;
        top: 30px;
        z-index: 101;
        width: 100%;
        height: 100%;
        margin-top: 10px;
        overflow: scroll;
        background-color: #fff;
        border-right: none;
      }
    }

    .workbook-content {
      margin-top: 20px;

      .wb-intro {
        .content {
          .wb-req {
            flex-direction: column;
            row-gap: 16px;
          }
        }
      }
    }
  }
}
</style>
