<template>
  <label
    v-if="label"
    v-on="$listeners"
    class="porsche-button"
    :class="{ 'porsche-button-light': light, 'porsche-button-inactive': inactive }"
  >
    <slot />
  </label>
  <a
    target="_blank"
    v-else-if="link"
    v-on="$listeners"
    class="porsche-button"
    :class="{ 'porsche-button-light': light, 'porsche-button-inactive': inactive }"
  >
    <slot />
  </a>
  <button
    v-else
    v-on="$listeners"
    class="porsche-button"
    :class="{
      'porsche-button-light': light,
      'porsche-button-inactive': inactive,
      'porsche-button-transparent': transparent,
      'porsche-button-dark': dark,
    }"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'default',
  components: {},
  props: ['light', 'dark', 'transparent', 'label', 'link', 'inactive'],
  data() {
    return {};
  },
  created() {},
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style lang="scss">
.porsche-button {
  display: flex;
  align-items: center;
  padding: 12px 24px 12px 16px;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  background-color: $primary;
  border: 1px solid transparent;
  transition: 0.2s ease all;

  &:hover {
    background-color: $primary-hover;
  }

  .arrow-icon {
    width: auto;
    height: 20px;
    margin-right: 6px;
  }

  .cancel-icon {
    width: auto;
    height: 20px;
    margin-right: 6px;
    path {
      fill: #fff;
    }
  }

  .upload-icon {
    width: 17px;
    height: auto;
    margin-right: 10px;
    path {
      fill: #fff;
    }
  }
}

.porsche-button-light {
  color: #000;
  background-color: #fff;
  border-color: #323639;

  &:hover {
    color: $primary;
    background-color: #fff;
    border-color: $primary;

    .icon {
      path {
        fill: $primary;
      }
    }

    .arrow-icon {
      path:nth-child(2) {
        fill: $primary;
      }
    }

    .cancel-icon {
      path {
        fill: $primary;
      }
    }
  }

  .icon {
    margin-right: 6px;
    path {
      fill: #000;
      transition: 0.3s ease all;
    }
  }

  .cancel-icon {
    path {
      fill: #000;
    }
  }

  .arrow-icon {
    path:nth-child(1) {
      fill: transparent;
    }
    path:nth-child(2) {
      fill: #000;
    }
  }

  .download-icon {
    width: 22px;
    height: 15px;
  }

  .delete-icon {
    width: 12px;
    height: 16px;
  }
}

.porsche-button-dark {
  color: #fff;
  background-color: #000;

  &:hover {
    background-color: rgb(52, 52, 52);
  }

  .icon {
    margin-right: 6px;
    path {
      fill: #000;
      transition: 0.3s ease all;
    }
  }

  .cancel-icon {
    path {
      fill: #000;
    }
  }

  .arrow-icon {
    path:nth-child(1) {
      fill: transparent;
    }
    path:nth-child(2) {
      fill: #fff;
    }
  }

  .download-icon {
    width: 22px;
    height: 15px;
  }

  .delete-icon {
    width: 12px;
    height: 16px;
  }
}

.porsche-button-transparent {
  padding: 0;
  color: #000;
  background-color: transparent;
  .arrow-icon {
    path:nth-child(2) {
      fill: $primary;
    }
  }

  &:hover {
    background-color: transparent;
  }
}

.porsche-button-inactive {
  pointer-events: none;
  background-color: #c9cacb;
}
</style>
