<template>
  <div ref="viewer" class="pdf">
    <a ref="download" :href="download"></a>
  </div>
</template>

<script>
import WebViewer from '@pdftron/pdfjs-express-viewer';
import axios from 'axios';

export default {
  name: 'PdfModal',
  data() {
    return {
      index: '',
    };
  },
  props: {
    url: String,
    download: String,
    fileName: String,
  },
  methods: {
    async downloadBlob(downloadURL) {
      try {
        const { fileName } = this;
        const instance = axios.create();
        delete instance.defaults.headers.common.Authorization;
        const fetch = await instance.get(downloadURL, {
          responseType: 'blob',
        });
        const res = fetch.data;
        const createUrl = URL.createObjectURL(res);
        this.forceDownload(createUrl, fileName);
      } catch (err) {
        console.warn(err);
      }
    },

    forceDownload(blob, fileName) {
      const anchorTag = document.createElement('a');
      anchorTag.download = fileName;
      anchorTag.href = blob;
      document.body.appendChild(anchorTag);
      anchorTag.click();
      anchorTag.remove();
    },
  },
  mounted() {
    WebViewer(
      {
        licenseKey: process.env.VUE_APP_PDFJS_KEY,
        path: `${process.env.BASE_URL}lib/pdfjsexpress`,
        initialDoc: this.url,
        disabledElements: [
          'toolbarGroup-FillAndSign',
          'signatureToolGroupButton',
          // 'selectToolButton',
          'fileAttachmentToolGroupButton',
          'calloutToolGroupButton',
          'toolsOverlay',
          'ribbons',
          'toolsHeader',
        ],
      },
      this.$refs.viewer,
    )
      .then((instance) => {
        instance.UI.disableElements(['fullscreenButton']);
        instance.UI.setToolbarGroup('default');
        instance.setHeaderItems((header) => {
          header.getHeader('default').push({
            img: 'icon-header-full-screen',
            index: -1,
            type: 'actionButton',
            element: 'fullScreenButton',
            onClick: () => {
              instance.UI.toggleFullScreen();
            },
          });
          header.getHeader('default').push({
            img: 'icon-header-download',
            index: -1,
            type: 'actionButton',
            element: 'downloadButton',

            onClick: () => {
              this.downloadBlob(this.download);
            },
          });
        });
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pdf {
  width: 528px;
  height: 500px;
}
</style>
