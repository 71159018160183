<template>
  <div class="section-view">
    <!-- Modal -->
    <Modal
      :modalType="'delete'"
      :sectionAsset="activeAsset"
      v-if="deleteModal"
      v-on:close-modal="promptDelete"
      v-on:delete="deleteAsset"
    />

    <Loading v-if="!dataAvailable" :type="'section'" />
    <div v-if="dataAvailable">
      <div class="progress">
        <transition-group name="progress-update">
          <span :key="1" class="status-msg error" v-show="error">
            <CancelIcon class="cancel-icon" /> Error: {{ statusMsg }}
          </span>
          <span :key="2" class="status-msg success" v-show="success">
            <Check class="check-icon" /> Success: {{ statusMsg }}
          </span>
        </transition-group>
        <PorscheButton
          v-if="dataAvailable && !retrievalError"
          @click="enablePreviewMode"
          type="button"
          :class="{ 'porsche-button-inactive': !disabledState }"
        >
          <Arrow class="arrow-icon icon" />
          <span>Preview workbook</span>
        </PorscheButton>
        <PorscheButton :light="true" @click="goToRoute('workbooks')">
          <Arrow class="arrow-icon icon" />
          <span>Exit</span>
        </PorscheButton>
      </div>
      <div class="view-heading">
        <h1 class="view-title">Section Setup</h1>
        <p class="view-summary">
          Create a new section by completing the form field below and save your section information to continue. You can
          also add assets to this section below. Each added asset must be saved individually
        </p>
      </div>

      <div v-if="!retrievalError" class="section-content">
        <div class="input">
          <label for="sectionTitle">Section Title</label>
          <input type="text" id="moduleTitle" v-model.lazy="localSection.title" />
        </div>
        <div class="textarea">
          <label for="moduleDescription">Participant Section Description</label>
          <quill-editor
            ref="myQuillEditor"
            class="quill-editors"
            :options="editorOption"
            v-model="localSection.description"
          />
        </div>

        <div class="textarea">
          <label for="moduleDescription">Instructor Section Description</label>
          <quill-editor
            ref="myQuillEditor"
            class="quill-editors"
            :options="editorOption"
            v-model="localSection.instructorDescription"
          />
        </div>
        <div class="save-content">
          <PorscheButton type="submit" @click="saveSection">
            <Arrow class="arrow-icon icon" />
            <span v-if="currentSection.id.length > 11">Update section information</span>
            <span v-else>Save section information</span>
          </PorscheButton>
        </div>
        <hr />

        <div class="asset-builder" v-if="currentSection.id.length > 11">
          <h1 v-if="localSectionAssets.length > 0" class="view-title">Section Assets</h1>

          <!-- Assets -->
          <draggable :list="localSectionAssets" :disabled="disableDrag">
            <div v-for="(asset, index) in localSectionAssets" :key="asset.id">
              <SectionAssets
                :key="asset.id"
                :asset="asset"
                :index="index"
                :sectionId="localSection.id"
                :assetVisible="asset.assetVisible"
                @prompt-delete="promptDelete"
                @toggleAssetView="toggleAssetView"
                @createAsset="updateAsset"
              />
            </div>
          </draggable>

          <h1 class="view-title">Add Asset</h1>

          <!-- Asset Builder Tags -->
          <div class="asset-builders flex">
            <div
              @dragstart="dragStart(asset.name, asset.id)"
              draggable="true"
              v-for="(asset, index) in sectionAssetTypes"
              :key="index"
              class="asset-tab flex"
            >
              <Pdf class="icon" v-if="asset.name === 'PDF'" />
              <ImageAsset class="icon" v-if="asset.name === 'Image'" />
              <VideoAsset class="icon" v-if="asset.name === 'Video'" />
              <Slides class="icon" v-if="asset.name === 'Slides'" />
              <TextAsset class="icon" v-if="asset.name === 'Links'" />
              <TextAsset class="icon" v-if="asset.name === 'Text'" />
              <Hotspot class="icon" v-if="asset.name === 'Hotspot'" />
              <span :id="asset.id">{{ asset.name }}</span>
            </div>
          </div>

          <!-- Dropzone -->
          <div class="dropzone" v-on:drop="drop" @dragover="allowDrop"></div>
        </div>
      </div>
      <div v-else>
        <h2>Something went wrong, please try again.</h2>
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from 'uid';
import ApiService from '@/utilities/ApiService';
import draggable from 'vuedraggable';
import Arrow from '@/assets/images/Icons/arrow_right_white.svg';
import SectionAssets from '@/components/SectionAssets.vue';
import Loading from '@/components/Loading.vue';
import { createNamespacedHelpers } from 'vuex';
import CancelIcon from '@/assets/images/Icons/cancel.svg';
import Check from '../assets/images/Icons/check.svg';
import Pdf from '../assets/images/Icons/icon_pdf.svg';
import ImageAsset from '../assets/images/Icons/icon_image.svg';
import VideoAsset from '../assets/images/Icons/icon_video.svg';
import Slides from '../assets/images/Icons/icon_slideshow.svg';
import TextAsset from '../assets/images/Icons/icon_text.svg';
import Hotspot from '../assets/images/Icons/icon_hotspot.svg';
import PorscheButton from './PorscheButton.vue';
import Modal from './Modal.vue';

const { mapMutations, mapState, mapActions } = createNamespacedHelpers('workbookBuilder');

export default {
  name: 'section-details',
  components: {
    draggable,
    Pdf,
    ImageAsset,
    VideoAsset,
    Slides,
    TextAsset,
    Hotspot,
    PorscheButton,
    Arrow,
    Check,
    SectionAssets,
    Modal,
    CancelIcon,
    Loading,
  },
  async created() {
    window.scrollTo(0, 0);
    await this.getSection();
  },
  beforeDestroy() {
    this.RESET_SECTION_ASSETS();
  },
  props: ['currentSection', 'index', 'secondaryIndex', 'currentModuleId'],
  data() {
    return {
      dataAvailable: null,
      retrievalError: null,
      disableDrag: false,
      deleteModal: false,
      activeAsset: null,
      error: false,
      success: null,
      statusMsg: null,
      originalLocalSection: null,
      localSection: null,
      localSectionAssets: [],
      workbookId: false,
      moduleId: false,
      sectionId: false,
      sectionTitle: false,
      sectionDescription: '',
      editorOption: {
        modules: {
          toolbar: [
            { header: [1, 2, 3, 4, 5, 6, false] },
            'bold',
            'italic',
            { list: 'ordered' },
            { list: 'bullet' },
            'link',
          ],
        },
      },
      draggedAsset: null,
      draggedAssetType: null,
    };
  },
  computed: {
    ...mapState(['sectionAssets', 'sectionInfo', 'sectionAssetTypes', 'workbook']),

    disabledState() {
      return this.$route.params.workbookId.length > 11;
    },
  },
  watch: {
    // Watching for section object to be updated
    localSection: {
      deep: true,
      handler() {
        if (JSON.stringify(this.originalLocalSection) !== JSON.stringify(this.localSection)) {
          this.SET_ISDIRTY();
          return;
        }
        this.RESET_ISDIRTY();
      },
    },

    localSectionAssets() {
      if (JSON.stringify(this.sectionAssets) !== JSON.stringify(this.localSectionAssets)) {
        if (!this.disableDrag) {
          this.updateAssetOrdering();
        }
      }
    },
  },
  methods: {
    ...mapMutations([
      'ADD_SECTION_ASSET',
      'UPDATE_SECTION',
      'SET_ISDIRTY',
      'RESET_ISDIRTY',
      'REMOVE_SECTION_ASSET',
      'RESET_SECTION_ASSETS',
    ]),
    ...mapActions(['GET_SECTION']),

    toggleAssetView(index) {
      // if open, close all
      if (this.localSectionAssets[index].assetVisible) {
        this.localSectionAssets.forEach((asset) => {
          asset.assetVisible = false;
        });
        this.disableDrag = false;
        return;
      }
      // not open, close all then open correct asset
      this.localSectionAssets.forEach((asset) => {
        asset.assetVisible = false;
      });
      this.localSectionAssets[index].assetVisible = true;
      this.disableDrag = true;
    },

    HTTPSuccess(msg) {
      this.success = true;
      this.statusMsg = msg;
      setTimeout(() => {
        this.success = false;
      }, 5000);
    },

    HTTPError(msg) {
      this.error = true;
      this.statusMsg = msg;
      setTimeout(() => {
        this.error = false;
      }, 5000);
    },

    async getSection() {
      try {
        // section already exist? Grab it from the db.
        if (this.currentSection.id.length > 11) {
          // get section
          await this.GET_SECTION(this.currentSection.id);
          this.localSection = { ...this.sectionInfo };
          this.originalLocalSection = { ...this.localSection };
          this.localSectionAssets = [...this.sectionAssets];
          this.dataAvailable = true;
          return;
        }

        // don't have it? Grab local section.
        const id = uid();
        this.localSection = {
          id,
          moduleId: this.currentModuleId,
          title: 'New Section',
          description: '',
          instructorDescription: '',
        };
        this.originalLocalSection = { ...this.localSection };
      } catch (error) {
        this.retrievalError = true;
        this.HTTPError(error);
      }
      this.dataAvailable = true;
    },

    async saveSection() {
      // reset error
      this.error = false;
      this.success = false;

      // scroll to top
      window.scrollTo(0, 0);

      // validate
      if (this.localSection.title !== '') {
        try {
          // section already exists? update.
          if (this.currentSection.id.length > 11) {
            await ApiService.patch(`${process.env.VUE_APP_API_URL}/workbook/module/section/${this.currentSection.id}`, {
              moduleId: this.currentSection.moduleId,
              title: this.localSection.title,
              description: this.localSection.description,
              instructorDescription: this.localSection.instructorDescription,
            });

            this.UPDATE_SECTION({
              content: this.localSection,
              index: this.index,
              secondaryIndex: this.secondaryIndex,
            });

            this.HTTPSuccess('Progress Saved');
            this.RESET_ISDIRTY();
            return;
          }

          // does not exist? create new
          const createSection = await ApiService.post(`${process.env.VUE_APP_API_URL}/workbook/module/section`, {
            moduleId: this.currentSection.moduleId,
            title: this.localSection.title,
            description: this.localSection.description,
            instructorDescription: this.localSection.instructorDescription,
            order: this.secondaryIndex,
          });

          this.localSection.id = createSection.data.data.id;

          this.UPDATE_SECTION({
            content: this.localSection,
            index: this.index,
            secondaryIndex: this.secondaryIndex,
          });

          // Updates Local ID stored in Parent (Workbook Content)
          this.$emit('current-section', createSection.data.data.id);

          await this.GET_SECTION(this.localSection.id);
          this.HTTPSuccess('Progress Saved');
          this.RESET_ISDIRTY();
        } catch (err) {
          this.HTTPError(err.message);
        }
        return;
      }
      // Req not met, show error.
      this.HTTPError('Ensure all fields are completed.');
    },

    promptDelete(asset) {
      this.deleteModal = !this.deleteModal;
      this.activeAsset = asset;
    },

    async updateAssetOrdering() {
      const newOrder = [];
      this.localSectionAssets.forEach((item, index) => {
        newOrder.push({
          assetId: item.newId ? item.newId : item.id,
          order: index,
        });
      });
      await ApiService.patch(
        `${process.env.VUE_APP_API_URL}/workbook/module/section/order-assets/${this.currentSection.id}`,
        {
          sectionAssets: newOrder,
        },
      );
    },

    async deleteAsset() {
      try {
        // db asset
        if (this.activeAsset.id.length > 11) {
          await ApiService.delete(`${process.env.VUE_APP_API_URL}/asset/${this.activeAsset.id}`);
        }
        this.REMOVE_SECTION_ASSET(this.activeAsset.id);
        this.localSectionAssets = this.localSectionAssets.filter((asset) => asset.id !== this.activeAsset.id);
        this.deleteModal = false;
        this.disableDrag = false;
      } catch (error) {
        this.HTTPError(error);
        this.deleteModal = false;
        console.warn(error);
      }
    },

    drop() {
      this.addAssetToPage(this.draggedAsset, this.draggedAssetType);
    },

    // prevents default on dropzone
    allowDrop(e) {
      e.preventDefault();
    },

    // Get's current tag being dragged to correctly
    dragStart(assetType, assetId) {
      this.draggedAssetType = assetId;
      this.draggedAsset = assetType;
    },

    addAssetToPage(asset, assetId) {
      const id = uid();

      // locks assets from being reordered
      this.disableDrag = true;

      if (asset === 'PDF' || asset === 'Image' || asset === 'Video' || asset === 'Slides') {
        this.localSectionAssets.push({
          id,
          active: true,
          newId: null,
          awsIdentifier: null,
          assetTypeId: assetId,
          assetVisible: false,
          title: '',
          file: false,
          fileName: false,
          url: '',
          note: '',
          description: '',
          type: asset,
        });
        return;
      }
      if (asset === 'Text') {
        this.localSectionAssets.push({
          id,
          active: true,
          newId: null,
          awsIdentifier: null,
          assetTypeId: assetId,
          assetVisible: false,
          title: '',
          note: '',
          description: '',
          type: asset,
        });
        return;
      }
      if (asset === 'Web-Based Training' || asset === 'Links') {
        this.localSectionAssets.push({
          id,
          active: true,
          newId: null,
          awsIdentifier: null,
          assetTypeId: assetId,
          assetVisible: false,
          title: '',
          url: '',
          note: '',
          description: '',
          type: asset,
        });
        return;
      }
      this.localSectionAssets.push({
        id,
        active: true,
        newId: null,
        assetTypeId: assetId,
        assetVisible: false,
        title: '',
        notes: '',
        url: false,
        file: false,
        fileName: false,
        pins: [],
        description: '',
        type: asset,
      });
    },

    updateAsset(newAsset) {
      this.disableDrag = false;
      this.localSectionAssets[newAsset.index].newId = newAsset.content.newId;
    },

    goToRoute(route, param, paramValue) {
      this.$router.push({ name: route, params: { param: paramValue } });
    },

    enablePreviewMode() {
      this.$router.push({
        name: 'workbook view',
        params: { 'workbook-id': this.workbook.id },
        query: { preview: 'true' },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.section-view {
  .save-content {
    margin-top: 24px;
  }

  .asset-builder {
    .view-title {
      margin-bottom: 24px;
    }
  }
}
</style>
