/* eslint-disable linebreak-style */
/* eslint-disable prettier/prettier */
import axios from 'axios';

export default async (url, fileData) => {
  const instance = axios.create();
  delete instance.defaults.headers.common.Authorization;
  await instance.put(`${url}`, fileData, {
    headers: {
      'Content-Type': '',
    },
  });
};
