<template>
  <div>
    <!-- Report Generator -->
    <div ref="sidePanelWrap" v-if="sidePanel" class="side-panel-wrap">
      <div v-click-outside="toggleSidePanel" class="side-panel">
        <Loading :type="'section'" v-if="creatingReport" />
        <div>
          <div @click="toggleSidePanel" class="close-report">
            <ArrowRed class="icon arrow-red" />
            <span>Back to Reports</span>
          </div>
          <h2 class="panel-title">Generate New Report</h2>
          <p class="panel-summary">
            Set up a new report by completing the form below. After report setup, you can generate a user report, quiz
            report, or survey report based on the information provided in the setup.
          </p>

          <AlertError :type="'block'" :alertError="createReportError || associatedDataError">
            <template v-slot:alert-message>
              <span> {{ createReportErrorMsg }}</span>
            </template>
          </AlertError>

          <!-- Report Title -->
          <div class="input">
            <label for="reportName">Report Name <span class="required">*</span></label>
            <input type="text" placeholder="Name This Report" id="reportName" v-model="reportName" />
          </div>

          <!-- Workbook/Module Selection -->
          <div class="vue-multiselect">
            <span class="label">Event/Module <span class="required">*</span></span>
            <VueMultiselect
              v-model="moduleId"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="workbookTitle"
              :options="moduleIdOptions"
              placeholder="Select an Event/Module"
              @input="getWorkbookAssociatedData"
            />
          </div>

          <!-- Only show rest of panel once user has selected a Event/Module & we have received  session data -->
          <div style="position: relative">
            <Loading :type="'section'" v-if="moduleId && !associatedDataAvailable && !associatedDataError" />
            <div v-if="moduleId && associatedDataAvailable">
              <div class="sessions-wrap">
                <span class="label">Session to Show<span class="required">*</span></span>
                <div class="session" v-for="(session, index) in sessionOptions" :key="index">
                  <div class="checkbox">
                    <input type="checkbox" v-model="session.selected" />
                    <Check v-show="session.selected" class="check-icon" />
                  </div>
                  <div class="session-content">
                    <p class="field"><span class="label">Session Name:</span> {{ session.palmsSessionTitle }}</p>
                    <div class="date">
                      <p class="field">
                        <span class="label">Start Date:</span>
                        {{ new Date(session.startDate).toLocaleDateString('en-us') }}
                      </p>
                      <p class="field">
                        <span class="label">End Date:</span>
                        {{ new Date(session.endDate).toLocaleDateString('en-us') }}
                      </p>
                    </div>
                    <p class="field">
                      <span class="label">Location:</span> {{ session.location ? session.location : '-' }}
                    </p>
                  </div>
                </div>
              </div>
              <hr />
              <!-- Temp removing -->
              <!-- <div>
          <div class="vue-multiselect half-size">
            <span class="label">Market</span>
            <VueMultiselect
              v-model="market"
              :searchable="false"
              :close-on-select="true"
              :show-labels="false"
              :options="marketOptions"
            />
          </div>
          <div class="vue-multiselect half-size">
            <span class="label">Area</span>
            <VueMultiselect
              v-model="area"
              :searchable="false"
              :close-on-select="true"
              :show-labels="false"
              :options="areaOptions"
            />
          </div>
          <div class="vue-multiselect half-size">
            <span class="label">Region</span>
            <VueMultiselect
              v-model="region"
              :searchable="false"
              :close-on-select="true"
              :show-labels="false"
              :options="regionOptions"
            />
          </div>
          <div class="vue-multiselect half-size">
            <span class="label">Department</span>
            <VueMultiselect
              v-model="department"
              :searchable="false"
              :close-on-select="true"
              :show-labels="false"
              :options="departmentOptions"
            />
          </div>
        <hr />
        </div> -->
              <div class="report-type user-report">
                <h3>User Report</h3>
                <PorscheButton @click="createReport('user', null)">
                  <Arrow class="arrow-icon icon" />
                  <span> Generate .CSV</span>
                </PorscheButton>
              </div>
              <hr />
              <div class="report-type quiz-data">
                <h3>Quiz Reports</h3>
                <div class="vue-multiselect half-size">
                  <span class="label">Quiz Report Type</span>
                  <VueMultiselect
                    v-model="quizReportType"
                    :searchable="false"
                    :close-on-select="true"
                    :show-labels="false"
                    :options="quizReportTypeOptions"
                    label="name"
                  />
                </div>
                <div class="vue-multiselect half-size">
                  <span class="label">Quizzes</span>
                  <VueMultiselect
                    v-model="selectedQuiz"
                    :searchable="false"
                    :close-on-select="true"
                    :show-labels="false"
                    :options="quizzesOptions"
                    label="title"
                  />
                </div>
                <!-- <span class="label"> User metrics to include </span> -->
                <!-- <div class="data-options">
            <div class="report-column">
              <div class="checkbox">
                <input type="checkbox" v-model="quizTotalUser" />
                <span>Total users</span>
                <Check v-show="quizTotalUser" class="check-icon" />
              </div>
              <div class="checkbox">
                <input type="checkbox" v-model="quizTotalResult" />
                <span>Total results</span>
                <Check v-show="quizTotalResult" class="check-icon" />
              </div>
              <div class="checkbox">
                <input type="checkbox" v-model="quizNotStarted" />
                <span>Not started</span>
                <Check v-show="quizNotStarted" class="check-icon" />
              </div>
              <div class="checkbox">
                <input type="checkbox" v-model="quizInProgress" />
                <span>In progress</span>
                <Check v-show="quizInProgress" class="check-icon" />
              </div>
            </div>
            <div class="report-column">
              <div class="checkbox">
                <input type="checkbox" v-model="quizAverageScore" />
                <span>Average score</span>
                <Check v-show="quizAverageScore" class="check-icon" />
              </div>
              <div class="checkbox">
                <input type="checkbox" v-model="quizLowestScore" />
                <span>Lowest score</span>
                <Check v-show="quizLowestScore" class="check-icon" />
              </div>
              <div class="checkbox">
                <input type="checkbox" v-model="quizHighestScore" />
                <span>Highest score</span>
                <Check v-show="quizHighestScore" class="check-icon" />
              </div>
            </div>
            <div class="report-column">
              <div class="checkbox">
                <input type="checkbox" v-model="quizCompleted" />
                <span>Completed</span>
                <Check v-show="quizCompleted" class="check-icon" />
              </div>
              <div class="checkbox">
                <input type="checkbox" v-model="quizPassed" />
                <span>Passed</span>
                <Check v-show="quizPassed" class="check-icon" />
              </div>
              <div class="checkbox">
                <input type="checkbox" v-model="quizFailed" />
                <span>Failed</span>
                <Check v-show="quizFailed" class="check-icon" />
              </div>
            </div>
          </div> -->
                <PorscheButton
                  :inactive="!selectedQuiz || !quizReportType"
                  @click="createReport('quiz', quizReportType)"
                >
                  <Arrow class="arrow-icon icon" />
                  <span> Generate .CSV</span>
                </PorscheButton>
              </div>
              <hr />
              <div class="report-type survey-data">
                <h3>Survey Report</h3>
                <div class="vue-multiselect half-size">
                  <span class="label">Surveys</span>
                  <VueMultiselect
                    v-model="selectedSurvey"
                    :searchable="false"
                    :close-on-select="true"
                    :show-labels="false"
                    :options="surveysOptions"
                    label="title"
                  />
                </div>
                <!-- <span class="label"> User metrics to include </span> -->
                <!-- <div class="data-options">
            <div class="report-column">
              <div class="checkbox">
                <input type="checkbox" v-model="surveyTotalUser" />
                <span>Total users</span>
                <Check v-show="surveyTotalUser" class="check-icon" />
              </div>
              <div class="checkbox">
                <input type="checkbox" v-model="surveyTotalResult" />
                <span>Total results</span>
                <Check v-show="surveyTotalResult" class="check-icon" />
              </div>
              <div class="checkbox">
                <input type="checkbox" v-model="surveyNotStarted" />
                <span>Not started</span>
                <Check v-show="surveyNotStarted" class="check-icon" />
              </div>
            </div>
            <div class="report-column">
              <div class="checkbox">
                <input type="checkbox" v-model="surveyInProgress" />
                <span>In progress</span>
                <Check v-show="surveyInProgress" class="check-icon" />
              </div>
              <div class="checkbox">
                <input type="checkbox" v-model="surveyCompleted" />
                <span>Completed</span>
                <Check v-show="surveyCompleted" class="check-icon" />
              </div>
            </div>
          </div> -->
                <PorscheButton :inactive="!selectedSurvey" @click="createReport('survey', null)">
                  <Arrow class="arrow-icon icon" />
                  <span> Generate .CSV</span>
                </PorscheButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal
      v-if="deleteReportModal"
      modalType="delete"
      @close-modal="closeDeleteReportModal"
      :modalContent="deleteReportModalContent"
      @delete-view="deleteReport"
    />

    <div class="container">
      <div class="view-wrap reports">
        <div class="view-heading">
          <h1>Reports</h1>

          <AlertSuccess :type="'inline'" :alertSuccess="alertSuccess">
            <template v-slot:alert-message>
              <span> {{ alertMsg }}</span>
            </template>
          </AlertSuccess>

          <AlertError :type="'inline'" :alertError="alertError">
            <template v-slot:alert-message>
              <span> {{ alertMsg }}</span>
            </template>
          </AlertError>
        </div>

        <div class="view-content">
          <!-- Filtering -->
          <div class="reports-filter">
            <div class="vue-multiselect display-count">
              <span class="label">Show:</span>
              <VueMultiselect
                v-model="displayCount"
                :searchable="false"
                :close-on-select="true"
                :allow-empty="false"
                :show-labels="false"
                :options="displayCountOptions"
                @input="getReports"
                :disabled="!workbookDataAvailable && workbookDataError"
              />
            </div>

            <!-- Remove temp -->
            <div @click="toggleFilterMenu" class="filter" :class="{ 'filter-menu-active': filterMenu }">
              <p>Filter</p>
              <FilterIcon class="filter-icon" />
            </div>

            <div class="button-actions">
              <!-- Remove temp -->
              <!-- <PorscheButton v-if="selectedReports.length > 0" :light="true">
            <DeleteIcon class="delete-icon icon" />
            <span>Delete selected groups </span>
          </PorscheButton> -->

              <!-- Remove temp -->
              <!-- <PorscheButton v-if="selectedReports.length > 0" :light="true">
            <DownloadIcon class="download-icon icon" />
            Download all selected
          </PorscheButton> -->

              <PorscheButton @click="toggleSidePanel" :inactive="!workbookDataAvailable && workbookDataError">
                <Arrow class="arrow-icon icon" />
                Generate new report (CSV)
              </PorscheButton>
            </div>

            <!-- Advanced Filter Menu -->
            <div v-if="filterMenu && reportDataAvailable" class="filter-menu-wrap">
              <div class="filter-menu">
                <div class="vue-multiselect generated-by">
                  <span class="label">Generated by</span>
                  <VueMultiselect
                    v-model="generatedBy"
                    :searchable="false"
                    :close-on-select="true"
                    :show-labels="false"
                    :options="filterMenuOptions.users"
                  />
                </div>
                <div class="module-info">
                  <div class="vue-multiselect">
                    <span class="label">Event/Module ID</span>
                    <VueMultiselect
                      v-model="filteredModuleId"
                      :searchable="false"
                      :close-on-select="true"
                      :show-labels="false"
                      :options="filterMenuOptions.events"
                    />
                  </div>
                  <div class="vue-multiselect">
                    <span class="label">Event/Module Title</span>
                    <VueMultiselect
                      v-model="filteredModuleTitle"
                      :searchable="false"
                      :close-on-select="true"
                      :show-labels="false"
                      :options="filterMenuOptions.workbookTitles"
                    />
                  </div>
                </div>
                <div class="date-picker">
                  <label>Time Generated</label>
                  <div class="prime-cal">
                    <PrimeCalendar
                      v-model="timeGenerated"
                      selectionMode="range"
                      :showButtonBar="true"
                      placeholder="Select Date Range"
                    />
                    <CalendarIcon class="cal-icon" />
                  </div>
                </div>
              </div>
              <div class="filter-actions">
                <PorscheButton @click="getReports">
                  <Arrow class="arrow-icon icon" />
                  <span>Apply Filters</span>
                </PorscheButton>
                <PorscheButton @click="clearFilters" :light="true">
                  <Arrow class="arrow-icon icon" />
                  <span>Clear Filters</span>
                </PorscheButton>
              </div>
            </div>
          </div>

          <div class="reports-table">
            <div class="labels-wrap">
              <div class="labels">
                <!-- Remove temp -->
                <!-- <span></span> -->
                <span>Report Title</span>
                <span>Event/Module ID</span>
                <span>Event/Module Title</span>
                <span>Session</span>
                <span>Time Generated</span>
                <span>Generated by</span>
                <span>Actions</span>
              </div>
              <hr />
            </div>
            <div class="report-wrap">
              <Loading :type="'section'" v-if="fetchingReportData" />
              <div v-if="reportDataAvailable && paginatedReports.length > 0">
                <div v-for="(report, index) in paginatedReports" :key="index">
                  <div class="report">
                    <!-- Remove temp -->
                    <!-- <span>
              <div class="checkbox">
                <input type="checkbox" :id="`selectedReport${index}`" @change="selectedReport(index, report)" />
                <Check class="check-icon" />
              </div>
            </span> -->
                    <span>{{ report.reportTitle ? report.reportTitle : '-' }}</span>
                    <span>{{ report.eventId ? report.eventId : '-' }}</span>
                    <span>{{ report.eventTitle ? report.eventTitle : '-' }}</span>
                    <span v-if="report.sessions.length > 0">
                      <span v-for="(session, index) in report.sessions" :key="index"> {{ session.title }}, </span>
                    </span>
                    <span v-else>-</span>
                    <span>
                      {{ new Date(report.createdAt).toLocaleDateString() }}
                      {{ new Date(report.createdAt).toLocaleTimeString('en-us', { timeStyle: 'short' }) }}
                    </span>
                    <span>{{ report.userName ? report.userName : '-' }}</span>
                    <span class="actions">
                      <div class="download-icon-wrap">
                        <Loading :type="'small'" v-if="report.downloadingReport" />
                        <DownloadIcon @click="downloadReport(report.id, index)" class="download-icon" />
                      </div>
                      <DeleteIcon @click="promptDeleteReport(report)" class="delete-icon" />
                    </span>
                  </div>
                  <hr />
                </div>
                <Pagination :pageCount="pageCount" @page-change="pageChange" :currentPage="activePage" />
              </div>
              <h2 v-if="reportDataAvailable && paginatedReports.length === 0" class="header-error">
                Sorry, there are no reports to display.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/utilities/ApiService';
import Loading from '@/components/Loading.vue';
import Modal from '@/components/Modal.vue';
import AlertError from '@/components/AlertError.vue';
import AlertSuccess from '@/components/AlertSuccess.vue';
import vClickOutside from 'v-click-outside';
import PrimeCalendar from '@/components/PrimeCalendar.vue';
import CalendarIcon from '@/assets/images/Icons/calendar.svg';
import Check from '../assets/images/Icons/check.svg';
import VueMultiselect from '../components/VueMultiselect.vue';
import FilterIcon from '../assets/images/Icons/filter.svg';
import PorscheButton from '../components/PorscheButton.vue';
import DownloadIcon from '../assets/images/Icons/download.svg';
import DeleteIcon from '../assets/images/Icons/delete.svg';
import Arrow from '../assets/images/Icons/arrow_right_white.svg';
import ArrowRed from '../assets/images/Icons/arrow_right_red.svg';
import Pagination from '../components/Pagination.vue';

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  name: 'reports',
  components: {
    Check,
    VueMultiselect,
    FilterIcon,
    PorscheButton,
    DownloadIcon,
    DeleteIcon,
    Arrow,
    ArrowRed,
    Pagination,
    Loading,
    Modal,
    AlertError,
    AlertSuccess,
    PrimeCalendar,
    CalendarIcon,
  },
  props: [],
  data() {
    return {
      alertMsg: null,
      alertSuccess: null,
      alertError: null,
      createReportError: null,
      createReportErrorMsg: null,
      workbookDataAvailable: null,
      workbookDataError: null,
      moduleId: null,
      moduleIdOptions: [],
      associatedDataAvailable: null,
      associatedDataError: null,
      sessionOptions: [],
      reportDataAvailable: null,
      fetchingReportData: null,
      creatingReport: null,
      reportsPages: {},
      paginatedReports: [],
      deleteReportModal: null,
      deleteReportModalContent: null,
      downloadingReport: null,
      displayCount: 8,
      displayCountOptions: [8, 16, 24],
      offset: 0,
      filterMenu: null,
      filterMenuOptions: [],
      generatedBy: null,
      filteredModuleId: null,
      filteredModuleTitle: null,
      timeGenerated: null,
      selectedReports: [],
      sidePanel: null,
      reportName: null,
      market: 'USA',
      marketOptions: ['USA'],
      area: 'USA - South/Central',
      areaOptions: ['USA - South/Central'],
      region: 'USA-23',
      regionOptions: ['USA-23'],
      department: 'Porsche Jacksonville',
      departmentOptions: ['Porsche Jacksonville'],
      quizReportType: null,
      quizReportTypeOptions: [
        {
          type: 'quiz',
          name: 'Summary',
        },
        {
          type: 'quizUser',
          name: 'User Performance',
        },
        {
          type: 'quizQuestion',
          name: 'Question Performance',
        },
        {
          type: 'quizOverview',
          name: 'Quiz Score',
        },
      ],
      selectedQuiz: null,
      quizzesOptions: [],
      quizTotalUser: null,
      quizTotalResult: null,
      quizNotStarted: null,
      quizInProgress: null,
      quizAverageScore: null,
      quizLowestScore: null,
      quizHighestScore: null,
      quizCompleted: null,
      quizPassed: null,
      quizFailed: null,
      selectedSurvey: null,
      surveysOptions: [],
      surveyTotalUser: null,
      surveyTotalResult: null,
      surveyNotStarted: null,
      surveyInProgress: null,
      surveyCompleted: null,
      pageCount: 0,
      activePage: 1,
    };
  },
  async created() {
    await this.popInitialData();
  },
  computed: {},
  watch: {},
  methods: {
    showAlertSuccess(msg) {
      this.alertMsg = msg;
      this.alertSuccess = true;

      setTimeout(() => {
        this.alertSuccess = null;
      }, 5000);
    },

    showAlertError(msg, permAlert) {
      this.alertMsg = msg;
      this.alertError = true;

      if (!permAlert) {
        setTimeout(() => {
          this.alertError = null;
        }, 5000);
      }
    },

    resetAlerts() {
      this.alertError = null;
      this.alertSuccess = null;
      this.alertMsg = null;
    },

    toggleFilterMenu() {
      this.filterMenu = !this.filterMenu;
    },

    selectedReport(index, report) {
      const isChecked = document.querySelector(`#selectedReport${index}:checked`);
      if (isChecked) {
        this.selectedReports.push(report);
        return;
      }
      this.selectedReports = this.selectedReports.filter((selectedReport) => selectedReport.id !== report.id);
    },

    toggleSidePanel() {
      // helps prevent page from scrolling when panel is open
      const body = document.querySelector('BODY');
      if (this.sidePanel) {
        // revert body to scrolling
        body.style.overflow = 'initial';
      } else {
        // restrict body from scrolling
        body.style.overflow = 'hidden';
      }

      this.sidePanel = !this.sidePanel;
    },

    resetSidePanel() {
      this.reportName = null;
      this.moduleId = null;
      this.selectedSurvey = null;
      this.selectedQuiz = null;
    },

    async popInitialData() {
      try {
        await Promise.all([this.getReports(), this.getWorkbookOptions()]);
      } catch (err) {
        console.warn(err);
      }
    },

    async getWorkbookOptions() {
      try {
        const workbookData = await ApiService.get(`${process.env.VUE_APP_API_URL}/report/workbooks`);
        this.moduleIdOptions = workbookData.data.data;
      } catch (err) {
        this.workbookDataError = true;
        console.warn(err);
      }
      this.workbookDataAvailable = true;
    },

    async getWorkbookAssociatedData() {
      try {
        // reset to null
        this.associatedDataAvailable = null;
        this.associatedDataError = null;

        const sessions = ApiService.get(
          `${process.env.VUE_APP_API_URL}/report/workbooks/${this.moduleId.workbookId}/sessions`,
        );
        const quizzes = ApiService.get(`${process.env.VUE_APP_API_URL}/quiz/workbook/${this.moduleId.workbookId}`);
        const surveys = ApiService.get(`${process.env.VUE_APP_API_URL}/survey/workbook/${this.moduleId.workbookId}`);

        const [sessionsData, quizzesData, surveysData] = await Promise.all([sessions, quizzes, surveys]);

        // setting sessions
        this.sessionOptions = [];
        sessionsData.data.data.forEach((session) => {
          this.sessionOptions.push({
            ...session,
            selected: null,
          });
        });

        // setting quizzes
        this.quizzesOptions = [];
        this.quizzesOptions = quizzesData.data.data;

        // setting surveys
        this.surveysOptions = [];
        this.surveysOptions = surveysData.data.data;

        this.associatedDataAvailable = true;
      } catch (err) {
        this.associatedDataError = true;
        this.createReportErrorMsg = 'Something went wrong, please try again';
        console.warn(err);
      }
    },

    async getReports() {
      try {
        // init loading
        this.reportDataAvailable = null;
        this.fetchingReportData = true;
        this.filterMenu = null;

        // configure date
        let startDate = null;
        let endDate = null;

        if (this.timeGenerated) {
          startDate = this.timeGenerated[0] ? new Date(this.timeGenerated[0]).toISOString().split('T')[0] : null;
          endDate = this.timeGenerated[1] ? new Date(this.timeGenerated[1]).toISOString().split('T')[0] : null;
        }

        const getReportsData = ApiService.get(`${process.env.VUE_APP_API_URL}/report`, {
          offset: this.offset,
          limit: this.displayCount,
          user: this.generatedBy,
          event: this.filteredModuleId,
          workbookTitle: this.filteredModuleTitle,
          startDate,
          endDate,
        });
        const getReportsFilterData = ApiService.get(`${process.env.VUE_APP_API_URL}/report/dropdown`);

        const [reportsData, reportsFilterData] = await Promise.all([getReportsData, getReportsFilterData]);

        this.filterMenuOptions = reportsFilterData.data.data;

        // add state for downloading
        reportsData.data.data.rows.map((report) => ({ ...report, downloadingReport: false }));

        this.pageCount = Math.ceil(reportsData.data.data.count / this.displayCount);
        this.reportsPages[this.activePage] = reportsData.data.data.rows;
        this.paginatedReports = this.reportsPages[this.activePage];

        this.reportDataAvailable = true;
      } catch (err) {
        // show error alert
        this.showAlertError('Something went wrong, please try again', true);
        console.warn(err);
      }
      this.fetchingReportData = null;
    },

    clearFilters() {
      this.generatedBy = null;
      this.filteredModuleId = null;
      this.filteredModuleTitle = null;
      this.timeGenerated = null;
      this.getReports();
    },

    async downloadReport(id, index) {
      try {
        this.resetAlerts();

        this.$set(this.paginatedReports[index], 'downloadingReport', true);

        const reportUrl = await ApiService.get(`${process.env.VUE_APP_API_URL}/report/${id}/download`);

        window.open(reportUrl.data.data.url);

        this.$set(this.paginatedReports[index], 'downloadingReport', null);

        this.showAlertSuccess('Report downloaded');
      } catch (err) {
        this.$set(this.paginatedReports[index], 'downloadingReport', null);
        this.showAlertError('Something went wrong, please try again');
        console.warn(err);
      }
    },

    promptDeleteReport(report) {
      this.deleteReportModal = true;
      this.deleteReportModalContent = {
        content: {
          title: report.reportTitle,
          ...report,
        },
      };
    },

    closeDeleteReportModal() {
      this.deleteReportModal = null;
    },

    async deleteReport() {
      try {
        // Use modal content value to obtain id to delete report
        await ApiService.delete(`${process.env.VUE_APP_API_URL}/report/${this.deleteReportModalContent.content.id}`);

        // close delete modal
        this.closeDeleteReportModal();

        // refetch data
        this.getReports();

        // success delete alert
        this.showAlertSuccess('Report deleted');
      } catch (err) {
        // close delete modal
        this.closeDeleteReportModal();

        // error alert
        this.showAlertError('Something went wrong, please try again');

        console.warn(err);
      }
    },

    validateCreateReport() {
      const sessions = this.sessionOptions.filter((session) => session.selected);
      const selectedSessionsIds = [];
      sessions.forEach((session) => {
        selectedSessionsIds.push(session.palmsSessionId);
      });

      if (!this.reportName || !this.moduleId || selectedSessionsIds.length === 0) {
        throw new Error('Ensure all required fields are completed.');
      }
    },

    async createReport(type, subType) {
      try {
        // remove error (specific to panel, not the view)
        this.createReportError = null;

        // scroll to top of panel
        const sidePanel = document.querySelector('.side-panel');
        sidePanel.scrollTo(0, 0);

        // validate
        this.validateCreateReport();

        // init loading
        this.creatingReport = true;

        const sessions = this.sessionOptions.filter((session) => session.selected);
        const selectedSessionsIds = [];
        sessions.forEach((session) => {
          selectedSessionsIds.push(session.palmsSessionId);
        });

        const selectedQuizzesId = [];
        const selectedSurveysId = [];

        if (type === 'quiz') {
          selectedQuizzesId.push(this.selectedQuiz.id);
        }

        if (type === 'survey') {
          selectedSurveysId.push(this.selectedSurvey.id);
        }

        await ApiService.post(`${process.env.VUE_APP_API_URL}/report`, {
          reportType: subType ? subType.type : type,
          workbookId: this.moduleId.workbookId,
          reportTitle: this.reportName,
          sessions: selectedSessionsIds,
          quizzes: selectedQuizzesId,
          surveys: selectedSurveysId,
        });

        // stop loading, reduce flash with set timeout
        setTimeout(async () => {
          this.creatingReport = null;

          // reset data in side panel
          this.resetSidePanel();

          // close side panel
          this.toggleSidePanel();

          // fetch new data
          this.getReports();

          // alert success
          this.showAlertSuccess('Report Created');
        }, 500);
      } catch (err) {
        this.createReportError = true;
        this.createReportErrorMsg = err.message;
        console.warn(err);
      }
    },

    async pageChange(page) {
      // Check if we already have page
      if (this.reportsPages[page]) {
        this.paginatedReports = this.reportsPages[page];
        this.activePage = page;
        return;
      }

      // Get new page
      this.offset = (page - 1) * this.displayCount;
      this.activePage = page;
      await this.getReports();
      this.paginatedReports = this.workbookPages[page];
    },
  },
};
</script>

<style lang="scss" scoped>
.side-panel-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.6);

  .side-panel {
    position: fixed;
    right: 0;
    left: initial;
    width: 100%;
    max-width: 785px;
    height: 100%;
    min-height: 100vh;
    padding: 36px 36px 76px 36px;
    overflow: scroll;
    background-color: #fff;

    .header-error {
      font-size: 24px;
    }

    .close-report {
      display: flex;
      align-items: center;
      margin-bottom: 36px;
      cursor: pointer;

      .arrow-red {
        width: 6px;
        height: 13px;
        margin-right: 6px;
        transform: rotate(180deg);
      }
    }

    h2 {
      margin-bottom: 16px;
      font-size: 36px;
      font-weight: 500;
    }

    .panel-summary {
      margin-bottom: 24px;
    }

    .required {
      color: #d5001c;
    }

    .report-column {
      display: flex;
      flex: 1;
      flex-direction: column;
    }

    .checkbox {
      display: flex;
      align-items: center;
    }

    .half-size {
      max-width: 348.5px;
    }

    .input {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;

      label {
        display: block;
        margin-bottom: 6px;
      }
    }

    .sessions-wrap {
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      .label {
        display: block;
        margin-bottom: 8px;
      }

      .session {
        display: flex;
        row-gap: 20px;

        .checkbox {
          align-self: start;
          margin-bottom: 0;
        }

        &-content {
          display: flex;
          flex-direction: column;
          row-gap: 10px;
          .field {
            color: $grey-light;
            .label {
              display: initial;
              color: #000;
            }
          }

          .date {
            display: flex;
            column-gap: 20px;
          }
        }
      }
    }

    hr {
      margin: 32px 0;
    }

    .report-type {
      display: flex;
      flex-direction: column;

      h3 {
        margin-bottom: 16px;
        font-size: 24px;
        font-weight: 500;
      }

      .data-options {
        display: flex;
        margin-top: 6px;
      }

      .porsche-button {
        align-self: flex-start;
        margin-top: 16px;
      }
    }

    .survey-data {
      .report-column {
        max-width: 237.67px;
      }
    }
  }
}

.reports {
  margin-top: 40px;

  h1 {
    font-size: 36px;
  }

  .reports-filter {
    position: relative;
    display: flex;
    gap: 10px;
    margin: 25px 0;

    .filter {
      position: relative;
      z-index: 99;
      display: flex;
      align-items: center;
      padding: 10px 12px;
      cursor: pointer;
      background-color: #fff;
      border: 1px solid #626669;
      .filter-icon {
        height: 16px;
        margin-left: 18px;
      }
    }

    .button-actions {
      display: flex;
      gap: 10px;
      margin-left: auto;
    }

    .filter-menu-active {
      border-bottom-color: transparent;
    }

    .filter-menu-wrap {
      position: absolute;
      top: 48px;
      left: 119px;
      z-index: 98;
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      width: 100%;
      max-width: 600px;
      padding: 24px 26px;
      background-color: #fff;
      border: 1px solid #626669;

      .generated-by,
      .date-picker {
        max-width: 265px;
      }

      .filter-menu {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .module-info {
          display: flex;
          gap: 16px;

          div {
            flex: 1;
          }
        }
      }

      .filter-actions {
        display: flex;
        gap: 16px;
      }
    }
  }

  .reports-table {
    margin-top: 40px;

    hr {
      margin: 16px 0;
    }

    .labels,
    .report {
      display: flex;
      gap: 16px;
      span {
        // &:nth-child(1) {
        //   display: flex;
        //   flex-basis: 5%;
        //   justify-content: center;
        // }
        &:nth-child(1) {
          flex-basis: 20%;
        }

        &:nth-child(2) {
          flex-basis: 14%;
        }

        &:nth-child(3) {
          flex-basis: 19%;
        }

        &:nth-child(4) {
          flex-basis: 10%;
        }

        &:nth-child(5) {
          flex-basis: 15%;
        }

        &:nth-child(6) {
          flex-basis: 10%;
        }

        &:nth-child(7) {
          flex-basis: 7%;
        }
      }
    }

    .labels {
      span {
        font-weight: 500;
      }
    }

    .report-wrap {
      position: relative;
      .report {
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .checkbox {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 0;

          .check-icon {
            path {
              fill: #f2f2f2;
            }
          }

          input:checked {
            .check-icon {
              path {
                fill: #f2f2f2;
              }
            }
          }
        }

        .actions {
          display: flex;
          gap: 8px;
          align-items: center;

          .download-icon-wrap {
            position: relative;
            display: flex;
            align-items: center;

            .download-icon {
              width: 24px;
              height: 17px;
              cursor: pointer;
            }
          }
          .delete-icon {
            width: 12px;
            height: 26px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
