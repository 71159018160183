<template>
  <div class="container vehicle-comparison-tool">
    <BreadCrumbs :trail="breadcrumbTrail" />
    <div class="header">
      <h1>Vehicle Comparison Tool</h1>
      <span>
        Last updated:
        {{ new Date(this.lastUpdated).toLocaleDateString() }},
        {{ new Date(this.lastUpdated).toLocaleTimeString('en-us', { hour: 'numeric', minute: 'numeric' }) }}
      </span>
    </div>
    <div class="comparison">
      <!-- SPECS -->
      <div class="grid">
        <span class="label"></span>
        <div class="input">
          <div class="image-wrap">
            <Car class="car-icon" v-if="!vehicleSlotOne || !vehicleSlotOne.awsIdentifier" />
            <img v-else-if="vehicleSlotOne.imageUrl" :src="vehicleSlotOne.imageUrl" alt="" />
            <img v-else src="../assets/images/ComparisonTool/comparison_tool_vehicle.png" alt="" />
          </div>
          <VueMultiselect
            v-model.lazy="vehicleModelOne"
            :options="vehicleModelOptions"
            label="name"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            placeholder="Select Vehicle"
          />
        </div>
        <div class="input">
          <div class="image-wrap">
            <Car class="car-icon" v-if="!vehicleSlotTwo || !vehicleSlotTwo.awsIdentifier" />
            <img v-else-if="vehicleSlotTwo.imageUrl" :src="vehicleSlotTwo.imageUrl" alt="" />
            <img v-else src="../assets/images/ComparisonTool/comparison_tool_vehicle.png" alt="" />
          </div>

          <VueMultiselect
            v-model.lazy="vehicleModelTwo"
            :options="vehicleModelOptions"
            label="name"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            placeholder="Select Vehicle"
          />
        </div>
        <div class="input">
          <div class="image-wrap">
            <Car class="car-icon" v-if="!vehicleSlotThree || !vehicleSlotThree.awsIdentifier" />
            <img v-else-if="vehicleSlotThree.imageUrl" :src="vehicleSlotThree.imageUrl" alt="" />
            <img v-else src="../assets/images/ComparisonTool/comparison_tool_vehicle.png" alt="" />
          </div>
          <VueMultiselect
            v-model.lazy="vehicleModelThree"
            :options="vehicleModelOptions"
            label="name"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            placeholder="Select Vehicle"
          />
        </div>
      </div>
      <div class="grid section-label">
        <span class="label">Specs</span>
      </div>
      <div class="grid">
        <span class="label">MSRP</span>
        <span>{{ this.vehicleSlotOne ? `$${this.vehicleSlotOne.MSRP}` : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? `$${this.vehicleSlotTwo.MSRP}` : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? `$${this.vehicleSlotThree.MSRP}` : '--' }}</span>
      </div>
      <!-- ENGINE -->
      <div class="grid section-label">
        <span class="label">Engine</span>
      </div>
      <div class="grid">
        <span class="label">Liters (L)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.engineLiters : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.engineLiters : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.engineLiters : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Cylinders</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.engineCylinders : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.engineCylinders : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.engineCylinders : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Total System Output (HP)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.engineTotalSystemOutputHP : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.engineTotalSystemOutputHP : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.engineTotalSystemOutputHP : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Output (HP)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.engineOutputHP : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.engineOutputHP : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.engineOutputHP : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">RPM</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.engineOutputRPM : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.engineOutputRPM : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.engineOutputRPM : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Torque (lb.-ft.)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.engineTorque : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.engineTorque : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.engineTorque : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">RPM</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.engineTorqueRPM : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.engineTorqueRPM : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.engineTorqueRPM : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Acceleration (0-60 MPH)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.engineAcceleration : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.engineAcceleration : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.engineAcceleration : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Top Track Speed (MPH)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.engineTopTrackSpeed : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.engineTopTrackSpeed : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.engineTopTrackSpeed : '--' }}</span>
      </div>

      <!-- TRANSMISSION -->
      <div class="grid section-label">
        <span class="label">Transmission</span>
      </div>
      <div class="grid">
        <span class="label">Transmission (Type)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.transmissionType : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.transmissionType : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.transmissionType : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Transmission (Speeds)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.transmissionSpeeds : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.transmissionSpeeds : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.transmissionSpeeds : '--' }}</span>
      </div>

      <!-- FUEL ECONOMY & RANGE -->
      <div class="grid section-label">
        <span class="label">ICE Fuel Economy Range</span>
      </div>
      <div class="grid">
        <span class="label">Fuel Economy City (MPG)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.fuelEconomyCity : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.fuelEconomyCity : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.fuelEconomyCity : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Fuel Economy Highway (MPG)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.fuelEconomyHighway : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.fuelEconomyHighway : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.fuelEconomyHighway : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Fuel Economy Combined (MPG)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.fuelEconomyCombined : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.fuelEconomyCombined : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.fuelEconomyCombined : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Fuel Capacity (gal.)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.fuelCapacity : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.fuelCapacity : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.fuelCapacity : '--' }}</span>
      </div>

      <!-- FUEL TANK -->
      <div class="grid section-label">
        <span class="label"> EV Specifications</span>
      </div>
      <div class="grid">
        <span class="label"> Hybrid/Electric Motor 1 Output (HP)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.HEMotor1Output : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.HEMotor1Output : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.HEMotor1Output : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Hybrid/Electric Motor 1 Torque (lb.-ft.)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.HEMotor1Torque : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.HEMotor1Torque : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.HEMotor1Torque : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Hybrid/Electric Motor 2 Output (HP)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.HEMotor2Output : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.HEMotor2Output : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.HEMotor2Output : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Hybrid/Electric Motor 2 Torque (lb.-ft.)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.HEMotor2Torque : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.HEMotor2Torque : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.HEMotor2Torque : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Hybrid/Electric Traction Battery Capacity (kWh)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.HETractionBatteryCapacity : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.HETractionBatteryCapacity : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.HETractionBatteryCapacity : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Hybrid/Electric Traction Battery Charge Time (hrs.) @120V</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.HETractionBatteryChargeTime120V : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.HETractionBatteryChargeTime120V : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.HETractionBatteryChargeTime120V : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Hybrid/Electric Traction Battery Charge Time (hrs.) @240V</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.HETractionBatteryChargeTime240V : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.HETractionBatteryChargeTime240V : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.HETractionBatteryChargeTime240V : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Hybrid/Electric Traction Battery Charge Time (hrs.) @50kW DC Fast Charge </span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.HETractionBatteryChargeTime50kW : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.HETractionBatteryChargeTime50kW : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.HETractionBatteryChargeTime50kW : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Hybrid/Electric Traction Battery All-Electric Range (miles)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.HETractionBatteryAllElectricRange : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.HETractionBatteryAllElectricRange : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.HETractionBatteryAllElectricRange : '--' }}</span>
      </div>

      <!-- EXTERIOR DIMENSIONS -->
      <div class="grid section-label">
        <span class="label">Exterior Dimensions</span>
      </div>
      <div class="grid">
        <span class="label">Wheelbase (“)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.exteriorWheelBase : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.exteriorWheelBase : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.exteriorWheelBase : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Length (“)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.exteriorLength : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.exteriorLength : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.exteriorLength : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Body Width (“)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.exteriorBodyWidth : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.exteriorBodyWidth : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.exteriorBodyWidth : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Body Height (“)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.exteriorBodyHeight : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.exteriorBodyHeight : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.exteriorBodyHeight : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Front Track (“)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.exteriorTrackFront : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.exteriorTrackFront : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.exteriorTrackFront : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Rear Track (“)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.exteriorTrackRear : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.exteriorTrackRear : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.exteriorTrackRear : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Turning Radius (‘)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.exteriorTurningRadius : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.exteriorTurningRadius : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.exteriorTurningRadius : '--' }}</span>
      </div>

      <!-- INTERIOR DIMENSIONS -->
      <div class="grid section-label">
        <span class="label">Interior Dimensions</span>
      </div>
      <div class="grid">
        <span class="label">Seats/Capacity</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.interiorSeatsCapacity : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.interiorSeatsCapacity : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.interiorSeatsCapacity : '--' }}</span>
      </div>

      <!-- CARGO AREA DIMENSIONS -->
      <div class="grid section-label">
        <span class="label">Cargo Area Dimensions</span>
      </div>
      <div class="grid">
        <span class="label">Maximum Cargo Volume (cu. ft.)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.maxCargoVolume : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.maxCargoVolume : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.maxCargoVolume : '--' }}</span>
      </div>

      <!-- SUSPENSION -->
      <div class="grid section-label">
        <span class="label">Suspension</span>
      </div>
      <div class="grid">
        <span class="label">Suspension, Front Spring Type</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.suspensionSpringTypeFront : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.suspensionSpringTypeFront : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.suspensionSpringTypeFront : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label"> Suspension, Rear Spring Type</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.suspensionSpringTypeRear : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.suspensionSpringTypeRear : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.suspensionSpringTypeRear : '--' }}</span>
      </div>

      <!-- BRAKES -->
      <div class="grid section-label">
        <span class="label">Brakes</span>
      </div>
      <div class="grid">
        <span class="label">Vented Discs</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.brakeVentedDiscs : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.brakeVentedDiscs : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.brakeVentedDiscs : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Front Diameter (inches)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.brakeDiameterFront : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.brakeDiameterFront : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.brakeDiameterFront : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Rear Diameter (inches)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.brakeDiameterRear : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.brakeDiameterRear : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.brakeDiameterRear : '--' }}</span>
      </div>

      <!-- WHEELS & TIRES -->
      <div class="grid section-label">
        <span class="label">Wheels & Tires</span>
      </div>
      <div class="grid">
        <span class="label">Wheels Front Diameter (“)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.wheelDiameterFront : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.wheelDiameterFront : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.wheelDiameterFront : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Wheels Rear Diameter (“)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.wheelDiameterRear : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.wheelDiameterRear : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.wheelDiameterRear : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Tires Front Width (mm)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.tireWidthFront : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.tireWidthFront : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.tireWidthFront : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Tires Front Aspect (mm)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.tireAspectFront : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.tireAspectFront : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.tireAspectFront : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Tires Rear Width (mm)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.tireWidthRear : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.tireWidthRear : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.tireWidthRear : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Tires Rear Aspect (mm)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.tireAspectRear : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.tireAspectRear : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.tireAspectRear : '--' }}</span>
      </div>

      <!-- TOWING -->
      <div class="grid section-label">
        <span class="label">Towing</span>
      </div>
      <div class="grid">
        <span class="label">Towing Capacity (lbs)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.towingCapacity : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.towingCapacity : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.towingCapacity : '--' }}</span>
      </div>

      <!-- WEIGHT INFORMATION -->
      <div class="grid section-label">
        <span class="label">Weight Information</span>
      </div>
      <div class="grid">
        <span class="label">Curb Weight (lbs)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.curbWeight : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.curbWeight : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.curbWeight : '--' }}</span>
      </div>

      <!-- WARRANTY -->
      <div class="grid section-label">
        <span class="label">Warranty</span>
      </div>
      <div class="grid">
        <span class="label">Months</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.warrantyMonths : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.warrantyMonths : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.warrantyMonths : '--' }}</span>
      </div>
      <hr />
      <div class="grid">
        <span class="label">Distance (mi.)</span>
        <span>{{ this.vehicleSlotOne ? this.vehicleSlotOne.warrantyDistance : '--' }}</span>
        <span>{{ this.vehicleSlotTwo ? this.vehicleSlotTwo.warrantyDistance : '--' }}</span>
        <span>{{ this.vehicleSlotThree ? this.vehicleSlotThree.warrantyDistance : '--' }}</span>
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
import ApiService from '@/utilities/ApiService';
import VueMultiselect from '@/components/VueMultiselect.vue';
import BreadCrumbs from '../components/BreadCrumbs.vue';
import Car from '../assets/images/ComparisonTool/car.svg';

export default {
  name: 'VehicleComparisonTool',
  components: { BreadCrumbs, VueMultiselect, Car },
  props: {},
  data() {
    return {
      lastUpdated: null,
      vehicleModelOne: null,
      vehicleModelTwo: null,
      vehicleModelThree: null,
      vehicleModelOptions: [],
      vehicleData: {},
      breadcrumbTrail: [
        { name: 'dashboard', route: 'dashboard' },
        { name: 'vehicle comparison tool', route: 'vehicle comparison tool' },
      ],
    };
  },
  created() {
    this.getVehicleData();
  },
  computed: {
    vehicleSlotOne() {
      return this.vehicleModelOne ? this.vehicleData[this.vehicleModelOne.id] : null;
    },
    vehicleSlotTwo() {
      return this.vehicleModelTwo ? this.vehicleData[this.vehicleModelTwo.id] : null;
    },
    vehicleSlotThree() {
      return this.vehicleModelThree ? this.vehicleData[this.vehicleModelThree.id] : null;
    },
  },
  watch: {},
  methods: {
    async getVehicleData() {
      try {
        const { data } = await ApiService.get(`${process.env.VUE_APP_API_URL}/vehicles`);
        const result = data.data;
        this.lastUpdated = result.lastUpdated;
        result.vehicles.forEach((vehicle) => {
          // convert object into key & pair
          const objectEntries = Object.entries(vehicle);

          // loop through pairs to check for empty strings, if empty add '--'
          objectEntries.forEach((item) => {
            if (item[1] === '') {
              item[1] = '--';
            }
          });

          // convert new key-value pair back into object
          const newVehicle = Object.fromEntries(objectEntries);

          // assign data
          this.vehicleData[vehicle.id] = newVehicle;
          this.vehicleModelOptions.push({
            id: newVehicle.id,
            name: `${newVehicle.make} ${newVehicle.model} ${newVehicle.year}`,
          });
        });
      } catch (err) {
        console.warn(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vehicle-comparison-tool {
  .breadcrumbs {
    margin-top: 24px;
  }

  .header {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 22px;

    span {
      margin-left: auto;
      font-size: 14px;
    }
  }

  .comparison {
    display: flex;
    flex-direction: column;
    margin-top: 32px;

    .section-label {
      padding: 13px 0;
      margin: 38px 0;
      background-color: #f2f2f2;

      .label {
        font-weight: 600;
      }
    }

    .grid {
      grid-template-columns: repeat(4, 1fr);
      column-gap: 60px;

      &:first-child {
        .label {
          align-items: flex-end;
          font-weight: 600;
        }
      }

      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .label {
        justify-content: flex-end;
        text-align: right;
      }

      .input {
        display: flex;
        flex-direction: column;
        .image-wrap {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 105px;
          margin-bottom: 20px;
        }
        img {
          height: 100%;
          object-fit: cover;
        }

        .car-icon {
          max-height: 79px;
        }
      }
    }

    hr {
      margin: 32px 0;
    }
  }
}
</style>
