<template>
  <div class="container audit-trail-view">
    <div class="view-wrap">
      <div class="view-heading">
        <h1>Audit Trail</h1>
        <transition-group name="progress-update">
          <span :key="1" class="status-msg error" v-show="error"> <Cancel class="cancel-icon" /> {{ statusMsg }} </span>
        </transition-group>
      </div>
      <div class="view-content">
        <div class="audit-filter">
          <div class="vue-multiselect display-count">
            <span class="label">Show:</span>
            <VueMultiselect
              v-model="displayCount"
              :searchable="false"
              :close-on-select="true"
              :show-labels="false"
              :allow-empty="false"
              :options="displayCountOptions"
              @input="getAuditData"
            />
          </div>
          <PorscheButton :link="true">
            <Arrow class="arrow-icon icon" />
            <span>Export CSV</span>
          </PorscheButton>
        </div>
        <div class="audit-trail-wrap">
          <div class="labels-wrap">
            <div class="labels">
              <span>User ID</span>
              <span>Last</span>
              <span>First</span>
              <span>Email</span>
              <span>Description of Change</span>
              <span>Time of Change</span>
            </div>
            <hr />
          </div>
          <div class="audit-trail-content">
            <Loading v-if="!dataAvailable" :type="'section'" />
            <div v-else class="audit-data-wrap">
              <div class="audit-data" v-for="(trail, index) in paginatedContentPage" :key="index">
                <div>
                  <span>{{ trail.userName }}</span>
                  <span>{{ trail.lastName }}</span>
                  <span>{{ trail.firstName }}</span>
                  <span>{{ trail.email }}</span>
                  <span>{{ trail.verb }}: {{ trail.primaryAffectedTable }}</span>
                  <span
                    >{{ new Date(trail.updatedAt).toLocaleDateString() }}
                    {{ new Date(trail.updatedAt).toLocaleTimeString() }}</span
                  >
                </div>
                <hr />
              </div>
              <Pagination :pageCount="pageCount" @page-change="pageChange" :currentPage="activePage" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/utilities/ApiService';
import Cancel from '../assets/images/Icons/cancel.svg';
import Arrow from '../assets/images/Icons/arrow_right_white.svg';
import VueMultiselect from '../components/VueMultiselect.vue';
import Pagination from '../components/Pagination.vue';
import PorscheButton from '../components/PorscheButton.vue';
import Loading from '../components/Loading.vue';

export default {
  name: 'audit-trail',
  components: { Arrow, Cancel, VueMultiselect, Pagination, PorscheButton, Loading },
  props: {},
  data() {
    return {
      error: null,
      statusMsg: null,
      displayCount: '10',
      offset: 0,
      displayCountOptions: ['10', '12', '14'],
      dataAvailable: null,
      contentPages: {},
      paginatedContentPage: [],
      pageCount: 103,
      activePage: 1,
    };
  },
  created() {
    this.getAuditData();
  },
  computed: {},
  watch: {},
  methods: {
    HTTPError(msg) {
      this.error = true;
      this.statusMsg = msg;
      setTimeout(() => {
        this.error = false;
      }, 5000);
    },

    async getAuditData() {
      try {
        const auditData = await ApiService.get(
          `${process.env.VUE_APP_API_URL}/audit-record?limit=${this.displayCount}&offset=${this.offset}`,
        );
        this.pageCount = Math.ceil(auditData.data.data.count / this.displayCount);
        this.contentPages[this.activePage] = auditData.data.data.rows;
        this.paginatedContentPage = this.contentPages[this.activePage];
        this.dataAvailable = true;
      } catch (error) {
        this.HTTPError(error);
        console.warn(error.message);
        this.dataAvailable = true;
      }
    },

    async pageChange(page) {
      this.dataAvailable = false;
      if (this.contentPages[page]) {
        this.paginatedContentPage = this.contentPages[page];
        this.activePage = page;
        this.dataAvailable = true;
        return;
      }

      // Get new page
      try {
        this.offset = (page - 1) * this.displayCount;
        this.activePage = page;
        await this.getAuditData();
        this.paginatedContentPage = this.contentPages[page];
      } catch (error) {
        this.HTTPError(error);
        console.warn(error.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.audit-trail-view {
  margin-top: 40px;

  .view-content {
    .audit-filter {
      display: flex;
      margin: 25px 0;

      .display-count {
        width: 109px;
        margin-bottom: 0;
        .multiselect__tags {
          padding: 12px 20px 12px 52px;
        }

        .label {
          position: absolute;
          top: 12px;
          left: 12px;
          z-index: 99;
          color: #000;
        }
      }

      .porsche-button {
        margin-left: auto;
      }
    }

    .audit-trail-wrap {
      position: relative;

      hr {
        margin: 16px 0;
      }

      .labels,
      .audit-data div {
        display: flex;
        gap: 32px;
        span {
          flex-basis: 8%;
          &:nth-child(4) {
            flex-basis: 25%;
          }
          &:nth-child(5) {
            flex-basis: 30%;
          }
          &:last-child {
            flex-basis: 20%;
          }
        }
      }

      .labels {
        span {
          font-weight: 500;
        }
      }

      .audit-trail-content {
        position: relative;

        .audit-data {
          span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
</style>
