<template>
  <transition name="alert">
    <span
      class="status-msg success"
      :class="{ inline: type === 'inline', block: type === 'block' }"
      v-show="alertSuccess"
    >
      <Check class="check-icon" />
      <slot name="alert-message" />
    </span>
  </transition>
</template>

<script>
import Check from '@/assets/images/Icons/check.svg';

export default {
  name: 'alert-success',
  components: { Check },
  props: { alertSuccess: Boolean, type: String },
  data() {
    return {};
  },
  created() {},
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.alert-enter-active {
  transition: 0.3s ease all;
}

.alert-enter {
  opacity: 0;
}

.status-msg {
  display: flex;
  align-items: center;
  margin-left: 0;
  font-size: 16px;

  .check-icon {
    width: auto;
    height: 16px;
    margin-right: 10px;
  }
}

.success {
  color: $green;
}

.inline {
  margin-left: 24px;
}

.block {
  margin-bottom: 16px;
}
</style>
