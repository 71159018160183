<template>
  <div v-if="modalType === 'delete'" class="modal-wrap">
    <div class="modal">
      <h1 v-if="contentType === 'dashboard'">Are you sure you wish to delete this {{ modalContent.title }}?</h1>
      <h1 v-else-if="moduleAsset">WARNING</h1>
      <h1 v-else-if="sectionAsset">Are you sure you wish to delete this {{ sectionAsset.type }}?</h1>
      <h1 v-else-if="workbook">Are you sure you wish to delete {{ workbook.title }}?</h1>
      <h1 v-else>Are you sure you wish to delete {{ modalContent.content.title }}?</h1>

      <div class="input">
        <p v-if="moduleAsset">
          Deleting this {{ moduleAsset.module_asset.type }} will result in the loss of all data and results from this
          {{ moduleAsset.module_asset.type }}. Once deleted, this data cannot be recovered. Are you sure you wish to
          delete this {{ moduleAsset.module_asset.type }}?
        </p>
        <p>If so, please type "DELETE" in the box below</p>
        <input type="text" v-model="deleteText" />
      </div>

      <span class="disclaimer">This action cannot be undone.</span>

      <div class="actions">
        <PorscheButton v-if="modalContent" @click="deleteView" :class="{ inactive: deleteText !== 'DELETE' }">
          <Arrow class="arrow-icon icon" />
          <span>Confirm delete</span>
        </PorscheButton>
        <PorscheButton v-else @click="deleteObject" :class="{ inactive: deleteText !== 'DELETE' }">
          <Arrow class="arrow-icon icon" />
          <span v-if="sectionAsset">Delete {{ sectionAsset.type }}</span>
          <span v-else-if="moduleAsset">Delete {{ moduleAsset.module_asset.type }}</span>
          <span v-else>Confirm delete</span>
        </PorscheButton>
        <PorscheButton :light="true" @click="closeModal">
          <Arrow class="arrow-icon icon" />
          <span>Nevermind, cancel</span>
        </PorscheButton>
      </div>
    </div>
  </div>
  <div v-else-if="modalType === 'confirm'" class="modal-wrap">
    <div class="modal confirm">
      <h1>{{ modalContent.title }}</h1>
      <p>{{ modalContent.message }}</p>
      <div class="actions">
        <PorscheButton @click="deleteObject">
          <Arrow class="arrow-icon icon" />
          <span>Continue</span>
        </PorscheButton>
        <PorscheButton :light="true" @click="closeModal">
          <Arrow class="arrow-icon icon" />
          <span>Nevermind, cancel</span>
        </PorscheButton>
      </div>
    </div>
  </div>
  <div v-else-if="modalType === 'dirty'" class="modal-wrap">
    <div class="modal">
      <h1>Unsaved changes, continue?</h1>
      <h2>This will delete all unsaved changes.</h2>

      <div class="actions">
        <PorscheButton @click="continueView">
          <Arrow class="arrow-icon icon" />
          <span>Continue</span>
        </PorscheButton>
        <PorscheButton :light="true" @click="closeModal">
          <Arrow class="arrow-icon icon" />
          <span>Nevermind, cancel</span>
        </PorscheButton>
      </div>
    </div>
  </div>
  <div v-else-if="modalType === 'video'" class="modal-wrap">
    <div class="modal video">
      <h1>{{ modalContent.title }}</h1>
      <video controls :src="modalContent.video"></video>
      <div class="actions">
        <PorscheButton :light="true" @click="closeModal">
          <Arrow class="arrow-icon icon" />
          <span>Close</span>
        </PorscheButton>
      </div>
    </div>
  </div>
  <div v-else-if="modalType === 'image'" class="modal-wrap">
    <div class="modal image">
      <h1 v-if="modalContent.title">{{ modalContent.title }}</h1>
      <img :src="modalContent.image" alt="" />
      <div class="actions">
        <PorscheButton :light="true" @click="closeModal">
          <Arrow class="arrow-icon icon" />
          <span>Close</span>
        </PorscheButton>
      </div>
    </div>
  </div>
  <div v-else-if="modalType === 'popup'" class="modal-wrap">
    <div class="modal popup">
      <h1 v-if="modalContent.title">{{ modalContent.title }}</h1>
      <p v-if="modalContent.message">{{ modalContent.message }}</p>
      <div class="actions">
        <PorscheButton :light="true" @click="closeModal">
          <Arrow class="arrow-icon icon" />
          <span>Close</span>
        </PorscheButton>
      </div>
    </div>
  </div>
  <div v-else-if="modalType === 'access-code'" class="access-code modal-wrap">
    <div class="modal">
      <h1>Access Locked</h1>
      <div class="input">
        <p v-if="accessCodeModalContent.unlockTime && accessCodeModalContent.accessCode">
          This module is locked until {{ new Date(accessCodeModalContent.unlockTime).toLocaleDateString() }}. Enter
          Access Code to proceed.
        </p>
        <p v-else-if="accessCodeModalContent.unlockTime">
          This module is locked until {{ new Date(accessCodeModalContent.unlockTime).toLocaleDateString() }}. Access
          will be granted automatically.
        </p>
        <p v-else>Enter Access Code</p>
        <input
          v-if="accessCodeModalContent.accessCode"
          type="text"
          v-model="accessCode"
          placeholder="Enter 6-digit access code"
        />
      </div>
      <div>
        <!-- Module date locked, no access code option -->
        <PorscheButton
          v-if="accessCodeModalContent.unlockTime && !accessCodeModalContent.accessCode"
          :light="true"
          @click="closeModal"
        >
          <Arrow class="arrow-icon icon" />
          <span>Go back</span>
        </PorscheButton>

        <!-- Module can be unlocked with access code -->
        <div v-else class="actions">
          <PorscheButton @click="accessCodeDone">
            <Arrow class="arrow-icon icon" />
            <span>Done</span>
          </PorscheButton>
          <PorscheButton :light="true" @click="closeModal">
            <Arrow class="arrow-icon icon" />
            <span>Cancel</span>
          </PorscheButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from '@/assets/images/Icons/arrow_right_white.svg';
import PorscheButton from './PorscheButton.vue';

export default {
  name: 'modal',
  components: { PorscheButton, Arrow },
  props: [
    'modalType',
    'contentType',
    'sectionAsset',
    'moduleAsset',
    'workbook',
    'modalContent',
    'accessCodeModalContent',
  ],
  data() {
    return {
      deleteText: null,
      accessCode: '',
    };
  },
  created() {
    // Restrict body from scrolling
    const body = document.querySelector('BODY');
    body.style.overflow = 'hidden';
  },
  beforeDestroy() {
    // revert body to scrolling
    const body = document.querySelector('BODY');
    body.style.overflow = 'initial';
  },
  computed: {},
  watch: {},
  methods: {
    closeModal() {
      this.$emit('close-modal');
    },

    deleteObject() {
      this.$emit('delete');
    },

    deleteView() {
      this.$emit('delete-view');
    },

    continueView() {
      this.$emit('continue', this.modalContent, this.modalContent, this.modalContent);
    },

    accessCodeDone() {
      this.$emit('done', this.accessCode);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 0 10px;
  overflow-y: scroll;
  background-color: rgb(0, 0, 0, 0.5);

  .modal {
    width: 100%;
    max-width: 540px;
    padding: 32px 42px;
    margin-top: 180px;
    background-color: #fff;

    h1 {
      font-size: 24px;
      font-weight: 500;
    }

    h2 {
      margin-top: 8px;
      font-size: 15px;
      font-weight: 400;
    }

    .input {
      margin-top: 30px;

      p {
        margin-bottom: 6px;
        font-size: 18px;
      }

      input {
        width: 100%;
        padding: 12px;
        font-size: 16px;
        border: 1px solid #626669;
      }
    }

    .disclaimer {
      display: block;
      margin-top: 45px;
    }

    .actions {
      display: flex;
      gap: 8px;
      margin-top: 16px;

      .inactive {
        pointer-events: none;
        background-color: $grey-extra-light;
      }
    }
  }

  .video,
  .image {
    max-width: 800px;
    video,
    img {
      width: 100%;
      height: auto;
      margin-top: 16px;
      box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    }
  }

  .confirm,
  .popup {
    h1,
    p {
      margin-bottom: 16px;
    }
  }
}

.pop-up {
  .modal {
    max-width: 500px;
  }
}

.access-code {
  .modal {
    max-width: 568px;
    padding: 32px 24px;
    .input {
      p {
        margin-bottom: 10px;
        font-size: 16px;
      }
    }
  }
}
</style>
