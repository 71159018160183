<template>
  <div class="container page-not-found">
    <h1>Sorry, this page can't be found.</h1>
    <PorscheButton @click="goToRoute">
      <ArrowIcon class="arrow-icon icon" />
      <span>Return to Workbooks</span>
    </PorscheButton>
  </div>
</template>

<script>
import ArrowIcon from '@/assets/images/Icons/arrow_right_white.svg';
import PorscheButton from '@/components/PorscheButton.vue';

export default {
  name: 'page-not-found',
  components: { PorscheButton, ArrowIcon },
  props: {},
  data() {
    return {};
  },
  created() {},
  computed: {},
  watch: {},
  methods: {
    goToRoute() {
      this.$router.push({ name: 'workbooks' });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;

  h1 {
    margin-bottom: 16px;
  }
}
</style>
