<template>
  <div class="training-navigation" v-if="workbookId">
    <ul>
      <router-link
        :class="{ inactive: this.workbookId.length <= 11 }"
        class="nav-link"
        :to="{ name: 'workbook setup', params: { workbookId: workbookId } }"
        >Workbook</router-link
      >
      <router-link
        :class="{ inactive: this.workbookId.length <= 11 }"
        class="nav-link"
        :to="{ name: 'users', params: { workbookId: workbookId } }"
        >Users</router-link
      >
      <router-link
        :class="{ inactive: this.workbookId.length <= 11 }"
        class="nav-link"
        :to="{ name: 'quiz results', params: { workbookId: workbookId } }"
        >Quiz Results</router-link
      >
      <router-link
        :class="{ inactive: this.workbookId.length <= 11 }"
        class="nav-link"
        :to="{ name: 'survey results', params: { workbookId: workbookId } }"
        >Survey Results</router-link
      >
    </ul>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState } = createNamespacedHelpers('workbookBuilder');

export default {
  name: 'trainingNavigation',
  components: {},
  props: {},
  data() {
    return {
      workbookActive: null,
      usersAcive: null,
      quizActive: null,
      surveyActive: null,
      workbookId: null,
    };
  },
  created() {
    this.workbookId = this.$route.params.workbookId;
  },
  watch: {
    $route() {
      this.workbookId = this.$route.params.workbookId;
    },
  },
  computed: {
    ...mapState(['workbook']),
  },
  methods: {
    resetRoutes() {
      this.workbookActive = false;
      this.usersAcive = false;
      this.quizActive = false;
      this.surveyActive = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.training-navigation {
  margin-top: 32px;
  ul {
    display: flex;
    border-bottom: 2px solid #c9cacb;

    .nav-link {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }

    .inactive {
      color: $grey-light;
      pointer-events: none;
    }
  }
}
</style>
