<template>
  <div>
    <Modal modalType="image" v-if="imageModal" :modalContent="mediaModalContent" @close-modal="toggleMediaModal" />
    <Modal modalType="video" v-if="videoModal" :modalContent="mediaModalContent" @close-modal="toggleMediaModal" />

    <div class="hotspot-img">
      <img :src="hotspotImage" alt="" class="asset-image" />
      <div class="hotspot-pins">
        <div
          class="pin"
          v-for="(pin, index) in pins"
          :style="{ left: `${pin.placementX}%`, top: `${pin.placementY}%` }"
          :key="pin.id"
        >
          <span @click="updateActivePin(pin, index)" class="pin-circle">{{ index + 1 }}</span>
        </div>
      </div>
    </div>

    <div v-if="activePin" class="hotspot-pin">
      <span class="pin-circle">
        {{ activePin.index + 1 }}
      </span>
      <div class="pin-content">
        <p>{{ activePin.pin.text }}</p>
        <PorscheButton v-if="activePin.pin.type !== 'Text'" :light="true" @click="toggleMediaModal(activePin.pin)">
          <Arrow class="arrow-icon icon" />
          <span v-if="activePin.pin.type === 'Image'">View Image</span>
          <span v-else>View Video</span>
        </PorscheButton>
      </div>
    </div>
  </div>
</template>

<script>
import PorscheButton from '@/components/PorscheButton.vue';
import Modal from '@/components/Modal.vue';

export default {
  name: 'base-hotspot',
  components: {
    PorscheButton,
    Modal,
  },
  props: {
    hotspotImage: {
      type: String,
      required: true,
    },
    pins: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      imageModal: null,
      videoModal: null,
      mediaModalContent: null,
      activePin: null,
    };
  },
  created() {},
  computed: {},
  watch: {},
  methods: {
    updateActivePin(pin, index) {
      if (this.activePin) {
        if (index === this.activePin.index) {
          this.activePin = null;
          return;
        }
      }

      this.activePin = {
        pin,
        index,
      };
    },

    toggleMediaModal(pin) {
      if (pin) {
        if (pin.type === 'Image') {
          this.imageModal = true;
        } else {
          this.videoModal = true;
        }

        this.mediaModalContent = {
          title: null,
          image: pin.hotspotDisplayLink,
          video: pin.hotspotDisplayLink,
        };
        return;
      }

      this.imageModal = null;
      this.videoModal = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.hotspot-img {
  position: relative;
  max-width: 800px;

  .hotspot-pins {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    .pin {
      position: absolute;
    }
  }
}

.pin-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 8px #0003;
}

.hotspot-pin {
  display: flex;
  column-gap: 16px;
  padding: 0 2px;
  margin: 12px 0 16px;

  .pin-content {
    flex: 1;
    .porsche-button {
      padding: 6px 12px 6px 8px;
      margin-top: 6px;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
</style>
