<template>
  <div v-if="userInformation.authenticated" id="app">
    <Navigation v-if="!isTablet && $route.query.preview !== 'true'" />
    <MobileNavigation v-if="isTablet && !$route.query.preview !== 'true'" />

    <Search v-if="userInformation.searchActive" />
    <router-view v-show="!userInformation.searchActive" class="router-view" />

    <FooterNavigation v-if="!workbookBuilder.workbookPreviewMode" />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import Navigation from './components/Navigation.vue';
import MobileNavigation from './components/MobileNavigation.vue';
import FooterNavigation from './components/FooterNavigation.vue';
import Search from './components/Search.vue';

export default {
  name: 'app',
  components: {
    Navigation,
    MobileNavigation,
    FooterNavigation,
    Search,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      userInformation: (state) => state.userInformation,
      workbookBuilder: (state) => state.workbookBuilder,
    }),
    UserTypeSwitchTablet() {
      return this.$mq === 'tablet' || this.$mq === 'mobile';
    },

    isTablet() {
      return this.$mq === 'tablet' || this.$mq === 'mobile';
    },
  },
  methods: {
    ...mapMutations('userInformation', ['SET_USER_INFO']),
  },
  created() {},
};
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';
@import '@/assets/scss/utility-styles.scss';
@import '@/assets/scss/font-family.scss';

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.router-view {
  height: 100%;
}

// Date Input
.date-picker {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  label {
    margin-bottom: 6px;
  }

  .prime-cal {
    position: relative;

    .cal-icon {
      position: absolute;
      top: 16px;
      left: 12px;
      width: 17px;
      height: auto;
    }

    .clock-icon {
      position: absolute;
      top: 18px;
      left: 12px;
      width: 17px;
      height: auto;

      path {
        fill: #000;
      }
    }

    input {
      width: 100%;
      min-height: 49px;
      padding: 12px 8px 12px 40px;
      font-family: 'ProscheNext', sans-serif;
      font-size: 16px;
      line-height: initial;
      color: #000;
      border: 1px solid #626669;
      border-radius: 0;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}

// View / Page Defaults
.view-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 22px;

  .view-heading {
    display: flex;
    align-items: center;

    h1 {
      font-size: 36px;
      font-weight: 500;
      line-height: 1;
    }
  }

  .view-filter {
    position: relative;
    display: flex;
    margin: 25px 0 40px;

    .filter-options,
    .filter-options-mobile {
      z-index: 99;
      display: flex;
      flex: 1;
      gap: 10px;

      .filtered-options {
        display: flex;
        gap: 8px;
        align-items: center;
        .tag {
          display: flex;
          align-items: center;
          padding: 4px 8px;
          font-size: 12px;
          background-color: #e3e4e5;
          border-radius: 2px;
          .icon {
            height: 16px;
            margin-right: 7px;
            cursor: pointer;
          }
        }
      }
    }

    .filter-options-mobile {
      flex-direction: column;
      .filter {
        .filter-icon {
          margin-left: auto;
        }
      }
    }

    .filter {
      position: relative;
      z-index: 99;
      display: flex;
      align-items: center;
      padding: 10px 12px;
      cursor: pointer;
      background-color: #fff;
      border: 1px solid #626669;

      .filter-icon {
        height: 16px;
        margin-left: 18px;
      }
    }

    .filter-menu {
      position: absolute;
      z-index: 1000;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 24px 26px;
      background-color: #fff;
      border: 1px solid #626669;

      .porsche-button {
        align-self: flex-start;
      }

      .filter-actions {
        display: flex;
        column-gap: 16px;
      }
    }

    .filter-menu-active {
      border-bottom-color: transparent;
    }
  }
}

// Side Panel Defaults
.side-panel-wrap,
.side-panel {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.6);

  .side-panel,
  .side-panel-content {
    position: fixed;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 36px 36px 76px 36px;
    overflow: scroll;
    background-color: #fff;

    .panel-row {
      display: flex;
      gap: 16px;

      .vue-multiselect {
        flex: 1;
      }
    }

    .close-panel,
    .panel-close {
      display: flex;
      align-items: center;
      margin-bottom: 36px;
      cursor: pointer;

      .arrow-icon {
        width: 6px;
        height: 13px;
        margin-right: 6px;
      }
    }

    h1 {
      margin-bottom: 14px;
      font-size: 24px;
    }

    label,
    .label {
      display: flex;
      margin-bottom: 6px;
    }

    .input,
    .panel-input {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;

      input {
        width: 100%;
        padding: 12px;
        font-size: 16px;
        border: 1px solid #626669;
      }
    }

    hr {
      margin: 36px 0;
    }

    .disclaimer {
      margin-top: 4px;
      font-size: 12px;
      color: #4a4a4a;
    }
  }
}

.dashboard {
  .input,
  .textarea {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    label {
      margin-bottom: 6px;
    }

    input,
    select,
    textarea {
      width: 100%;
      padding: 12px;
      font-size: 16px;
      background-color: #fff;
      border: 1px solid #626669;
      &:focus {
        outline: none;
      }
    }
  }

  hr {
    margin: 36px 0;
    background-color: #c9cacb;
  }
}
</style>
