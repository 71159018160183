<template>
  <div class="survey-q question">
    <div class="flex reorder">
      <Dots class="dots-icon" />
      <span>Click and drag to reorder</span>
    </div>
    <div class="question-heading flex">
      <p v-if="surveyObj.question">
        {{ surveyObj.question }}
      </p>
      <p class="placeholder" v-else>Question</p>
      <ArrowDown @click="toggleQuestion" :class="{ 'arrow-active': visible }" class="chevron" />
    </div>
    <div class="question-content" v-show="visible">
      <BaseInput
        v-model="surveyObj.question"
        :lazy="true"
        :hasError="error && surveyObj.question === ''"
        label="Question"
        type="text"
      />

      <div class="answer-type flex">
        <div class="vue-multiselect">
          <label for="moduleID">Answer Type</label>
          <Multiselect
            :allow-empty="false"
            class="single"
            :class="{ 'invalid-field': error && !surveyObj.questionTypeId }"
            @input="questionTypeChange(questionTypeObj)"
            v-model="questionTypeObj"
            :options="surveyQuestionTypes"
            track-by="id"
            label="type"
            :searchable="false"
            :close-on-select="true"
            :show-labels="false"
            placeholder="Select Answer Type"
          ></Multiselect>
          <ArrowDownMultiselect class="select-icon" />
        </div>

        <div v-if="surveyObj.questionTypeId === this.keySurveyQuestionTypes['Dropdown Selection'].id" class="input">
          <label for="answer-options">Number of Answer Options</label>
          <input
            type="number"
            min="1"
            max="6"
            @change="dropDownChange"
            id="answer-option"
            placeholder="Answer Options"
            v-model.lazy="surveyObj.dropDownCount"
          />
        </div>

        <template v-if="surveyObj.questionTypeId === this.keySurveyQuestionTypes['Slider'].id">
          <div class="slider-options">
            <BaseInput
              v-model="surveyObj.options[0].text"
              :lazy="true"
              :hasError="error && surveyObj.options[0].text === ''"
              label="Start"
              placeholder="Numerical"
              type="number"
            />
            <BaseInput
              v-model="surveyObj.options[1].text"
              :lazy="true"
              :hasError="error && surveyObj.options[1].text === ''"
              label="Increment"
              placeholder="Numerical"
              type="number"
            />
            <BaseInput
              v-model="surveyObj.options[2].text"
              :lazy="true"
              :hasError="error && surveyObj.options[2].text === ''"
              label="End"
              placeholder="Numerical"
              type="number"
            />
          </div>
        </template>

        <div class="checkbox">
          <input type="checkbox" v-model="surveyObj.isRequired" />
          <span>This question is required</span>
          <Check v-show="surveyObj.isRequired" class="check-icon" />
        </div>
      </div>

      <!-- Answers -->
      <div v-if="surveyObj.questionTypeId" class="answers">
        <!-- Description Text -->
        <template>
          <span
            v-if="surveyObj.questionTypeId === this.keySurveyQuestionTypes['Single-line response'].id"
            class="a-span-sm"
          >
            Single-line responses allow for a maximum of 140 characters.
          </span>

          <span v-if="surveyObj.questionTypeId === this.keySurveyQuestionTypes['Slider'].id" class="a-span-sm">
            Displays a slider for the learner to select a value from a scale defined by the fields above.
          </span>

          <span v-else-if="surveyObj.questionTypeId === this.keySurveyQuestionTypes['Dropdown Selection'].id"
            >Answer Options
          </span>

          <span v-else-if="surveyObj.questionTypeId === this.keySurveyQuestionTypes['Yes/No'].id" class="a-span-sm">
            Yes/No response allow for a selection of yes or no.
          </span>

          <span
            v-else-if="surveyObj.questionTypeId === this.keySurveyQuestionTypes['Multi-line response'].id"
            class="a-span-sm"
            >Multi-line response allow for a maximum of 750 characters.
          </span>

          <span v-else>Add response options</span>
        </template>

        <!-- Answer Options -->
        <template>
          <!-- Checkboxes -->
          <div
            v-if="surveyObj.questionTypeId === this.keySurveyQuestionTypes['Checkboxes'].id"
            class="multiple-options"
          >
            <div v-for="(option, index) in surveyObj.options" :key="index" class="option">
              <input
                type="text"
                :class="{ 'invalid-field': error && option.text === '' }"
                v-model.lazy="option.text"
                :placeholder="`Option ${index + 1}`"
              />
            </div>
            <div @click="addOption" class="add-option">
              <Add class="add-icon" />
              <span>Add Option</span>
            </div>
          </div>

          <!-- Multiple Choice -->
          <div
            v-if="surveyObj.questionTypeId === this.keySurveyQuestionTypes['Multiple Choice'].id"
            class="multiple-options"
          >
            <div v-for="(option, index) in surveyObj.options" :key="index" class="option">
              <input
                type="text"
                :class="{ 'invalid-field': error && option.text === '' }"
                v-model.lazy="option.text"
                :placeholder="`Option ${index + 1}`"
              />
            </div>
            <div @click="addOption" class="add-option">
              <Add class="add-icon" />
              <span>Add Option</span>
            </div>
          </div>

          <!-- Dropdown -->
          <div
            v-if="surveyObj.questionTypeId === this.keySurveyQuestionTypes['Dropdown Selection'].id"
            class="dropdown"
          >
            <div v-for="(option, index) in surveyObj.options" :key="index" class="d-input">
              <input
                type="text"
                :class="{ 'invalid-field': error && option.text === '' }"
                v-model.lazy="option.text"
                :placeholder="`Option ${index + 1}`"
              />
            </div>
          </div>

          <!-- These three options don't require any markup  -->
          <!-- Yes/No -->
          <!-- Single Line Res -->
          <!-- Multiline Res -->
          <!-- Slider -->
        </template>
      </div>

      <div class="asset-actions">
        <div @click="toggleQuestion" class="a-action">
          <Hide class="hide-icon" />
          <span>Hide question</span>
        </div>
        <div @click="removeQuestion(surveyObj.questionId)" class="a-action">
          <Delete class="delete-icon" />
          <span>Delete question</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from 'uid';
import Multiselect from 'vue-multiselect';
import BaseInput from '@/components/BaseInput.vue';
import Hide from '@/assets/images/Icons/view-off.svg';
import Delete from '@/assets/images/Icons/delete.svg';
import Check from '@/assets/images/Icons/check.svg';
import Dots from '@/assets/images/Icons/dots_vertical.svg';
import Add from '@/assets/images/Icons/add.svg';
import ArrowDown from '@/assets/images/Icons/arrow_right_red.svg';
import ArrowDownMultiselect from '@/assets/images/Icons/arrow_left.svg';

export default {
  name: 'survey-question',
  components: { Dots, BaseInput, ArrowDown, Multiselect, ArrowDownMultiselect, Check, Hide, Delete, Add },
  props: {
    surveyId: {
      type: String,
      default: '',
    },
    question: {
      type: String,
      default: '',
    },
    questionTypeId: {
      type: [Boolean, String],
      default: '',
    },
    isRequired: {
      type: [Boolean, String],
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    surveyQuestionTypes: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      surveyObj: {
        id: this.id,
        question: this.question,
        questionTypeId: this.questionTypeId ? this.questionTypeId : null,
        isRequired: this.isRequired,
        options: this.options,
        dropDownCount: this.options.length > 0 ? this.options.length : 1,
      },
      keySurveyQuestionTypes: {},
      questionTypeObj: null,
      questionContent: null,
    };
  },
  created() {
    this.getQuestionTypes();
  },
  computed: {},
  watch: {
    surveyObj: {
      deep: true,
      handler() {
        const object = {
          ...this.surveyObj,
          visible: this.visible,
          error: this.error,
        };
        this.$emit('input', object, this.index);
      },
    },
  },
  methods: {
    // toggles asset content view
    toggleQuestion() {
      this.$emit('toggleQuestion', this.index);
    },

    getQuestionTypes() {
      this.surveyQuestionTypes.forEach((type) => {
        this.keySurveyQuestionTypes[type.type] = type;
      });
      this.questionTypeObj = this.surveyQuestionTypes.filter((type) => type.id === this.questionTypeId);
    },

    questionTypeChange(questionTypeObj) {
      this.surveyObj.options = [];
      this.surveyObj.questionTypeId = questionTypeObj.id;

      if (
        questionTypeObj.id === this.keySurveyQuestionTypes.Checkboxes.id ||
        questionTypeObj.id === this.keySurveyQuestionTypes['Multiple Choice'].id
      ) {
        this.addOption();
        return;
      }

      if (questionTypeObj.id === this.keySurveyQuestionTypes['Dropdown Selection'].id) {
        this.dropDownChange();
        return;
      }

      // set slider options
      if (questionTypeObj.id === this.keySurveyQuestionTypes.Slider.id) {
        // slider has 3 options to configure
        for (let i = 0; i <= 2; i += 1) {
          this.surveyObj.options.push({
            id: uid(),
            text: '',
          });
        }
        return;
      }

      if (questionTypeObj.id === this.keySurveyQuestionTypes['Yes/No'].id) {
        this.surveyObj.options.push(
          {
            id: uid(),
            text: 'Yes',
          },
          {
            id: uid(),
            text: 'No',
          },
        );
      }
    },

    removeQuestion(id) {
      this.$emit('removequestion', id);
    },

    addOption() {
      if (this.surveyObj.options.length < 6) {
        this.surveyObj.options.push({
          id: uid(),
          text: '',
        });
      }
    },

    dropDownChange() {
      if (this.surveyObj.options.length < 6) {
        const localOptions = this.surveyObj.options;
        this.surveyObj.options = [];
        for (let i = 0; i < this.surveyObj.dropDownCount; i += 1) {
          this.surveyObj.options.push({
            id: uid(),
            text: localOptions[i] ? localOptions[i].text : '',
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/survey-quiz.scss';
.question {
  .answer-type {
    flex-wrap: wrap;
    gap: 16px;
    align-items: flex-end;
    margin-bottom: 8px;

    .vue-multiselect {
      margin-bottom: 0;
    }

    .input {
      flex: initial;
      margin-bottom: 0;
    }

    .single {
      width: 200px;
    }

    .checkbox {
      padding-bottom: 14px !important;
      margin-bottom: 0;
    }

    .slider-options {
      display: flex;
      gap: 8px;
    }
  }

  .answers {
    input[type='radio'] {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border: 1px solid $grey;
      border-radius: 50%;
      -webkit-appearance: none;
    }

    input[type='radio']:checked::after {
      position: absolute;
      width: 16px;
      height: 16px;
      content: '';
      background-color: #000;
      border-radius: 50%;
    }

    .a-span-light {
      margin-left: 4px;
      color: $grey-light;
    }

    .a-span-sm {
      font-size: 12px;
      color: $grey-light;
    }

    .add-option {
      display: flex;
      align-items: center;
      margin-top: 8px;
      cursor: pointer;
    }

    .true-false {
      margin-top: 8px;

      span {
        margin-left: 8px;
        font-size: 16px;
      }
    }

    .multiple-options {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      margin-top: 8px;

      .option {
        display: flex;
        gap: 8px;
        align-items: center;
        .checkbox {
          margin-bottom: 0;
        }

        input[type='text'] {
          flex: 1;
          width: 100%;
          padding: 10px;
          font-size: 16px;
        }

        input[type='checkbox'] {
          margin-right: 0;
        }
      }
    }

    .dropdown {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      margin-top: 8px;

      .d-input {
        input {
          padding: 12px;
        }
      }
    }
  }
}
</style>
