import ApiService from '@/utilities/ApiService';

export default async () => {
  try {
    const logoutUser = await ApiService.post(`${process.env.VUE_APP_API_URL}/saml/logout`);
    localStorage.removeItem('jwtToken');
    window.location = logoutUser.data.data;
  } catch (error) {
    throw new Error(error);
  }
};
