<template>
  <VueMultiselect v-bind="$attrs" v-on="$listeners" class="g-select">
    <template v-slot:caret="">
      <ArrowDown v-if="caret === undefined || caret" class="arrow-down" />
    </template>
  </VueMultiselect>
</template>

<script>
import VueMultiselect from 'vue-multiselect';
import ArrowDown from '../assets/images/Icons/arrow_left.svg';

export default {
  components: {
    VueMultiselect,
    ArrowDown,
  },
  props: ['caret'],
  methods: {},
  mounted() {},
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.multiselect {
  .multiselect__select {
    display: none;
  }

  .multiselect__element {
    color: #000;
  }

  .multiselect__option--highlight {
    color: #000;
    background-color: #e3e4e5;

    &::after {
      color: #000;
      background-color: #e3e4e5;
    }
  }

  .multiselect__option--selected {
    color: #000;

    &::after {
      color: #000;
    }
  }

  .multiselect__tags {
    z-index: 99;
    width: 100%;
    min-height: 51px;
    padding: 12px 40px 12px 8px;
    font-size: 16px;
    border: 1px solid #626669;
    border-radius: 0;

    .multiselect__tags-wrap {
      display: flex;
      .multiselect__tag {
        display: flex;
        padding: 8px 8px 8px 12px;
        margin-bottom: 4px;
        color: #000;
        background-color: #e3e4e5;
        border-radius: 0;

        span {
          margin-left: 18px;
        }

        .multiselect__tag-icon {
          left: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 0;

          &:hover {
            background-color: #e3e4e5;
          }

          &::after {
            font-size: 16px;
            color: #000;
            content: '\2715';
          }
        }
      }
    }

    // targets single input
    .multiselect__single {
      margin-bottom: 0;
      line-height: initial;
      color: #000;
    }
  }

  .multiselect__placeholder {
    padding-top: 0;
    margin-bottom: 0;
  }

  input {
    margin-bottom: 0;
  }
}

// normal
.vue-multiselect {
  position: relative;
  margin-bottom: 16px;
  cursor: pointer;

  label {
    display: block;
    margin-bottom: 6px;
  }
}

.sort-by,
.display-count,
.asset-type {
  margin-bottom: 0;

  .label {
    position: absolute;
    top: 13px;
    left: 12px;
    z-index: 99;
    color: #000;
  }

  .multiselect__tags {
    text-transform: capitalize;
  }

  .multiselect__content {
    li {
      span {
        text-transform: capitalize;
      }
    }
  }
}

.display-count {
  width: 109px;
  .multiselect__tags {
    padding: 12px 20px 12px 52px;
  }
}

.sort-by {
  width: 220px;
  .multiselect__tags {
    padding: 12px 20px 12px 60px;
  }
}

.asset-type {
  width: 210px;
  .multiselect__tags {
    padding: 12px 20px 12px 85px;
  }
  .multiselect__placeholder {
    padding-left: 4px;
  }
}

.select-icon {
  position: absolute;
  right: 16px;
  bottom: 13px;
  z-index: 51;
  width: auto;
  height: 20px;
  pointer-events: none;
  transform: rotate(-90deg);
}

.arrow-down {
  position: absolute;
  top: 18px;
  right: 6px;
  width: 16px;
  height: auto;
  margin-left: 18px;
  pointer-events: none;
  transform: rotate(-90deg);
}
</style>
