<template>
  <div>
    <Modal
      v-if="showAccessCodeModal"
      modalType="access-code"
      :accessCodeModalContent="accessCodeModalContent"
      @done="verifyAccessCode"
      @close-modal="closeAccessCodeModal"
    />

    <Loading :type="'section'" v-if="!dataAvailable && !dataError" />

    <div class="wb-module-error" v-if="dataError">
      <h1>{{ currentModule.title }}</h1>
      <p class="error-msg" v-if="dataErrorMsg === '400'">The access code you entered is incorrect.</p>
      <p v-else>Sorry, something went wrong, please try again.</p>
      <PorscheButton @click="openAccessCodeModal">
        <Arrow class="arrow-icon icon" />
        <span>Retry access code</span>
      </PorscheButton>
    </div>

    <div v-if="dataAvailable" class="wb-view wb-module" :class="{ 'wb-view-mobile': isLaptop }">
      <h1>{{ localModules.module.title }}</h1>

      <div class="description">
        <p class="label">Description:</p>
        <p class="value" v-html="localModules.module.description"></p>
      </div>

      <div class="description" v-if="userInformation.instructor || isWorkbookPreview">
        <p class="label">Instructor Description:</p>
        <p class="value" v-html="localModules.module.instructorDescription"></p>
      </div>
      <hr />
      <div class="wb-assets">
        <div v-for="(asset, index) in localModules.assets" :key="index">
          <!-- Wavier -->
          <div
            v-if="asset.module_asset.type === 'waiver' && asset.active === true"
            class="wb-asset-column wb-asset wavier"
          >
            <div class="wavier-asset">
              <img src="@/assets/images/AssetImgs/content_waiver.png" class="static-img" alt="" />
              <div class="wb-asset-info">
                <h2>{{ asset.title }}</h2>
                <span class="tag">{{ asset.module_asset.type }}</span>
                <div class="actions">
                  <a :href="asset.fileUrl ? asset.fileUrl : asset.url" target="_blank" class="action">
                    <DownloadIcon class="icon dl-icon" />
                    <span>Download waiver</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="checkbox">
              <input
                type="checkbox"
                @change="sendEsig(asset)"
                v-model="asset.isSigned"
                :disabled="asset.isSigned || !userInformation.participant"
              />
              <span>By checking this box, I am e-signing this waiver. Checking this box cannot be undone.</span>
              <CheckIcon class="check-icon" v-if="asset.isSigned" />
            </div>
            <hr />
          </div>

          <div v-if="asset.module_asset.type === 'quiz' && asset.active === true" class="wb-test" :data-id="asset.id">
            <div v-if="localModules.quizzes[asset.id].takenQuiz || isWorkbookPreview" class="notification-alert">
              <p>You have submitted a response for this quiz.</p>
            </div>
            <h2>{{ asset.title }}</h2>
            <h3 v-if="localModules.quizzes[asset.id].takenQuiz">
              Score:
              {{ localModules.quizzes[asset.id].totalCorrect }}
              /
              {{ localModules.quizzes[asset.id].totalQuestions }}
            </h3>
            <template v-if="!localModules.quizzes[asset.id].takenQuiz">
              <div
                v-for="(question, index) in localModules.quizzes[asset.id].quizQuestion"
                :key="question.id"
                class="q-wrap"
                :class="{ 'q-wrap-invalid': question.invalid }"
              >
                <div class="q-heading">
                  <div class="q-question">
                    <span class="q-number">{{ index + 1 }}.</span>
                    <span>{{ question.question }}</span>
                  </div>
                  <p class="q-explanation">{{ question.explanation }}</p>
                </div>
                <!-- True/False -->
                <div class="q-answers" v-if="quizQuestionTypes['True/False'].id === question.questionTypeId">
                  <div v-for="option in question.quizQuestionOption" :key="option.id" class="circle-checkbox">
                    <input
                      type="radio"
                      :disabled="localModules.quizzes[asset.id].takenQuiz || !userInformation.participant"
                      :value="option.id"
                      v-model="question.answer"
                    />
                    <span>{{ option.text }}</span>
                  </div>
                </div>
                <!-- Multiple Choice -->
                <div class="q-answers" v-if="quizQuestionTypes['Multiple Choice'].id === question.questionTypeId">
                  <div v-for="option in question.quizQuestionOption" :key="option.id" class="circle-checkbox">
                    <input
                      type="radio"
                      :disabled="localModules.quizzes[asset.id].takenQuiz || !userInformation.participant"
                      :value="option.id"
                      v-model="question.answer"
                    />
                    <span>{{ option.text }}</span>
                  </div>
                </div>
                <!-- Checkboxes -->
                <div class="q-answers" v-if="quizQuestionTypes['Checkboxes'].id === question.questionTypeId">
                  <div v-for="option in question.quizQuestionOption" :key="option.id" class="checkbox">
                    <input
                      type="checkbox"
                      :disabled="localModules.quizzes[asset.id].takenQuiz || !userInformation.participant"
                      :value="option.id"
                      v-model="option.answer"
                    />
                    <span>{{ option.text }}</span>
                    <CheckIcon class="check-icon" v-if="option.answer" />
                  </div>
                </div>
              </div>
            </template>
            <PorscheButton
              @click="
                submitQuiz(localModules.quizzes[asset.id]);
                scrollUp(localModules.quizzes[asset.id]);
              "
              :disabled="localModules.quizzes[asset.id].takenQuiz || !userInformation.participant"
              :inactive="localModules.quizzes[asset.id].takenQuiz || !userInformation.participant"
            >
              <Arrow class="arrow-icon icon" />
              <span>Submit responses</span>
            </PorscheButton>
            <hr />
          </div>

          <div
            v-if="asset.module_asset.type === 'survey' && asset.active === true"
            class="wb-test"
            :data-id="asset.id"
            :key="asset.id"
          >
            <div v-if="localModules.surveys[asset.id].takenSurvey || isWorkbookPreview" class="notification-alert">
              <p>You have submitted a response for this survey.</p>
            </div>
            <h2>{{ asset.title }}</h2>
            <div
              v-for="(question, index) in localModules.surveys[asset.id].surveyQuestion"
              :key="question.id"
              class="q-wrap"
              :class="{ 'q-wrap-invalid': question.invalid }"
            >
              <div class="q-heading">
                <div class="q-question">
                  <span class="q-number">{{ index + 1 }}.</span>
                  <span>{{ question.question }}</span>
                </div>
              </div>
              <!-- Dropdown -->
              <div class="q-answers" v-if="surveyQuestionTypes['Dropdown Selection'].id === question.questionTypeId">
                <div class="vue-multiselect">
                  <VueMultiselect
                    v-model="question.answer"
                    class="single"
                    :disabled="localModules.surveys[asset.id].takenSurvey || !userInformation.participant"
                    :options="question.surveyQuestionOption"
                    label="text"
                    track-by="text"
                    :searchable="false"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="Select"
                  />
                </div>
              </div>
              <!-- Checkboxes -->
              <div class="q-answers" v-if="surveyQuestionTypes['Checkboxes'].id === question.questionTypeId">
                <div v-for="(option, index) in question.surveyQuestionOption" :key="index" class="checkbox">
                  <input
                    type="checkbox"
                    :disabled="localModules.surveys[asset.id].takenSurvey || !userInformation.participant"
                    :value="option.id"
                    v-model="option.answer"
                  />
                  <span>{{ option.text }}</span>
                  <CheckIcon class="check-icon" v-if="option.answer" />
                </div>
              </div>
              <!-- Multi-line response -->
              <div class="q-answers" v-if="surveyQuestionTypes['Multi-line response'].id === question.questionTypeId">
                <textarea
                  v-if="localModules.surveys[asset.id].takenSurvey && question.userQuestionResponse"
                  :disabled="localModules.surveys[asset.id].takenSurvey || !userInformation.participant"
                  class="multiline-res"
                  v-model="question.userQuestionResponse[0].text"
                ></textarea>
                <textarea
                  v-else
                  :disabled="localModules.surveys[asset.id].takenSurvey || !userInformation.participant"
                  class="multiline-res"
                  v-model="question.answer"
                ></textarea>
              </div>
              <!-- Multiple choice -->
              <div class="q-answers" v-if="surveyQuestionTypes['Multiple Choice'].id === question.questionTypeId">
                <div v-for="(option, index) in question.surveyQuestionOption" :key="index" class="circle-checkbox">
                  <input
                    type="radio"
                    :disabled="localModules.surveys[asset.id].takenSurvey || !userInformation.participant"
                    :value="option.id"
                    v-model="question.answer"
                  />
                  <span>{{ option.text }}</span>
                </div>
              </div>
              <!-- Single-line response -->
              <div class="q-answers" v-if="surveyQuestionTypes['Single-line response'].id === question.questionTypeId">
                <input
                  v-if="localModules.surveys[asset.id].takenSurvey && question.userQuestionResponse"
                  class="singleline-res"
                  :disabled="localModules.surveys[asset.id].takenSurvey || !userInformation.participant"
                  type="text"
                  v-model="question.userQuestionResponse[0].text"
                />
                <input
                  v-else
                  class="singleline-res"
                  :disabled="localModules.surveys[asset.id].takenSurvey || !userInformation.participant"
                  type="text"
                  v-model="question.answer"
                />
              </div>
              <!-- Yes/No -->
              <div class="q-answers" v-if="surveyQuestionTypes['Yes/No'].id === question.questionTypeId">
                <div v-for="(option, index) in question.surveyQuestionOption" :key="index" class="circle-checkbox">
                  <input
                    type="radio"
                    :disabled="localModules.surveys[asset.id].takenSurvey || !userInformation.participant"
                    :value="option.id"
                    v-model="question.answer"
                  />
                  <span>{{ option.text }}</span>
                </div>
              </div>
              <!-- Slider -->
              <div class="q-answers" v-if="surveyQuestionTypes['Slider'].id === question.questionTypeId">
                <BaseSlider
                  v-model="question.answer"
                  :min="question.surveyQuestionOption[0].text"
                  :max="question.surveyQuestionOption[2].text"
                  :step="question.surveyQuestionOption[1].text"
                />
              </div>
            </div>
            <PorscheButton
              :disabled="localModules.surveys[asset.id].takenSurvey || !userInformation.participant"
              :inactive="localModules.surveys[asset.id].takenSurvey || !userInformation.participant"
              @click="
                submitSurvey(localModules.surveys[asset.id], asset);
                scrollUp(localModules.surveys[asset.id]);
              "
            >
              <Arrow class="arrow-icon icon" />
              <span>Submit responses</span>
            </PorscheButton>
            <hr />
          </div>
        </div>
      </div>
      <PorscheButton v-if="isSectionAvailable" :light="true" @click="nextSection">
        <Arrow class="arrow-icon icon" />
        <span>Next Section</span>
      </PorscheButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import DownloadIcon from '@/assets/images/Icons/download.svg';
import CheckIcon from '@/assets/images/Icons/check.svg';
import PorscheButton from '@/components/PorscheButton.vue';
import Arrow from '@/assets/images/Icons/arrow_right_white.svg';
import VueMultiselect from '@/components/VueMultiselect.vue';
import Loading from '@/components/Loading.vue';
import ApiService from '@/utilities/ApiService';
import Modal from '@/components/Modal.vue';
import BaseSlider from '@/components/BaseSlider.vue';

export default {
  name: 'view-module',
  components: { DownloadIcon, CheckIcon, PorscheButton, Loading, Arrow, VueMultiselect, Modal, BaseSlider },
  props: {
    currentModule: Object,
    index: Number,
  },
  data() {
    return {
      localModules: null,
      dataAvailable: null,
      dataError: null,
      dataErrorMsg: null,
      quizQuestionTypes: {},
      surveyQuestionTypes: {},
      showAccessCodeModal: false,
      accessCodeModalContent: null,
      changeViewArguments: null,
      accessCode: '',
      isWorkbookPreview: this.$route.query.preview === 'true',
    };
  },
  async created() {
    if (this.currentModule.isLocked && !this.currentModule.accessCodeValue) {
      if (this.currentModule.unlockTime <= new Date().toISOString()) {
        await this.getModule();
        return;
      }
      this.openAccessCodeModal();
      return;
    }
    await this.getModule(this.currentModule.accessCodeValue);
  },
  computed: {
    ...mapState({
      userInformation: (state) => state.userInformation,
      workbookViewer: (state) => state.workbookViewer,
      workbookBuilder: (state) => state.workbookBuilder,
    }),

    isLaptop() {
      return this.$mq === 'tablet' || this.$mq === 'mobile' || this.$mq === 'tabletLarge';
    },

    isTablet() {
      return this.$mq === 'tablet' || this.$mq === 'mobile';
    },

    isSectionAvailable() {
      return this.workbookViewer.moduleNavigation[this.index].sections.length > 0;
    },
  },
  watch: {},
  methods: {
    ...mapActions('workbookViewer', ['GET_MODULE']),
    ...mapMutations('workbookViewer', ['SET_MODULE_ACCESS_CODE']),

    scrollUp(asset) {
      const note = document.querySelector(`[data-id='${asset.id}']`);
      window.scrollTo(0, note.offsetTop);
    },

    openAccessCodeModal() {
      this.showAccessCodeModal = true;
      this.accessCodeModalContent = this.currentModule;
    },

    closeAccessCodeModal() {
      this.showAccessCodeModal = null;
      this.$emit('goToWorkbookOverview');
    },

    async verifyAccessCode(accessCode) {
      this.showAccessCodeModal = null;
      await this.getModule(accessCode);
    },

    async getModule(accessCode) {
      try {
        // reset error
        this.dataError = null;
        this.dataErrorMsg = null;

        await this.GET_MODULE({
          moduleId: this.currentModule.id,
          accessCode,
          isParticipant: this.userInformation.participant,
        });

        this.localModules = { ...this.workbookViewer.moduleInfo };

        this.getQuizQuestionTypes();
        this.getSurveyQuestionTypes();
        // set access token into module navigation within state
        this.SET_MODULE_ACCESS_CODE({
          index: this.index,
          accessCode,
        });

        this.dataAvailable = true;
      } catch (error) {
        this.dataError = true;
        this.dataErrorMsg = error.message;
        console.warn(error.message);
      }
    },

    async sendEsig(asset) {
      try {
        await ApiService.get(`${process.env.VUE_APP_API_URL}/workbook/module/waiver/sign/${asset.id}`);
      } catch (error) {
        console.warn(error);
      }
    },

    async submitQuiz(quiz) {
      try {
        // is type "checkbox"
        const checkboxes = this.quizQuestionTypes.Checkboxes.id;

        const answers = [];
        quiz.quizQuestion.forEach((question) => {
          let answerObj = {};

          // multiple answers (Checkbox)
          if (checkboxes === question.questionTypeId) {
            const isValid = question.quizQuestionOption.some((answer) => answer.answer);
            question.quizQuestionOption.forEach((answer) => {
              if (isValid) {
                question.invalid = false;

                if (answer.answer) {
                  answerObj = {
                    quizQuestionId: question.id,
                    quizQuestionOptionId: answer.id,
                  };
                  answers.push(answerObj);
                }
              } else {
                question.invalid = true;
              }
            });
            return;
          }

          // all other types
          if (question.answer) {
            question.invalid = false;
            answerObj = {
              quizQuestionId: question.id,
              quizQuestionOptionId: question.answer,
            };
            answers.push(answerObj);
          } else {
            question.invalid = true;
          }
        });

        const invalidQuiz = quiz.quizQuestion.some((question) => question.invalid);

        if (!invalidQuiz) {
          const submitQuiz = await ApiService.post(`${process.env.VUE_APP_API_URL}/quiz/result/submit`, {
            quizId: quiz.id,
            questions: answers,
          });
          const submitQuizRes = submitQuiz.data.data;

          this.localModules.quizzes[quiz.id].takenQuiz = true;
          this.localModules.quizzes[quiz.id].totalCorrect = submitQuizRes.totalCorrect;
          this.localModules.quizzes[quiz.id].totalQuestions = submitQuizRes.totalQuestions;
        }
      } catch (error) {
        console.warn(error);
      }
    },

    async submitSurvey(survey) {
      try {
        // is type "checkbox"
        const checkboxes = this.surveyQuestionTypes.Checkboxes.id;
        const dropDown = this.surveyQuestionTypes['Dropdown Selection'].id;
        const singleline = this.surveyQuestionTypes['Single-line response'].id;
        const multiline = this.surveyQuestionTypes['Multi-line response'].id;

        const answers = [];
        survey.surveyQuestion.forEach((question) => {
          let answerObj = {};

          // multiple answers (Checkbox)
          if (checkboxes === question.questionTypeId) {
            const isValid = question.surveyQuestionOption.some((answer) => answer.answer);
            question.surveyQuestionOption.forEach((answer) => {
              if (isValid) {
                question.invalid = false;

                if (answer.answer) {
                  answerObj = {
                    surveyQuestionId: question.id,
                    surveyQuestionOptionId: answer.id,
                  };
                  answers.push(answerObj);
                }
              } else {
                question.invalid = true;
              }
            });
            return;
          }

          // single text(string) answer
          if (singleline === question.questionTypeId || multiline === question.questionTypeId) {
            if (question.answer !== '') {
              question.invalid = false;
              answerObj = {
                surveyQuestionId: question.id,
                surveyQuestionOptionId: null,
                text: question.answer,
              };
              answers.push(answerObj);
            } else {
              question.invalid = true;
            }
            return;
          }

          // all other options
          if (question.answer) {
            question.invalid = false;
            answerObj = {
              surveyQuestionId: question.id,
              surveyQuestionOptionId: dropDown === question.questionTypeId ? question.answer.id : question.answer,
            };
            answers.push(answerObj);
          } else {
            question.invalid = true;
          }
        });

        const invalidSurvey = survey.surveyQuestion.some((question) => question.invalid);

        if (!invalidSurvey) {
          await ApiService.post(`${process.env.VUE_APP_API_URL}/survey/result/submit`, {
            surveyId: survey.id,
            questions: answers,
          });

          this.localModules.surveys[survey.id].takenSurvey = true;
        }
      } catch (error) {
        console.warn(error);
      }
    },

    getQuizQuestionTypes() {
      this.localModules.questionTypes.forEach((type) => {
        if (type.isQuizOption === true) {
          this.quizQuestionTypes[type.type] = type;
        }
      });
    },

    getSurveyQuestionTypes() {
      this.localModules.questionTypes.forEach((type) => {
        if (type.isSurveyOption === true) {
          this.surveyQuestionTypes[type.type] = type;
        }
      });
    },

    nextSection() {
      this.$emit('nextSection', 'module', this.index);
    },
  },
};
</script>

<style lang="scss" scoped>
.wb-module-error {
  .porsche-button {
    margin-top: 24px;
  }
}

.wavier {
  flex-direction: column;

  .wavier-asset {
    display: flex;
    column-gap: 16px;
    margin-bottom: 24px;

    .wb-asset-info {
      margin-top: 0 !important;
    }
  }

  .checkbox {
    display: flex;
    align-items: center;
  }
}

.wb-test {
  .notification-alert {
    margin-bottom: 16px;
  }

  h2,
  h3 {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 500;
    text-transform: capitalize;
  }

  .q-wrap {
    padding: 20px 40px 26px;
    margin-bottom: 18px;
    background-color: $white-smoke;
    border: 2px solid transparent;

    &-invalid {
      border-color: $primary;
    }

    &:nth-last-of-type(1) {
      margin-bottom: 30px;
    }

    .q-heading {
      margin-bottom: 16px;
      .q-question {
        position: relative;
        margin-bottom: 16px;

        .q-number {
          position: absolute;
          top: 0;
          left: -25px;
        }
      }
    }

    .q-answers {
      display: flex;
      flex-direction: column;
      row-gap: 12px;

      .checkbox {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        input[type='checkbox'] {
          background: #fff;
        }
        input[type='checkbox']:checked {
          background: #000;
        }
      }

      .circle-checkbox {
        input[type='radio'] {
          background: #fff;
          border: 1px solid $grey;
        }
      }

      .vue-multiselect {
        max-width: 300px;
      }

      .multiline-res,
      .singleline-res {
        padding: 12px;
        font-size: 16px;
        border: 1px solid $grey-light;
        &:focus {
          outline: none;
        }
      }

      .multiline-res {
        height: 150px;
        resize: none;
      }

      .range-wrap {
        position: relative;
        padding-bottom: 32px;

        input[type='range'] {
          width: 100%;
          height: 8px;
          background-color: $grey-extra-light;
          border-radius: 4px;
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
          appearance: none;
        }

        .range-button {
          position: absolute;
          top: 0;
          left: 50%;
          width: 25px;
          height: 25px;
          pointer-events: none;
          background-color: $primary;
          border-radius: 50%;
          transform: translate(-50%);
        }

        .range-value {
          position: absolute;
          top: 30px;
          left: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 25px;
          height: 25px;
          transform: translateX(-50%);
        }

        .range-progress {
          position: absolute;
          top: 9px;
          bottom: 4px;
          left: 0;
          width: 50%;
          height: 8px;
          pointer-events: none;
          background-color: $primary;
          border-radius: 4px;
        }
      }
    }
  }
}
</style>
