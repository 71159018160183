import ApiService from '@/utilities/ApiService';

export default {
  namespaced: true,
  state: {
    admin: false,
    designer: false,
    instructor: false,
    participant: false,
    username: null,
    avatar: null,
    firstName: null,
    lastName: null,
    email: null,
    phoneNumber: null,
    userId: null,
    enableNews: null,
    enableNotifications: null,
    enablePush: null,
    enableSMS: null,
    enableUpdates: null,
    searchNavigation: false,
    searchQuery: null,
    searchedQuery: null,
    searchDataAvailable: null,
    searchActive: null,
    searchData: null,
    searchResultQuantity: null,
    searchError: null,
    authenticated: null,
  },
  mutations: {
    OPEN_SEARCH_NAVIGATION(state) {
      state.searchNavigation = !state.searchNavigation;
    },

    CLOSE_SEARCH(state) {
      state.searchNavigation = null;
      state.searchQuery = null;
      state.searchActive = null;
      state.searchData = null;
    },

    changeUserType(state, user) {
      state.admin = false;
      state.designer = false;
      state.instructor = false;
      state.participant = false;

      if (user === 'Admin') {
        state.admin = true;
        return;
      }
      if (user === 'Designer') {
        state.designer = true;
        return;
      }
      if (user === 'Instructor') {
        state.instructor = true;
        return;
      }
      if (user === 'Participant') {
        state.participant = true;
      }
    },

    ENABLE_SEARCH_ACTIVE(state) {
      state.searchError = null;
      state.searchDataAvailable = null;
      state.searchActive = true;
      state.searchedQuery = state.searchQuery;
    },

    UPDATE_SEARCH_QUERY(state, payload) {
      state.searchQuery = payload;
    },

    UPDATE_USER_INFO(state, payload) {
      state.phoneNumber = payload.phoneNumber;
      state.enableNews = payload.enableNews;
      state.enableNotifications = payload.enableNotifications;
      state.enablePush = payload.enablePush;
      state.enableSMS = payload.enableSMS;
      state.enableUpdates = payload.enableUpdates;
      state.avatar = payload.avatar;
    },

    SET_SEARCH_DATA(state, payload) {
      state.searchDataAvailable = true;
      state.searchData = payload.data;
      state.searchResultQuantity = payload.amount;
    },

    SET_SEARCH_ERROR(state) {
      state.searchError = true;
      state.searchDataAvailable = true;
    },
    SET_USER_INFO(state, payload) {
      state.authenticated = true;
      state.admin = payload.userRoleId === 4;
      state.designer = payload.userRoleId === 3;
      state.instructor = payload.userRoleId === 2;
      state.participant = payload.userRoleId === 1;
      state.avatar = payload.avatar;
      state.username = payload.username;
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.phoneNumber = payload.phoneNumber;
      state.email = payload.email;
      state.userId = payload.id;

      if (this._vm.$datadogRum) {
        this._vm.$datadogRum.setUser({
          id: state.userId,
          name: `${state.firstName} ${state.lastName}`,
          email: state.email,
        });
      }

      state.enableNews = payload.enableNews;
      state.enableNotifications = payload.enableNotifications;
      state.enablePush = payload.enablePush;
      state.enableSMS = payload.enableSMS;
      state.enableUpdates = payload.enableUpdates;
    },
  },
  actions: {
    async SEARCH_PLATFORM({ commit, state }) {
      try {
        commit('ENABLE_SEARCH_ACTIVE');
        const data = await ApiService.get(
          `${process.env.VUE_APP_API_URL}/content-library/search?query=${state.searchQuery}`,
        );

        const payload = {
          data: data.data.data,
          amount: data.data.data.length,
        };

        commit('SET_SEARCH_DATA', payload);
      } catch (error) {
        commit('SET_SEARCH_ERROR');
        throw new Error(error);
      }
    },
  },
};
