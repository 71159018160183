<template>
  <div>
    <div ref="sidePanelWrap" v-if="sidePanel" class="side-panel-wrap">
      <div v-click-outside="toggleSidePanel" class="side-panel">
        <Loading :type="'section'" v-if="updatingUser" />
        <div>
          <div @click="toggleSidePanel" class="close-report">
            <ArrowRed class="icon arrow-red" />
            <span>Back to Users</span>
          </div>
          <h2 class="panel-title">Edit User Role</h2>

          <AlertError :type="'block'" :alertError="updateUserError">
            <template v-slot:alert-message>
              <span> {{ updateUserErrorMsg }}</span>
            </template>
          </AlertError>

          <div class="active-user-info">
            <div class="active-user-field">
              <p class="active-user-label">User ID</p>
              <p class="active-user-content">{{ activeUser.username }}</p>
            </div>
            <div class="active-user-field">
              <p class="active-user-label">Last</p>
              <p class="active-user-content">{{ activeUser.lastName }}</p>
            </div>
            <div class="active-user-field">
              <p class="active-user-label">First</p>
              <p class="active-user-content">{{ activeUser.firstName }}</p>
            </div>
          </div>

          <div class="vue-multiselect">
            <span class="label">PMA Role</span>
            <VueMultiselect
              v-model="pmaRole"
              :searchable="false"
              :close-on-select="true"
              :show-labels="false"
              label="role"
              :options="pmaRoleOptions"
            />
          </div>

          <PorscheButton @click="updateUserRole">
            <Arrow class="arrow-icon icon" />
            <span>Update user role</span>
          </PorscheButton>
        </div>
      </div>
    </div>

    <Loading v-if="csvApiRetrieving" />

    <div class="view-wrap admin-users container">
      <div class="view-heading">
        <h1>Users</h1>

        <AlertSuccess :type="'inline'" :alertSuccess="alertSuccess">
          <template v-slot:alert-message>
            <span> {{ alertMsg }}</span>
          </template>
        </AlertSuccess>

        <AlertError :type="'inline'" :alertError="userApiError">
          <template v-slot:alert-message>
            <span> {{ alertMsg }}</span>
          </template>
        </AlertError>
      </div>

      <div class="view-content">
        <!-- Search -->
        <div class="search">
          <IconSearch class="svg-icon" />
          <input type="text" placeholder="Search users..." v-model="searchQuery" @keypress.enter="getUsers" />
          <IconCancel v-show="searchQuery" @click="clearSearch" class="svg-icon cancel-btn" />
        </div>

        <!-- Filtering -->
        <div class="user-filter">
          <div class="filter-options">
            <div class="vue-multiselect display-count">
              <span class="label">Show:</span>
              <VueMultiselect
                v-model="displayCount"
                :searchable="false"
                :close-on-select="true"
                :show-labels="false"
                :options="displayCountOptions"
                @input="getUsers"
              />
            </div>

            <div class="vue-multiselect sort-by">
              <span class="label">Sort By:</span>
              <VueMultiselect
                v-model="sortBy"
                label="title"
                :searchable="false"
                :close-on-select="true"
                :allow-empty="false"
                :show-labels="false"
                :options="sortByOptions"
                @input="getUsers"
              />
            </div>

            <div @click="toggleFilterMenu" class="filter flex" :class="{ 'filter-menu-active': filterMenu }">
              <p>Filter</p>
              <FilterIcon class="filter-icon" />
            </div>
          </div>

          <div class="button-actions" v-if="userApiData">
            <span class="update">
              Last update:
              {{ new Date(this.dataLastUpdated).toLocaleDateString() }},
              {{
                new Date(this.dataLastUpdated).toLocaleTimeString('en-us', { hour: 'numeric', minute: 'numeric' })
              }}</span
            >
            <PorscheButton @click="exportAllUsersCSV">
              <Arrow class="arrow-icon icon" />
              <span>Export CSV</span>
            </PorscheButton>
          </div>

          <!-- Advanced Filter Menu -->
          <div v-if="filterMenu && userApiData" class="filter-menu-wrap">
            <div class="filter-menu">
              <div class="filter-row">
                <div class="vue-multiselect">
                  <span class="label">Department</span>
                  <VueMultiselect
                    v-model="department"
                    :searchable="false"
                    :close-on-select="true"
                    :show-labels="false"
                    :options="filterMenuOptions.departments"
                  />
                </div>
                <div class="vue-multiselect">
                  <span class="label">Job Role</span>
                  <VueMultiselect
                    v-model="jobRole"
                    :searchable="false"
                    :close-on-select="true"
                    :show-labels="false"
                    :options="filterMenuOptions.jobRoles"
                  />
                </div>
              </div>
              <div class="filter-row">
                <div class="vue-multiselect">
                  <span class="label">Region</span>
                  <VueMultiselect
                    v-model="region"
                    :searchable="false"
                    :close-on-select="true"
                    :show-labels="false"
                    :options="filterMenuOptions.regions"
                  />
                </div>
                <div class="vue-multiselect">
                  <span class="label">Area</span>
                  <VueMultiselect
                    v-model="area"
                    :searchable="false"
                    :close-on-select="true"
                    :show-labels="false"
                    :options="filterMenuOptions.areas"
                  />
                </div>
              </div>
              <div class="filter-row">
                <div class="vue-multiselect">
                  <span class="label">Market</span>
                  <VueMultiselect
                    v-model="market"
                    :searchable="false"
                    :close-on-select="true"
                    :show-labels="false"
                    :options="filterMenuOptions.markets"
                  />
                </div>
              </div>
            </div>
            <div class="filter-actions">
              <PorscheButton @click="getUsers">
                <Arrow class="arrow-icon icon" />
                <span>Apply filters</span>
              </PorscheButton>
              <PorscheButton @click="clearFilters" :light="true">
                <Arrow class="arrow-icon icon" />
                <span>Clear filters</span>
              </PorscheButton>
            </div>
          </div>
        </div>

        <!-- User Info -->
        <div class="users-table">
          <div class="labels-wrap">
            <div class="labels">
              <span>User ID</span>
              <span>Last</span>
              <span>First</span>
              <span>Email</span>
              <span>Job Role</span>
              <span>Area</span>
              <span>Market</span>
              <span>PMA Role</span>
              <span></span>
            </div>
            <hr />
          </div>
          <div class="user-wrap">
            <Loading :type="'section'" v-if="!userApiResolved" />
            <div v-if="userApiResolved && userApiData && paginatedUsers.length > 0">
              <div v-for="user in paginatedUsers" :key="user.id">
                <div class="user">
                  <span>{{ user.username ? user.username : '-' }}</span>
                  <span>{{ user.lastName ? user.lastName : '-' }}</span>
                  <span>{{ user.firstName ? user.firstName : '-' }}</span>
                  <span>{{ user.email ? user.email : '-' }}</span>
                  <span>{{ user.jobRoleTitle ? user.jobRoleTitle : '-' }}</span>
                  <template v-if="user.userOrganization.length > 0">
                    <span>
                      {{ user.userOrganization[0].area ? user.userOrganization[0].area : '-' }}
                    </span>
                    <span>
                      {{ user.userOrganization[0].market ? user.userOrganization[0].market : '-' }}
                    </span>
                  </template>
                  <template v-else>
                    <span>-</span>
                    <span>-</span>
                  </template>
                  <div class="pma-roles">
                    <span v-if="user.userRoleId === 1">Participant</span>
                    <span v-if="user.userRoleId === 2">Instructor</span>
                    <span v-if="user.userRoleId === 3">Curriculum Designer </span>
                    <span v-if="user.userRoleId === 4">Admin</span>
                  </div>
                  <div class="pma-actions">
                    <div class="user-action">
                      <EditIcon @click="toggleSidePanel(user)" class="edit-icon" />
                      <ToolTip :right="true">Change Job Role</ToolTip>
                    </div>
                    <div class="user-action">
                      <UserReport @click="exportSingleUserCSV(user)" class="report-icon" />
                      <ToolTip :right="true">Associated Workbooks</ToolTip>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
              <Pagination :pageCount="pageCount" @page-change="pageChange" :currentPage="activePage" />
            </div>
            <h2
              v-if="(userApiResolved && userApiData && paginatedUsers.length === 0) || userApiError"
              class="header-error"
            >
              Sorry, there are no users to display.
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from '@/assets/images/Icons/arrow_right_white.svg';
import ArrowRed from '@/assets/images/Icons/arrow_right_red.svg';
import EditIcon from '@/assets/images/Icons/edit.svg';
import FilterIcon from '@/assets/images/Icons/filter.svg';
import UserReport from '@/assets/images/Icons/user_report.svg';
import IconSearch from '@/assets/images/Header/search.svg';
import IconCancel from '@/assets/images/Header/cancel.svg';
import PorscheButton from '@/components/PorscheButton.vue';
import Loading from '@/components/Loading.vue';
import AlertError from '@/components/AlertError.vue';
import AlertSuccess from '@/components/AlertSuccess.vue';
import VueMultiselect from '@/components/VueMultiselect.vue';
import Pagination from '@/components/Pagination.vue';
import ApiService from '@/utilities/ApiService';
import vClickOutside from 'v-click-outside';
import ExportCSV from '@/utilities/ExportCSV';
import ToolTip from '@/components/ToolTip.vue';

export default {
  name: 'admin-users',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    Arrow,
    FilterIcon,
    ArrowRed,
    VueMultiselect,
    Pagination,
    PorscheButton,
    Loading,
    EditIcon,
    AlertError,
    AlertSuccess,
    IconSearch,
    IconCancel,
    UserReport,
    ToolTip,
  },
  props: {},
  data() {
    return {
      userApiResolved: null,
      userApiData: null,
      userApiError: null,
      csvApiRetrieving: null,
      dataLastUpdated: null,
      alertSuccess: null,
      alertMsg: null,
      sidePanel: null,
      updatingUser: null,
      updateUserError: null,
      updateUserErrorMsg: null,
      activeUser: null,
      pmaRole: null,
      pmaRoleOptions: [
        { id: 1, role: 'Participant ' },
        { id: 2, role: 'Instructor' },
        { id: 3, role: 'Curriculum Designer ' },
        { id: 4, role: 'Admin ' },
      ],
      displayCount: 8,
      displayCountOptions: [8, 16, 24],
      sortBy: {
        title: 'Date (Newest First)',
        value: 'createdAt',
        order: 'DESC',
      },
      sortByOptions: [
        {
          title: 'Date (Newest First)',
          value: 'createdAt',
          order: 'DESC',
        },
        {
          title: 'Date (Oldest First)',
          value: 'createdAt',
          order: 'ASC',
        },
        {
          title: 'Username (A-Z)',
          value: 'username',
          order: 'ASC',
        },
        {
          title: 'Username (Z-A)',
          value: 'username',
          order: 'DESC',
        },
      ],
      filterMenu: null,
      filterMenuOptions: [],
      department: null,
      jobRole: null,
      region: null,
      area: null,
      market: null,
      offset: 0,
      activePage: 1,
      userPages: {},
      paginatedUsers: [],
      pageCount: null,
      searchQuery: '',
    };
  },
  async created() {
    await this.getUsers();
  },
  computed: {},
  watch: {},
  methods: {
    showAlertSuccess(msg) {
      this.alertMsg = msg;
      this.alertSuccess = true;

      setTimeout(() => {
        this.alertSuccess = null;
      }, 5000);
    },

    toggleFilterMenu() {
      this.filterMenu = !this.filterMenu;
    },

    toggleSidePanel(user) {
      // helps prevent page from scrolling when panel is open
      const body = document.querySelector('BODY');
      if (this.sidePanel) {
        // revert body to scrolling
        body.style.overflow = 'initial';
      } else {
        // restrict body from scrolling
        body.style.overflow = 'hidden';
      }

      // if user param is true, set active user
      if (user) {
        this.activeUser = user;
        [this.pmaRole] = this.pmaRoleOptions.filter((option) => option.id === this.activeUser.userRoleId);
      }

      // toggle side panel
      this.sidePanel = !this.sidePanel;
    },

    async getUsers() {
      try {
        // close filterMenu
        this.filterMenu = null;

        // reset error
        this.userApiError = null;

        // init loading
        this.userApiResolved = null;

        // fetch data
        const getUserData = ApiService.get(`${process.env.VUE_APP_API_URL}/users`, {
          offset: this.offset,
          limit: this.displayCount,
          search: this.searchQuery,
          department: this.department,
          region: this.region,
          area: this.area,
          jobRole: this.jobRole,
          order: this.sortBy.order,
          sortBy: this.sortBy.value,
        });

        const getUserFilterData = ApiService.get(`${process.env.VUE_APP_API_URL}/users/dropdown/`);

        const [userData, userFilterData] = await Promise.all([getUserData, getUserFilterData]);

        this.filterMenuOptions = userFilterData.data.data;

        // set data
        this.dataLastUpdated = userData.data.data.lastUpdated;
        this.pageCount = Math.ceil(userData.data.data.count / this.displayCount);
        this.userPages[this.activePage] = userData.data.data.rows;
        this.paginatedUsers = this.userPages[this.activePage];

        // complete fetching, show data (reduce flashing for UX)
        this.userApiData = true;
      } catch (err) {
        this.userApiError = true;
        this.alertMsg = 'Sorry, something went wrong, please try again.';
        console.warn(err);
      }

      this.userApiResolved = true;
    },

    clearFilters() {
      this.department = null;
      this.jobRole = null;
      this.region = null;
      this.area = null;
      this.market = null;
      this.getUsers();
    },

    async exportAllUsersCSV() {
      try {
        this.csvApiRetrieving = true;
        await ExportCSV('users/downloadcsv', {
          search: this.searchQuery,
          titleOrder: this.sortBy.value === 'title' ? this.sortBy.order : null,
          dateOrder: this.sortBy.value === 'createdAt' ? this.sortBy.order : null,
          department: this.department,
          region: this.region,
          area: this.area,
          jobRole: this.jobRole,
        });
      } catch (err) {
        console.warn(err);
      }
      this.csvApiRetrieving = null;
    },

    async exportSingleUserCSV(user) {
      try {
        this.csvApiRetrieving = true;
        await ExportCSV(`users/${user.id}/workbooks/csv`);
      } catch (err) {
        console.warn(err);
      }
      this.csvApiRetrieving = null;
    },

    async pageChange(page) {
      // Check if we already have page
      if (this.userPages[page]) {
        this.paginatedUsers = this.userPages[page];
        this.activePage = page;
        return;
      }

      // Get new page
      this.offset = (page - 1) * this.displayCount;
      this.activePage = page;
      await this.getUsers();
      this.paginatedUsers = this.userPages[page];
    },

    async updateUserRole() {
      try {
        // init loading, reset error
        this.updatingUser = true;
        this.updateUserError = null;

        // make update
        await ApiService.patch(`${process.env.VUE_APP_API_URL}/users/role/`, {
          userId: this.activeUser.id,
          role: this.pmaRole.id,
        });

        // complete update

        this.updatingUser = null;
        this.toggleSidePanel();
        this.getUsers();
        this.showAlertSuccess('User updated successfully');
      } catch (err) {
        this.updatingUser = null;
        this.updateUserError = true;
        this.updateUserErrorMsg = 'Sorry, something went wrong please try again';
        console.warn(err);
      }
    },

    clearSearch() {
      this.searchQuery = '';
      this.getUsers();
    },
  },
};
</script>

<style lang="scss" scoped>
.side-panel-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.6);

  .side-panel {
    position: fixed;
    right: 0;
    left: initial;
    width: 100%;
    max-width: 450px;
    height: 100%;
    min-height: 100vh;
    padding: 36px 36px 76px 36px;
    overflow: scroll;
    background-color: #fff;

    .close-report {
      display: flex;
      align-items: center;
      margin-bottom: 36px;
      cursor: pointer;

      .arrow-red {
        width: 6px;
        height: 13px;
        margin-right: 6px;
        transform: rotate(180deg);
      }
    }

    .panel-title {
      margin-bottom: 14px;
    }

    .active-user {
      &-info {
        display: flex;
        column-gap: 24px;
        margin-bottom: 20px;
      }

      &-field {
        display: flex;
        flex-direction: column;
        row-gap: 6px;
      }

      &-content {
        font-weight: 500;
      }
    }

    .vue-multiselect {
      margin-bottom: 32px;
    }

    .porsche-button {
      align-self: start;
    }
  }
}

.search {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 62px;
  margin-top: 25px;

  .svg-icon {
    width: 24px;
  }
  input {
    flex-grow: 1;
    height: 40px;
    padding-right: 24px;
    padding-left: 4px;
    margin-left: 24px;
    font-size: 16px;
    border: 0;
    border-bottom: 1px solid #c9cacb;
  }
  .cancel-btn {
    position: absolute;
    right: 0;
    width: 20px;
    height: 100%;
  }
}

.admin-users {
  margin-top: 40px;

  .view-heading {
    row-gap: 40px;
  }

  .user-filter {
    position: relative;
    display: flex;
    margin: 25px 0;

    .filter-options {
      z-index: 99;
      display: flex;
      flex: 1;
      gap: 10px;

      .vue-multiselect {
        margin-bottom: 0;
      }

      .display-count,
      .sort-by {
        .label {
          position: absolute;
          top: 12px;
          left: 12px;
          z-index: 99;
          color: #000;
        }
      }

      .display-count {
        width: 109px;
        .multiselect__tags {
          padding: 12px 20px 12px 52px;
        }
      }

      .sort-by {
        width: 220px;
        .multiselect__tags {
          padding: 12px 20px 12px 60px;
        }
      }

      .filter {
        position: relative;
        z-index: 99;
        align-items: center;
        padding: 10px 12px;
        cursor: pointer;
        background-color: #fff;
        border: 1px solid #626669;
        .filter-icon {
          height: 16px;
          margin-left: 18px;
        }
      }

      .filter-menu-active {
        border-bottom-color: transparent;
      }
    }

    .filter-menu-wrap {
      position: absolute;
      top: 48px;
      left: 349px;
      z-index: 98;
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      width: 100%;
      max-width: 550px;
      padding: 24px 26px;
      background-color: #fff;
      border: 1px solid #626669;

      .filter-menu {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .filter-row {
          display: flex;
          gap: 16px;

          .vue-multiselect {
            flex-basis: 48%;
          }
        }
      }

      .filter-actions {
        display: flex;
        gap: 16px;
      }
    }

    .button-actions {
      display: flex;
      gap: 16px;
      align-items: center;
      margin-left: auto;

      .update {
        font-size: 14px;
      }
    }
  }

  .users-table {
    margin-top: 40px;

    hr {
      margin: 16px 0;
    }

    .labels,
    .user {
      display: flex;
      gap: 16px;
      span,
      div {
        flex: 1;

        &:nth-child(4) {
          flex-basis: 10%;
        }

        &:nth-child(5) {
          flex-basis: 15%;
        }

        &:nth-child(8) {
          flex-basis: 3%;
        }
      }
    }

    .labels {
      span {
        font-weight: 500;
      }
    }

    .user-wrap {
      position: relative;
    }

    .user {
      span,
      .pma-roles {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .pma-actions {
        display: flex;
        column-gap: 16px;
        .user-action {
          position: relative;
          flex: initial;

          .edit-icon {
            width: 12px;
            height: auto;
            cursor: pointer;

            path {
              fill: $primary;
            }
          }

          .report-icon {
            width: 20px;
            height: auto;
            cursor: pointer;
          }

          .tool-tip {
            top: 30px;
            right: -13px;
            padding: 8px 12px;
          }

          &:hover {
            .tool-tip {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
</style>
