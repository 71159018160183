<template>
  <div class="workbook-users" v-if="Object.keys(this.workbook).length > 0">
    <h1>Users in {{ this.workbook.title }}</h1>
    <div class="summary">
      <p>Users are added to workbooks based on the event/module IDs, titles, and wave data pulled in from PALMS.</p>
    </div>

    <div class="user-content">
      <!-- Search -->
      <div class="search">
        <IconSearch class="svg-icon" />
        <input type="text" placeholder="Search users..." v-model="searchQuery" @keypress.enter="getFilteredUsers" />
        <IconCancel v-show="searchQuery" @click="clearSearch" class="svg-icon cancel-btn" />
      </div>
      <!-- Filtering -->
      <div class="user-filter">
        <div class="filter-options">
          <div class="vue-multiselect display-count">
            <span class="label">Show:</span>
            <VueMultiselect
              @input="getFilteredUsers"
              v-model="displayCount"
              :searchable="false"
              :close-on-select="true"
              :show-labels="false"
              :options="displayCountOptions"
            />
          </div>
          <div class="vue-multiselect sort-by">
            <span class="label">Sort By:</span>
            <VueMultiselect
              @input="getFilteredUsers"
              v-model="sortBy"
              :searchable="false"
              label="title"
              :close-on-select="true"
              :show-labels="false"
              :options="sortByOptions"
            />
          </div>
          <div @click="toggleFilterMenu" class="filter flex" :class="{ 'filter-menu-active': filterMenu }">
            <p>Filter</p>
            <FilterIcon class="filter-icon" />
          </div>
        </div>
        <!-- Advanced Filter Menu -->
        <div v-show="filterMenu" class="filter-menu-wrap">
          <div class="filter-menu">
            <div class="column">
              <div class="vue-multiselect">
                <span class="label">Department:</span>
                <VueMultiselect
                  v-model="department"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  :options="departmentOptions"
                  placeholder="All departments"
                />
              </div>
              <div class="vue-multiselect">
                <span class="label">Region:</span>
                <VueMultiselect
                  v-model="region"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  :options="regionOptions"
                  placeholder="All regions"
                />
              </div>
              <div class="vue-multiselect">
                <span class="label">Area:</span>
                <VueMultiselect
                  v-model="area"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  :options="areaOptions"
                  placeholder="All areas"
                />
              </div>
              <div class="vue-multiselect">
                <span class="label">Market:</span>
                <VueMultiselect
                  v-model="market"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  :options="marketOptions"
                  placeholder="All markets"
                />
              </div>
            </div>
            <div class="column">
              <div class="vue-multiselect">
                <span class="label">Job Role:</span>
                <VueMultiselect
                  v-model="jobRole"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  :options="jobRoleOptions"
                  placeholder="All job roles"
                />
              </div>
              <div class="sessions-filter">
                <label>Sessions to Show</label>
                <div class="checkbox" v-for="(session, index) in sessionOptions" :key="index">
                  <input @change="addSessionFilter(session)" v-model="session.selected" type="checkbox" />
                  <span>{{ session.session }}</span>
                  <Check class="check-icon" />
                </div>
              </div>
            </div>
          </div>
          <div class="filter-actions">
            <PorscheButton @click="getFilteredUsers">
              <Arrow class="arrow-icon icon" />
              <span>Apply filters</span>
            </PorscheButton>
            <PorscheButton @click="clearFilters" :light="true">
              <Arrow class="arrow-icon icon" />
              <span>Clear filters</span>
            </PorscheButton>
          </div>
        </div>
        <PorscheButton @click="exportCSV(`workbook/${workbook.id}/users/csv`)">
          <Arrow class="arrow-icon icon" />
          <span>Export CSV</span>
        </PorscheButton>
      </div>

      <div class="users">
        <div class="labels-wrap">
          <div class="labels">
            <span>PPN ID</span>
            <span>Last</span>
            <span>First</span>
            <span>Email</span>
            <span>Job Role</span>
            <span>Dept.</span>
            <span>Partner No.</span>
            <span>Region</span>
            <span>Area</span>
            <span>Market</span>
            <span>Session</span>
          </div>
          <hr />
        </div>
        <div class="user-wrap">
          <Loading v-if="loadingUsers" type="section" />

          <!-- Users -->
          <div v-if="!loadingUsers && paginatedUsers.length > 0">
            <div v-for="(user, index) in paginatedUsers" :key="index">
              <div class="user">
                <span>{{ user.username ? user.username : '-' }}</span>
                <span>{{ user.lastName ? user.lastName : '-' }}</span>
                <span>{{ user.firstName ? user.firstName : '-' }}</span>
                <span>{{ user.email ? user.email : '-' }}</span>
                <span>{{ user.jobRoleTitle ? user.jobRoleTitle : '-' }}</span>
                <span>
                  {{ user['userOrganization.departmentName'] ? user['userOrganization.departmentName'] : '-' }}
                </span>
                <span>
                  {{ user['userOrganization.partnerNumber'] ? user['userOrganization.partnerNumber'] : '-' }}
                </span>
                <span>{{ user['userOrganization.region'] ? user['userOrganization.region'] : '-' }}</span>
                <span>{{ user['userOrganization.area'] ? user['userOrganization.area'] : '-' }}</span>
                <span>{{ user['userOrganization.market'] ? user['userOrganization.market'] : '-' }}</span>
                <span>{{ user['userSession.palmsId'] ? user['userSession.palmsId'] : '-' }}</span>
              </div>
              <hr />
            </div>
            <Pagination :pageCount="pageCount" @page-change="pageChange" :currentPage="activePage" />
          </div>

          <!-- No users matching search filters -->
          <div v-if="!loadingUsers && paginatedUsers.length < 1 && !apiError" class="no-users">
            <UsersIcon class="user-icon" />
            <p v-if="noUsers">
              There are no users in this workbook yet. <br />
              When users are added to the event and sessions in PPX Campus, they will appear here.
            </p>
            <p v-else>There are no users matching your request.</p>
          </div>

          <h2 class="header-error" v-if="apiError">Sorry, something went wrong. Please try again</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from '@/assets/images/Icons/arrow_right_white.svg';
import FilterIcon from '@/assets/images/Icons/filter.svg';
import Check from '@/assets/images/Icons/check.svg';
import UsersIcon from '@/assets/images/Icons/workbook_users.svg';
import PorscheButton from '@/components/PorscheButton.vue';
import VueMultiselect from '@/components/VueMultiselect.vue';
import Pagination from '@/components/Pagination.vue';
import Loading from '@/components/Loading.vue';
import ApiService from '@/utilities/ApiService';
import ExportCSV from '@/utilities/ExportCSV';
import { createNamespacedHelpers } from 'vuex';
import IconSearch from '../assets/images/Header/search.svg';
import IconCancel from '../assets/images/Header/cancel.svg';

const { mapState, mapActions } = createNamespacedHelpers('workbookBuilder');

export default {
  components: {
    Arrow,
    FilterIcon,
    Check,
    UsersIcon,
    VueMultiselect,
    Pagination,
    PorscheButton,
    Loading,
    IconSearch,
    IconCancel,
  },
  name: 'workbookUsers',
  props: {},
  data() {
    return {
      noUsers: null,
      apiError: null,
      loadingUsers: null,
      filterOptions: null,
      offset: 0,
      displayCount: 8,
      displayCountOptions: [8, 16, 24],
      sortBy: {
        title: 'PPN ID (A-Z)',
        value: 'title',
        order: 'ASC',
      },
      sortByOptions: [
        {
          title: 'PPN ID (A-Z)',
          value: 'title',
          order: 'ASC',
        },
        {
          title: 'PPN ID  (Z-A)',
          value: 'title',
          order: 'DESC',
        },
      ],
      filterMenu: null,
      department: null,
      departmentOptions: [],
      region: null,
      regionOptions: [],
      area: null,
      areaOptions: [],
      market: null,
      marketOptions: [],
      jobRole: null,
      jobRoleOptions: [],
      users: null,
      sessionOptions: [],
      sessions: [],
      paginatedUsers: [],
      userPages: {},
      activePage: 1,
      pageCount: null,
      searchQuery: '',
    };
  },
  async created() {
    // Do we have workbook stored in state?
    if (Object.keys(this.workbook).length > 0) {
      this.getUsers();
      return;
    }

    // No workbook store, get it
    try {
      await this.GET_WORKBOOK(this.$route.params.workbookId);
      this.getUsers();
    } catch (error) {
      console.warn(error.message);
    }
  },
  computed: {
    ...mapState(['workbook']),
  },
  watch: {},
  methods: {
    ...mapActions(['GET_WORKBOOK']),

    async getUsers() {
      try {
        this.loadingUsers = true;

        // Get Filter Options
        this.filterOptions = await ApiService.get(
          `${process.env.VUE_APP_API_URL}/filter/workbook/users/${this.$route.params.workbookId}`,
        );

        // set filter options (options returning null, causing issues)
        this.departmentOptions = this.filterOptions.data.data.departmentNames;
        this.regionOptions = this.filterOptions.data.data.regions;
        this.areaOptions = this.filterOptions.data.data.areas;
        this.marketOptions = this.filterOptions.data.data.markets;
        this.jobRoleOptions = this.filterOptions.data.data.jobRoleTitles;
        this.filterOptions.data.data.sessionIds.forEach((session) => {
          this.sessionOptions.push({
            session,
            selected: null,
          });
        });

        // get all users initially
        const getUsers = await ApiService.post(`${process.env.VUE_APP_API_URL}/workbook/filter/user`, {
          workbookId: this.$route.params.workbookId,
          department: null,
          region: null,
          area: null,
          market: null,
          jobRole: null,
          sessionIds: [],
          orderBy: this.sortBy.value,
          order: this.sortBy.order,
          limit: this.displayCount,
          offset: this.offset,
          search: this.searchQuery,
        });

        // pagination
        this.pageCount = Math.ceil(getUsers.data.data.count / this.displayCount);
        this.userPages[this.activePage] = getUsers.data.data.users;
        this.paginatedUsers = this.userPages[this.activePage];

        // No users? display no user content.
        if (this.paginatedUsers.length < 1) {
          this.noUsers = true;
        }

        this.loadingUsers = false;
      } catch (error) {
        console.warn(error.message);
        this.apiError = true;
        this.loadingUsers = false;
      }
    },

    async getFilteredUsers() {
      this.filterMenu = false;
      this.loadingUsers = true;
      try {
        const getUsers = await ApiService.post(`${process.env.VUE_APP_API_URL}/workbook/filter/user`, {
          workbookId: this.workbook.id,
          department: this.department ? this.department : null,
          region: this.region ? this.region : null,
          area: this.area ? this.area : null,
          market: this.market ? this.market : null,
          jobRole: this.jobRole ? this.jobRole : null,
          sessionIds: this.sessions.length > 0 ? this.sessions : [],
          orderBy: this.sortBy.value,
          order: this.sortBy.order,
          limit: this.displayCount,
          offset: this.offset,
          search: this.searchQuery,
        });

        this.pageCount = Math.ceil(getUsers.data.data.count / this.displayCount);
        this.userPages[this.activePage] = getUsers.data.data.users;
        this.paginatedUsers = this.userPages[this.activePage];

        this.loadingUsers = false;
      } catch (error) {
        console.warn(error);
        this.loadingUsers = false;
      }
    },

    clearFilters() {
      this.department = null;
      this.region = null;
      this.area = null;
      this.market = null;
      this.jobRole = null;
      this.sessions = [];
      this.sessionOptions.forEach((session) => {
        session.selected = null;
      });
      this.getFilteredUsers();
    },

    // ref utility function for exporting csv
    async exportCSV(path) {
      ExportCSV(path);
    },

    toggleFilterMenu() {
      this.filterMenu = !this.filterMenu;
    },

    async pageChange(page) {
      // Check if we already have page
      if (this.userPages[page]) {
        this.paginatedUsers = this.userPages[page];
        this.activePage = page;
        if (this.displayCount >= 16) {
          window.scrollTo(0, 0);
        }
        return;
      }

      // Get new page
      this.offset = (page - 1) * this.displayCount;
      this.activePage = page;
      await this.getFilteredUsers();
      this.paginatedUsers = this.userPages[page];
      if (this.displayCount >= 16) {
        window.scrollTo(0, 0);
      }
    },

    addSessionFilter(session) {
      // session exist?
      if (!session.selected) {
        this.sessions = this.sessions.filter((ses) => ses !== session.session);
        return;
      }
      this.sessions.push(session.session);
    },

    clearSearch() {
      this.searchQuery = '';
      this.getFilteredUsers();
    },
  },
};
</script>

<style lang="scss" scoped>
.workbook-users {
  margin-top: 40px;

  h1 {
    font-size: 36px;
  }

  .summary {
    display: flex;
    margin: 8px 0 28px;
  }

  .user-content {
    position: relative;
    min-height: 70vh;

    .search {
      position: relative;
      display: flex;
      align-items: center;
      min-height: 62px;
      margin: 25px 0;

      .svg-icon {
        width: 24px;
      }
      input {
        flex-grow: 1;
        height: 40px;
        padding-right: 24px;
        padding-left: 4px;
        margin-left: 24px;
        font-size: 16px;
        border: 0;
        border-bottom: 1px solid #c9cacb;
      }
      .cancel-btn {
        position: absolute;
        right: 0;
        width: 20px;
        height: 100%;
      }
    }

    .user-filter {
      position: relative;
      display: flex;
      margin: 25px 0;

      .filter-options {
        z-index: 99;
        display: flex;
        flex: 1;
        gap: 10px;

        .filter {
          position: relative;
          z-index: 99;
          align-items: center;
          padding: 10px 12px;
          cursor: pointer;
          background-color: #fff;
          border: 1px solid #626669;
          .filter-icon {
            height: 16px;
            margin-left: 18px;
          }
        }

        .filter-menu-active {
          border-bottom-color: transparent;
        }
      }

      .filter-menu-wrap {
        position: absolute;
        top: 48px;
        left: 349px;
        z-index: 98;
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        width: 100%;
        max-width: 550px;
        padding: 24px 26px;
        background-color: #fff;
        border: 1px solid #626669;

        .filter-menu {
          display: flex;
          gap: 16px;

          .column {
            flex: 1;

            .filter-item {
              margin-bottom: 16px;
            }

            .sessions-filter {
              label {
                display: block;
                margin-bottom: 8px;
              }

              .checkbox {
                margin-bottom: 8px;

                .check-icon {
                  opacity: 0;
                }

                input[type='checkbox']:checked ~ .check-icon {
                  opacity: 1;
                }

                span {
                  font-size: 14px;
                }
              }
            }
          }
        }

        .filter-actions {
          display: flex;
          gap: 16px;
        }
      }
    }

    .users {
      position: relative;
      display: flex;
      flex-direction: column;
      min-height: 70vh;
      margin-top: 40px;

      .labels,
      .user {
        display: flex;
        gap: 16px;
        span {
          flex: 1;
          &:last-child {
            flex: 3;
          }
        }
      }

      hr {
        margin: 16px 0;
      }

      .labels-wrap {
        .labels {
          span {
            font-weight: 500;
          }
        }
      }

      .user-wrap {
        position: relative;

        .user {
          span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .no-users {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 270px;
          color: $grey-light;
          border: 1px solid #707070;

          .user-icon {
            width: auto;
            height: 65px;
            margin-bottom: 24px;
            path {
              fill: $grey-light;
            }
          }

          p {
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
