import ApiService from '@/utilities/ApiService';
import GetPresigned from '@/utilities/GetPresigned';
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    workbook: {},
    moduleNavigation: [],
    moduleInfo: {},
    sectionInfo: {},
  },
  mutations: {
    SET_WORKBOOK(state, payload) {
      const result = payload.data.data;

      state.workbook = {
        ...result,
      };

      result.modules.forEach((res) => {
        res.accessCodeValue = null;
      });

      state.moduleNavigation = result.modules;
    },

    SET_MODULE(state, payload) {
      state.moduleInfo = {
        module: payload.module.data.data,
        assets: payload.assets.data.data,
        questionTypes: payload.questions.data.data,
        quizzes: payload.keyQuizData,
        surveys: payload.keySurveyData,
      };
    },

    SET_MODULE_ACCESS_CODE(state, { index, accessCode }) {
      const navItem = state.moduleNavigation[index];
      navItem.accessCodeValue = accessCode;
      Vue.set(state.moduleNavigation, index, navItem);
    },

    SET_SECTION(state, payload) {
      state.sectionInfo = {
        section: payload.section.data.data,
        assets: payload.getAssets ? payload.getAssets : null,
        assetTypes: payload.keyedAssetTypes,
        hotspotTypes: payload.keyHotspotPinTypes,
        notes: payload.notes.data.data,
      };
    },
  },
  actions: {
    async GET_WORKBOOK({ commit }, workbookId) {
      try {
        const wb = await ApiService.get(`${process.env.VUE_APP_API_URL}/workbook/${workbookId}`);
        commit('SET_WORKBOOK', wb);
      } catch (error) {
        throw new Error(error);
      }
    },

    async GET_MODULE({ commit }, { moduleId, accessCode, isParticipant }) {
      try {
        const dataArray = [
          ApiService.get(`${process.env.VUE_APP_API_URL}/workbook/module/${moduleId}`, { accessCode }),
          ApiService.get(`${process.env.VUE_APP_API_URL}/workbook/module/assets/${moduleId}`),
          ApiService.get(`${process.env.VUE_APP_API_URL}/question-type/`),
        ];

        const [module, assets, questions] = await ApiService.all(dataArray);

        const quizzesArray = [];
        const surveyArray = [];
        const surveyQuestionTypes = [];

        // ref to each type of question type
        questions.data.data.forEach((type) => {
          if (type.isSurveyOption) {
            surveyQuestionTypes[type.type] = type;
          }
        });

        // getting additional data needed for quiz & survey
        assets.data.data.forEach((asset) => {
          if (asset.module_asset.type === 'quiz') {
            quizzesArray.push(ApiService.get(`${process.env.VUE_APP_API_URL}/quiz/${asset.id}`));
          }
          if (asset.module_asset.type === 'survey') {
            surveyArray.push(ApiService.get(`${process.env.VUE_APP_API_URL}/survey/${asset.id}`));
          }
        });

        // creating quiz data structure
        const quizData = await ApiService.all(quizzesArray);
        const keyQuizData = {};
        quizData.forEach((quiz) => {
          keyQuizData[quiz.data.data.id] = quiz.data.data;

          // set answer values
          keyQuizData[quiz.data.data.id].quizQuestion.forEach((question) => {
            let correctAnswerId = null;
            question.quizQuestionOption.forEach((option) => {
              option.answer = isParticipant ? false : option.isAnswer;
              if (option.isAnswer) {
                correctAnswerId = option.id;
              }
            });
            question.answer = isParticipant ? false : correctAnswerId;
            question.invalid = false;
          });
        });

        // creating survey data structure
        const surveyData = await ApiService.all(surveyArray);
        const keySurveyData = {};
        surveyData.forEach((survey) => {
          keySurveyData[survey.data.data.id] = survey.data.data;

          // check if survey has been taken
          if (survey.data.data.takenSurvey) {
            keySurveyData[survey.data.data.id].surveyQuestion.forEach((question) => {
              question.surveyQuestionOption.forEach((option) => {
                // if value greater then 0, means this was users choice
                if (option.userSurveyQuestionOptionResponse.length > 0) {
                  // dropdown requires whole object
                  if (surveyQuestionTypes['Dropdown Selection'].id === question.questionTypeId) {
                    question.answer = option;
                    return;
                  }
                  question.answer = option.id;
                  option.answer = option.id;
                }
              });
            });
            return;
          }

          // set empty answer values
          keySurveyData[survey.data.data.id].surveyQuestion.forEach((question) => {
            // single & multiline questions, need to be strings
            question.invalid = false;
            if (
              surveyQuestionTypes['Multi-line response'].id === question.questionTypeId ||
              surveyQuestionTypes['Single-line response'].id === question.questionTypeId
            ) {
              question.answer = '';
            } else {
              question.answer = false;
            }
            question.surveyQuestionOption.forEach((option) => {
              option.answer = false;
            });
          });
        });

        const completeModule = {
          module,
          assets,
          questions,
          keyQuizData,
          keySurveyData,
        };

        commit('SET_MODULE', completeModule);
      } catch (error) {
        throw new Error(error.response.status);
      }
    },

    async GET_SECTION({ commit }, { sectionId, userId }) {
      try {
        const dataArray = [
          ApiService.get(`${process.env.VUE_APP_API_URL}/workbook/module/section/${sectionId}`),
          ApiService.get(`${process.env.VUE_APP_API_URL}/asset-type/`),
          ApiService.get(`${process.env.VUE_APP_API_URL}/hotspot-pin-type/`),
          ApiService.get(`${process.env.VUE_APP_API_URL}/users/${userId}/notes?moduleSectionId=${sectionId}`),
        ];
        let getAssets = null;

        const [section, assetTypes, hotspotPinTypes, notes] = await ApiService.all(dataArray);

        // key assetTypes
        const keyedAssetTypes = [];
        assetTypes.data.data.forEach((type) => {
          keyedAssetTypes[type.name] = type;
        });

        const keyHotspotPinTypes = [];
        hotspotPinTypes.data.data.forEach((type) => {
          keyHotspotPinTypes[type.id] = type;
        });

        if (section.data.data.assets.length > 0) {
          getAssets = await ApiService.get(
            `${process.env.VUE_APP_API_URL}/workbook/module/section/assets/${sectionId}`,
          );
          const data = await GetPresigned.wvGetPresigned(getAssets.data.data);

          data.forEach((asset) => {
            // add hotpot pin type to data
            if (asset.assetTypeId === 8) {
              asset.assetPin.forEach((pin) => {
                pin.type = keyHotspotPinTypes[pin.hotspotPinTypeId].name;
              });
            }
            asset.linkCopied = null;
          });
          getAssets = data;
        }

        // check if notes have length
        if (notes.data.data.length > 0) {
          notes.data.data.forEach((note) => {
            note.noteActive = null;
            note.editActive = null;
          });
        }

        const completeSection = {
          section,
          getAssets: getAssets || null,
          keyedAssetTypes,
          keyHotspotPinTypes,
          notes,
        };

        commit('SET_SECTION', completeSection);
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
