<template>
  <div class="section-assets">
    <Modal v-if="mediaModal" :modalType="'video'" :modalContent="mediaModalContent" @close-modal="closeMediaModal" />

    <div class="asset">
      <!-- Reorder Content -->
      <div class="flex reorder">
        <Dots class="dots-icon" />
        <span>Click and drag to reorder</span>
      </div>

      <!-- Conditional Headers -->
      <div class="header">
        <div class="asset-type" @click="toggleAsset">
          <Pdf v-if="asset.type === 'PDF'" class="icon" />
          <ImageAsset v-if="asset.type === 'Image'" class="icon" />
          <VideoAsset v-if="asset.type === 'Video'" class="icon" />
          <Slides v-if="asset.type === 'Slides'" class="icon" />
          <LinkAsset v-if="asset.type === 'Links'" class="icon" />
          <TextAsset v-if="asset.type === 'Text'" class="icon" />
          <Hotspot v-if="asset.type === 'Hotspot'" class="icon" />
          <h3>
            <span v-if="localAsset.title">{{ localAsset.title }} -</span> <span>{{ asset.type }}</span>
          </h3>
        </div>
        <div class="asset-toggle">
          <transition-group name="progress-update">
            <span :key="1" class="status-msg error" v-show="error">
              <CancelIcon class="cancel-icon" /> {{ statusMsg }}
            </span>
            <span :key="2" class="status-msg success" v-show="success">
              <Check class="check-icon" /> Success: {{ statusMsg }}
            </span>
          </transition-group>
          <ArrowDown @click="toggleAsset" :class="{ 'arrow-active': assetVisible }" class="chevron" />
        </div>
      </div>

      <div v-if="assetVisible">
        <!-- Loading -->
        <Loading type="section" v-if="creatingAsset" />
        <!-- Navigation -->

        <div class="asset-navigation">
          <ul>
            <li @click="toggleAssetNavigation(1)" :class="{ 'active-item': createNewActive }" class="nav-link">
              Create New
            </li>
            <li
              v-if="asset.type !== 'Text' && asset.type !== 'Hotspot'"
              @click="toggleAssetNavigation(2)"
              :class="{ 'active-item': importActive }"
              class="nav-link"
            >
              Import from Content Library
            </li>
          </ul>
        </div>

        <div v-if="createNewActive" class="create-new">
          <!-- Title -->
          <div class="input">
            <label for="title">Title</label>
            <input type="text" id="title" v-model.lazy="localAsset.title" placeholder="Title" />
          </div>

          <!-- PDF, Image, Video & Slides -->
          <div
            v-if="asset.type === 'PDF' || asset.type === 'Image' || asset.type === 'Video' || asset.type === 'Slides'"
          >
            <!-- Content -->
            <div class="content-upload flex flex-row">
              <div class="input">
                <label for="file-upload" ref="fileUploadLabel">Upload</label>
                <div :class="{ disabled: localAsset.url }" class="file-upload">
                  <p class="file-name" v-if="localAsset.file">{{ localAsset.fileName }}</p>
                  <div v-if="localAsset.file" @click="removeFile" class="icon-wrap">
                    <CancelIcon class="cancel-icon" />
                    <span>Remove</span>
                  </div>
                  <label v-if="!localAsset.file" for="file-upload" class="icon-wrap">
                    <UploadIcon class="upload-icon" />
                    <span>Choose file</span>
                  </label>
                  <!-- PDF -->
                  <input
                    v-if="asset.type === 'PDF'"
                    accept=".pdf"
                    @change="fileChange"
                    ref="file"
                    type="file"
                    id="file-upload"
                  />
                  <!-- Image -->
                  <input
                    v-if="asset.type === 'Image'"
                    accept=".jpg, .jpeg, .png, .gif"
                    @change="fileChange"
                    ref="file"
                    type="file"
                    id="file-upload"
                  />
                  <!-- Video -->
                  <input
                    v-if="asset.type === 'Video'"
                    accept=".mov, .mp4"
                    @change="fileChange"
                    ref="file"
                    type="file"
                    id="file-upload"
                  />
                  <!-- Slides -->
                  <input
                    v-if="asset.type === 'Slides'"
                    accept=".pdf"
                    @change="fileChange"
                    ref="file"
                    type="file"
                    id="file-upload"
                  />
                </div>

                <span v-if="asset.type === 'PDF'" class="disclaimer">PDF uploads cannot exceed 2 MB in size.</span>
                <span v-if="asset.type === 'Image'" class="disclaimer"
                  >Image uploads cannot exceed 2 MB in size. Accepted file types: JPG, PNG, GIF.</span
                >
                <span v-if="asset.type === 'Video'" class="disclaimer"
                  >Video uploads cannot exceed 500 MB in size. Accepted file types: MOV, MP4</span
                >
                <span v-if="asset.type === 'Slides'" class="disclaimer"
                  >Slideshow uploads cannot exceed 50 MB in size. Accepted file type: PDF</span
                >
              </div>

              <span class="or">OR</span>

              <div class="input">
                <label for="">URL</label>
                <input
                  @input="urlChange"
                  :class="{ disabled: localAsset.file }"
                  type="text"
                  v-model="localAsset.url"
                  placeholder="URL"
                  ref="assetURL"
                />
              </div>
            </div>
          </div>

          <!-- Link & Text  -->
          <div v-if="asset.type === 'Links' || asset.type === 'Text'">
            <div v-if="asset.type !== 'Text'" class="input">
              <label>URL</label>
              <input type="text" v-model="localAsset.url" placeholder="URL" />
            </div>
          </div>

          <!-- Hotspot -->
          <div v-if="asset.type === 'Hotspot'">
            <div class="hotspot-creator">
              <div class="content-upload">
                <div class="input">
                  <label>Upload</label>

                  <div class="file-upload">
                    <p v-if="localAsset.file">{{ localAsset.fileName }}</p>
                    <div v-if="localAsset.file" @click="removeHotspotImg" class="icon-wrap remove-wrap">
                      <CancelIcon class="remove-icon" />
                      <span>Remove</span>
                    </div>
                    <label v-else for="file-upload" class="icon-wrap">
                      <UploadIcon class="upload-icon" />
                      <span>Choose file</span>
                    </label>
                    <input
                      accept=".jpg, .jpeg, .png"
                      @change="hotspotImgChange"
                      ref="file"
                      type="file"
                      id="file-upload"
                    />
                  </div>

                  <PorscheButton v-if="!localfileURL && !localAsset.url" for="file-upload" :label="true" type="button">
                    <Arrow class="arrow-icon icon" />
                    <span>Upload new image</span>
                  </PorscheButton>

                  <span v-if="!localfileURL && !localAsset.url" class="disclaimer">
                    Image uploads cannot exceed 2 MB in size. Accepted file types: JPG, PNG.
                  </span>
                </div>
                <div v-if="localfileURL || localAsset.url" class="add-pin" @click="addPin">
                  <AddIcon class="add-icon icon" />
                  <span v-if="addHotspotPin">Select anywhere on the image to add a pin</span>
                  <span v-else>Add pin</span>
                </div>
                <div class="hotspot-img">
                  <img @click="addHotspotMarker" v-if="localfileURL" :src="localfileURL" alt="" />
                  <img @click="addHotspotMarker" v-else-if="localAsset.url" :src="localAsset.url" alt="" />

                  <div class="hotspot-markers">
                    <span
                      v-for="(marker, index) in localAsset.pins"
                      :key="index"
                      :style="{ left: `${marker.placementX}%`, top: `${marker.placementY}%` }"
                    >
                      {{ index + 1 }}
                    </span>
                  </div>
                </div>
              </div>
              <div v-if="localfileURL || localAsset.url" class="hotspot-pins">
                <div v-for="(pin, index) in localAsset.pins" :key="index" class="pin">
                  <span class="display-pin">
                    {{ index + 1 }}
                  </span>
                  <ul class="hotspot-types">
                    <li
                      @click="updateHotspotType(type, index)"
                      :id="type.id"
                      v-for="(type, typeIndex) in hotspotTypes"
                      :key="typeIndex"
                      class="nav-link"
                      :class="{ 'active-item': pin.hotspotPinTypeId === type.id }"
                    >
                      {{ type.name }}
                    </li>
                  </ul>
                  <div v-if="pin.hotspotPinTypeId !== hotspotKeyedTypes['Text'].id" class="input">
                    <label :for="`hotspot-asset-${index}`">Upload</label>
                    <div class="file-upload">
                      <p v-if="pin.file">{{ pin.fileName }}</p>
                      <div v-if="pin.file" @click="removeHotspotAsset(index)" class="icon-wrap">
                        <CancelIcon class="cancel-icon" />
                        <span>Remove</span>
                      </div>
                      <input
                        @change="hotspotAssetChange(index)"
                        :class="`hotspot-asset-${index}`"
                        type="file"
                        :id="`hotspot-asset-${index}`"
                      />
                    </div>
                    <PorscheButton :for="`hotspot-asset-${index}`" :label="true" type="button">
                      <Arrow class="arrow-icon icon" />
                      <span>Upload new image</span>
                    </PorscheButton>
                  </div>
                  <div class="pin-notes">
                    <label for="add-notes">Additional notes</label>
                    <textarea name="add-notes" id="add-notes" v-model="pin.text"></textarea>
                  </div>
                  <div class="pin-actions">
                    <div @click="deletePin(pin, index)" class="action">
                      <Delete class="delete-icon" />
                      <span>Delete pin</span>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>

          <div class="notes-navigation">
            <ul>
              <li @click="toggleNotes(1)" :class="{ 'active-item': participantNotes }" class="nav-link">
                Participant Guide Description
              </li>
              <li @click="toggleNotes(2)" :class="{ 'active-item': instructorNotes }" class="nav-link">
                Instructor Guide Notes
              </li>
            </ul>
          </div>

          <div v-show="participantNotes" class="textarea">
            <label for="workbookDescription">Participant Guide Description</label>
            <quill-editor
              ref="myQuillEditor"
              class="quill-editors"
              :options="editorOption"
              v-model.lazy="localAsset.description"
            />
          </div>

          <div v-show="instructorNotes" class="textarea">
            <label for="workbookDescription">Instructor Guide Notes</label>
            <quill-editor
              ref="myQuillEditor"
              class="quill-editors"
              :options="editorOption"
              v-model.lazy="localAsset.note"
            />
          </div>

          <div class="asset-actions">
            <div class="toggle-switch">
              <span> Inactive </span>
              <input type="checkbox" v-model="localAsset.active" />
              <span> Active </span>
            </div>
            <div @click="promptDelete" class="flex">
              <Delete class="delete-icon" />
              <span>Delete {{ localAsset.type }}</span>
            </div>
            <PorscheButton @click="isDataBaseAsset ? updateAsset() : createAsset()" type="button">
              <Arrow class="arrow-icon icon" />
              <span>
                <span v-if="isDataBaseAsset">Update</span>
                <span v-else>Save</span>
                {{ localAsset.type }}
              </span>
            </PorscheButton>
          </div>
        </div>

        <!-- Import from content lib -->
        <div v-if="importActive">
          <div class="content-lib-search">
            <div class="search-heading">
              <span>Content Library</span>
              <span>|</span>
              <span>{{ asset.type }}s ({{ this.assetCount }}) </span>
            </div>

            <div class="input search-query">
              <input
                @keyup="queryContentSearch"
                type="text"
                placeholder="Search Content Library"
                v-model="contentLibQuery"
              />
              <div class="icon-wrap">
                <SearchIcon class="search-icon icon" />
              </div>
            </div>

            <div class="search-results">
              <div class="search-asset" v-for="(asset, index) in paginatedContentPage" :key="asset.id">
                <div class="asset-info">
                  <div class="asset-content">
                    <div class="image">
                      <img
                        v-if="asset.type === 'Video' && asset.thumbnailDisplayLink"
                        :src="asset.thumbnailDisplayLink"
                        alt=""
                      />
                      <img v-else-if="asset.type === 'Image' && asset.displayLink" :src="asset.displayLink" alt="" />
                      <img
                        v-else
                        :src="require(`@/assets/images/ContentLibrary/content_${asset.type.toLowerCase()}@2x.png`)"
                        alt=""
                      />
                    </div>
                    <div class="asset-details">
                      <h3>{{ asset.title }}</h3>
                      <div v-if="asset.type === 'Video'" class="view-media" @click="viewMedia(asset)">
                        <Arrow class="arrow-icon icon" />
                        <span>View media</span>
                      </div>
                      <div class="tags">
                        <span class="tag">{{ asset.type }}</span>
                        <span v-if="asset.vehicleModel" class="tag">{{ asset.vehicleModel }}</span>
                        <span v-if="asset.vehicleYear" class="tag">{{ asset.vehicleYear }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="asset-select" @click="getAsset(asset)">
                    <Arrow class="arrow-icon icon" />
                    <span>Choose file</span>
                  </div>
                </div>
                <hr v-if="paginatedContentPage.length !== index + 1" />
              </div>
            </div>

            <Pagination :pageCount="pageCount" @page-change="pageChange" :currentPage="activePage" />
          </div>
        </div>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { uid } from 'uid';
import Arrow from '@/assets/images/Icons/arrow_right_white.svg';
import ApiService from '@/utilities/ApiService';
import AddIcon from '@/assets/images/Icons/add.svg';
import Modal from '@/components/Modal.vue';
import Loading from '@/components/Loading.vue';
import GetPresigned from '../utilities/GetPresigned';
import Dots from '../assets/images/Icons/dots_vertical.svg';
import ArrowDown from '../assets/images/Icons/arrow_right_red.svg';
import Pdf from '../assets/images/Icons/icon_pdf.svg';
import ImageAsset from '../assets/images/Icons/icon_image.svg';
import VideoAsset from '../assets/images/Icons/icon_video.svg';
import Slides from '../assets/images/Icons/icon_slideshow.svg';
import LinkAsset from '../assets/images/Icons/icon_link.svg';
import TextAsset from '../assets/images/Icons/icon_text.svg';
import Hotspot from '../assets/images/Icons/icon_hotspot.svg';
import PorscheButton from './PorscheButton.vue';
import Delete from '../assets/images/Icons/delete.svg';
import S3Api from '../utilities/S3Api';
import CancelIcon from '../assets/images/Icons/cancel.svg';
import UploadIcon from '../assets/images/Icons/upload.svg';
import Check from '../assets/images/Icons/check.svg';
import SearchIcon from '../assets/images/Icons/search.svg';
import Pagination from './Pagination.vue';

const { mapMutations } = createNamespacedHelpers('workbookBuilder');

export default {
  name: 'section-assets',
  components: {
    PorscheButton,
    Arrow,
    CancelIcon,
    UploadIcon,
    Delete,
    Dots,
    ArrowDown,
    Pdf,
    ImageAsset,
    VideoAsset,
    Slides,
    LinkAsset,
    TextAsset,
    Hotspot,
    Check,
    AddIcon,
    SearchIcon,
    Pagination,
    Modal,
    Loading,
  },
  props: ['asset', 'index', 'sectionId', 'assetVisible'],
  data() {
    return {
      localAsset: { ...this.asset },
      originalLocalAsset: JSON.parse(JSON.stringify(this.asset)),
      contentLibAsset: null,
      creatingAsset: null,
      success: false,
      error: false,
      statusMsg: '',
      awsIdentifier: this.asset.awsIdentifier,
      assetContent: false,
      createNewActive: true,
      participantNotes: true,
      instructorNotes: false,
      importActive: null,
      uploadCSVActive: null,
      localfileURL: null,
      addHotspotPin: null,
      hotspotTypes: null,
      hotspotKeyedTypes: {},
      editorOption: {
        modules: {
          toolbar: [
            { header: [1, 2, 3, 4, 5, 6, false] },
            'bold',
            'italic',
            { list: 'ordered' },
            { list: 'bullet' },
            'link',
          ],
        },
      },
      mediaModal: null,
      mediaModalContent: null,
      contentLibQuery: null,
      queryTimeout: null,
      assetCount: 0,
      contentPages: {},
      paginatedContentPage: [],
      offset: 0,
      pageCount: 0,
      activePage: 0,
    };
  },
  created() {
    if (this.localAsset.type === 'Hotspot') {
      this.getHotspotData();
    }
    this.getContentLibraryData();
  },
  computed: {
    isDataBaseAsset() {
      return this.localAsset.id.length > 11 || this.localAsset.newId;
    },
  },
  watch: {
    localAsset: {
      deep: true,
      handler() {
        if (JSON.stringify(this.originalLocalAsset) !== JSON.stringify(this.localAsset)) {
          this.SET_ISDIRTY();
          return;
        }
        this.RESET_ISDIRTY();
      },
    },
  },
  methods: {
    ...mapMutations(['UPDATE_SECTION_ASSET', 'SET_ISDIRTY', 'RESET_ISDIRTY']),

    HTTPSuccess(msg) {
      this.success = true;
      this.statusMsg = msg;
      setTimeout(() => {
        this.success = false;
      }, 5000);
    },

    HTTPError(msg) {
      this.error = true;
      this.statusMsg = msg;
      setTimeout(() => {
        this.error = false;
      }, 5000);
    },

    async getHotspotData() {
      try {
        const types = await ApiService.get(`${process.env.VUE_APP_API_URL}/hotspot-pin-type/`);
        this.hotspotTypes = types.data.data;

        // creating object keyed to compare value inside of hotspot
        types.data.data.forEach((type) => {
          this.hotspotKeyedTypes[type.name] = type;
        });
      } catch (error) {
        console.warn(error);
      }
    },

    async getContentLibraryData() {
      const assetType = [];
      assetType.push(this.asset.type);
      try {
        const assets = await ApiService.post(`${process.env.VUE_APP_API_URL}/content-library`, {
          types: assetType,
          titleOrder: 'ASC',
          limit: 3,
          offset: this.offset,
          query: this.contentLibQuery,
        });

        this.assetCount = assets.data.data.count;
        this.pageCount = Math.ceil(this.assetCount / 3);

        // display image
        if (this.asset.type === 'Image' || this.asset.type === 'Video') {
          const data = await GetPresigned.clGetPresigned(assets.data.data.assets);
          this.contentPages[this.activePage] = data;
        } else {
          assets.data.data.assets.forEach((asset) => {
            asset.displayLink = null;
          });
          this.contentPages[this.activePage] = assets.data.data.assets;
        }
        this.paginatedContentPage = this.contentPages[this.activePage];
      } catch (error) {
        console.warn(error);
      }
    },

    queryContentSearch() {
      clearTimeout(this.queryTimeout);
      this.queryTimeout = setTimeout(() => {
        this.getContentLibraryData();
      }, 500);
    },

    // Displays the modal containing the media (Only for Video currently)
    viewMedia(asset) {
      this.mediaModalContent = {
        title: asset.title,
        video: asset.displayLink,
      };
      this.mediaModal = true;
    },

    closeMediaModal() {
      this.mediaModal = false;
    },

    // Get selected asset, set data to local asset & toggle view
    async getAsset(asset) {
      try {
        // store original assigned id && type
        const assetId = this.localAsset.newId ? this.localAsset.newId : this.localAsset.id;
        const assetType = this.localAsset.type;

        // get asset data
        const assetData = await ApiService.get(`${process.env.VUE_APP_API_URL}/asset/${asset.id}`);
        this.localAsset = assetData.data.data;
        this.localAsset.id = assetId;
        this.localAsset.type = assetType;
        // if identifier exists, set file to true for UI visual
        if (this.localAsset.awsIdentifier) {
          this.localAsset.file = true;
          this.awsIdentifier = this.localAsset.awsIdentifier;
        }

        this.toggleAssetNavigation(1);
        this.contentLibAsset = true;
      } catch (error) {
        console.warn(error.message);
      }
    },

    async pageChange(page) {
      // Check if we already have page
      if (this.contentPages[page]) {
        this.paginatedContentPage = this.contentPages[page];
        this.activePage = page;
        return;
      }

      // Get new page
      this.offset = (page - 1) * 3;
      this.activePage = page;
      await this.getContentLibraryData();
      this.paginatedContentPage = this.contentPages[page];
    },

    // toggles asset content view
    toggleAsset() {
      this.$emit('toggleAssetView', this.index);
    },

    // toggles asset navigation view
    toggleAssetNavigation(listItem) {
      if (listItem === 1) {
        this.createNewActive = true;
        this.importActive = false;
        return;
      }
      if (listItem === 2) {
        this.createNewActive = false;
        this.importActive = true;
      }
    },

    fileChange() {
      this.localAsset = { ...this.localAsset, file: this.$refs.file.files[0], fileName: this.$refs.file.files[0].name };
      // need to disable the url input
      this.$refs.assetURL.setAttribute('disabled', 'true');
    },

    removeFile() {
      this.localAsset.file = null;
      this.localAsset.fileName = null;

      // clear out identifier, on both data values
      this.localAsset.awsIdentifier = null;
      this.awsIdentifier = null;

      // remove disabled attribute
      this.$refs.assetURL.removeAttribute('disabled');
    },

    hotspotImgChange() {
      this.localAsset.file = this.$refs.file.files[0];
      this.localAsset.fileName = this.localAsset.file.name;
      this.localfileURL = URL.createObjectURL(this.$refs.file.files[0]);
    },

    removeHotspotImg() {
      this.localAsset.file = false;
      this.localAsset.fileName = false;
      this.localfileURL = false;
      this.localAsset.url = null;
      this.localAsset.pins = [];
    },

    addPin() {
      this.addHotspotPin = true;
    },

    deletePin(pin) {
      if (this.localAsset.pins.length > 1) {
        this.localAsset.pins = this.localAsset.pins.filter((localPin) => localPin.id !== pin.id);
      }
    },

    addHotspotMarker(e) {
      if (this.addHotspotPin) {
        this.localAsset.pins.push({
          id: uid(),
          hotspotPinTypeId: this.hotspotTypes[0].id,
          text: '',
          awsIdentifier: null,
          file: null,
          fileName: false,
          placementX: (e.offsetX / e.target.width) * 100,
          placementY: (e.offsetY / e.target.height) * 100,
        });
        this.addHotspotPin = false;
      }
    },

    updateHotspotType(type, index) {
      // updating selected type
      const allNavItems = document.querySelectorAll('.hotspot-types .nav-link');
      let targetedType;
      allNavItems.forEach((item) => {
        item.classList.remove('active-item');
        if (item.getAttribute('id') === type.id) {
          targetedType = item;
        }
      });
      targetedType.classList.add('active-item');

      // changing pin type
      this.localAsset.pins[index].hotspotPinTypeId = type.id;
    },

    hotspotAssetChange(index) {
      const fileInput = document.querySelector(`.hotspot-asset-${index}`);
      this.localAsset.pins[index].file = fileInput.files[0];
      this.localAsset.pins[index].fileName = fileInput.files[0].name;
    },

    removeHotspotAsset(index) {
      this.localAsset.pins[index].file = null;
      this.localAsset.pins[index].fileName = null;
    },

    urlChange() {
      const porscheButton = document.querySelector('.content-upload .input .porsche-button');
      if (this.localAsset.url.length === 0) {
        porscheButton.setAttribute('for', 'file-upload');
        this.$refs.assetURL.setAttribute('for', 'file-upload');
        return;
      }
      this.$refs.assetURL.removeAttribute('for');
      porscheButton.removeAttribute('for');
    },

    toggleNotes(item) {
      if (item === 1) {
        this.participantNotes = true;
        this.instructorNotes = false;
        return;
      }
      this.participantNotes = false;
      this.instructorNotes = true;
    },

    promptDelete() {
      this.$emit('prompt-delete', this.localAsset);
    },

    async uploadToS3() {
      try {
        const fileExtension = `${this.$refs.file.files[0].name.split('.').pop()}`;
        const s3 = await ApiService.get(`${process.env.VUE_APP_API_URL}/asset/presignedPut?extension=${fileExtension}`);
        this.awsIdentifier = s3.data.data.awsIdentifier;
        await S3Api(s3.data.data.presignedPut, this.$refs.file.files[0]);
      } catch (error) {
        throw new Error(error);
      }
    },

    async uploadToS3Hotspot() {
      return new Promise((res, rej) => {
        for (let i = 0; i < this.localAsset.pins.length; i += 1) {
          const pin = this.localAsset.pins[i];
          if (pin.file) {
            try {
              const fileExtension = pin.file.name.split('.').pop();
              ApiService.get(`${process.env.VUE_APP_API_URL}/asset/presignedPut?extension=${fileExtension}`).then(
                (s3) => {
                  pin.awsIdentifier = s3.data.data.awsIdentifier;
                  S3Api(s3.data.data.presignedPut, pin.file);
                },
              );
            } catch (error) {
              throw new Error(rej);
            }
          }
        }
        res();
      });
    },

    assetValidation() {
      // PDF, Image, Video & Slides
      if (
        this.localAsset.type === 'PDF' ||
        this.localAsset.type === 'Image' ||
        this.localAsset.type === 'Video' ||
        this.localAsset.type === 'Slides'
      ) {
        if (!this.localAsset.title || (!this.localAsset.file && !this.localAsset.url)) {
          throw new Error('Ensure all fields are completed.');
        }
      }
      // Links
      if (this.localAsset.type === 'Links') {
        if (!this.localAsset.title || !this.localAsset.url) {
          throw new Error('Ensure all fields are completed.');
        }
      }
      // Text
      if (this.localAsset.type === 'Text') {
        if (!this.localAsset.title) {
          throw new Error('Ensure all fields are completed.');
        }
      }
      if (this.localAsset.type === 'Hotspot') {
        if (!this.localAsset.title || !this.localAsset.file || this.localAsset.pins.length === 0) {
          throw new Error('Ensure all fields are completed.');
        }
      }
    },

    async createAsset() {
      try {
        this.assetValidation();

        // start loading
        this.creatingAsset = true;

        if (this.localAsset.type === 'Hotspot') {
          await this.uploadToS3Hotspot();
        }

        // check for file upload to S3
        if (this.localAsset.file && this.localAsset !== 'Hotspot' && !this.awsIdentifier) {
          await this.uploadToS3();
        }

        const createAsset = await ApiService.post(`${process.env.VUE_APP_API_URL}/asset`, {
          sectionId: this.sectionId,
          active: this.localAsset.active,
          order: this.index,
          assetTypeId: this.localAsset.assetTypeId,
          title: this.localAsset.title,
          fileName: this.localAsset.fileName ? this.localAsset.fileName : '',
          url: this.localAsset.url,
          vehicleYear: null,
          vehicleModel: null,
          awsIdentifier: this.awsIdentifier ? this.awsIdentifier : '',
          note: this.localAsset.note,
          description: this.localAsset.description,
          pins: this.localAsset.pins ? this.localAsset.pins : [],
          copy: this.contentLibAsset && this.localAsset.awsIdentifier ? true : null,
        });

        this.localAsset.newId = createAsset.data.data.id;

        const newAsset = {
          content: this.localAsset,
          index: this.index,
        };

        this.UPDATE_SECTION_ASSET(newAsset);
        this.$emit('createAsset', newAsset);

        this.toggleAsset();
        this.originalLocalAsset = JSON.parse(JSON.stringify(this.localAsset));

        this.RESET_ISDIRTY();
        this.HTTPSuccess('asset created successfully');
      } catch (error) {
        this.HTTPError(error.message);
      }
      // stop loading
      this.creatingAsset = null;
    },

    async updateAsset() {
      try {
        // start loading
        this.creatingAsset = true;

        await ApiService.patch(
          `${process.env.VUE_APP_API_URL}/asset/${
            this.localAsset.id.length > 11 ? this.localAsset.id : this.localAsset.newId
          }`,
          {
            active: this.localAsset.active,
            order: this.index,
            assetTypeId: this.localAsset.assetTypeId,
            title: this.localAsset.title,
            fileName: this.localAsset.fileName ? this.localAsset.fileName : '',
            url: this.localAsset.url,
            vehicleYear: null,
            vehicleModel: null,
            awsIdentifier: this.awsIdentifier ? this.awsIdentifier : '',
            note: this.localAsset.note,
            description: this.localAsset.description,
            pins: this.localAsset.pins ? this.localAsset.pins : [],
            copy: this.contentLibAsset && this.localAsset.awsIdentifier ? true : null,
          },
        );

        this.toggleAsset();
        this.RESET_ISDIRTY();
        this.HTTPSuccess('asset updated successfully');
      } catch (error) {
        this.HTTPError(error.message);
      }
      // stop loading
      this.creatingAsset = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.asset {
  position: relative;
  padding: 40px 34px;
  border: 1px solid #7c7f81;

  .add-icon {
    width: 17px;
    height: 17px;
    margin-right: 8px;
  }

  .chevron {
    width: 12px;
    height: 16px;
    transition: 0.5s ease all;
    transform: rotate(90deg);
    path {
      fill: #000;
    }
  }

  .arrow-active {
    transform: rotate(270deg);
  }

  .arrow-icon {
    width: 12px;
    height: auto;
  }

  .reorder {
    position: absolute;
    top: -12px;
    left: 20px;
    z-index: 1000;
    align-items: center;
    padding: 0 16px;
    background-color: #fff;
    .dots-icon {
      width: 4px;
      height: auto;
      margin-right: 6px;
    }
  }

  .header {
    display: flex;
    align-items: center;

    .left {
      display: flex;
      align-items: center;

      h3 {
        margin-left: 16px;
        font-size: 24px;
      }
    }

    .icon {
      width: 17px;
      height: 24px;
    }
  }

  .hotspot-creator {
    display: flex;
    column-gap: 16px;

    .content-upload {
      flex-basis: 50%;
      flex-direction: column;
      justify-content: flex-start;

      .add-pin {
        display: flex;
        align-items: center;
      }

      .input {
        flex: initial;
        margin-bottom: 0;
      }

      .hotspot-img {
        position: relative;
        img {
          display: block;
          width: 100%;
          height: auto;
        }

        .hotspot-markers {
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          pointer-events: none;

          span {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 28px;
            height: 28px;
            background-color: #fff;
            border-radius: 50%;
          }
        }
      }
    }

    .hotspot-pins {
      position: relative;
      flex-basis: 50%;
      padding-left: 40px;

      .pin {
        .display-pin {
          position: absolute;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 28px;
          height: 28px;
          background-color: $white-smoke;
          border-radius: 50%;
        }

        .hotspot-types {
          display: flex;
          column-gap: 16px;
          margin-bottom: 24px;
          list-style: none;
        }

        .input {
          margin-bottom: 24px;
          .porsche-button {
            align-self: flex-end;
          }
        }

        .pin-notes {
          display: flex;
          flex-direction: column;

          textarea {
            height: 100px;
            padding: 8px 12px;
            resize: none;
            border: 2px solid $grey-light;
          }
        }

        .pin-actions {
          display: flex;
          column-gap: 16px;
          align-items: center;
          margin-top: 16px;

          .action {
            display: flex;
            flex: 1;
            column-gap: 8px;
            align-items: center;
            cursor: pointer;
          }
        }
      }
    }
  }

  .asset-actions {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    margin-top: 24px;

    .toggle-switch {
      flex: 1;
      gap: 10px;
    }

    div {
      align-items: center;
      cursor: pointer;
      span {
        margin-left: 6px;
      }
    }
  }
}

.content-lib-search {
  display: flex;
  flex-direction: column;
  .search-heading {
    display: flex;
    column-gap: 8px;
    margin-bottom: 6px;
  }

  .search-query {
    margin-bottom: 24px;
    input {
      width: 100%;
      padding-left: 40px;
    }

    .icon-wrap {
      position: absolute;
      left: 13px;
      display: flex;
      align-items: center;
      height: 100%;

      .search-icon {
        width: 18px;
        height: auto;
      }
    }
  }

  .search-results {
    .search-asset {
      display: flex;
      flex-direction: column;
      .asset-info {
        display: flex;
        .asset-content {
          display: flex;
          flex: 1;
          column-gap: 16px;
          img,
          video {
            width: 75px;
            height: auto;
          }

          .asset-details {
            h3 {
              margin-bottom: 4px;
              font-size: 16px;
            }

            .view-media {
              display: flex;
              align-items: center;
              margin-bottom: 8px;
              font-size: 14px;
              cursor: pointer;

              .arrow-icon {
                width: auto;
                height: 16px;
              }
            }

            .tags {
              display: flex;
              column-gap: 4px;
            }
          }
        }
        .asset-select {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          cursor: pointer;
          .arrow-icon {
            width: auto;
            height: 20px;
          }
        }
      }
    }

    .arrow-icon {
      path:nth-child(2) {
        fill: $primary;
      }
    }
  }
}
</style>
