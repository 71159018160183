<template>
  <div class="container audit-codes-view">
    <div class="view-wrap">
      <div class="view-heading">
        <h1>Access Code Reports</h1>
        <transition-group name="progress-update">
          <span :key="1" class="status-msg error" v-show="error"> <Cancel class="cancel-icon" /> {{ statusMsg }} </span>
        </transition-group>
      </div>
      <div class="view-content">
        <div class="audit-filter">
          <div class="vue-multiselect display-count">
            <span class="label">Show:</span>
            <VueMultiselect
              v-model="displayCount"
              :searchable="false"
              :close-on-select="true"
              :show-labels="false"
              :allow-empty="false"
              :options="displayCountOptions"
              @input="getAccessCodes"
            />
          </div>
          <PorscheButton :link="true" @click="generateReport">
            <Arrow class="arrow-icon icon" />
            <span>Generate new access code report (CSV)</span>
          </PorscheButton>
        </div>
        <div class="audit-codes-wrap">
          <div class="labels-wrap">
            <div class="labels">
              <span>Access Code Report Title</span>
              <span>Time Generated</span>
              <span>Generated by</span>
              <span>Actions</span>
            </div>
            <hr />
          </div>
          <div class="audit-codes-content">
            <Loading v-if="!dataAvailable" :type="'section'" />
            <div v-else class="audit-data-wrap">
              <div class="audit-data" v-for="(codes, index) in paginatedContentPage" :key="index" :data-id="index">
                <div>
                  <span>{{ codes.title }}</span>
                  <span>
                    {{ new Date(codes.createdAt).toLocaleDateString() }},
                    {{ new Date(codes.createdAt).toLocaleTimeString() }}</span
                  >
                  <span>{{ codes.userName }}</span>
                  <span>
                    <Download style="width: 15px; margin-left: 5px" @click="downloadUserCSV(index)" />
                    <DeleteIcon style="width: 10px; margin-left: 10px" @click="deleteReport(index)"
                  /></span>
                </div>
                <hr />
              </div>
              <Pagination :pageCount="pageCount" @page-change="pageChange" :currentPage="activePage" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/utilities/ApiService';
import Cancel from '../assets/images/Icons/cancel.svg';
import Arrow from '../assets/images/Icons/arrow_right_white.svg';
import VueMultiselect from '../components/VueMultiselect.vue';
import Pagination from '../components/Pagination.vue';
import PorscheButton from '../components/PorscheButton.vue';
import Loading from '../components/Loading.vue';
import DeleteIcon from '../assets/images/Icons/delete.svg';
import Download from '../assets/images/Icons/download.svg';

export default {
  name: 'access-code-reports',
  components: { Arrow, Cancel, VueMultiselect, Pagination, PorscheButton, Loading, DeleteIcon, Download },
  props: {},
  data() {
    return {
      error: null,
      statusMsg: null,
      displayCount: '10',
      offset: 0,
      displayCountOptions: ['10', '12', '14'],
      dataAvailable: null,
      contentPages: {},
      paginatedContentPage: [],
      pageCount: 103,
      activePage: 1,
    };
  },
  created() {
    this.getAccessCodes();
  },
  computed: {},
  watch: {},
  methods: {
    HTTPError(msg) {
      this.error = true;
      this.statusMsg = msg;
      setTimeout(() => {
        this.error = false;
      }, 5000);
    },

    async generateReport() {
      try {
        await ApiService.post(`${process.env.VUE_APP_API_URL}/access-code-reports/generate`);
        this.getAccessCodes();
      } catch (error) {
        this.HTTPError(error);
        console.warn(error.message);
      }
    },

    async downloadUserCSV(index) {
      try {
        const createFile = await ApiService.get(
          `${process.env.VUE_APP_API_URL}/access-code-reports?limit=${this.displayCount}&offset=${this.offset}`,
        );
        const csv = createFile.data.data[index].s3Path;

        window.open(csv, '_blank');
        URL.revokeObjectURL(csv);
      } catch (err) {
        console.warn(err);
      }
    },

    async deleteReport(index) {
      try {
        let deleteData = await ApiService.get(
          `${process.env.VUE_APP_API_URL}/access-code-reports?limit=${this.displayCount}&offset=${this.offset}`,
        );

        const { id } = deleteData.data.data[index];

        deleteData = await ApiService.delete(`${process.env.VUE_APP_API_URL}/access-code-reports/${id}`);
        this.getAccessCodes();
      } catch (error) {
        this.HTTPError(error);
        console.warn(error.message);
        this.dataAvailable = true;
      }
    },

    async getAccessCodes() {
      try {
        const auditData = await ApiService.get(
          `${process.env.VUE_APP_API_URL}/access-code-reports?limit=${this.displayCount}&offset=${this.offset}`,
        );

        this.pageCount = Math.ceil(auditData.data.data.count / this.displayCount);
        this.contentPages[this.activePage] = auditData.data.data;
        this.paginatedContentPage = this.contentPages[this.activePage];

        this.dataAvailable = true;
      } catch (error) {
        this.HTTPError(error);
        console.warn(error.message);
        this.dataAvailable = true;
      }
    },

    async pageChange(page) {
      this.dataAvailable = false;
      if (this.contentPages[page]) {
        this.paginatedContentPage = this.contentPages[page];
        this.activePage = page;
        this.dataAvailable = true;
        return;
      }

      // Get new page
      try {
        this.offset = (page - 1) * this.displayCount;
        this.activePage = page;
        await this.getAccessCodes();
        this.paginatedContentPage = this.contentPages[page];
      } catch (error) {
        this.HTTPError(error);
        console.warn(error.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.audit-codes-view {
  margin-top: 40px;

  .view-content {
    .audit-filter {
      display: flex;
      margin: 25px 0;

      .display-count {
        width: 109px;
        margin-bottom: 0;
        .multiselect__tags {
          padding: 12px 20px 12px 52px;
        }

        .label {
          position: absolute;
          top: 12px;
          left: 12px;
          z-index: 99;
          color: #000;
        }
      }

      .porsche-button {
        margin-left: auto;
      }
    }

    .audit-codes-wrap {
      position: relative;

      hr {
        margin: 16px 0;
      }

      .labels,
      .audit-data div {
        display: flex;
        gap: 32px;
        span {
          flex-basis: 8%;
          &:nth-child(1) {
            flex-basis: 50%;
          }
          &:nth-child(2) {
            flex-basis: 25%;
          }
          &:last-child {
            flex-basis: 5%;
          }
        }
      }

      .labels {
        span {
          font-weight: 500;
        }
      }

      .audit-codes-content {
        position: relative;

        .audit-data {
          span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
</style>
