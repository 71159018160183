import Vue from 'vue';
import PrimeVue from 'primevue/config';
import Calendar from 'primevue/calendar';
import VueMq from 'vue-mq';
import VueQuillEditor from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import Paginate from 'vuejs-paginate';
import { datadogRum } from '@datadog/browser-rum';
import axios from 'axios';
import VueGtag from 'vue-gtag';
import ApiService from '@/utilities/ApiService';
import jwtDecode from 'jwt-decode';
import App from './App.vue';
import router from './router';
import store from './store';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import './assets/primeicons.css';

// create workbook defaults
import '@/assets/scss/create-workbook.scss';

// view workbook default / global styles
import '@/assets/scss/view-workbook.scss';

// quiz & survey results default styles
import '@/assets/scss/results-workbook.scss';

ApiService.init();

Vue.use(PrimeVue);

Vue.component('paginate', Paginate);
Vue.component('Calendar', Calendar);

Vue.use(VueQuillEditor);

Vue.use(VueMq, {
  breakpoints: {
    // default breakpoints - customize this
    mobile: 450,
    tablet: 775,
    tabletLarge: 1025,
    laptop: 1100,
    lg: Infinity,
  },
  defaultBreakpoint: 'mobile', // customize this for SSR
});

Vue.use(
  VueGtag,
  {
    config: {
      id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
      js: new Date(),
    },
  },
  router,
);

Vue.config.productionTip = false;

if (process.env.VUE_APP_DATADOG_RUM_APP_ID && Number(process.env.VUE_APP_DATADOG_RUM_SAMPLE_RATE) > 0) {
  datadogRum.init({
    applicationId: process.env.VUE_APP_DATADOG_RUM_APP_ID,
    clientToken: process.env.VUE_APP_DATADOG_RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: process.env.VUE_APP_DATADOG_RUM_SERVICE,
    env: process.env.VUE_APP_DEPLOY_ENV,
    //  version: '1.0.0',
    sampleRate: Number(process.env.VUE_APP_DATADOG_RUM_SAMPLE_RATE),
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    useSecureSessionCookie: true,
  });

  // datadogRum.startSessionReplayRecording();
  Vue.prototype.$datadogRum = datadogRum;
}

router.beforeEach(async (to, from, next) => {
  const queryToken = to.query.token;
  const jwtToken = localStorage.getItem('jwtToken');

  // if token query exists, remove from url
  if (to.query.token) {
    const query = { ...to.query };
    delete query.token;
  }

  // navigate user to login if no token or jwtToken
  if (!queryToken && !jwtToken) {
    sessionStorage.setItem('currentRoute', to.name);
    window.location = `${process.env.VUE_APP_API_URL}/saml/auth`;
    return;
  }

  // if token exists in URL, but no token stored
  if (queryToken && !jwtToken) {
    try {
      const getJwt = await axios({
        method: 'get',
        url: `${process.env.VUE_APP_API_URL}/saml/jwt`,
        headers: {
          Authorization: `${queryToken}`,
        },
      });
      localStorage.setItem('jwtToken', getJwt.data.data.jwt);
      store.commit('userInformation/SET_USER_INFO', jwtDecode(getJwt.data.data.jwt));
      ApiService.setToken();
    } catch (err) {
      console.warn(err.message);
      window.location = `${process.env.VUE_APP_API_URL}/saml/auth`;
    }
  }

  // if token exists
  const decodedJwt = jwtDecode(localStorage.getItem('jwtToken'));
  const admin = decodedJwt.userRoleId === 4;
  const designer = decodedJwt.userRoleId === 3;
  if (!store.state.userInformation.authenticated) {
    store.commit('userInformation/SET_USER_INFO', decodedJwt);
    ApiService.setToken();
  }

  // Check to see if route requires admin
  if (to.matched.some((rec) => rec.meta.requireAdmin)) {
    // check to see if user is admin
    if (admin) {
      next();
      return;
    }
    next({ name: 'workbooks' });
    return;
  }

  // Check to see if route requires cd
  if (to.matched.some((rec) => rec.meta.requireCD)) {
    if (designer || admin) {
      next();
      return;
    }
    next({ name: 'workbooks' });
    return;
  }

  // no restriction on route, go to route
  if (queryToken) {
    const routeName = sessionStorage.getItem('currentRoute');
    next({ name: routeName });
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
