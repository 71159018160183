import ApiService from '@/utilities/ApiService';

const GetPresigned = {
  async wvGetPresigned(data) {
    const awsUrls = [];
    data.forEach((asset) => {
      if (asset.awsIdentifier !== '' && asset.awsIdentifier) {
        awsUrls.push(ApiService.get(`${process.env.VUE_APP_API_URL}/asset/presignedGet/${asset.awsIdentifier}`));
      } else {
        awsUrls.push(null);
      }
    });

    // checks for assets on hotspot
    const awsHotspotUrls = [];
    data.forEach((asset) => {
      if (asset.assetPin.length) {
        asset.assetPin.forEach((pin) => {
          if (pin.awsIdentifier) {
            awsHotspotUrls.push(
              ApiService.get(`${process.env.VUE_APP_API_URL}/asset/presignedGet/${pin.awsIdentifier}`),
            );
          } else {
            awsHotspotUrls.push(null);
          }
        });
      }
    });

    const awsData = await Promise.all(awsUrls);
    const awsHotspotData = await Promise.all(awsHotspotUrls);

    data.forEach((asset, index) => {
      if (asset.awsIdentifier !== '' && asset.awsIdentifier) {
        if (asset.assetPin.length) {
          asset.assetPin.forEach((pin, secondaryIndex) => {
            if (pin.awsIdentifier) {
              pin.hotspotDisplayLink = awsHotspotData[secondaryIndex].data.data;
            } else {
              pin.hotspotDisplayLink = null;
            }
          });
        }
        asset.displayLink = awsData[index].data.data;
      } else {
        asset.displayLink = null;
      }
    });
    return data;
  },

  async clGetPresigned(data) {
    const awsAssetUrls = [];
    const awsAssetThumbnailUrls = [];

    data.forEach((asset) => {
      // aws asset url
      if (asset.awsIdentifier) {
        awsAssetUrls.push(ApiService.get(`${process.env.VUE_APP_API_URL}/asset/presignedGet/${asset.awsIdentifier}`));
      } else {
        awsAssetUrls.push(null);
      }

      // aws thumbnail url (video's only)
      if (asset.thumbnailIdentifier) {
        awsAssetThumbnailUrls.push(
          ApiService.get(
            `${process.env.VUE_APP_API_URL}/asset/presignedGet/${asset.thumbnailIdentifier}?thumbnail=true`,
          ),
        );
      } else {
        awsAssetThumbnailUrls.push(null);
      }
    });

    const awsAssetData = await Promise.allSettled(awsAssetUrls);
    const awsAssetThumbnailData = await Promise.allSettled(awsAssetThumbnailUrls);

    data.forEach((asset, index) => {
      asset.displayLink = null;
      asset.thumbnailDisplayLink = null;

      // check for fulfilled api res (asset)
      if (awsAssetData[index].status === 'fulfilled') {
        // aws asset url
        if (asset.awsIdentifier) {
          asset.displayLink = awsAssetData[index].value.data.data;
        }
      }

      // check for fulfilled api res (thumbnail)
      if (awsAssetThumbnailData[index].status === 'fulfilled') {
        if (asset.thumbnailIdentifier) {
          asset.thumbnailDisplayLink = awsAssetThumbnailData[index].value.data.data;
        }
      }
    });
    return data;
  },

  async assetGetPresigned(data) {
    try {
      data.displayLink = null;
      data.thumbnailDisplayLink = null;
      let getAsset = null;
      let getThumbnail = null;

      // check for asset
      if (data.awsIdentifier) {
        getAsset = ApiService.get(`${process.env.VUE_APP_API_URL}/asset/presignedGet/${data.awsIdentifier}`);
      }

      // check for thumbnail
      if (data.thumbnailIdentifier) {
        getThumbnail = ApiService.get(
          `${process.env.VUE_APP_API_URL}/asset/presignedGet/${data.thumbnailIdentifier}?thumbnail=true`,
        );
      }

      // wait for both to resolve
      const [asset, thumbnail] = await Promise.allSettled([getAsset, getThumbnail]);

      // set display links
      if (asset.status === 'fulfilled' && asset.value) {
        data.displayLink = asset.value.data.data;
      }

      if (thumbnail.status === 'fulfilled' && thumbnail.value) {
        data.thumbnailDisplayLink = thumbnail.value.data.data;
      }

      return data;
    } catch (error) {
      throw new Error(error);
    }
  },
};

export default GetPresigned;
