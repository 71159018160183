<template>
  <div v-if="dataLoaded" class="palms container">
    <Loading v-if="loading" />

    <div class="heading">
      <h1>PALMS Import Tool</h1>
      <transition-group name="upload-status">
        <span :key="1" class="status-msg error" v-show="error"
          ><CancelIcon class="cancel-icon" /> Upload Failed: {{ statusMsg }}</span
        >
        <span :key="2" class="status-msg success" v-show="success"
          ><CheckIcon class="check-icon" />{{ statusMsg }}</span
        >
      </transition-group>
    </div>

    <div class="palms-tool">
      <!-- Dropbox -->
      <div
        class="upload"
        ref="dropbox"
        @dragenter.prevent="dragenter"
        @dragleave="dragleave"
        @dragover.prevent
        @drop.prevent="drop"
      >
        <span>Drag and Drop File</span>
        <span>OR</span>
        <label for="uploadCSV" class="button-link"><Arrow class="icon" />Upload .CSV</label>
        <input @change="fileChange" type="file" class="uploadCSV" id="uploadCSV" ref="uploadCSV" accept=".csv" />
      </div>

      <!-- History -->
      <div class="history">
        <div class="history-data">
          <div class="history-labels">
            <span>Upload</span>
            <span>Time</span>
          </div>
          <div class="history-items" v-for="(item, index) in paginatedHistoryData" :key="index">
            <div class="history-item-wrap">
              <div class="item title">
                <CheckIcon class="icon" />
                <span>{{ item.title }}</span>
              </div>
              <div class="item">
                <span
                  >{{ new Date(item.createdAt).toLocaleDateString() }}
                  {{ new Date(item.createdAt).toLocaleTimeString() }}</span
                >
              </div>
            </div>
            <hr />
          </div>
        </div>
        <Pagination v-on:page-change="pageChange" :pageCount="pageCount" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import CheckIcon from '@/assets/images/Icons/check.svg';
import CancelIcon from '@/assets/images/Icons/cancel.svg';
import Pagination from '@/components/Pagination.vue';
import Arrow from '../assets/images/Icons/arrow_right_white.svg';
import ApiService from '../utilities/ApiService';
import Loading from '../components/Loading.vue';

export default {
  name: 'palms-import',
  components: { Arrow, CheckIcon, CancelIcon, Pagination, Loading },
  props: {},
  data() {
    return {
      dataLoaded: null,
      loading: null,
      historyData: [],
      historyDataPages: {},
      paginatedHistoryData: [],
      pageCount: 0,
      activePage: 1,
      file: null,
      success: null,
      error: null,
      statusMsg: false,
    };
  },
  async created() {
    await this.getHistory(0, 7, 1);
    this.dataLoaded = true;
  },
  computed: {},
  watch: {},
  methods: {
    async getHistory(offset, limit, page) {
      const data = await ApiService.get(
        `${process.env.VUE_APP_API_URL}/data-import/history?offset=${offset}&limit=${limit}`,
      );
      const result = data.data.data.rows;
      this.pageCount = Math.ceil(data.data.data.count / 7);
      this.historyDataPages[page] = result;
      this.paginatedHistoryData = this.historyDataPages[page];
    },

    async getPresigned(file) {
      try {
        const url = await ApiService.get(`${process.env.VUE_APP_API_URL}/data-import/upload-csv`);

        const instance = axios.create();
        delete instance.defaults.headers.common.Authorization;
        await instance.put(`${url.data.data.url}`, file, {
          headers: {
            'Content-Type': '',
          },
        });

        await ApiService.post(`${process.env.VUE_APP_API_URL}/data-import`, {
          awsIdentifier: url.data.data.awsIdentifier,
          originalFilename: file.name,
        });

        this.getHistory(0, 7, 1);

        this.success = true;
        this.statusMsg = 'Upload Successful';
        setTimeout(() => {
          this.success = null;
        }, 7000);
      } catch (error) {
        this.statusMsg = error.message;
        this.error = true;
        setTimeout(() => {
          this.error = null;
        }, 7000);
      }
    },

    async pageChange(value) {
      this.activePage = value;
      if (this.historyDataPages[value]) {
        this.paginatedHistoryData = this.historyDataPages[value];
        return;
      }
      await this.getHistory((value - 1) * 7, 7, value);
      this.paginatedHistoryData = this.historyDataPages[value];
    },

    dragenter() {
      this.$refs.dropbox.classList.add('active-dropbox');
    },

    dragleave() {
      this.$refs.dropbox.classList.remove('active-dropbox');
    },

    async drop(e) {
      this.loading = true;
      this.$refs.dropbox.classList.remove('active-dropbox');
      this.file = e.dataTransfer.files[0];
      if (this.file.type === 'text/csv') {
        await this.getPresigned(this.file);
        this.loading = false;
        return;
      }
      this.statusMsg = 'File must be a .CSV';
      this.error = true;
      this.loading = false;
      setTimeout(() => {
        this.error = null;
      }, 7000);
    },

    async fileChange() {
      this.loading = true;
      this.file = this.$refs.uploadCSV.files[0];
      await this.getPresigned(this.file);
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.palms {
  margin-top: 40px;

  .heading {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    h1 {
      font-size: 36px;
      font-weight: 500;
    }

    .upload-status-enter-active,
    .upload-status-leave-active {
      transition: 0.3s ease all;
    }

    .upload-status-enter,
    .upload-status-leave-to {
      opacity: 0;
    }

    .status-msg {
      display: flex;
      align-items: center;
      margin-left: 24px;
      font-size: 16px;
    }

    .success {
      color: $green;
    }

    .error {
      color: $primary;
    }

    .cancel-icon {
      width: auto;
      height: 24px;
      margin-right: 6px;
      path {
        fill: $primary;
      }
    }

    .check-icon {
      width: auto;
      height: 16px;
      margin-right: 10px;
    }
  }

  .palms-tool {
    display: flex;
    gap: 32px;

    .icon {
      width: auto;
      height: 20px;
      margin-right: 6px;
    }

    .upload,
    .history {
      flex: 1;
    }

    .active-dropbox {
      background-color: rgba(201, 202, 203, 0.3);
    }

    .upload {
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: center;
      justify-content: center;
      min-height: 415px;
      max-height: 415px;
      border: 1px dashed #96989a;

      input {
        display: none;
      }
    }

    .history {
      display: flex;
      flex-direction: column;

      .history-data {
        min-height: 415px;
      }

      .history-labels {
        display: flex;
        gap: 16px;
        width: 100%;
        span {
          display: block;
          flex: 1;
          font-size: 16px;
          font-weight: 600;
        }
      }

      .history-items {
        .history-item-wrap {
          display: flex;
          gap: 16px;
          padding: 14px;

          .item {
            flex: 1;
            .icon {
              height: 14px;
            }
          }

          .title {
            span {
              display: inline-block;
              width: 200px;
              height: 20px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
</style>
