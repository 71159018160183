<template>
  <div class="quiz-q question" :class="{ 'question-invalid': error }">
    <div class="reorder">
      <Dots class="dots-icon" />
      <span>Click and drag to reorder</span>
    </div>
    <div class="question-heading">
      <p v-if="questionObj.question">
        {{ questionObj.question }}
      </p>
      <p class="placeholder" v-else>Question</p>
      <ArrowDown @click="toggleQuestion" :class="{ 'arrow-active': visible }" class="chevron" />
    </div>
    <div class="question-content" v-show="visible">
      <BaseInput
        v-model="questionObj.question"
        :lazy="true"
        :hasError="error && questionObj.question === ''"
        label="Question"
        type="text"
      />

      <div class="input">
        <label for="explanation">Explanation</label>
        <textarea id="explanation" v-model.lazy="questionObj.explanation" placeholder="Explanation"></textarea>
      </div>

      <div class="answer-type flex">
        <div class="vue-multiselect">
          <label for="moduleID">Answer Type</label>
          <Multiselect
            :allow-empty="false"
            class="single"
            :class="{ 'invalid-field': error && !questionObj.questionTypeId }"
            @input="questionTypeChange(questionTypeObj)"
            v-model="questionTypeObj"
            :options="quizQuestionTypes"
            track-by="id"
            label="type"
            :searchable="false"
            :close-on-select="true"
            :show-labels="false"
            placeholder="Select Answer Type"
          ></Multiselect>
          <ArrowDownMultiselect class="select-icon" />
        </div>
        <div class="checkbox">
          <input type="checkbox" v-model="questionObj.isRequired" />
          <span>This question is required</span>
          <Check v-show="questionObj.isRequired" class="check-icon" />
        </div>
        <div class="checkbox">
          <input type="checkbox" v-model="questionObj.isShuffled" />
          <span>Shuffle Choices</span>
          <Check v-show="questionObj.isShuffled" class="check-icon" />
        </div>
      </div>

      <!-- Answers -->
      <div v-if="questionObj.questionTypeId" class="answers">
        <span class="a-span">Answers <span class="a-span-light">Select the correct answer.</span></span>

        <div v-if="questionObj.questionTypeId === this.trueFalse[0].id" class="true-false">
          <div class="circle-checkbox" v-for="(option, index) in questionObj.options" :key="index">
            <input
              type="checkbox"
              @change="updateSingleOption(option.id)"
              v-model="option.isAnswer"
              name="true-false-choice"
            />
            <span>{{ option.text.toLowerCase() }}</span>
          </div>
        </div>

        <div v-if="questionObj.questionTypeId === this.checkboxes[0].id" class="multiple-options">
          <div v-for="(option, index) in questionObj.options" :key="index" class="option">
            <div class="checkbox">
              <input
                type="checkbox"
                :disabled="!option.text"
                v-model="option.isAnswer"
                @change="updateMultipleOptions(option.id)"
              />
              <Check v-show="option.isAnswer" class="check-icon" />
            </div>
            <input type="text" v-model.lazy="option.text" :placeholder="`Option ${index + 1}`" />
          </div>
          <div @click="addQuizOption" class="add-option">
            <Add class="add-icon" />
            <span>Add Option</span>
          </div>
        </div>

        <div v-if="questionObj.questionTypeId === this.multipleChoice[0].id" class="multiple-choice">
          <div v-for="(option, index) in questionObj.options" :key="index" class="option">
            <div class="circle-checkbox">
              <input
                type="checkbox"
                @change="updateSingleOption(option.id)"
                v-model="option.isAnswer"
                name="multiple-choice"
              />
            </div>
            <input type="text" v-model.lazy="option.text" :placeholder="`Option ${index + 1}`" />
          </div>
          <div @click="addQuizOption" class="add-option">
            <Add class="add-icon" />
            <span>Add Option</span>
          </div>
        </div>
      </div>

      <div class="asset-actions">
        <div @click="toggleQuestion" class="a-action">
          <Hide class="hide-icon" />
          <span>Hide question</span>
        </div>
        <div @click="removeQuestion" class="a-action">
          <Delete class="delete-icon" />
          <span>Delete question</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from 'uid';
import Multiselect from 'vue-multiselect';
import BaseInput from '@/components/BaseInput.vue';
import Hide from '../assets/images/Icons/view-off.svg';
import Delete from '../assets/images/Icons/delete.svg';
import Check from '../assets/images/Icons/check.svg';
import Dots from '../assets/images/Icons/dots_vertical.svg';
import Add from '../assets/images/Icons/add.svg';
import ArrowDown from '../assets/images/Icons/arrow_right_red.svg';
import ArrowDownMultiselect from '../assets/images/Icons/arrow_left.svg';

export default {
  name: 'quiz-question',
  components: { Dots, ArrowDown, Multiselect, ArrowDownMultiselect, Check, Hide, Delete, Add, BaseInput },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    id: String,
    index: Number,
    question: String,
    explanation: String,
    questionTypeId: [Boolean, String],
    isRequired: [Boolean, String],
    isShuffled: [Boolean, String],
    options: Array,
    quizQuestionTypes: Array,
  },
  data() {
    return {
      questionObj: {
        id: this.id,
        question: this.question,
        explanation: this.explanation,
        questionTypeId: this.questionTypeId ? this.questionTypeId : null,
        isRequired: this.isRequired,
        isShuffled: this.isShuffled,
        options: this.options,
      },
      questionContent: null,
      questionTypeObj: null,
      trueFalse: null,
      multipleChoice: null,
      checkboxes: null,
    };
  },
  created() {
    this.getQuestionTypes();
    this.questionTypeObj = this.quizQuestionTypes.filter((type) => type.id === this.questionTypeId);
  },
  watch: {
    questionObj: {
      deep: true,
      handler() {
        const object = {
          ...this.questionObj,
          visible: this.visible,
          error: this.error,
        };
        this.$emit('input', object, this.index);
      },
    },
  },
  methods: {
    // toggles asset content view
    toggleQuestion() {
      this.$emit('toggleQuestion', this.index);
    },

    getQuestionTypes() {
      this.trueFalse = this.quizQuestionTypes.filter((type) => type.type === 'True/False');
      this.multipleChoice = this.quizQuestionTypes.filter((type) => type.type === 'Multiple Choice');
      this.checkboxes = this.quizQuestionTypes.filter((type) => type.type === 'Checkboxes');
    },

    questionTypeChange(questionTypeObj) {
      this.questionObj.options = [];
      this.questionObj.questionTypeId = questionTypeObj.id;

      if (questionTypeObj.type !== 'True/False') {
        this.addQuizOption(questionTypeObj.type);
        return;
      }

      this.questionObj.options.push(
        {
          id: uid(),
          text: 'True',
          isAnswer: false,
        },
        {
          id: uid(),
          text: 'False',
          isAnswer: false,
        },
      );
    },

    removeQuestion() {
      this.$emit('removequestion', this.id);
    },

    addQuizOption(type) {
      if (this.questionObj.options.length < 6) {
        if (type === 'Checkboxes') {
          this.questionObj.options.push({
            id: uid(),
            text: null,
            isAnswer: false,
          });
          return;
        }
        this.questionObj.options.push({
          id: uid(),
          text: null,
          isAnswer: false,
        });
      }
    },

    updateMultipleOptions(id) {
      this.questionObj.options.forEach((option) => {
        if (option.id === id) {
          option.isAnswer = !option.isAnswer;
          this.$set(option, 'isAnswer', !option.isAnswer);
        }
      });
    },

    updateSingleOption(id) {
      this.questionObj.options.forEach((option) => {
        this.$set(option, 'isAnswer', false);
      });
      this.questionObj.options.forEach((option) => {
        if (option.id === id) {
          this.$set(option, 'isAnswer', true);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/survey-quiz.scss';

.question {
  .answer-type {
    gap: 16px;
    align-items: flex-end;

    .single {
      width: 200px;
    }

    .checkbox {
      padding-bottom: 10px;
    }
  }

  .answers {
    input[type='radio'] {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border: 1px solid $grey;
      border-radius: 50%;
      -webkit-appearance: none;
    }

    input[type='radio']:checked::after {
      position: absolute;
      width: 16px;
      height: 16px;
      content: '';
      background-color: #000;
      border-radius: 50%;
    }

    .a-span-light {
      margin-left: 4px;
      color: $grey-light;
    }

    .add-option {
      display: flex;
      align-items: center;
      margin-top: 8px;
      cursor: pointer;
    }

    .true-false {
      margin-top: 8px;

      span {
        margin-left: 8px;
        font-size: 16px;
        text-transform: capitalize;
      }
    }

    .multiple-options,
    .multiple-choice {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      margin-top: 8px;

      .option {
        display: flex;
        gap: 8px;
        align-items: center;
        .checkbox,
        .circle-checkbox {
          margin-bottom: 0;
        }

        input[type='text'] {
          flex: 1;
          width: 100%;
          padding: 10px;
          font-size: 16px;
        }

        input[type='checkbox'] {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
