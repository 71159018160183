import ApiService from '@/utilities/ApiService';

export default async (path, data) => {
  const createFile = await ApiService.get(`${process.env.VUE_APP_API_URL}/${path}`, data || null, {
    responseType: 'blob',
  });
  const res = createFile.data;
  const createUrl = URL.createObjectURL(res);
  window.open(createUrl, '_blank');
  URL.revokeObjectURL(createUrl);
};
