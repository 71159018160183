<template>
  <div class="module-assets">
    <div class="asset">
      <!-- Reorder Content -->
      <div class="reorder">
        <Dots class="dots-icon" />
        <span>Click and drag to reorder</span>
      </div>

      <!-- Conditional Headers -->
      <div class="header">
        <div class="asset-type" @click="toggleAsset">
          <Waiver v-if="asset.module_asset.type === 'waiver'" class="icon" />
          <Survey v-if="asset.module_asset.type === 'survey'" class="icon" />
          <Quiz v-if="asset.module_asset.type === 'quiz'" class="icon" />
          <h3>
            <span v-if="localAsset.title">{{ localAsset.title }} -</span> <span>{{ asset.module_asset.type }}</span>
          </h3>
        </div>
        <div class="asset-toggle">
          <transition-group name="progress-update">
            <span :key="1" class="status-msg error" v-show="error">
              <CancelIcon class="cancel-icon" /> {{ statusMsg }}
            </span>
            <span :key="2" class="status-msg success" v-show="success">
              <Check class="check-icon" /> {{ statusMsg }}
            </span>
          </transition-group>
          <ArrowDown @click="toggleAsset" :class="{ 'arrow-active': assetVisible }" class="chevron" />
        </div>
      </div>

      <!-- Creating asset -->
      <Loading type="section" v-if="creatingAsset" />

      <Modal modalType="popup" v-if="parsingErrorModal" :modalContent="parsingErrorContent" @close-modal="closeModal" />

      <!-- Waiver -->
      <div v-if="asset.module_asset.type === 'waiver' && assetVisible">
        <!-- Navigation -->
        <div class="asset-navigation">
          <ul>
            <li class="nav-link active-item">Create New</li>
          </ul>
        </div>

        <!-- Title -->
        <div class="create-new">
          <div class="input">
            <label for="waiverTitle">Title</label>
            <input type="text" id="waiverTitle" v-model.lazy="localAsset.title" />
          </div>

          <!-- Waiver Content -->
          <div class="content-upload">
            <div class="input">
              <label for="file-upload" :class="`wavierLabel${index}`">Upload</label>

              <div :class="{ disabled: localAsset.url }" class="file-upload">
                <p class="file-name" v-if="localAsset.fileName !== ''">{{ localAsset.fileName }}</p>
                <div v-if="localAsset.file" @click="removeWaiverFile" class="icon-wrap remove-upload">
                  <CancelIcon class="remove-icon" />
                  <span>Remove</span>
                </div>
                <label v-else for="file-upload" class="icon-wrap">
                  <UploadIcon class="upload-icon" />
                  <span>Choose file</span>
                </label>
                <input
                  @change="waiverFileChange"
                  :class="`wavierFile${index}`"
                  accept=".pdf"
                  type="file"
                  id="file-upload"
                />
              </div>

              <span class="disclaimer"
                >Uploads cannot exceed 2 MB in size. Accepted file types: PDF, DOC, DOCX, RTF</span
              >
            </div>

            <span class="or">OR</span>

            <div class="input">
              <label for="url">URL</label>
              <input
                id="url"
                @input="waiverUrlChange"
                :class="({ disabled: localAsset.file || localAsset.fileUrl }, `waiverUrl${index}`)"
                type="text"
                v-model="localAsset.url"
                placeholder="URL"
              />
            </div>
          </div>

          <!-- E - Sig -->
          <div class="checkbox">
            <input type="checkbox" v-model="localAsset.isSignatureRequired" />
            <span>E-signature is required</span>
            <Check class="check-icon" v-show="localAsset.isSignatureRequired" />
          </div>

          <div class="asset-actions">
            <div class="toggle-switch">
              <span> Inactive </span>
              <input type="checkbox" v-model="localAsset.active" />
              <span> Active </span>
            </div>
            <div @click="promptDelete" class="a-action">
              <Delete class="delete-icon" />
              <span>Delete waiver</span>
            </div>
            <PorscheButton @click="isDataBaseAsset ? updateWavier() : createWavier()" type="button">
              <Arrow class="arrow-icon icon" />
              <span v-if="isDataBaseAsset">Update waiver</span>
              <span v-else>Save wavier</span>
            </PorscheButton>
          </div>
        </div>
      </div>

      <!-- Survey -->
      <div class="survey" v-if="asset.module_asset.type === 'survey' && assetVisible">
        <div class="asset-navigation">
          <ul>
            <li @click="toggleAssetNavigation(1)" :class="{ 'active-item': createNewActive }" class="nav-link">
              Create New
            </li>
            <li @click="toggleAssetNavigation(2)" :class="{ 'active-item': uploadCSVActive }" class="nav-link">
              Upload CSV and Parse
            </li>
            <li @click="toggleAssetNavigation(3)" :class="{ 'active-item': importActive }" class="nav-link">
              Import from Content Library
            </li>
          </ul>
        </div>
        <div v-if="createNewActive" class="create-new">
          <div class="input">
            <label for="surveyTitle">Survey Title</label>
            <input type="text" id="surveyTitle" v-model.lazy="localAsset.title" placeholder="Survey Title" />
          </div>

          <draggable :list="localAsset.survey.questions" @change="updateSurveyQuestionOrder($event, index)">
            <SurveyQuestion
              v-for="(surveyQuestion, index) in localAsset.survey.questions"
              :key="surveyQuestion.id"
              :index="index"
              v-bind="surveyQuestion"
              :surveyQuestionTypes="surveyQuestionTypes"
              @input="updateSurveyQuestion"
              @removequestion="removeSurveyQuestion"
              @toggleQuestion="toggleQuestion"
            />
          </draggable>

          <div @click="addSurveyQuestion" class="add-question flex">
            <Add class="add-icon" />
            <span>Add Question</span>
          </div>

          <div class="asset-actions">
            <div class="toggle-switch">
              <span> Inactive </span>
              <input type="checkbox" v-model="localAsset.active" />
              <span> Active </span>
            </div>
            <div @click="promptDelete" class="a-action">
              <Delete class="delete-icon" />
              <span>Delete survey</span>
            </div>
            <PorscheButton @click="isDataBaseAsset ? updateSurvey() : createSurvey()" type="button">
              <Arrow class="arrow-icon icon" />
              <span v-if="isDataBaseAsset">Update survey</span>
              <span v-else>Save survey</span>
            </PorscheButton>
          </div>
        </div>

        <div v-if="uploadCSVActive" class="upload-csv-wrap">
          <div class="upload-csv">
            <PorscheButton :link="true" :href="surveyTemplate" type="button">
              <Arrow class="arrow-icon icon" />
              <span>Download CSV Template</span>
            </PorscheButton>
            <input @change="parseSurvey" ref="parseSurvey" type="file" accept=".csv" id="quiz-csv" />
            <PorscheButton for="quiz-csv" :label="true" type="button">
              <Arrow class="arrow-icon icon" />
              <span>Upload CSV</span>
            </PorscheButton>
          </div>
          <div class="disclaimer">
            <p>
              To be accurately parsed, the CSV must be used exactly as formatted. Do not delete, add or alter columns in
              any way. Do not delete or alter Row 1. Do not leave rows blank unless noted as "optional" below.
            </p>
            <ul class="disclaimer-list">
              <li v-for="(option, index) in surveyDisclaimer" :key="index">
                {{ option }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Quiz -->
      <div class="quiz" v-if="asset.module_asset.type === 'quiz' && assetVisible">
        <div class="asset-navigation">
          <ul>
            <li @click="toggleAssetNavigation(1)" :class="{ 'active-item': createNewActive }" class="nav-link">
              Create New
            </li>
            <li @click="toggleAssetNavigation(2)" :class="{ 'active-item': uploadCSVActive }" class="nav-link">
              Upload CSV and Parse
            </li>
            <li @click="toggleAssetNavigation(3)" :class="{ 'active-item': importActive }" class="nav-link">
              Import from Content Library
            </li>
          </ul>
        </div>
        <div v-if="createNewActive" class="create-new">
          <div class="quiz-header">
            <div class="quiz-title input">
              <label for="quizTitle">Quiz Title</label>
              <input type="text" id="quizTitle" v-model.lazy="localAsset.title" placeholder="Quiz Title" />
            </div>
            <div class="quiz-pass-percentage input">
              <label for="passPercentage">Pass Percentage</label>
              <input
                type="text"
                id="passPercentage"
                v-model="localAsset.passPercentage"
                placeholder="Pass Percentage"
              />
            </div>
          </div>

          <draggable :list="localAsset.quiz.questions" @change="updateQuizQuestionOrder($event, index)">
            <QuizQuestion
              v-for="(quizQuestion, index) in localAsset.quiz.questions"
              :key="quizQuestion.id"
              :index="index"
              :quizQuestionTypes="quizQuestionTypes"
              v-bind="quizQuestion"
              @input="updateQuizQuestion"
              @removequestion="removeQuizQuestion"
              @toggleQuestion="toggleQuestion"
            />
          </draggable>

          <div @click="addQuestion" class="add-question flex">
            <Add class="add-icon" />
            <span>Add Question</span>
          </div>
          <div class="asset-actions">
            <div class="toggle-switch">
              <span> Inactive </span>
              <input type="checkbox" v-model="localAsset.active" />
              <span> Active </span>
            </div>
            <div class="asset-actions-child">
              <div @click="promptDelete" class="a-action">
                <Delete class="delete-icon" />
                <span>Delete quiz</span>
              </div>
              <PorscheButton @click="isDataBaseAsset ? updateQuiz() : createQuiz()" type="button">
                <Arrow class="arrow-icon icon" />
                <span v-if="isDataBaseAsset">Update quiz</span>
                <span v-else>Save quiz</span>
              </PorscheButton>
            </div>
          </div>
        </div>

        <!-- Upload/Parse CSV -->
        <div v-if="uploadCSVActive" class="upload-csv-wrap">
          <div class="upload-csv">
            <PorscheButton :link="true" :href="quizTemplate" type="button">
              <Arrow class="arrow-icon icon" />
              <span>Download CSV Template</span>
            </PorscheButton>
            <input @change="parseQuiz" ref="parseQuiz" type="file" accept=".csv" id="quiz-csv" />
            <PorscheButton for="quiz-csv" :label="true" type="button">
              <Arrow class="arrow-icon icon" />
              <span>Upload CSV</span>
            </PorscheButton>
          </div>

          <div class="disclaimer">
            <p>
              To be accurately parsed, the CSV must be used exactly as formatted. Do not delete, add or alter columns in
              any way. Do not delete or alter Row 1. Do not leave rows blank unless noted as "optional" below.
            </p>
            <ul class="disclaimer-list">
              <li v-for="(option, index) in quizDisclaimer" :key="index">
                {{ option }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Import from content lib -->
      <div v-if="importActive">
        <div class="content-lib-search">
          <div class="search-heading">
            <span>Content Library</span>
            <span>|</span>
            <span>{{ localAsset.module_asset.type }}s ({{ this.assetCount }}) </span>
          </div>

          <div class="input search-query">
            <input
              @keyup="queryContentSearch"
              type="text"
              placeholder="Search Content Library"
              v-model="contentLibQuery"
            />
            <div class="icon-wrap">
              <SearchIcon class="search-icon icon" />
            </div>
          </div>

          <div class="search-results">
            <div class="search-asset" v-for="(asset, index) in paginatedContentPage" :key="asset.id">
              <div class="asset-info">
                <div class="asset-content">
                  <div class="image">
                    <img
                      :src="require(`@/assets/images/ContentLibrary/content_${asset.category.toLowerCase()}@2x.png`)"
                      alt=""
                    />
                  </div>
                  <div class="asset-details">
                    <h3>{{ asset.title }}</h3>
                    <div class="tags">
                      <span class="tag">{{ asset.category }}</span>
                      <span v-if="asset.vehicleModel" class="tag">{{ asset.vehicleModel }}</span>
                      <span v-if="asset.vehicleYear" class="tag">{{ asset.vehicleYear }}</span>
                    </div>
                  </div>
                </div>
                <div class="asset-select" @click="getAsset(asset)">
                  <Arrow class="arrow-icon icon" />
                  <span>Choose file</span>
                </div>
              </div>
              <hr v-if="paginatedContentPage.length !== index + 1" />
            </div>
          </div>

          <Pagination :pageCount="pageCount" @page-change="pageChange" :currentPage="activePage" />
        </div>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
import ApiService from '@/utilities/ApiService';
import { createNamespacedHelpers } from 'vuex';
import { uid } from 'uid';
import draggable from 'vuedraggable';
import Arrow from '@/assets/images/Icons/arrow_right_white.svg';
import SurveyQuestion from '@/components/SurveyQuestion.vue';
import Loading from '@/components/Loading.vue';
import Modal from '@/components/Modal.vue';
import S3Api from '@/utilities/S3Api';
import ParseSurvey from '@/utilities/ParseSurvey';
import ParseQuiz from '@/utilities/ParseQuiz';
import Add from '@/assets/images/Icons/add.svg';
import Dots from '@/assets/images/Icons/dots_vertical.svg';
import Waiver from '@/assets/images/Icons/icon_waiver.svg';
import Survey from '@/assets/images/Icons/icon_survey.svg';
import ArrowDown from '@/assets/images/Icons/arrow_right_red.svg';
import Quiz from '@/assets/images/Icons/icon_quiz.svg';
import Delete from '@/assets/images/Icons/delete.svg';
import Check from '@/assets/images/Icons/check.svg';
import CancelIcon from '@/assets/images/Icons/cancel.svg';
import UploadIcon from '@/assets/images/Icons/upload.svg';
import Pagination from '@/components/Pagination.vue';
import SearchIcon from '@/assets/images/Icons/search.svg';
import csvDisclaimer from '@/assets/json/csvDisclaimer.json';
import QuizQuestion from './QuizQuestion.vue';
import PorscheButton from './PorscheButton.vue';

const { mapState, mapMutations } = createNamespacedHelpers('workbookBuilder');

export default {
  name: 'module-assets',
  components: {
    Dots,
    Waiver,
    ArrowDown,
    Survey,
    Quiz,
    Delete,
    Check,
    Add,
    CancelIcon,
    UploadIcon,
    QuizQuestion,
    draggable,
    PorscheButton,
    Arrow,
    SurveyQuestion,
    Loading,
    Modal,
    SearchIcon,
    Pagination,
  },
  props: ['asset', 'index', 'id', 'moduleId', 'assetVisible'],
  data() {
    return {
      localAsset: { ...this.asset },
      originalLocalAsset: JSON.parse(JSON.stringify(this.asset)),
      creatingAsset: null,
      parsingErrorModal: null,
      parsingErrorContent: null,
      quizQuestions: [],
      quizQuestionTypes: [],
      surveyQuestions: [],
      surveyQuestionTypes: [],
      createNewActive: true,
      uploadCSVActive: null,
      importActive: null,
      error: null,
      success: null,
      statusMsg: null,
      assetCount: null,
      activePage: 1,
      pageCount: 0,
      offset: 0,
      contentPages: {},
      paginatedContentPage: [],
      contentLibQuery: null,
      queryTimeout: null,
      quizDisclaimer: csvDisclaimer.quizDisclaimer,
      surveyDisclaimer: csvDisclaimer.surveyDisclaimer,
    };
  },
  computed: {
    ...mapState(['moduleAssets']),

    quizTemplate() {
      return `${process.env.VUE_APP_ASSETS}/public/static/quiz_template.csv`;
    },

    surveyTemplate() {
      return `${process.env.VUE_APP_ASSETS}/public/static/survey_template.csv`;
    },

    // check to see if asset is local or stored in db
    isDataBaseAsset() {
      return this.localAsset.id.length > 11 || this.localAsset.newId;
    },

    // gets id of current asset
    localAssetId() {
      return this.localAsset.newId ? this.localAsset.newId : this.localAsset.id;
    },
  },
  async created() {
    if (this.asset.module_asset.type === 'quiz') {
      const allQuestionTypes = await ApiService.get(`${process.env.VUE_APP_API_URL}/question-type/`);
      this.quizQuestionTypes = allQuestionTypes.data.data.filter((type) => type.isQuizOption === true);
      this.getContentLibraryData();
      return;
    }
    if (this.asset.module_asset.type === 'survey') {
      const allQuestionTypes = await ApiService.get(`${process.env.VUE_APP_API_URL}/question-type/`);
      this.surveyQuestionTypes = allQuestionTypes.data.data.filter((type) => type.isSurveyOption === true);
      this.getContentLibraryData();
    }
  },
  watch: {
    localAsset: {
      deep: true,
      handler() {
        if (JSON.stringify(this.originalLocalAsset) !== JSON.stringify(this.localAsset)) {
          this.SET_ISDIRTY();
          return;
        }
        this.RESET_ISDIRTY();
      },
    },
  },
  methods: {
    ...mapMutations(['UPDATE_MODULE_ASSET', 'SET_ISDIRTY', 'RESET_ISDIRTY']),

    closeModal() {
      this.parsingErrorModal = null;
    },

    // toggles asset content view
    toggleAsset() {
      this.$emit('toggleAssetView', this.index);

      // Hacky way of resolving issue. Need to revisit.
      setTimeout(() => {
        if (this.localAsset.assetVisible) {
          if (this.asset.id.length > 11) {
            if (this.localAsset.module_asset.type === 'waiver') {
              if (this.localAsset.fileUrl) {
                // disable url
                const waiverUrl = document.querySelector(`.waiverUrl${this.index}`);
                waiverUrl.setAttribute('disabled', 'true');
              }
              if (this.localAsset.url) {
                const wavierLabel = document.querySelector(`.wavierLabel${this.index}`);
                const porscheButton = document.querySelector('.content-upload .input .porsche-button');

                wavierLabel.removeAttribute('for');
                porscheButton.removeAttribute('for');
              }
            }
          }
        }
      }, 1);
    },

    promptDelete() {
      this.$emit('prompt-delete', this.localAsset);
    },

    HTTPSuccess(msg) {
      this.success = true;
      this.statusMsg = msg;
      setTimeout(() => {
        this.success = false;
      }, 5000);
    },

    HTTPError(msg) {
      this.error = true;
      this.statusMsg = msg;
      setTimeout(() => {
        this.error = false;
      }, 5000);
    },

    // WAVIER
    waiverFileChange() {
      const wavierFile = document.querySelector(`.wavierFile${this.index}`);
      const waiverUrl = document.querySelector(`.waiverUrl${this.index}`);

      this.localAsset.file = wavierFile.files[0];
      this.localAsset.fileName = this.localAsset.file.name;
      // need to disable the url input
      waiverUrl.setAttribute('disabled', 'true');
    },

    removeWaiverFile() {
      const waiverUrl = document.querySelector(`.waiverUrl${this.index}`);
      this.localAsset.file = null;
      this.localAsset.fileName = null;
      this.localAsset.url = null;

      // remove disabled attribute
      waiverUrl.removeAttribute('disabled');
    },

    waiverUrlChange() {
      const wavierLabel = document.querySelector(`.wavierLabel${this.index}`);
      const porscheButton = document.querySelector('.content-upload .input .porsche-button');
      if (this.localAsset.url.length === 0) {
        porscheButton.setAttribute('for', 'file-upload');
        wavierLabel.setAttribute('for', 'file-upload');
        return;
      }
      wavierLabel.removeAttribute('for');
      porscheButton.removeAttribute('for');
    },

    async uploadToS3() {
      const wavierFile = document.querySelector(`.wavierFile${this.index}`);
      const getURL = await ApiService.get(
        `${process.env.VUE_APP_API_URL}/workbook/module/waiver/upload-waiver/presigned`,
      );
      await S3Api(getURL.data.data.putUrl, wavierFile.files[0]);
      this.localAsset.fileUrl = getURL.data.data.getUrl;
    },

    async createWavier() {
      try {
        // start loading
        this.creatingAsset = true;

        // Check for file, upload to S3
        if (this.localAsset.file) {
          await this.uploadToS3();
        }

        // Create Wavier
        const createAsset = await ApiService.post(`${process.env.VUE_APP_API_URL}/workbook/module/waiver`, {
          moduleId: this.moduleId,
          active: this.localAsset.active,
          title: this.localAsset.title,
          fileName: this.localAsset.fileName ? this.localAsset.fileName : '',
          fileUrl: this.localAsset.fileUrl,
          url: this.localAsset.url,
          isSignatureRequired: this.localAsset.isSignatureRequired,
          order: this.index,
        });

        // create new value for new id
        this.localAsset.newId = createAsset.data.data.id;

        const newAsset = {
          content: this.localAsset,
          index: this.index,
        };

        this.UPDATE_MODULE_ASSET(newAsset);
        this.$emit('createAsset', newAsset);

        this.$emit('toggleAssetView', this.index);
        this.originalLocalAsset = JSON.parse(JSON.stringify(this.localAsset));

        this.RESET_ISDIRTY();
        this.HTTPSuccess('Waiver created');
      } catch (error) {
        this.HTTPError(error);
      }
      // stop loading
      this.creatingAsset = null;
    },

    async updateWavier() {
      try {
        // start loading
        this.creatingAsset = true;

        // Check for file, upload to S3
        if (this.localAsset.file) {
          await this.uploadToS3();
        }

        await ApiService.patch(`${process.env.VUE_APP_API_URL}/workbook/module/waiver/${this.localAssetId}`, {
          moduleId: this.moduleId,
          active: this.localAsset.active,
          title: this.localAsset.title,
          fileName: this.localAsset.fileName ? this.localAsset.fileName : '',
          fileUrl: this.localAsset.fileUrl,
          url: this.localAsset.url,
          isSignatureRequired: this.localAsset.isSignatureRequired,
          order: this.index,
        });

        this.UPDATE_MODULE_ASSET(this.localAsset);
        this.$emit('toggleAssetView', this.index);
        this.originalLocalAsset = JSON.parse(JSON.stringify(this.localAsset));
        this.RESET_ISDIRTY();
        this.HTTPSuccess('Waiver updated');
      } catch (error) {
        this.HTTPError(error);
      }
      // stop loading
      this.creatingAsset = null;
    },

    // SURVEY
    toggleQuestion(index) {
      if (this.asset.module_asset.type === 'survey') {
        this.$set(this.localAsset.survey.questions[index], 'visible', !this.localAsset.survey.questions[index].visible);
        return;
      }
      this.$set(this.localAsset.quiz.questions[index], 'visible', !this.localAsset.quiz.questions[index].visible);
    },

    async parseSurvey() {
      try {
        const parseData = await ParseSurvey(this.$refs.parseSurvey.files[0]);
        this.localAsset.survey.questions = parseData.data.data.questions;
        this.toggleAssetNavigation(1);
      } catch (err) {
        this.parsingErrorContent = {
          title: 'Import Failed',
          message: 'Please review your file and ensure it matches the provided template, then try again.',
        };
        this.parsingErrorModal = true;
        console.warn(err.message);
      }
    },

    addSurveyQuestion() {
      this.localAsset.survey.questions.push({
        id: uid(),
        question: null,
        questionTypeId: null,
        isRequired: null,
        options: [],
        visible: null,
      });
    },

    removeSurveyQuestion(id) {
      this.localAsset.survey.questions = this.localAsset.survey.questions.filter((question) => question.id !== id);
    },

    updateSurveyQuestion(payload) {
      this.localAsset.survey.questions.forEach((question) => {
        if (question.id === payload.id) {
          question.question = payload.question;
          question.isRequired = payload.isRequired;
          question.questionTypeId = payload.questionTypeId ? payload.questionTypeId : null;
          question.options = payload.options;
        }
      });
    },

    async updateSurveyQuestionOrder() {
      // new array for order
      const newOrder = [];
      // loop through questions to build new array with new order of questions
      this.localAsset.survey.questions.forEach((question, index) => {
        newOrder.push({
          id: question.id,
          order: index,
        });
      });
      // send new order to BE
      await ApiService.post(`${process.env.VUE_APP_API_URL}/survey/question/order`, {
        questions: newOrder,
      });
    },

    async updateSurvey() {
      try {
        // start loading
        this.creatingAsset = true;

        if (this.localAsset.survey.submissions === true) {
          await ApiService.patch(`${process.env.VUE_APP_API_URL}/survey/${this.localAssetId}/active`, {
            active: this.localAsset.active,
          });
        } else {
          await ApiService.patch(`${process.env.VUE_APP_API_URL}/survey/${this.localAssetId}`, {
            moduleId: this.moduleId,
            title: this.localAsset.title,
            vehicleYear: null,
            vehicleModel: null,
            active: this.localAsset.active,
            order: this.index,
            questions: this.localAsset.survey.questions,
          });
        }
        const updatedAsset = {
          content: this.localAsset,
          index: this.index,
        };

        this.UPDATE_MODULE_ASSET(updatedAsset);
        this.$emit('toggleAssetView', this.index);
        this.originalLocalAsset = JSON.parse(JSON.stringify(this.localAsset));

        this.RESET_ISDIRTY();
        this.HTTPSuccess('Survey updated');
      } catch (error) {
        this.HTTPError(error);
      }
      // stop loading
      this.creatingAsset = null;
    },

    async createSurvey() {
      try {
        // start loading
        this.creatingAsset = true;

        const createAsset = await ApiService.post(`${process.env.VUE_APP_API_URL}/survey`, {
          vehicleYear: null,
          vehicleModel: null,
          moduleId: this.moduleId,
          title: this.localAsset.title,
          order: this.index,
          active: this.localAsset.active,
          questions: this.localAsset.survey.questions,
        });

        // create new value for new id
        this.localAsset.newId = createAsset.data.data.survey.id;
        this.localAsset.survey.questions = createAsset.data.data.questions;

        const newAsset = {
          content: this.localAsset,
          index: this.index,
        };

        this.UPDATE_MODULE_ASSET(newAsset);
        this.$emit('createAsset', newAsset);

        this.$emit('toggleAssetView', this.index);
        this.originalLocalAsset = JSON.parse(JSON.stringify(this.localAsset));

        this.RESET_ISDIRTY();
        this.HTTPSuccess('Survey Created');
      } catch (error) {
        this.HTTPError(error);
      }

      // stop loading
      this.creatingAsset = null;
    },

    // QUIZ
    async parseQuiz() {
      try {
        const parseData = await ParseQuiz(this.$refs.parseQuiz.files[0]);
        this.localAsset.quiz.questions = parseData.data.data.questions;
        this.toggleAssetNavigation(1);
      } catch (err) {
        this.parsingErrorContent = {
          title: 'Import Failed',
          message: 'Please review your file and ensure it matches the provided template, then try again.',
        };
        this.parsingErrorModal = true;
        console.warn(err.message);
      }
    },

    addQuestion() {
      this.localAsset.quiz.questions.push({
        id: uid(),
        question: null,
        explanation: '',
        questionTypeId: false,
        isRequired: false,
        isShuffled: false,
        options: [],
        visible: null,
      });
    },

    updateQuizQuestion(payload) {
      this.localAsset.quiz.questions.forEach((question) => {
        if (question.id === payload.id) {
          question.question = payload.question;
          question.explanation = payload.explanation;
          question.questionTypeId = payload.questionTypeId ? payload.questionTypeId : null;
          question.isRequired = payload.isRequired;
          question.isShuffled = payload.isShuffled;
          question.options = payload.options;
        }
      });
    },

    removeQuizQuestion(id) {
      this.localAsset.quiz.questions = this.localAsset.quiz.questions.filter((question) => question.id !== id);
    },

    async updateQuizQuestionOrder() {
      // new array for order
      const newOrder = [];
      // loop through questions to build new array with new order of questions
      this.localAsset.quiz.questions.forEach((question, index) => {
        newOrder.push({
          id: question.id,
          order: index,
        });
      });
      // send new order to BE
      await ApiService.post(`${process.env.VUE_APP_API_URL}/quiz/question/order`, {
        questions: newOrder,
      });
    },

    async createQuiz() {
      try {
        // start loading
        this.creatingAsset = true;

        const createAsset = await ApiService.post(`${process.env.VUE_APP_API_URL}/quiz`, {
          moduleId: this.moduleId,
          title: this.localAsset.title,
          passPercentage: this.localAsset.passPercentage,
          order: this.index,
          active: this.localAsset.active,
          questions: this.localAsset.quiz.questions,
          vehicleYear: null,
          vehicleModel: null,
        });

        // create new value for new id & update quiz questions
        this.localAsset.newId = createAsset.data.data.quiz.id;
        this.localAsset.quiz.questions = createAsset.data.data.questions;

        const newAsset = {
          content: this.localAsset,
          index: this.index,
        };

        this.UPDATE_MODULE_ASSET(newAsset);
        this.$emit('createAsset', newAsset);

        this.$emit('toggleAssetView', this.index);
        this.originalLocalAsset = JSON.parse(JSON.stringify(this.localAsset));

        this.RESET_ISDIRTY();
        this.HTTPSuccess('Quiz created');
      } catch (error) {
        this.HTTPError(error);
      }
      // stop loading
      this.creatingAsset = null;
    },

    async updateQuiz() {
      try {
        // start loading
        this.creatingAsset = true;

        if (this.localAsset.quiz.submissions === true) {
          await ApiService.patch(`${process.env.VUE_APP_API_URL}/quiz/${this.localAssetId}/active`, {
            active: this.localAsset.active,
          });
        } else {
          await ApiService.patch(`${process.env.VUE_APP_API_URL}/quiz/${this.localAssetId}`, {
            moduleId: this.localAsset.moduleId,
            title: this.localAsset.title,
            passPercentage: this.localAsset.passPercentage,
            order: this.index,
            active: this.localAsset.active,
            questions: this.localAsset.quiz.questions,
            vehicleYear: null,
            vehicleModel: null,
          });
        }

        const updatedAsset = {
          content: this.localAsset,
          index: this.index,
        };

        this.UPDATE_MODULE_ASSET(updatedAsset);
        this.$emit('toggleAssetView', this.index);
        this.originalLocalAsset = JSON.parse(JSON.stringify(this.localAsset));
        this.RESET_ISDIRTY();
        this.HTTPSuccess('Quiz updated');
      } catch (error) {
        this.HTTPError(error);
      }
      // stop loading
      this.creatingAsset = null;
    },

    // toggles asset navigation view
    toggleAssetNavigation(listItem) {
      if (listItem === 1) {
        this.createNewActive = true;
        this.uploadCSVActive = false;
        this.importActive = false;
        return;
      }
      if (listItem === 2) {
        this.createNewActive = false;
        this.uploadCSVActive = true;
        this.importActive = false;
      }
      if (listItem === 3) {
        this.createNewActive = false;
        this.uploadCSVActive = false;
        this.importActive = true;
      }
    },

    async getContentLibraryData() {
      const assetType = [];
      assetType.push(this.asset.type);

      try {
        const assets = await ApiService.post(`${process.env.VUE_APP_API_URL}/content-library/quiz-survey`, {
          order: 'ASC',
          sortBy: 'ASC',
          limit: 3,
          offset: this.offset,
          query: this.contentLibQuery,
          assetType: this.localAsset.module_asset.type,
        });
        this.assetCount = assets.data.data.count;
        this.pageCount = Math.ceil(this.assetCount / 3);
        this.contentPages[this.activePage] = assets.data.data.content;
        this.paginatedContentPage = this.contentPages[this.activePage];
      } catch (error) {
        console.warn(error);
      }
    },

    queryContentSearch() {
      clearTimeout(this.queryTimeout);
      this.queryTimeout = setTimeout(() => {
        this.getContentLibraryData();
      }, 500);
    },

    // Get selected asset, set data to local asset & toggle view
    async getAsset(asset) {
      try {
        const { data } = await ApiService.get(`${process.env.VUE_APP_API_URL}/${asset.category}/${asset.id}`);
        this.localAsset.title = data.data.title;
        // Survey
        if (asset.category === 'survey') {
          const questions = [];
          data.data.surveyQuestion.forEach((question) => {
            questions.push({ ...question, options: question.surveyQuestionOption });
          });
          this.localAsset.survey = {
            id: data.data.id,
            title: data.data.title,
            takenSurvey: data.data.takenSurvey,
            submissions: data.data.submissions,
            questions,
          };
        }
        // Quiz
        if (asset.category === 'quiz') {
          // set pass percentage
          this.localAsset.passPercentage = data.data.passPercentage;
          const questions = [];
          data.data.quizQuestion.forEach((question) => {
            questions.push({ ...question, options: question.quizQuestionOption });
          });
          this.localAsset.quiz = {
            id: data.data.id,
            title: data.data.title,
            takenQuiz: data.data.takenQuiz,
            submissions: data.data.submissions,
            questions,
          };
        }
        this.toggleAssetNavigation(1);
      } catch (error) {
        console.warn(error.message);
      }
    },

    async pageChange(page) {
      // Check if we already have page
      if (this.contentPages[page]) {
        this.paginatedContentPage = this.contentPages[page];
        this.activePage = page;
        return;
      }

      // Get new page
      this.offset = (page - 1) * 3;
      this.activePage = page;
      await this.getContentLibraryData();
      this.paginatedContentPage = this.contentPages[page];
    },
  },
};
</script>

<style lang="scss" scoped>
.asset {
  position: relative;
  padding: 40px 34px;
  border: 1px solid #7c7f81;

  .add-icon {
    width: 17px;
    height: 17px;
    margin-right: 8px;
  }

  .chevron {
    width: 12px;
    height: 16px;
    transition: 0.5s ease all;
    transform: rotate(90deg);
    path {
      fill: #000;
    }
  }

  .arrow-active {
    transform: rotate(270deg);
  }

  .reorder {
    position: absolute;
    top: -12px;
    left: 20px;
    z-index: 1000;
    display: flex;
    align-items: center;
    padding: 0 16px;
    background-color: #fff;
    .dots-icon {
      width: 4px;
      height: auto;
      margin-right: 6px;
    }
  }

  .asset-actions {
    display: flex;
    gap: 16px;
    justify-content: flex-start;
    margin-top: 24px;
    .toggle-switch {
      flex: 1;
      gap: 10px;
    }
    .asset-actions-child {
      display: flex;
      gap: 10px;
    }
    .a-action {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
      span {
        margin-left: 6px;
      }
    }
    .hide-icon {
      width: 18px;
      height: 16px;
    }

    .delete-icon {
      width: 12px;
      height: 16px;
    }
  }

  .add-question {
    align-items: center;
    margin-top: 24px;
  }
}

.quiz,
.survey {
  .upload-csv-wrap {
    margin-top: 32px;
    .upload-csv {
      display: flex;
      flex-direction: row;
      column-gap: 16px;

      input[type='file'] {
        display: none;
      }

      .porsche-button {
        align-self: flex-start;
      }
    }

    .disclaimer {
      display: block;
      margin-top: 16px;
      font-size: 12px;
      color: #4a4a4a;

      &-list {
        margin-top: 4px;
        margin-left: 16px;
        text-indent: -16px;
        list-style: none;

        li {
          margin-bottom: 2px;

          &::before {
            padding-right: 8px;
            content: '-';
          }
        }
      }
    }
  }
}

.quiz {
  .quiz-header {
    display: flex;
    column-gap: 16px;

    .quiz-title {
      flex-basis: 80%;
    }

    .quiz-pass-percentage {
      flex-basis: 20%;
    }
  }
}

.content-lib-search {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  .search-heading {
    display: flex;
    column-gap: 8px;
    margin-bottom: 6px;
    text-transform: capitalize;
  }

  .search-query {
    margin-bottom: 24px;
    input {
      width: 100%;
      padding-left: 40px;
    }

    .icon-wrap {
      position: absolute;
      left: 13px;
      display: flex;
      align-items: center;
      height: 100%;

      .search-icon {
        width: 18px;
        height: auto;
      }
    }
  }

  .search-results {
    .search-asset {
      display: flex;
      flex-direction: column;
      .asset-info {
        display: flex;
        .asset-content {
          display: flex;
          flex: 1;
          column-gap: 16px;
          img,
          video {
            width: 75px;
            height: auto;
          }

          .asset-details {
            h3 {
              margin-bottom: 4px;
              font-size: 16px;
            }

            .view-media {
              display: flex;
              align-items: center;
              margin-bottom: 8px;
              font-size: 14px;
              cursor: pointer;

              .arrow-icon {
                width: auto;
                height: 16px;
              }
            }

            .tags {
              display: flex;
              column-gap: 4px;
            }
          }
        }
        .asset-select {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          cursor: pointer;
          .arrow-icon {
            width: auto;
            height: 20px;
          }
        }
      }
    }

    .arrow-icon {
      path:nth-child(2) {
        fill: $primary;
      }
    }
  }
}
</style>
