import ApiService from '@/utilities/ApiService';
import { uid } from 'uid';

export default async (file) => {
  const formData = new FormData();
  formData.append('template', file);
  const parse = await ApiService.post(`${process.env.VUE_APP_API_URL}/quiz/upload/palms`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  parse.data.data.questions.forEach((quizQuestion) => {
    quizQuestion.id = uid();
    quizQuestion.options.forEach((option) => {
      option.id = uid();
    });
  });

  return parse;
};
