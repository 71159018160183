<template>
  <div class="breadcrumbs">
    <p>
      <span v-for="(route, index) in trail" :key="index" class="trail-item">
        <router-link v-if="route.route" class="link" :to="{ name: route.route }">{{ route.name }}</router-link>
        <span v-else class="link">{{ route.name }}</span>
        <span class="divider" v-if="index + 1 !== trail.length">/</span>
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'breadcrumbs',
  components: {},
  props: {
    trail: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  created() {},
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  margin-top: 40px;

  p {
    font-size: 14px;

    .link {
      font-size: 14px;
      color: #000;
    }

    span {
      text-transform: capitalize;
    }

    .divider {
      padding: 0 6px;
      color: #4a4a4a;
    }
  }

  .router-link-active {
    color: #000;
  }
}
</style>
