import { uid } from 'uid';
import Vue from 'vue';
import ApiService from '@/utilities/ApiService';

export default {
  namespaced: true,
  state: {
    workbook: {},
    moduleNavigation: [],
    moduleInfo: {},
    moduleAssets: [],
    sectionInfo: {},
    sectionAssetTypes: null,
    sectionAssets: [],
    isDirty: null,
    workbookPreviewMode: null,
  },
  mutations: {
    // Dirty State
    SET_ISDIRTY(state) {
      state.isDirty = true;
    },

    RESET_ISDIRTY(state) {
      state.isDirty = false;
    },
    // Workbook
    SET_WORKBOOK(state, payload) {
      const result = payload.data.data;
      if (result.event) {
        result.event.fullTitle = `${result.event.palmsId} - ${result.event.title}`;
      }
      state.workbook = {
        id: result.workbook.id,
        coverImageUrl: result.workbook.coverImageUrl,
        title: result.workbook.title,
        moduleId: result.event,
        sessions: result.sessions,
        model: result.workbook.vehicleModel,
        modelYear: result.workbook.vehicleYear,
        category: result.workbook.category,
        workbookDescription: result.workbook.description,
        workbookInstructorDescription: result.workbook.instructorDescription,
        isPublished: result.workbook.isPublished,
        isRequired: result.workbook.isRequired,
        eventId: result.event ? result.event.palmsId : null,
      };

      state.moduleNavigation = result.modules;
    },

    SET_CREATE_WORKBOOK(state, { create, eventId, sessions }) {
      const result = create.data.data;

      state.workbook = {
        id: result.workbook.id,
        coverImageUrl: result.workbook.coverImageUrl,
        title: result.workbook.title,
        moduleId: eventId,
        sessions,
        model: result.workbook.vehicleModel,
        modelYear: result.workbook.vehicleYear,
        category: result.workbook.category,
        curriculumDesigners: result.curriculumDesigners,
        prerequisites: result.prerequisites,
        workbookDescription: result.workbook.description,
        isPublished: result.workbook.isPublished,
        isRequired: result.workbook.isRequired,
        eventId,
      };
    },

    UPDATE_WORKBOOK(state, payload) {
      state.workbook = payload;
    },

    RESET_WORKBOOK(state) {
      state.workbook = {};
      state.moduleNavigation = [];
      state.moduleInfo = {};
      state.moduleAssets = [];
      state.sectionInfo = {};
      state.sectionAssets = [];
      state.isDirty = null;
    },

    UPDATE_SECTION_MODULE(state, payload) {
      state.moduleNavigation[payload.newModulePosition].sections.forEach((section) => {
        section.moduleId = payload.newModule;
      });
    },

    // Module

    ADD_MODULE(state) {
      const id = uid();
      state.moduleNavigation.push({
        id,
        title: 'New Module',
        sections: [],
        isLocked: false,
      });
      state.moduleInfo = {
        id,
        workbookId: state.workbook.workbookId,
        title: 'New Module',
        description: '',
        instructorDescription: '',
        isLocked: false,
        accessCode: null,
        unlockedTime: null,
        date: null,
        time: null,
      };
    },

    SET_MODULE(state, content) {
      state.moduleInfo = {
        ...content.data.data,
      };

      if (state.moduleInfo.unlockTime) {
        state.moduleInfo.date = new Date(state.moduleInfo.unlockTime).toLocaleDateString('en-us', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });

        state.moduleInfo.time = new Date(state.moduleInfo.unlockTime).toLocaleTimeString('en-us', {
          hour12: true,
          hour: '2-digit',
          minute: 'numeric',
        });
      }
    },

    UPDATE_MODULE(state, { content, index }) {
      state.moduleInfo = {};
      state.moduleInfo = content;
      state.moduleNavigation[index].title = content.title;
      state.moduleNavigation[index].id = content.id;
      state.moduleNavigation[index].isLocked = content.isLocked;
    },

    DELETE_MODULE(state, content) {
      state.moduleNavigation = state.moduleNavigation.filter((module) => module.id !== content.id);
    },

    SET_MODULE_ASSETS(state, assets) {
      state.moduleAssets = [];
      assets.data.data.forEach((asset) => {
        state.moduleAssets.push({
          ...asset,
          file: asset.fileName !== '',
          assetVisible: false,
          newId: null,
        });
      });
    },

    REMOVE_MODULE_ASSET(state, assetId) {
      state.moduleAssets = state.moduleAssets.filter((asset) => asset.id !== assetId);
    },

    UPDATE_MODULE_ASSET(state, { index, content }) {
      Vue.set(state.moduleAssets, index, { ...content });
    },

    // Section
    ADD_SECTION(state, { index, moduleId }) {
      const id = uid();
      state.moduleNavigation[index].sections.push({
        id,
        moduleId,
        title: 'New Section',
        description: '',
        instructorDescription: '',
      });
    },

    SET_SECTION(state, content) {
      state.sectionInfo = {
        ...content.section.data.data.section,
      };
      state.sectionAssetTypes = [...content.assetTypes.data.data];
    },

    UPDATE_SECTION(state, { content, index, secondaryIndex }) {
      state.sectionInfo = {};
      state.sectionInfo = content;
      state.moduleNavigation[index].sections[secondaryIndex].title = content.title;
      state.moduleNavigation[index].sections[secondaryIndex].id = content.id;
    },

    DELETE_SECTION(state, content) {
      state.moduleNavigation[content.index].sections = state.moduleNavigation[content.index].sections.filter(
        (section) => section.id !== content.content.id,
      );
    },

    SET_SECTION_ASSETS(state, payload) {
      state.sectionAssets = [];
      payload.restructuredData.forEach((asset) => {
        let type = null;
        payload.assetTypes.data.data.forEach((assetType) => {
          if (assetType.id === asset.assetTypeId) {
            type = assetType.name;
          }
        });
        state.sectionAssets.push({
          ...asset,
          file: asset.fileName !== '',
          assetVisible: false,
          type,
          newId: null,
        });
      });
    },

    REMOVE_SECTION_ASSET(state, assetId) {
      state.sectionAssets = state.sectionAssets.filter((asset) => asset.id !== assetId);
    },

    UPDATE_SECTION_ASSET(state, { index, content }) {
      state.sectionAssets[index] = content;
    },

    RESET_SECTION_ASSETS(state) {
      state.sectionAssets = [];
    },
  },
  actions: {
    async GET_WORKBOOK({ commit }, workbookId) {
      try {
        const getWorkbook = await ApiService.get(`${process.env.VUE_APP_API_URL}/workbook/${workbookId}`);
        commit('SET_WORKBOOK', getWorkbook);
      } catch (error) {
        throw new Error(error);
      }
    },

    async GET_MODULE({ commit }, moduleId) {
      try {
        const dataArray = [
          ApiService.get(`${process.env.VUE_APP_API_URL}/workbook/module/${moduleId}`),
          ApiService.get(`${process.env.VUE_APP_API_URL}/workbook/module/assets/${moduleId}`),
        ];
        const [module, moduleAssets] = await ApiService.all(dataArray);

        // if nullable, need to filter out null asset & reorder them on the BE.
        moduleAssets.data.data = moduleAssets.data.data.filter((asset) => asset !== null);

        // quiz & survey data, stored in separate route
        const quizzesArray = [];
        const surveyArray = [];
        moduleAssets.data.data.forEach((asset) => {
          if (asset.module_asset.type === 'quiz') {
            quizzesArray.push(ApiService.get(`${process.env.VUE_APP_API_URL}/quiz/${asset.id}`));
          }
          if (asset.module_asset.type === 'survey') {
            surveyArray.push(ApiService.get(`${process.env.VUE_APP_API_URL}/survey/${asset.id}`));
          }
        });

        const quizData = await ApiService.all(quizzesArray);
        const surveyData = await ApiService.all(surveyArray);
        moduleAssets.data.data.forEach((asset) => {
          // creating quiz data, post/patch requires alt names
          if (asset.module_asset.type === 'quiz') {
            quizData.forEach((quiz) => {
              if (quiz.data.data.id === asset.id) {
                const { data } = quiz.data;
                const questions = [];
                data.quizQuestion.forEach((question) => {
                  questions.push({ ...question, options: question.quizQuestionOption });
                });
                asset.quiz = {
                  id: data.id,
                  title: data.title,
                  takenQuiz: data.takenQuiz,
                  submissions: data.submissions,
                  questions,
                };
              }
            });
          }
          // creating survey data, post/patch requires alt names
          if (asset.module_asset.type === 'survey') {
            surveyData.forEach((survey) => {
              if (survey.data.data.id === asset.id) {
                const { data } = survey.data;
                const questions = [];
                data.surveyQuestion.forEach((question) => {
                  questions.push({ ...question, options: question.surveyQuestionOption });
                });
                asset.survey = {
                  id: data.id,
                  title: data.title,
                  takenSurvey: data.takenSurvey,
                  submissions: data.submissions,
                  questions,
                };
              }
            });
          }
        });

        commit('SET_MODULE', module);
        commit('SET_MODULE_ASSETS', moduleAssets);
      } catch (error) {
        throw new Error(error);
      }
    },

    async GET_SECTION({ commit }, sectionId) {
      try {
        const dataArray = [
          ApiService.get(`${process.env.VUE_APP_API_URL}/workbook/module/section/${sectionId}`),
          ApiService.get(`${process.env.VUE_APP_API_URL}/asset-type/`),
        ];
        const [section, assetTypes] = await ApiService.all(dataArray);
        commit('SET_SECTION', { section, assetTypes });

        // check for assets
        if (section.data.data.assets.length > 0) {
          const getAssets = await ApiService.get(
            `${process.env.VUE_APP_API_URL}/workbook/module/section/assets/${sectionId}`,
          );

          // creating hotspot data. post/patch requires alt name
          const restructuredData = [];
          for (let i = 0; i < getAssets.data.data.length; i += 1) {
            const asset = getAssets.data.data[i];
            if (asset.assetTypeId === 8) {
              if (asset.awsIdentifier) {
                // eslint-disable-next-line no-await-in-loop
                const urlImg = await ApiService.get(
                  `${process.env.VUE_APP_API_URL}/asset/presignedGet/${asset.awsIdentifier}`,
                );
                asset.url = urlImg.data.data;
              }
              restructuredData.push({ ...asset, pins: asset.assetPin });
            } else {
              restructuredData.push(asset);
            }
          }

          commit('SET_SECTION_ASSETS', { restructuredData, assetTypes });
        }
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
