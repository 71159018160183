<template>
  <footer class="footer">
    <ul class="container">
      <li>&copy; {{ year }} Porsche</li>
    </ul>
  </footer>
</template>

<script>
export default {
  name: 'footer-navigation',
  components: {},
  props: {},
  data() {
    return {};
  },
  created() {},
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.footer {
  padding: 26px 0;
  margin-top: auto;
  ul {
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
    list-style: none;
    @media (min-width: 750px) {
      justify-content: initial;
    }

    li {
      flex-basis: 100%;
      margin-bottom: 8px;
      @media (min-width: 750px) {
        flex-basis: initial;
        margin-right: 30px;
        margin-bottom: 0;
        text-align: initial;
      }
    }

    .link {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      color: inherit;
      text-decoration: none;
      @media (min-width: 750px) {
        margin-right: 30px;
      }
      .svg-icon {
        width: 6px;
        height: auto;
        margin-right: 8px;
      }
    }
  }
}
</style>
