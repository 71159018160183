<template>
  <div class="search-wrap">
    <Loading v-if="!searchDataAvailable" :type="'section'" />
    <div v-if="searchDataAvailable && !searchError" class="search container">
      <h1>{{ this.searchResultQuantity }} search results from "{{ this.searchedQuery }}"</h1>
      <div class="search-results">
        <div @click="goToResult(result.type, result.id)" v-for="result in searchData" :key="result.id" class="result">
          <div class="search-content">
            <h2>{{ result.title }}</h2>
            <p v-if="result.description" v-html="result.description"></p>
            <div class="tags">
              <span v-for="(tag, index) in result.tags" :key="index" class="tag">{{ tag }}</span>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>
    <div v-if="searchDataAvailable && searchError" class="container">
      <h1 class="error">Something went wrong, please try again.</h1>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Loading from './Loading.vue';

const { mapState, mapMutations } = createNamespacedHelpers('userInformation');
export default {
  name: 'default',
  components: { Loading },
  props: {},
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapState([
      'admin',
      'designer',
      'searchDataAvailable',
      'searchData',
      'searchedQuery',
      'searchResultQuantity',
      'searchError',
    ]),
  },
  watch: {},
  methods: {
    ...mapMutations(['CLOSE_SEARCH']),

    goToResult(type, id) {
      if (type === 'workbook') {
        if (this.admin || this.designer) {
          this.$router.push({ name: 'workbook setup', params: { workbookId: id } });
        } else {
          this.$router.push({ name: 'workbook view', params: { workbookId: id } });
        }
      } else if (type === 'asset') {
        this.$router.push({ name: 'content library asset', params: { assetId: id } });
      }

      this.CLOSE_SEARCH();
    },
  },
};
</script>

<style lang="scss" scoped>
.search-wrap {
  position: relative;

  h1 {
    margin-top: 60px;
    margin-bottom: 32px;
  }

  .search {
    .search-results {
      .result {
        display: flex;
        flex-direction: column;
        cursor: pointer;

        .search-content {
          h2,
          p {
            margin-bottom: 12px;
          }

          h2 {
            font-size: 16px;
          }

          .tags {
            display: flex;
            column-gap: 8px;
          }
        }

        hr {
          margin: 32px 0;
        }
      }
    }
  }
}
</style>
