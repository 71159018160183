<template>
  <div class="workbook-setup">
    <!-- Is Dirty Modal -->
    <Modal
      modalType="dirty"
      v-if="dirtyModal"
      :modalContent="modalContent"
      v-on:close-modal="closeModule"
      v-on:continue="proceedLeaveView"
    />

    <!-- Delete Modal -->
    <Modal
      modalType="delete"
      v-if="deleteModal"
      v-on:close-modal="closeModule"
      :modalContent="modalContent"
      v-on:delete-view="deleteView"
    />

    <Loading :type="'section'" v-if="!dataAvailable" />

    <div class="workbook">
      <!-- WORKBOOK NAVIGATION -->
      <ul class="workbook-nav">
        <!-- Workbook title -->
        <li
          v-if="localWorkbook.title"
          @click="changeView($event, 'setup')"
          ref="workbookTitle"
          class="title nav-item"
          :class="{ active: workbookCD }"
        >
          {{ localWorkbook.title }}
        </li>

        <!-- Workbook Modules -->
        <draggable :list="workbookBuilder.moduleNavigation" @change="updateModuleOrdering($event)">
          <li
            class="module"
            v-for="(workbookModule, index) in workbookBuilder.moduleNavigation"
            :key="workbookModule.id"
          >
            <div
              @click="changeView($event, 'module', workbookModule)"
              class="module-details nav-item"
              :class="{ active: currentModuleId === workbookModule.id && moduleView }"
            >
              {{ workbookModule.title }}
              <div class="module-icons">
                <Key v-if="workbookModule.isLocked" class="key-icon" />
                <CancelIcon
                  @click="promptDelete(workbookModule, 'module', index)"
                  class="cancel-icon"
                  ref="cancelIcon"
                />
              </div>
            </div>

            <!-- Workbook Sections -->
            <ul class="workbook-section" :data-id="workbookModule.id" :data-position="index">
              <draggable
                :list="workbookModule.sections"
                :group="{ name: 'sections' }"
                @add="moveSection($event)"
                @change="updateSectionOrder($event, index)"
              >
                <li
                  v-for="(workbookSection, secondaryIndex) in workbookModule.sections"
                  class="nav-item"
                  :key="workbookSection.id"
                  :data-position="index"
                  @click="changeView($event, 'section', workbookSection)"
                  :class="{ active: currentSectionId === workbookSection.id && sectionView }"
                >
                  {{ workbookSection.title }}
                  <CancelIcon
                    @click="promptDelete(workbookSection, 'section', index, secondaryIndex)"
                    class="cancel-icon"
                    ref="cancelIcon"
                  />
                </li>
              </draggable>
              <li v-if="workbookModule.id.length > 11" @click="addSection(index, workbookModule.id)" class="add">
                <Add class="add-icon" />Add Section
              </li>
            </ul>
          </li>
        </draggable>
        <!-- Add -->
        <li class="add" v-if="workbookBuilder.workbook.id" @click="addModule"><Add class="add-icon" />Add module</li>
      </ul>

      <!-- WORKBOOK CONTENT -->
      <div class="workbook-content-wrap">
        <!-- Workbook Setup -->
        <div v-if="workbookCD">
          <!-- Progress Update && Error Handling -->
          <div class="progress">
            <transition-group name="progress-update">
              <span :key="1" class="status-msg error" v-show="error">
                <CancelIcon class="cancel-icon" /> {{ statusMsg }}
              </span>
              <span :key="2" class="status-msg success" v-show="success">
                <Check class="check-icon" /> Success: {{ statusMsg }}
              </span>
            </transition-group>
            <PorscheButton v-if="dataAvailable && !dataError" type="button" @click="saveWorkbook">
              <Arrow class="arrow-icon icon" />
              <span>Save workbook</span>
            </PorscheButton>
            <PorscheButton
              v-if="dataAvailable && !dataError"
              @click="enablePreviewMode"
              type="button"
              :class="{ 'porsche-button-inactive': !disabledState }"
            >
              <Arrow class="arrow-icon icon" />
              <span>Preview workbook</span>
            </PorscheButton>
            <PorscheButton :light="true" @click="goToRoute('workbooks')">
              <Arrow class="arrow-icon icon" />
              <span>Exit</span>
            </PorscheButton>
          </div>

          <!-- Workbook Setup Content -->
          <div v-if="dataAvailable && !dataError">
            <h1 class="view-title">Workbook Setup</h1>
            <p class="view-summary">
              Begin by completing the form field below. Once that is completed, save your progress to continue to build
              out module and section associated to this new workbook.
            </p>

            <div class="create-workbook-cd">
              <div class="left">
                <img v-if="coverPhotoURL" :src="coverPhotoURL" alt="" />
                <img v-else-if="localWorkbook.coverImageUrl" :src="localWorkbook.coverImageUrl" alt="" />
                <img class="default-img" v-else src="@/assets/images/Workbook/workbook_thumb@2x.png" alt="" />
                <PorscheButton :label="true" for="coverPhoto">
                  <Upload class="upload-icon" />
                  <span>Upload cover photo</span>
                </PorscheButton>
                <PorscheButton
                  v-if="coverPhotoURL || localWorkbook.coverImageUrl"
                  @click="removeCoverPhoto"
                  :light="true"
                >
                  <CancelIcon class="cancel-icon" />
                  <span>Remove cover Image</span>
                </PorscheButton>
                <input
                  @change="updateCoverPhoto"
                  ref="coverPhoto"
                  name="coverPhoto"
                  id="coverPhoto"
                  type="file"
                  accept=".png, .jpg"
                />
                <span class="img-req">Images must be 250 x 250 pixels, JPG or PNG</span>
              </div>
              <div class="right">
                <div class="input">
                  <label for="workbookTitle">Workbook Title <span class="required-field">*</span></label>
                  <input type="text" v-model.lazy="localWorkbook.title" placeholder="Workbook Title" />
                </div>

                <hr />

                <div class="vue-multiselect">
                  <label for="moduleID">Associated Event/Module ID <span class="required-field">*</span></label>
                  <VueMultiselect
                    class="single"
                    @input="popEventData"
                    v-model="localWorkbook.moduleId"
                    :options="moduleIDoptions"
                    track-by="palmsId"
                    label="fullTitle"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="Select ID to continue"
                  />
                </div>

                <!-- Only show content once user selects an Module ID -->
                <div class="additional-info" v-show="localWorkbook.moduleId" ref="additionalInfo">
                  <div class="sessions">
                    <div class="session" v-for="session in localWorkbook.sessions" :key="session.id">
                      <p class="session-field"><span class="label">Session Name:</span> {{ session.title }}</p>
                      <div class="session-date">
                        <p class="session-field">
                          <span class="label">Start Date:</span>
                          {{ new Date(session.startDate).toLocaleDateString('en-us') }}
                        </p>
                        <p class="session-field">
                          <span class="label">End Date:</span>
                          {{ new Date(session.endDate).toLocaleDateString('en-us') }}
                        </p>
                      </div>
                      <p class="session-field">
                        <span class="label">Location:</span> {{ session.location ? session.location : '-' }}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div class="vue-multiselect">
                    <label>Model Year</label>
                    <VueMultiselect
                      v-model="localWorkbook.modelYear"
                      :options="modelYearOptions"
                      :searchable="false"
                      :close-on-select="true"
                      :show-labels="false"
                    />
                  </div>
                  <div class="vue-multiselect">
                    <label>Carlines</label>
                    <VueMultiselect
                      v-model="localWorkbook.model"
                      :options="modelOptions"
                      :searchable="false"
                      :close-on-select="true"
                      :show-labels="false"
                    />
                  </div>
                  <div class="vue-multiselect">
                    <label>Category</label>
                    <VueMultiselect
                      v-model="localWorkbook.category"
                      :options="categoryOptions"
                      :searchable="false"
                      :close-on-select="true"
                      :show-labels="false"
                    />
                  </div>
                  <hr />
                  <div class="textarea">
                    <label for="workbookDescription">Participant Workbook Description</label>
                    <quill-editor
                      ref="myQuillEditor"
                      class="quill-editors"
                      :options="editorOption"
                      v-model="localWorkbook.workbookDescription"
                    />
                  </div>
                  <div class="textarea">
                    <label for="workbookDescription">Instructor Workbook Description</label>
                    <quill-editor
                      ref="myQuillEditor"
                      class="quill-editors"
                      :options="editorOption"
                      v-model="localWorkbook.workbookInstructorDescription"
                    />
                  </div>
                  <div class="mutltiple-inputs">
                    <div>
                      <h4>Publish this workbook?</h4>
                      <div class="toggle-switch">
                        <input type="checkbox" v-model="localWorkbook.isPublished" />
                      </div>
                      <span class="additional-info"
                        >This workbook will be visible to all Porsche Mobile Academy users to which it is
                        assigned.</span
                      >
                    </div>
                    <div>
                      <h4>Required workbook?</h4>
                      <div class="toggle-switch">
                        <input type="checkbox" v-model="localWorkbook.isRequired" />
                      </div>
                      <span class="additional-info"
                        >Admins will not be able to delete this workbook from the Training section.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-for="(moduleDetails, index) in workbookBuilder.moduleNavigation" :key="index">
          <!-- Module Details -->
          <div v-if="moduleView">
            <CreateModule
              v-if="currentModuleId === moduleDetails.id"
              :currentModule="currentModule"
              :index="index"
              @current-module="updateCurrentModule"
            />
          </div>

          <!-- Section Details -->
          <div v-if="sectionView">
            <div v-for="(sectionDetails, secondaryIndex) in moduleDetails.sections" :key="secondaryIndex">
              <CreateSection
                v-if="currentSectionId === sectionDetails.id"
                :currentSection="currentSection"
                :currentModuleId="currentModuleId"
                :index="index"
                :secondaryIndex="secondaryIndex"
                @current-section="updateCurrentSection"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import CreateModule from '@/components/CreateModule.vue';
import CreateSection from '@/components/CreateSection.vue';
import ApiService from '@/utilities/ApiService';
import axios from 'axios';
import Arrow from '@/assets/images/Icons/arrow_right_white.svg';
import CancelIcon from '@/assets/images/Icons/cancel.svg';
import draggable from 'vuedraggable';
import Loading from '@/components/Loading.vue';
import Key from '@/assets/images/Icons/key.svg';
import Add from '@/assets/images/Icons/add.svg';
import Check from '@/assets/images/Icons/check.svg';
import Upload from '@/assets/images/Icons/upload.svg';
import PorscheButton from '@/components/PorscheButton.vue';
import VueMultiselect from '@/components/VueMultiselect.vue';
import Modal from '@/components/Modal.vue';

export default {
  name: 'workbook-builder',
  components: {
    Add,
    Check,
    Arrow,
    Upload,
    CreateModule,
    CreateSection,
    Key,
    PorscheButton,
    VueMultiselect,
    CancelIcon,
    Modal,
    draggable,
    Loading,
  },
  beforeRouteLeave(to, from, next) {
    if (this.workbookBuilder.isDirty) {
      this.dirtyModal = true;
      this.modalContent = {
        to,
      };
      return;
    }
    next();
  },
  props: {},
  data() {
    return {
      modalContent: null,
      deleteModal: null,
      dirtyModal: null,
      error: null,
      errorMsg: null,
      success: null,
      successMsg: null,
      statusMsg: null,
      localWorkbook: {
        id: null,
        title: null,
        coverImageUrl: null,
        moduleId: false,
        sessions: [],
        model: null,
        modelYear: null,
        category: null,
        workbookDescription: '',
        workbookInstructorDescription: '',
        isPublished: false,
        isRequired: false,
        eventId: null,
      },
      localModuleNavigation: [],
      moduleIDoptions: [],
      modelOptions: [],
      modelYearOptions: [],
      categoryOptions: [],
      editorOption: {
        modules: {
          toolbar: [
            { header: [1, 2, 3, 4, 5, 6, false] },
            'bold',
            'italic',
            { list: 'ordered' },
            { list: 'bullet' },
            'link',
          ],
        },
        placeholder: 'enter your description here...',
      },
      coverPhotoURL: null,
      s3URL: null,
      workbookCD: true,
      currentModule: null,
      currentModuleId: 0,
      currentSection: null,
      currentSectionId: 0,
      moduleView: null,
      sectionView: null,
      dataAvailable: null,
      dataError: null,
    };
  },
  async created() {
    await this.getWorkbookData();
  },
  computed: {
    ...mapState({
      workbookBuilder: (state) => state.workbookBuilder,
    }),

    disabledState() {
      return this.$route.params.workbookId.length > 11;
    },
  },
  watch: {
    localWorkbook: {
      deep: true,
      handler() {
        if (JSON.stringify(this.localWorkbook) !== JSON.stringify(this.workbookBuilder.workbook)) {
          this.SET_ISDIRTY();
          return;
        }
        this.RESET_ISDIRTY();
      },
    },
  },
  methods: {
    ...mapActions('workbookBuilder', ['SAVE_WORKBOOK', 'GET_WORKBOOK']),
    ...mapMutations('workbookBuilder', [
      'UPDATE_WORKBOOK',
      'UPDATE_SESSIONS',
      'ADD_MODULE',
      'ADD_SECTION',
      'RESET_WORKBOOK',
      'RESET_ISDIRTY',
      'DELETE_MODULE',
      'SET_CREATE_WORKBOOK',
      'SET_ISDIRTY',
      'SET_WORKBOOK',
      'DELETE_SECTION',
      'UPDATE_SECTION_MODULE',
    ]),

    // adds section to new module
    async moveSection(e) {
      // get moved item and set old module to var
      const movedItem = e.item._underlying_vm_;
      const oldModulePosition = e.item.getAttribute('data-position');
      const newModule = e.to.parentNode.getAttribute('data-id');
      const newModulePosition = e.to.parentNode.getAttribute('data-position');

      // update the section to have a new module on FE & BE
      await ApiService.patch(`${process.env.VUE_APP_API_URL}/workbook/module/section/${movedItem.id}/newModule`, {
        moduleId: newModule,
      });
      const payload = {
        movedItem,
        newModulePosition,
        newModule,
      };
      this.UPDATE_SECTION_MODULE(payload);

      // update the order of the modules sections effected
      const oldModuleOrder = [];
      const newModuleOrder = [];

      this.workbookBuilder.moduleNavigation[oldModulePosition].sections.forEach((item, index) => {
        oldModuleOrder.push({
          assetId: item.id,
          order: index,
        });
      });

      this.workbookBuilder.moduleNavigation[newModulePosition].sections.forEach((item, index) => {
        newModuleOrder.push({
          assetId: item.id,
          order: index,
        });
      });

      await ApiService.patch(`${process.env.VUE_APP_API_URL}/workbook/module/section/order-section`, {
        sections: oldModuleOrder,
      });

      await ApiService.patch(`${process.env.VUE_APP_API_URL}/workbook/module/section/order-section`, {
        sections: newModuleOrder,
      });
    },

    async updateModuleOrdering(item) {
      if (item.moved) {
        try {
          const newOrder = [];
          this.workbookBuilder.moduleNavigation.forEach((loopItem, index) => {
            newOrder.push({
              moduleId: loopItem.id,
              order: index,
            });
          });
          await axios.patch(`${process.env.VUE_APP_API_URL}/workbook/module/order-modules`, {
            modules: newOrder,
          });
        } catch (err) {
          console.warn(err);
        }
      }
    },

    async updateSectionOrder(item, index) {
      try {
        if (item.moved) {
          const newSectionOrder = [];
          this.workbookBuilder.moduleNavigation[index].sections.forEach((loopItem, loopIndex) => {
            newSectionOrder.push({
              assetId: loopItem.id,
              order: loopIndex,
            });
          });

          await ApiService.patch(`${process.env.VUE_APP_API_URL}/workbook/module/section/order-section`, {
            sections: newSectionOrder,
          });
        }
      } catch (err) {
        console.warn(err.message);
      }
    },

    // Prepop data
    async prePopData() {
      try {
        // Init Loading
        this.dataAvailable = false;

        // EVENT ID / TITLES
        const getIdOptions = ApiService.get(`${process.env.VUE_APP_API_URL}/events`);
        const getCategoryData = ApiService.get(`${process.env.VUE_APP_API_URL}/workbook/categories`);
        const [moduleIdData, categoryData] = await Promise.all([getIdOptions, getCategoryData]);

        // set module id options
        moduleIdData.data.data.forEach((option) => {
          option.fullTitle = `${option.palmsId} - ${option.title}`;
        });
        this.moduleIDoptions = moduleIdData.data.data;

        // setting category data
        this.categoryOptions = [];
        categoryData.data.data.forEach((item) => {
          this.categoryOptions.push(item.category);
        });
      } catch (err) {
        throw new Error(err);
      }
    },

    // Populate module options
    async popEventData(selectedValue, eventId) {
      try {
        const palmsID = selectedValue ? selectedValue.palmsId : eventId;

        // reset data
        this.modelOptions = [];
        this.modelYearOptions = [];

        // obtaining options based on current moduleId
        const getEventData = await ApiService.get(`${process.env.VUE_APP_API_URL}/workbook/populated/${palmsID}`);
        const eventData = getEventData.data.data;

        // setting event data
        if (palmsID !== this.localWorkbook.eventId) {
          this.localWorkbook.sessions = eventData.sessions;
        }

        this.modelOptions = eventData.models;
        this.modelYearOptions = eventData.year;
      } catch (err) {
        throw new Error(err);
      }
    },

    async getWorkbookData() {
      try {
        // reset data error if present
        this.dataError = null;

        // Pre populate data for fields
        await this.prePopData();

        // check for editing workbook
        if (this.$route.params.workbookId.length > 11) {
          // Do we have workbook stored in state?
          if (Object.keys(this.workbookBuilder.workbook).length > 0) {
            this.localWorkbook = { ...this.workbookBuilder.workbook };
            if (this.localWorkbook.eventId) {
              await this.popEventData(null, this.localWorkbook.eventId);
            }
            // Complete Loading
            this.dataAvailable = true;
            return;
          }
          // No workbook in state
          await this.GET_WORKBOOK(this.$route.params.workbookId);
          this.localWorkbook = { ...this.workbookBuilder.workbook };
          if (this.localWorkbook.eventId) {
            await this.popEventData(null, this.localWorkbook.eventId);
          }
        }
      } catch (error) {
        // set error & stop loading
        this.dataError = true;
        this.dataAvailable = true;
        this.error = true;
        this.statusMsg = error.message;
        return;
      }

      // Complete Loading
      this.dataAvailable = true;
    },

    workbookValidation() {
      if (!this.localWorkbook.title || !this.localWorkbook.moduleId) {
        throw new Error('Ensure all required fields are completed.');
      }
    },

    // Save / Update Workbook
    async saveWorkbook() {
      try {
        // reset error
        this.error = false;
        this.success = false;

        this.workbookValidation();

        //  Only uploading to S3 if new img is present
        if (this.$refs.coverPhoto.files[0]) {
          const getURL = await ApiService.get(`${process.env.VUE_APP_API_URL}/workbook/image-upload`);
          const instance = axios.create();
          delete instance.defaults.headers.common.Authorization;
          await instance.put(`${getURL.data.data.putUrl}`, this.$refs.coverPhoto.files[0], {
            headers: {
              'Content-Type': '',
            },
          });
          this.s3URL = getURL.data.data.getUrl;
        }
        // If workbook exists, make patch
        if (this.$route.params.workbookId.length > 11) {
          await ApiService.patch(`${process.env.VUE_APP_API_URL}/workbook/${this.workbookBuilder.workbook.id}`, {
            title: this.localWorkbook.title,
            coverImageUrl: this.s3URL ? this.s3URL : this.localWorkbook.coverImageUrl,
            description: this.localWorkbook.workbookDescription,
            instructorDescription: this.localWorkbook.workbookInstructorDescription,
            vehicleYear: this.localWorkbook.modelYear,
            vehicleModel: this.localWorkbook.model,
            category: this.localWorkbook.category,
            isRequired: this.localWorkbook.isRequired,
            isPublished: this.localWorkbook.isPublished,
            eventId: this.localWorkbook.moduleId.palmsId,
          });

          this.UPDATE_WORKBOOK(this.localWorkbook);

          this.success = true;
          this.statusMsg = 'Progress Saved';
          setTimeout(() => {
            this.success = false;
          }, 5000);
          this.RESET_ISDIRTY();
          return;
        }

        // New workbook
        const create = await ApiService.post(`${process.env.VUE_APP_API_URL}/workbook`, {
          title: this.localWorkbook.title,
          description: this.localWorkbook.workbookDescription,
          instructorDescription: this.localWorkbook.workbookInstructorDescription,
          vehicleYear: this.localWorkbook.modelYear,
          vehicleModel: this.localWorkbook.model,
          category: this.localWorkbook.category,
          isRequired: this.localWorkbook.isRequired,
          isPublished: this.localWorkbook.isPublished,
          coverImageUrl: this.s3URL ? this.s3URL : '',
          eventId: this.localWorkbook.moduleId.palmsId,
        });

        this.SET_CREATE_WORKBOOK({
          create,
          eventId: this.localWorkbook.moduleId.palmsId,
          sessions: this.localWorkbook.sessions,
        });

        this.$router.replace({ name: 'workbook setup', params: { workbookId: create.data.data.workbook.id } });

        this.success = true;
        this.statusMsg = 'Progress Saved';
        setTimeout(() => {
          this.success = false;
        }, 5000);

        this.RESET_ISDIRTY();
      } catch (error) {
        this.error = true;
        this.statusMsg = error.message;
        setTimeout(() => {
          this.error = false;
        }, 5000);
      }
    },

    // Create a local view of the cover photo
    updateCoverPhoto() {
      this.coverPhotoURL = URL.createObjectURL(this.$refs.coverPhoto.files[0]);
    },

    removeCoverPhoto() {
      this.coverPhotoURL = null;
      this.localWorkbook.coverImageUrl = '';
    },

    // reset all views to false
    resetViews() {
      this.workbookCD = null;
      this.moduleView = null;
      this.sectionView = null;
    },

    changeView(e, type, content) {
      if (!e.target.classList.contains('cancel-icon')) {
        if (this.workbookBuilder.isDirty) {
          this.dirtyModal = true;
          this.modalContent = {
            e,
            type,
            content: content || '',
          };
          return;
        }

        // Reverting local workbook data back to initial state value
        this.localWorkbook = { ...this.workbookBuilder.workbook };

        this.resetViews();

        if (type === 'setup') {
          this.workbookCD = !this.workbookCD;
          return;
        }

        if (type === 'module') {
          this.moduleView = !this.moduleView;
          this.currentModule = {
            ...content,
            workbookId: this.localWorkbook.id,
          };
          this.currentModuleId = content.id;
          return;
        }

        this.sectionView = !this.sectionView;
        this.currentSection = content;
        this.currentSectionId = content.id;
      }
    },

    proceedLeaveView(modalContent) {
      this.RESET_ISDIRTY();
      this.dirtyModal = false;

      // if leaving view / route
      if (this.modalContent.to) {
        // reset state, leaving workbook
        if (!this.modalContent.to.params.workbookId) {
          this.RESET_WORKBOOK();
        }

        this.$router.push(`${this.modalContent.to.fullPath}`);
        return;
      }
      // else changing component within view / route
      this.changeView(modalContent.e, modalContent.type, modalContent.content);
    },

    promptDelete(content, type, index, secondaryIndex) {
      this.modalContent = {
        content,
        type,
        index,
        secondaryIndex,
      };
      this.deleteModal = true;
    },

    async deleteView() {
      const viewInfo = this.modalContent.content;
      // Delete Module
      if (this.modalContent.type === 'module') {
        // Checking to see if view is stored in DB and not local
        if (viewInfo.id.length > 11) {
          ApiService.delete(`${process.env.VUE_APP_API_URL}/workbook/module/${viewInfo.id}`);
        }
        // Remove Module from module navigation
        this.DELETE_MODULE(viewInfo);

        this.resetViews();

        // Navigating user to workbook setup page
        this.workbookCD = true;
        this.$refs.workbookTitle.classList.add('active');
      }

      // Delete Section
      if (this.modalContent.type === 'section') {
        ApiService.delete(`${process.env.VUE_APP_API_URL}/workbook/module/section/${viewInfo.id}`);

        // Remove Module from module navigation
        this.DELETE_SECTION(this.modalContent);

        this.resetViews();

        // Navigating user to workbook setup page
        this.workbookCD = true;
        this.$refs.workbookTitle.classList.add('active');
      }
      this.deleteModal = false;
    },

    closeModule() {
      this.dirtyModal = false;
      this.deleteModal = false;
    },

    // Updates new section with returned DB id.
    updateCurrentModule(id) {
      this.currentModuleId = id;
      this.currentModule.id = id;
    },

    // Updates new section with returned DB id.
    updateCurrentSection(id) {
      this.currentSectionId = id;
      this.currentSection.id = id;
    },

    addModule() {
      this.ADD_MODULE();
    },

    addSection(index, moduleId) {
      this.currentModuleId = moduleId;

      this.ADD_SECTION({
        index,
        moduleId,
      });
    },

    goToRoute(route, param, paramValue) {
      this.$router.push({ name: route, params: { param: paramValue } });
    },

    enablePreviewMode() {
      this.$router.push({
        name: 'workbook view',
        params: { 'workbook-id': this.localWorkbook.id },
        query: { preview: 'true' },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.create-workbook {
  display: flex;
  flex-direction: column;

  .workbook-setup {
    position: relative;
    .workbook {
      display: flex;
      gap: 32px;
      min-height: 100vh;
      margin: 24px 0 75px 0;

      ul {
        list-style: none;
      }

      .workbook-nav {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 300px;
        font-size: 16px;
        background-color: #f2f2f2;
        border: 1px solid #c9cacb;

        li {
          display: flex;
          padding: 14px 8px 14px 28px;
          cursor: pointer;
          background-color: #fff;
          border-top: 1px solid #c9cacb;

          &:last-child {
            border-bottom: 1px solid #c9cacb;
          }
        }

        .nav-item {
          &:hover {
            .cancel-icon {
              opacity: 1;
            }
          }
        }

        .cancel-icon {
          align-self: flex-end;
          margin-right: 0;
          margin-left: auto;
          opacity: 0;
          transition: 150ms ease-in-out;
          path {
            pointer-events: none;
            fill: #000;
          }
        }

        .module {
          flex-direction: column;
          padding: 0;
          background-color: #f2f2f2;

          .module-details {
            display: flex;
            align-items: center;
            padding: 14px 7px 14px 28px;
            background-color: #fff;
            border-bottom: 1px solid #c9cacb;

            .module-icons {
              display: flex;
              align-items: center;
              margin-left: auto;
            }
            .key-icon {
              width: 24px;
              height: 12px;
              margin-right: 16px;
              margin-left: auto;
              path {
                fill: #000;
              }
            }
          }

          .active {
            .cancel-icon {
              opacity: 1;
              path {
                fill: white;
              }
            }

            .key-icon {
              path {
                fill: white;
              }
            }
          }
        }

        .active {
          color: #fff;
          background-color: #323639 !important;
          border: 1px solid #c9cacb;
        }

        .title {
          font-weight: 500;
          border: none;
        }

        .add {
          background-color: #f9f9f9;
        }

        .add-icon {
          width: auto;
          height: 17px;
          margin-right: 7px;
        }

        .workbook-section {
          margin-left: 45px;

          li {
            align-items: center;
            border-left: 1px solid #c9cacb;

            &:first-child {
              border-top: 0;
            }

            &:last-child {
              border-bottom: 0;
            }
          }
        }
      }

      .workbook-content-wrap {
        position: relative;
        width: 100%;
        min-height: 100vh;

        .view-summary {
          margin-bottom: 24px;
        }

        .create-workbook-cd {
          display: flex;
          gap: 32px;
          min-height: 100vh;

          .left {
            display: flex;
            flex-direction: column;
            max-width: 250px;

            img {
              width: 100%;
              height: 260px;
              object-fit: cover;
            }

            .default-img {
              border: 1px solid $grey;
            }

            .porsche-button {
              align-self: center;
              margin-top: 16px;
            }

            input[type='file'] {
              display: none;
            }

            .img-req {
              display: flex;
              justify-content: center;
              margin-top: 6px;
              font-size: 12px;
              color: #4a4a4a;
            }
          }

          .right {
            flex: 1;
            max-width: 550px;

            .sessions {
              display: flex;
              flex-direction: column;
              row-gap: 30px;

              .session {
                display: flex;
                flex-direction: column;
                row-gap: 10px;
                &-field {
                  color: $grey-light;
                  .label {
                    color: #000;
                  }
                }

                &-date {
                  display: flex;
                  column-gap: 20px;
                }
              }
            }

            .mutltiple-inputs {
              h4 {
                margin-top: 16px;
                margin-bottom: 10px;
                font-size: 16px;
              }

              .additional-info {
                display: block;
                margin-top: 12px;
                font-size: 12px;
                line-height: 18px;
              }

              .checkbox {
                span {
                  display: inline;
                  margin: 0 0 0 16px !important;
                  font-size: 16px !important;
                }
                input[type='checkbox'] {
                  position: relative;
                  width: 53px;
                  height: 34px;
                  background: #d5001c;
                  border: none;
                  border-radius: 16px;
                  outline: none;
                  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
                  transition: 0.5s ease all;
                  -webkit-appearance: none;
                }

                input[type='checkbox']::before {
                  position: absolute;
                  top: 3px;
                  left: 3px;
                  width: 28px;
                  height: 28px;
                  content: '';
                  background: #fff;
                  border-radius: 20px;
                  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
                  transition: 0.5s ease all;
                  transform: scale(1.1);
                }

                input:checked[type='checkbox']::before {
                  left: 22px;
                  background: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
