<template>
  <div class="workbook-wrap">
    <div class="workbook flex" :class="{ 'mobile-workbook': isTablet, 'flex-column': isTablet }">
      <div class="image">
        <img
          v-if="workbook.coverImageUrl && workbook.coverImageUrl !== ''"
          :src="workbook.coverImageUrl"
          alt=""
          @click="goToRoute(canEdit ? 'workbook setup' : 'workbook view', workbook.id)"
        />
        <img
          class="default-img"
          v-else
          src="@/assets/images/Workbook/workbook_thumb@2x.png"
          alt=""
          @click="goToRoute(canEdit ? 'workbook setup' : 'workbook view', workbook.id)"
        />
        <FavoriteToggle
          v-if="participant"
          :favorite="workbook.favorite"
          type="absolute"
          @favoriteItem="favoriteWorkbook"
        />
      </div>
      <div class="content flex flex-column">
        <h3 @click="goToRoute(canEdit ? 'workbook setup' : 'workbook view', workbook.id)">{{ workbook.title }}</h3>
        <div class="tags flex">
          <span class="tag">{{ workbook['workbookSession.eventId'] }}</span>
        </div>
        <p v-if="!isTablet" v-html="workbook.description"></p>
        <p class="mobile-des" v-else v-html="workbook.description"></p>
        <div class="options flex" :class="{ 'flex-column': isTablet }">
          <div class="tags flex">
            <span v-if="workbook.vehicleModel" class="tag">{{ workbook.vehicleModel }}</span>
            <span v-if="workbook.vehicleYear" class="tag">{{ workbook.vehicleYear }}</span>
            <span v-if="workbook.category !== ''" class="tag">{{ workbook.category }}</span>
          </div>

          <!-- Participant & Instructor Options  -->
          <div v-if="participant || instructor" class="edit-options">
            <span @click="goToRoute('workbook view', workbook.id)" class="option">
              <WorkbookIcon class="icon" />
              <ToolTip :right="true">View</ToolTip>
            </span>
          </div>

          <!-- Admin & CD Options  -->
          <div v-if="admin || designer" class="edit-options">
            <span @click="duplicateWorkbook(workbook.id)" class="option">
              <DuplicateIcon class="icon" />
              <ToolTip :right="true">Duplicate</ToolTip>
            </span>
            <span @click="goToRoute('workbook setup', workbook.id)" class="option">
              <EditIcon class="icon" />
              <ToolTip :right="true">
                <span>Edit</span>
              </ToolTip>
            </span>
            <span @click="goToRoute('users', workbook.id)" class="option">
              <UsersIcon class="icon" />
              <ToolTip :right="true">
                <span>Users In Workbook</span>
              </ToolTip>
            </span>
            <span @click="goToRoute('quiz results', workbook.id)" class="option">
              <DataIcon class="icon" />
              <ToolTip :right="true">
                <span>Quiz Results</span>
              </ToolTip>
            </span>
            <span @click="goToRoute('survey results', workbook.id)" class="option">
              <SurveyIcon class="icon" />
              <ToolTip :right="true">
                <span>Survey Results</span>
              </ToolTip>
            </span>
            <span
              @click="removeWorkbook(workbook)"
              class="option"
              :class="{ inactive: workbook.isRequired }"
              v-if="admin"
            >
              <DeleteIcon class="icon" />
              <ToolTip v-if="workbook.isRequired" :right="true">
                <span>This is a required workbook and cannot be deleted.</span>
              </ToolTip>
              <ToolTip v-else :right="true">
                <span>Delete</span>
              </ToolTip>
            </span>
          </div>
        </div>
      </div>
    </div>
    <hr :class="{ 'mobile-hr': isTablet }" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import FavoriteToggle from '@/components/FavoriteToggle.vue';
import ToolTip from '@/components/ToolTip.vue';
import DataIcon from '@/assets/images/Icons/workbook_data.svg';
import SurveyIcon from '@/assets/images/Icons/workbook_survey.svg';
import UsersIcon from '@/assets/images/Icons/workbook_users.svg';
import EditIcon from '@/assets/images/Icons/workbook_edit.svg';
import DuplicateIcon from '@/assets/images/Icons/workbook_duplicate.svg';
import DeleteIcon from '@/assets/images/Icons/delete.svg';
import WorkbookIcon from '@/assets/images/Icons/workbook.svg';

export default {
  name: 'workbook',
  props: ['index', 'workbook'],
  components: {
    DataIcon,
    SurveyIcon,
    UsersIcon,
    EditIcon,
    DuplicateIcon,
    DeleteIcon,
    WorkbookIcon,
    FavoriteToggle,
    ToolTip,
  },
  created() {},
  computed: {
    ...mapState('userInformation', ['admin', 'designer', 'instructor', 'participant']),

    isTablet() {
      return this.$mq === 'tablet' || this.$mq === 'mobile';
    },

    canEdit() {
      return this.admin || this.designer;
    },
  },
  methods: {
    // emit out to parent component
    favoriteWorkbook() {
      this.$emit('favoriteWorkbook', this.index);
    },

    goToRoute(routeName, id) {
      this.$router.push({ name: `${routeName}`, params: { workbookId: id } });
    },

    removeWorkbook(workbook) {
      if (!this.workbook.isRequired) {
        this.$emit('delete-workbook', workbook);
      }
    },

    duplicateWorkbook(workbookId) {
      this.$emit('duplicate-workbook', workbookId);
    },
  },
};
</script>

<style lang="scss" scoped>
.workbook-wrap {
  .workbook {
    gap: 16px;

    .image {
      position: relative;
      width: 150px;
      height: 150px;
      cursor: pointer;

      .default-img {
        border: 1px solid $grey;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      // override component styling
      .favorite-toggle {
        top: 5px;
        right: 5px;
      }
    }

    .content {
      flex: 1;
      h3 {
        margin-bottom: 4px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
      }

      .tags {
        gap: 6px;

        .tag {
          padding: 4px 8px;
          font-size: 12px;
          font-weight: 400;
          background-color: #e3e4e5;
          border-radius: 2px;
        }
      }

      p {
        margin: 16px 0 12px 0;
      }

      .options {
        align-items: center;
        margin-top: auto;

        .edit-options {
          display: flex;
          flex: 1;
          gap: 12px;
          justify-content: flex-end;

          span,
          .option {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .tool-tip {
              top: 30px;
              right: -13px;
              padding: 8px 12px;
            }

            &:hover {
              .tool-tip {
                opacity: 1;
              }
            }
          }

          .inactive {
            .icon {
              path {
                fill: $grey-light;
              }
            }
          }

          .icon {
            width: auto;
            height: 18px;
          }
        }
      }
    }
  }

  .mobile-workbook {
    .content {
      p {
        font-size: 12px;
      }

      .options {
        flex: 1;
        align-items: flex-start;
        .tags {
          margin-bottom: 16px;
        }
      }
    }
  }

  hr {
    margin: 30px 0;
    background-color: #c9cacb;
  }

  .mobile-hr {
    margin: 20px 0;
  }
}
</style>
