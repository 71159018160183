<template>
  <div v-if="dataAvailable" class="wb-view" :class="{ 'wb-view-mobile': isLaptop }">
    <Modal
      modalType="delete"
      v-if="deleteNotePrompt"
      :modalContent="deleteNoteModalContent"
      @delete-view="deleteNote"
      @close-modal="toggleDeleteNoteModal"
    />
    <div class="wb-view-section" ref="wbViewSection">
      <h1>{{ sectionInfo.title }}</h1>
      <div class="description">
        <p class="label">Description:</p>
        <p class="value" v-html="sectionInfo.description"></p>
      </div>
      <div class="description" v-if="userInformation.instructor || isWorkbookPreview">
        <p class="label">Instructor Description:</p>
        <p class="value" v-html="sectionInfo.instructorDescription"></p>
      </div>
      <hr />
      <div class="wb-assets">
        <div v-for="asset in sectionAssets" :key="asset.id" class="wb-asset-wrap">
          <!-- PDF -->
          <div
            v-if="asset.assetTypeId === sectionAssetTypes['PDF'].id && asset.active === true"
            class="wb-asset"
            :class="{ 'wb-asset-column': isLaptop }"
          >
            <template v-if="isLaptop">
              <div class="wb-asset-heading">
                <img src="@/assets/images/AssetImgs/content_pdf@2x.png" class="static-img" alt="" />
                <div class="title">
                  <h2>{{ asset.title }}</h2>
                  <span class="tag">PDF </span>
                </div>
              </div>
              <div class="wb-asset-info">
                <!-- Instructor -->
                <div v-if="userInformation.instructor || isWorkbookPreview" class="notes">
                  <div class="note">
                    <p class="label">Instructor Guide Notes:</p>
                    <p v-html="asset.note"></p>
                  </div>
                  <div class="description">
                    <p class="label">Participant Guide Description:</p>
                    <p v-html="asset.description"></p>
                  </div>
                </div>
                <!-- Participant -->
                <div v-if="userInformation.participant" class="notes">
                  <p v-html="asset.description"></p>
                </div>
                <div class="actions">
                  <div v-if="!asset.display" @click="togglePdf(asset)">
                    <ViewOn class="icon dl-icon" />
                    <span> View PDF </span>
                  </div>
                  <div v-if="asset.display" @click="togglePdf(asset)">
                    <ViewOff class="icon dl-icon" />
                    <span> Close PDF </span>
                  </div>
                  <div class="action" @click="toClipboard(asset.displayLink ? asset.displayLink : asset.url, asset)">
                    <LinkIcon class="icon link-icon" />
                    <span>Copy link to share</span>
                    <ToolTip v-if="asset.linkCopied" style="top: 30px; left: -10px; opacity: 1">
                      <span>Link Copied!</span>
                    </ToolTip>
                  </div>
                </div>
              </div>
              <PdfModal
                v-if="asset.display"
                ref="pdfModal"
                :url="asset.displayLink"
                :download="asset.displayLink"
                :fileName="asset.fileName"
                style="width: 100%"
              />
            </template>

            <template v-else>
              <img src="@/assets/images/AssetImgs/content_pdf@2x.png" class="static-img" alt="" />
              <div class="wb-asset-info">
                <h2>{{ asset.title }}</h2>
                <span class="tag">PDF </span>
                <!-- Instructor -->
                <div v-if="userInformation.instructor || isWorkbookPreview" class="notes">
                  <div class="note">
                    <p class="label">Instructor Guide Notes:</p>
                    <p v-html="asset.note"></p>
                  </div>
                  <div class="description">
                    <p class="label">Participant Guide Description:</p>
                    <p v-html="asset.description"></p>
                  </div>
                </div>
                <!-- Participant -->
                <div v-if="userInformation.participant" class="notes">
                  <p v-html="asset.description"></p>
                </div>
                <div class="actions">
                  <div class="action" v-if="!asset.display" @click="togglePdf(asset)">
                    <ViewOn class="icon dl-icon" />
                    <span> View PDF </span>
                  </div>
                  <div class="action" v-if="asset.display" @click="togglePdf(asset)">
                    <ViewOff class="icon dl-icon" />
                    <span> Close PDF </span>
                  </div>
                  <div class="action" @click="toClipboard(asset.displayLink ? asset.displayLink : asset.url, asset)">
                    <LinkIcon class="icon link-icon" />
                    <span>Copy link to share</span>
                    <ToolTip v-if="asset.linkCopied" style="top: 30px; left: -10px; opacity: 1">
                      <span>Link Copied!</span>
                    </ToolTip>
                  </div>
                </div>
              </div>
            </template>
          </div>

          <PdfModal
            v-if="asset.display && !isLaptop"
            :url="asset.displayLink"
            :download="asset.displayLink"
            :fileName="asset.fileName"
            style="width: 100%"
          />

          <!-- Image -->
          <div
            v-if="asset.assetTypeId === sectionAssetTypes['Image'].id && asset.active === true"
            class="wb-asset wb-asset-column"
          >
            <img :src="asset.displayLink" alt="" class="asset-image" />
            <div class="wb-asset-info">
              <h2>{{ asset.title }}</h2>
              <span class="tag">Image</span>
              <!-- Instructor -->
              <div v-if="userInformation.instructor || isWorkbookPreview" class="notes">
                <div class="note">
                  <p class="label">Instructor Guide Notes:</p>
                  <p v-html="asset.note"></p>
                </div>
                <div class="description">
                  <p class="label">Participant Guide Description:</p>
                  <p v-html="asset.description"></p>
                </div>
              </div>
              <!-- Participant -->
              <div v-if="userInformation.participant" class="notes">
                <p v-html="asset.description"></p>
              </div>
              <div class="actions">
                <div class="action" @click="toClipboard(asset.displayLink ? asset.displayLink : asset.url, asset)">
                  <LinkIcon class="icon link-icon" />
                  <span>Copy link to share</span>
                  <ToolTip v-if="asset.linkCopied" style="top: 30px; left: -10px; opacity: 1">
                    <span>Link Copied!</span>
                  </ToolTip>
                </div>
              </div>
            </div>
          </div>

          <!-- Video -->
          <div
            v-if="asset.assetTypeId === sectionAssetTypes['Video'].id && asset.active === true"
            class="wb-asset wb-asset-column"
          >
            <video :src="asset.displayLink" alt="" class="asset-video" controls />
            <div class="wb-asset-info">
              <h2>{{ asset.title }}</h2>
              <span class="tag">Video</span>
              <!-- Instructor -->
              <div v-if="userInformation.instructor || isWorkbookPreview" class="notes">
                <div class="note">
                  <p class="label">Instructor Guide Notes:</p>
                  <p v-html="asset.note"></p>
                </div>
                <div class="description">
                  <p class="label">Participant Guide Description:</p>
                  <p v-html="asset.description"></p>
                </div>
              </div>
              <!-- Participant -->
              <div v-if="userInformation.participant" class="notes">
                <p v-html="asset.description"></p>
              </div>
              <div class="actions">
                <div class="action" @click="toClipboard(asset.displayLink ? asset.displayLink : asset.url, asset)">
                  <LinkIcon class="icon link-icon" />
                  <span>Copy link to share</span>
                  <ToolTip v-if="asset.linkCopied" style="top: 30px; left: -10px; opacity: 1">
                    <span>Link Copied!</span>
                  </ToolTip>
                </div>
              </div>
            </div>
          </div>

          <!-- Slides -->
          <div
            v-if="asset.assetTypeId === sectionAssetTypes['Slides'].id && asset.active === true"
            class="wb-asset"
            :class="{ 'wb-asset-column': isLaptop, 'wb-asset-row': !isLaptop }"
          >
            <template v-if="isLaptop">
              <div class="wb-asset-heading">
                <img src="@/assets/images/AssetImgs/content_slideshow@2x.png" class="static-img" alt="" />
                <div class="title">
                  <h2>{{ asset.title }}</h2>
                  <span class="tag">Slideshow</span>
                </div>
              </div>
              <div class="wb-asset-info">
                <!-- Instructor -->
                <div v-if="userInformation.instructor || isWorkbookPreview" class="notes">
                  <div class="note">
                    <p class="label">Instructor Guide Notes:</p>
                    <p v-html="asset.note"></p>
                  </div>
                  <div class="description">
                    <p class="label">Participant Guide Description:</p>
                    <p v-html="asset.description"></p>
                  </div>
                </div>
                <!-- Participant -->
                <div v-if="userInformation.participant" class="notes">
                  <p v-html="asset.description"></p>
                </div>
                <div class="actions">
                  <a :href="asset.displayLink ? asset.displayLink : asset.url" target="_blank" class="action">
                    <ExternalIcon class="icon external-icon" />
                    <span>Launch (opens in a new tab)</span>
                  </a>
                  <div class="action" @click="toClipboard(asset.displayLink ? asset.displayLink : asset.url, asset)">
                    <LinkIcon class="icon link-icon" />
                    <span>Copy link to share</span>
                    <ToolTip v-if="asset.linkCopied" style="top: 30px; left: -10px; opacity: 1">
                      <span>Link Copied!</span>
                    </ToolTip>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <img src="@/assets/images/AssetImgs/content_slideshow@2x.png" class="static-img" alt="" />
              <div class="wb-asset-info">
                <h2>{{ asset.title }}</h2>
                <span class="tag">Slideshow</span>
                <!-- Instructor -->
                <div v-if="userInformation.instructor || isWorkbookPreview" class="notes">
                  <div class="note">
                    <p class="label">Instructor Guide Notes:</p>
                    <p v-html="asset.note"></p>
                  </div>
                  <div class="description">
                    <p class="label">Participant Guide Description:</p>
                    <p v-html="asset.description"></p>
                  </div>
                </div>
                <!-- Participant -->
                <div v-if="userInformation.participant" class="notes">
                  <p v-html="asset.description"></p>
                </div>
                <div class="actions">
                  <a :href="asset.displayLink ? asset.displayLink : asset.url" target="_blank" class="action">
                    <ExternalIcon class="icon external-icon" />
                    <span>Launch (opens in a new tab)</span>
                  </a>
                  <div class="action" @click="toClipboard(asset.displayLink ? asset.displayLink : asset.url, asset)">
                    <LinkIcon class="icon link-icon" />
                    <span>Copy link to share</span>
                    <ToolTip v-if="asset.linkCopied" style="top: 30px; left: -10px; opacity: 1">
                      <span>Link Copied!</span>
                    </ToolTip>
                  </div>
                </div>
              </div>
            </template>
          </div>

          <!-- Links -->
          <div
            v-if="asset.assetTypeId === sectionAssetTypes['Links'].id && asset.active === true"
            class="wb-asset"
            :class="{ 'wb-asset-column': isLaptop, 'wb-asset-row': !isLaptop }"
          >
            <template v-if="isLaptop">
              <div class="wb-asset-heading">
                <img src="@/assets/images/AssetImgs/content_link@2x.png" class="static-img" alt="" />
                <div class="title">
                  <h2>{{ asset.title }}</h2>
                  <span class="tag">Link</span>
                </div>
              </div>
              <div class="wb-asset-info">
                <!-- Instructor -->
                <div v-if="userInformation.instructor || isWorkbookPreview" class="notes">
                  <div class="note">
                    <p class="label">Instructor Guide Notes:</p>
                    <p v-html="asset.note"></p>
                  </div>
                  <div class="description">
                    <p class="label">Participant Guide Description:</p>
                    <p v-html="asset.description"></p>
                  </div>
                </div>
                <!-- Participant -->
                <div v-if="userInformation.participant" class="notes">
                  <p v-html="asset.description"></p>
                </div>
                <div class="actions">
                  <a :href="asset.displayLink ? asset.displayLink : asset.url" target="_blank" class="action">
                    <ExternalIcon class="icon external-icon" />
                    <span>Launch (opens in a new tab)</span>
                  </a>
                  <div class="action" @click="toClipboard(asset.displayLink ? asset.displayLink : asset.url, asset)">
                    <LinkIcon class="icon link-icon" />
                    <span>Copy link to share</span>
                    <ToolTip v-if="asset.linkCopied" style="top: 30px; left: -10px; opacity: 1">
                      <span>Link Copied!</span>
                    </ToolTip>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <img src="@/assets/images/AssetImgs/content_link@2x.png" class="static-img" alt="" />
              <div class="wb-asset-info">
                <h2>{{ asset.title }}</h2>
                <span class="tag">Link</span>
                <!-- Instructor -->
                <div v-if="userInformation.instructor || isWorkbookPreview" class="notes">
                  <div class="note">
                    <p class="label">Instructor Guide Notes:</p>
                    <p v-html="asset.note"></p>
                  </div>
                  <div class="description">
                    <p class="label">Participant Guide Description:</p>
                    <p v-html="asset.description"></p>
                  </div>
                </div>
                <!-- Participant -->
                <div v-if="userInformation.participant" class="notes">
                  <p v-html="asset.description"></p>
                </div>
                <div class="actions">
                  <a :href="asset.displayLink ? asset.displayLink : asset.url" target="_blank" class="action">
                    <ExternalIcon class="icon external-icon" />
                    <span>Launch (opens in a new tab)</span>
                  </a>
                  <div class="action" @click="toClipboard(asset.displayLink ? asset.displayLink : asset.url, asset)">
                    <LinkIcon class="icon link-icon" />
                    <span>Copy link to share</span>
                    <ToolTip v-if="asset.linkCopied" style="top: 30px; left: -10px; opacity: 1">
                      <span>Link Copied!</span>
                    </ToolTip>
                  </div>
                </div>
              </div>
            </template>
          </div>

          <!-- Text -->
          <div
            v-if="asset.assetTypeId === sectionAssetTypes['Text'].id && asset.active === true"
            class="wb-asset wb-asset-column"
          >
            <div class="wb-asset-info">
              <h2>{{ asset.title }}</h2>
              <!-- Instructor -->
              <div v-if="userInformation.instructor || isWorkbookPreview" class="notes">
                <div class="note">
                  <p class="label">Instructor Guide Notes:</p>
                  <p v-html="asset.note"></p>
                </div>
                <div class="description">
                  <p class="label">Participant Guide Description:</p>
                  <p v-html="asset.description"></p>
                </div>
              </div>
              <!-- Participant -->
              <div v-if="userInformation.participant" class="notes">
                <p v-html="asset.description"></p>
              </div>
            </div>
          </div>

          <!-- Hotspot -->
          <div
            v-if="asset.assetTypeId === sectionAssetTypes['Hotspot'].id && asset.active === true"
            class="wb-asset wb-asset-column hotspot"
          >
            <BaseHotspot :hotspotImage="asset.displayLink" :pins="asset.assetPin" />

            <div class="wb-asset-info">
              <h2>{{ asset.title }}</h2>
              <!-- Instructor -->
              <div v-if="userInformation.instructor || isWorkbookPreview" class="notes">
                <div class="note">
                  <p class="label">Instructor Guide Notes:</p>
                  <p v-html="asset.note"></p>
                </div>
                <div class="description">
                  <p class="label">Participant Guide Description:</p>
                  <p v-html="asset.description"></p>
                </div>
              </div>
              <!-- Participant -->
              <div v-if="userInformation.participant" class="notes">
                <p v-html="asset.description"></p>
              </div>
              <div class="actions">
                <div @click="toClipboard(asset.displayLink ? asset.displayLink : asset.url)" class="action">
                  <LinkIcon class="icon link-icon" />
                  <span>Copy link to share</span>
                </div>
              </div>
            </div>
          </div>

          <hr v-if="asset.active" />
        </div>
      </div>

      <PorscheButton v-if="!isLastModule || !isLastSection" :light="true" @click="nextSection">
        <Arrow class="arrow-icon icon" />
        <span v-if="isLastSection">Next Module</span>
        <span v-else>Next Section</span>
      </PorscheButton>
    </div>
    <div class="wb-view-notes" :class="{ 'wb-view-notes-active': workbookNotes }">
      <div class="notes-heading">
        <div @click="toggleWorkbookNotes" class="toggle">
          <Arrow class="arrow-icon" />
        </div>
        <h2 v-if="workbookNotes">My Notes</h2>
      </div>
      <div v-if="workbookNotes && !notesDisabled" class="notes-wrapper">
        <div
          class="note"
          :class="{ 'note-active': note.noteActive }"
          :data-id="note.id"
          v-for="(note, index) in notes"
          :key="note.id"
        >
          <div class="note-heading">
            <div v-if="note.noteActive">
              <small>{{ note.snippet }}</small>
              <p v-if="!note.editActive">{{ note.body }}</p>
            </div>
            <div v-else>
              <small>{{ note.snippet.slice(0, 50) }}<span v-if="note.snippet.length >= 70">...</span></small>
              <p>{{ note.body.slice(0, 70) }}<span v-if="note.body.length >= 70">...</span></p>
            </div>
            <Arrow
              class="chevron-icon"
              :class="{ 'chevron-icon-active': note.noteActive }"
              @click="toggleNote(index)"
            />
          </div>
          <template v-if="note.noteActive">
            <textarea v-if="note.editActive" v-model="note.body"></textarea>
            <div class="note-timestamp" v-if="note.updatedAt">
              <span>
                {{ new Date(note.updatedAt).toLocaleTimeString('en-us', { hour: 'numeric', minute: 'numeric' }) }}
              </span>
              <span> | </span>
              <span>
                {{ new Date(note.updatedAt).toLocaleDateString() }}
              </span>
            </div>
            <div class="note-actions" :class="{ 'note-edit': note.editActive }">
              <template v-if="note.editActive">
                <div class="save">
                  <PorscheButton
                    :dark="true"
                    @click="note.createdAt ? updateNote(index, note) : createNote(index, note)"
                  >
                    <Arrow class="arrow-icon icon" />
                    <span>Save</span>
                  </PorscheButton>
                </div>
                <div class="cancel">
                  <PorscheButton :light="true" @click="toggleEditNote(note)">
                    <CancelIcon class="cancel-icon icon" />
                    <span>Cancel</span>
                  </PorscheButton>
                </div>
              </template>
              <template v-else>
                <div @click="toggleEditNote(note, index)" class="edit">
                  <EditIcon class="edit-icon" />
                  <span>Edit</span>
                </div>
                <div @click="toggleDeleteNoteModal(note)" class="delete">
                  <DeleteIcon class="delete-icon" />
                  <span>Delete</span>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
    <ToolTip
      v-click-outside="selectedText"
      v-if="selectedTextPosition"
      :style="{ top: `${selectedTextPosition.top}px`, left: `${selectedTextPosition.left}px` }"
      class="wb-view-notes-creator"
    >
      <div class="creator">
        <div class="add" @click="addNote">
          <AddIcon class="add-icon" />
          <span>Add Note</span>
        </div>
        <CancelIcon class="cancel-icon" @click="closeNoteCreator" />
      </div>
    </ToolTip>
  </div>
  <Loading :type="'section'" v-else />
</template>

<script>
import ApiService from '@/utilities/ApiService';
import Loading from '@/components/Loading.vue';
import { mapState, mapActions } from 'vuex';
import ViewOff from '@/assets/images/Icons/view-off.svg';
import ViewOn from '@/assets/images/Icons/view-on.svg';
import LinkIcon from '@/assets/images/Icons/link.svg';
import ExternalIcon from '@/assets/images/Icons/external.svg';
import PorscheButton from '@/components/PorscheButton.vue';
import Arrow from '@/assets/images/Icons/arrow_right_white.svg';
import EditIcon from '@/assets/images/Icons/edit.svg';
import DeleteIcon from '@/assets/images/Icons/delete.svg';
import CancelIcon from '@/assets/images/Icons/cancel.svg';
import ToolTip from '@/components/ToolTip.vue';
import AddIcon from '@/assets/images/Icons/add.svg';
import vClickOutside from 'v-click-outside';
import Modal from '@/components/Modal.vue';
import PdfModal from '@/components/PdfModal.vue';
import BaseHotspot from '@/components/BaseHotspot.vue';
import { uid } from 'uid';

export default {
  name: 'view-section',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    LinkIcon,
    Loading,
    ExternalIcon,
    Arrow,
    EditIcon,
    DeleteIcon,
    CancelIcon,
    PorscheButton,
    ToolTip,
    AddIcon,
    Modal,
    PdfModal,
    ViewOff,
    ViewOn,
    BaseHotspot,
  },
  props: ['currentSection', 'index', 'secondaryIndex'],
  data() {
    return {
      dataAvailable: null,
      sectionInfo: null,
      sectionAssets: null,
      sectionAssetTypes: null,
      hotspotPinTypes: null,
      notes: null,
      copyClipboard: null,
      workbookNotes: null,
      selectedTextContent: null,
      selectedTextPosition: null,
      deleteNotePrompt: null,
      deleteNoteModalContent: null,
      isWorkbookPreview: this.$route.query.preview === 'true',
    };
  },
  beforeDestroy() {
    document.removeEventListener('selectionchange', this.notesEventListeners());
  },
  created() {},
  async mounted() {
    window.scrollTo(0, 0);
    await this.getSection();
    document.addEventListener('selectionchange', this.notesEventListeners());
  },
  computed: {
    ...mapState({
      userInformation: (state) => state.userInformation,
      workbookViewer: (state) => state.workbookViewer,
      workbookBuilder: (state) => state.workbookBuilder,
    }),

    isLaptop() {
      return this.$mq === 'tablet' || this.$mq === 'mobile' || this.$mq === 'tabletLarge';
    },

    isTablet() {
      return this.$mq === 'tablet' || this.$mq === 'mobile';
    },

    notesDisabled() {
      return this.isWorkbookPreview || this.userInformation.admin || this.userInformation.designer;
    },

    isLastSection() {
      return this.workbookViewer.moduleNavigation[this.index].sections.length === this.secondaryIndex + 1;
    },

    isLastModule() {
      return this.workbookViewer.moduleNavigation.length === this.index + 1;
    },
  },
  watch: {},
  methods: {
    ...mapActions('workbookViewer', ['GET_SECTION']),

    notesEventListeners() {
      this.$refs.wbViewSection.addEventListener('mouseup', () => {
        this.selectedText();
      });
      this.$refs.wbViewSection.addEventListener('touchend', () => {
        this.selectedText();
      });
    },

    async getSection() {
      try {
        await this.GET_SECTION({ sectionId: this.currentSection.id, userId: this.userInformation.userId });
        this.sectionInfo = this.workbookViewer.sectionInfo.section.section;
        this.sectionAssets = this.workbookViewer.sectionInfo.assets;
        this.sectionAssetTypes = this.workbookViewer.sectionInfo.assetTypes;
        this.hotspotPinTypes = this.workbookViewer.sectionInfo.hotspotTypes;
        this.notes = this.workbookViewer.sectionInfo.notes;
        this.dataAvailable = true;
      } catch (error) {
        console.warn(error);
      }
    },

    togglePdf(asset) {
      this.$set(asset, 'display', !asset.display);
    },

    toClipboard(value, asset) {
      this.$set(asset, 'linkCopied', true);
      setTimeout(() => {
        this.$set(asset, 'linkCopied', false);
      }, 3000);
      navigator.clipboard.writeText(value);
    },

    nextSection() {
      this.$emit('nextSection', 'section', this.index, this.secondaryIndex);
    },

    selectedText() {
      if (!this.notesDisabled) {
        // check if the selected element is "P", "H1" or "H2"
        this.selectedTextContent = window.getSelection().toString();
        const selectedTextType = window.getSelection().anchorNode.parentNode.nodeName;
        const acceptedElements = ['P', 'H1', 'H2', 'LI'];
        if (this.selectedTextContent.length > 0 && acceptedElements.includes(selectedTextType)) {
          const selection = window.getSelection().anchorNode.parentNode;
          this.selectedTextPosition = {
            top: selection.offsetTop + selection.offsetHeight + 10,
            left: selection.offsetLeft,
          };
          return;
        }
        this.selectedTextPosition = null;
      }
    },

    addNote() {
      const id = uid();
      this.notes.push({
        body: '',
        editActive: true,
        noteActive: true,
        id,
        moduleSectionId: this.currentSection.id,
        snippet: this.selectedTextContent,
        userId: this.workbookViewer.userId,
      });
      this.selectedTextPosition = null;
      this.selectedTextContent = null;
      this.workbookNotes = true;
      // scroll to open note
      this.$nextTick(() => {
        const note = document.querySelector(`[data-id='${id}']`);
        window.scrollTo(0, note.offsetTop);
      });
    },

    closeNoteCreator() {
      this.selectedTextPosition = null;
    },

    toggleWorkbookNotes() {
      this.workbookNotes = !this.workbookNotes;
    },

    toggleNote(index) {
      this.notes[index].noteActive = !this.notes[index].noteActive;
    },

    toggleEditNote(note, index) {
      // only allow on edit note at a time
      this.notes.forEach((localNote) => {
        localNote.editActive = null;
      });

      if (index + 1) {
        this.notes[index].editActive = true;
      }

      if (!note.updatedAt) {
        this.notes = this.notes.filter((localNote) => localNote.id !== note.id);
      }
    },

    async createNote(index, note) {
      try {
        const newNote = await ApiService.post(
          `${process.env.VUE_APP_API_URL}/users/${this.userInformation.userId}/notes`,
          {
            moduleSectionId: note.moduleSectionId,
            snippet: note.snippet,
            body: note.body,
          },
        );
        this.$set(this.notes, index, {
          ...newNote.data.data,
          editActive: null,
          noteActive: true,
          updatedAt: new Date(),
        });
      } catch (error) {
        console.warn(error);
      }
    },

    async updateNote(index, note) {
      try {
        await ApiService.patch(`${process.env.VUE_APP_API_URL}/users/${this.userInformation.userId}/notes/${note.id}`, {
          moduleSectionId: note.moduleSectionId,
          body: note.body,
        });
        this.notes[index].editActive = null;
      } catch (error) {
        console.warn(error);
      }
    },

    toggleDeleteNoteModal(note) {
      // set content for delete modal
      if (note) {
        this.deleteNoteModalContent = {
          content: { ...note, title: 'Note' },
        };
      }

      this.deleteNotePrompt = !this.deleteNotePrompt;
    },

    async deleteNote() {
      try {
        await ApiService.delete(
          `${process.env.VUE_APP_API_URL}/users/${this.userInformation.userId}/notes/${this.deleteNoteModalContent.content.id}`,
        );
        this.notes = this.notes.filter((note) => note.id !== this.deleteNoteModalContent.content.id);
        this.toggleDeleteNoteModal();
      } catch (error) {
        console.warn(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wb-view {
  display: flex;
  flex: 1;
  column-gap: 16px;
  overflow: initial;

  &-section {
    flex: 1;
  }

  &-notes {
    padding: 15px 18px;
    background-color: $white-smoke;

    .notes-heading {
      display: flex;
      column-gap: 16px;
      margin-bottom: 12px;

      .toggle {
        display: flex;
        align-items: center;
        padding: 4px 2px;
        background-color: #000;

        .arrow-icon {
          width: auto;
          height: 24px;
          transition: 300ms ease all;
          transform: rotateZ(180deg);
        }
      }
    }

    .notes-wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 12px;

      .note {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        padding: 12px;
        border: 1px solid $grey-extra-light;

        &-heading {
          display: flex;
          column-gap: 16px;
          align-items: flex-start;

          div {
            display: flex;
            flex: 1;
            flex-direction: column;
          }

          .chevron-icon {
            width: auto;
            height: 22px;
            transition: 300ms ease-in-out all;
            transform: rotateZ(90deg);

            &-active {
              transform: rotateZ(-90deg);
            }

            path:nth-child(2) {
              fill: #000;
            }
          }
        }

        textarea {
          height: 200px;
          padding: 12px 8px;
          font-size: 16px;
          line-height: 1.2;
          resize: none;
          border: 1px solid $grey-extra-light;

          &:focus {
            border-color: $dark-blue;
            outline: none;
          }
        }

        &-timestamp {
          display: flex;
          column-gap: 4px;

          span {
            font-size: 12px;
            font-weight: 500;
          }
        }

        &-actions {
          display: flex;
          column-gap: 16px;

          > div {
            display: flex;
            column-gap: 6px;
            align-items: center;
          }

          .edit,
          .delete {
            cursor: pointer;
          }

          .edit-icon {
            width: 12px;
            height: auto;

            path {
              fill: $primary;
            }
          }

          .delete-icon {
            width: 12px;
            height: auto;
          }
        }

        &-active {
          background-color: $light-blue;
          border-color: $dark-blue;
        }
      }
    }

    &-active {
      width: 360px;

      .notes-heading {
        .toggle {
          .arrow-icon {
            transform: rotateZ(0deg);
          }
        }
      }
    }
  }

  &-notes-creator {
    pointer-events: initial;
    opacity: 1;

    .creator {
      display: flex;
      align-items: center;

      .add,
      .cancel-icon {
        cursor: pointer;
      }

      .add {
        display: flex;
        align-items: center;
        .add-icon {
          z-index: 102;
          width: auto;
          height: 18px;
          margin-right: 12px;
        }
      }

      .cancel-icon {
        margin-left: 30px;
        path {
          fill: #000;
        }
      }
    }
  }
}

.wb-view-mobile {
  .wb-view-section {
    padding-right: 70px;
  }

  .wb-view-notes {
    position: absolute;
    right: -10px;
    height: 100%;

    &-active {
      width: 270px;
    }

    .note {
      &-edit {
        flex-direction: column;
        row-gap: 8px;

        div {
          .porsche-button {
            flex: 1;
          }
        }
      }
    }
  }
}
</style>
