<template>
  <div class="input">
    <label v-if="label" for="assetName">
      {{ label }}
      <span v-if="required" class="required-field">*</span>
    </label>
    <!-- v-model.lazy -->
    <input
      v-if="lazy"
      v-bind="$attrs"
      :class="{ 'invalid-field': hasError }"
      :placeholder="placeholder ? placeholder : label"
      :value="value"
      @focusout="$emit('input', $event.target.value)"
    />
    <!-- v-model -->
    <input
      v-else
      v-bind="$attrs"
      :class="{ 'invalid-field': hasError }"
      :placeholder="placeholder ? placeholder : label"
      :value="value"
      @input="$emit('input', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    label: {
      type: [String, Boolean],
      default: false,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: [String, Boolean],
      default: false,
    },
  },
  data() {
    return {};
  },
  created() {},
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.input {
  margin-bottom: 16px;
  input {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #626669;
  }

  .invalid-field {
    border-color: $primary;
  }
}
</style>
