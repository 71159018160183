import Vue from 'vue';
import VueRouter from 'vue-router';
import PageNotFound from '@/components/PageNotFound.vue';
import AuditTrail from '../views/AuditTrail.vue';
import CreateWorkbook from '../views/CreateWorkbook.vue';
import ViewWorkbook from '../views/ViewWorkbook.vue';
import PalmsImport from '../views/PalmsImport.vue';
import Reports from '../views/Reports.vue';
import Training from '../views/Training.vue';
import AccessCodes from '../views/AccessCodesReports.vue';
import Users from '../views/Users.vue';
import VehicleComparisonTool from '../views/VehicleComparisonTool.vue';
import WorkbookContent from '../views/WorkbookContent.vue';
import WorkbookUsers from '../views/WorkbookUsers.vue';
import WorkbookQuizResults from '../views/WorkbookQuizResults.vue';
import WorkbookSurveyResults from '../views/WorkbookSurveyResults.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/workbooks',
  },
  // {
  //   path: '/dashboard',
  //   name: 'dashboard',
  //   component: Dashboard,
  //   children: [
  //     {
  //       name: 'featured-content',
  //       path: 'featured-content',
  //       component: FeatureContent,
  //       meta: {
  //         requireAdmin: true,
  //       },
  //     },
  //     {
  //       name: 'news',
  //       path: 'news',
  //       component: News,
  //       meta: {
  //         requireCD: true,
  //       },
  //     },
  //     {
  //       name: 'notifications',
  //       path: 'notifications',
  //       component: Notifications,
  //       meta: {
  //         requireCD: true,
  //       },
  //     },
  //     {
  //       name: 'fun-facts',
  //       path: 'fun-facts',
  //       component: FunFacts,
  //       meta: {
  //         requireCD: true,
  //       },
  //     },
  //     {
  //       name: 'admin-vehicle-comparison-tool',
  //       path: 'vehicle-comparison-tool',
  //       component: AdminVehicleComparisonTool,
  //       meta: {
  //         requireCD: true,
  //       },
  //     },
  //   ],
  // },
  {
    path: '/vehicle-comparison-tool',
    name: 'vehicle comparison tool',
    component: VehicleComparisonTool,
    meta: {
      route: 'dashboard',
      nestedRoute: 'Vehicle Comparison Tool',
    },
  },
  {
    path: '/palms-import',
    name: 'palms-import',
    component: PalmsImport,
    meta: {
      requireAdmin: true,
    },
  },
  // {
  //   path: '/favorites',
  //   component: Favorites,
  //   name: 'favorites',
  //   redirect: '/favorites/content-library',
  //   children: [
  //     {
  //       name: 'favorites-content-library',
  //       path: 'content-library',
  //       component: ContentLibrary,
  //     },
  //     {
  //       name: 'favorites-workbooks',
  //       path: 'workbooks',
  //       component: Training,
  //     },
  //   ],
  // },
  {
    path: '/audit-trail',
    name: 'audit-trail',
    component: AuditTrail,
    meta: {
      requireAdmin: true,
    },
  },
  // {
  //   path: '/settings',
  //   name: 'settings',
  //   component: Settings,
  // },
  {
    path: '/reports',
    name: 'reports',
    component: Reports,
    meta: {
      requireCD: false,
      requireAdmin: false,
    },
  },
  {
    path: '/access-code-reports',
    name: 'access-code-reports',
    component: AccessCodes,
    meta: {
      requireAdmin: true,
    },
  },
  {
    path: '/users',
    name: 'admin-users',
    component: Users,
    meta: {
      requireAdmin: true,
    },
  },
  // {
  //   path: '/content-library',
  //   name: 'content library',
  //   component: ContentLibrary,
  // },
  // {
  //   name: 'content library asset',
  //   path: '/content-library/:assetId',
  //   component: ContentLibraryAsset,
  // },
  // {
  //   path: '/quizzes-surveys',
  //   name: 'quizzes-surveys',
  //   component: ContentLibraryQuizzesSurveys,
  // },

  {
    path: '/workbooks',
    name: 'workbooks',
    component: Training,
    children: [
      {
        path: 'workbook',
        component: CreateWorkbook,
        meta: {
          requireCD: true,
        },
        children: [
          {
            name: 'workbook setup',
            path: 'builder/:workbookId',
            component: WorkbookContent,
            meta: {
              requireCD: true,
            },
          },
          {
            path: 'users/:workbookId',
            name: 'users',
            component: WorkbookUsers,
            meta: {
              requireCD: true,
            },
          },
          {
            path: 'quiz-results/:workbookId',
            name: 'quiz results',
            component: WorkbookQuizResults,
            meta: {
              requireCD: true,
            },
          },
          {
            path: 'survey-results/:workbookId',
            name: 'survey results',
            component: WorkbookSurveyResults,
            meta: {
              requireCD: true,
            },
          },
        ],
      },
      {
        name: 'workbook view',
        path: 'workbook/:workbookId',
        component: ViewWorkbook,
        meta: {
          requireCD: false,
          requireAdmin: false,
        },
      },
    ],
  },
  // Page not found
  {
    path: '*',
    name: 'page not found',
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    // ignore this on workbook builder/viewer
    if (to.matched.length > 0 && from.matched.length > 0) {
      if (to.matched[0].name !== from.matched[0].name || to.matched[0].name === 'workbooks') {
        // Scroll to the top of the page on route navigation
        return { x: 0, y: 0 };
      }
    }
    return false;
  },
  routes,
});

// page title's
router.beforeEach((to, from, next) => {
  document.title = 'Porsche Mobile Academy';
  next();
});

// route guards

export default router;
