<template>
  <div class="quiz-results-wrap">
    <!-- Loading animation for view on initial load -->
    <Loading v-if="!dataAvailable && !initialLoad" :type="'section'" />
    <!-- Popup modal for user reset confirmation -->
    <Modal
      v-if="resetUserModal"
      modalType="popup"
      :modalContent="resetUserModalContent"
      @close-modal="closeUserReset"
    />
    <Modal
      v-if="promptRetakeQuizModal"
      modalType="confirm"
      :modalContent="promptRetakeQuizModalContent"
      @close-modal="promptRetakeQuiz"
      @delete="resetUserQuiz"
    />
    <div v-if="dataAvailable && initialLoad" class="quiz-results">
      <!-- Check for errors on both API calls && to see if we have quizzes to choose from -->
      <div v-if="(!quizzesError || !quizResultError) && quizResultOptions.length > 0">
        <!--Quiz result dropdown  -->
        <div v-if="!quizzesError" class="result-header">
          <div class="result-options">
            <h1>Results for</h1>
            <div class="vue-multiselect">
              <VueMultiselect
                v-model="quizResult"
                label="title"
                track-by="id"
                :searchable="false"
                :close-on-select="true"
                :show-labels="false"
                :allow-empty="false"
                :options="quizResultOptions"
                @input="getQuizResults"
              />
            </div>
            <div class="vue-multiselect session-type">
              <div class="label-wrap">
                <span class="label">Session:</span>
              </div>
              <VueMultiselect
                v-model="session"
                label="fullTitle"
                track-by="fullTitle"
                :multiple="false"
                :searchable="false"
                :close-on-select="true"
                :allow-empty="true"
                :show-labels="false"
                :options="filterMenuOptions.workbookSessions"
                @input="getPaginatedUsers"
              />
            </div>
          </div>
          <PorscheButton @click="exportCSV(`quiz/result/${quizResult.id}/csv`)">
            <Arrow class="arrow-icon icon" />
            <span>Export CSV</span>
          </PorscheButton>
        </div>

        <h2 v-if="quizzesError" class="header-error">Something went wrong, please try again.</h2>

        <div class="quiz-content-wrap">
          <Loading v-if="quizResultsLoading" :type="'section'" />
          <!-- Check for errors on Quiz Result Content API -->
          <div v-if="!quizResultError && !quizResultsLoading" class="quiz-content">
            <div class="results">
              <div class="row">
                <div class="stat">
                  <span class="label">Total Users</span>
                  <span class="data">{{ this.quizTotalUsers }}</span>
                </div>
                <div class="stat">
                  <span class="label">Not Started</span>
                  <span class="data">{{ this.quizNotStarted }}</span>
                </div>
                <div class="stat">
                  <span class="label">Avg. Score</span>
                  <span class="data">{{ Math.round(this.quizAvgScore * 100) }}%</span>
                </div>
                <div class="stat">
                  <span class="label">Lowest Score</span>
                  <span class="data">{{ Math.round(this.quizLowestScore * 100) }}%</span>
                </div>
                <div class="stat">
                  <span class="label">Highest Score</span>
                  <span class="data">{{ Math.round(this.quizHighestScore * 100) }}%</span>
                </div>
                <div class="stat">
                  <span class="label">Total Results</span>
                  <span class="data">{{ this.quizTotalResults }}</span>
                </div>
                <div class="stat">
                  <span class="label">Passed</span>
                  <span class="data">{{ this.quizPassed }}</span>
                </div>
                <div class="stat">
                  <span class="label">Failed</span>
                  <span class="data">{{ this.quizFailed }}</span>
                </div>
                <div class="stat">
                  <span class="label">Completed</span>
                  <span class="data">{{ this.quizTotalResults }}</span>
                </div>
              </div>
            </div>

            <div class="user-results">
              <h1>Results by User</h1>

              <!-- Search -->
              <div class="search">
                <IconSearch class="svg-icon" />
                <input
                  type="text"
                  placeholder="Search users..."
                  v-model="searchQuery"
                  @keypress.enter="getPaginatedUsers"
                />
                <IconCancel v-show="searchQuery" @click="clearSearch" class="svg-icon cancel-btn" />
              </div>

              <div class="filter-options">
                <div class="vue-multiselect display-count">
                  <span class="label">Show:</span>
                  <VueMultiselect
                    v-model="displayCount"
                    :searchable="false"
                    :close-on-select="true"
                    :show-labels="false"
                    :options="displayCountOptions"
                    @input="getPaginatedUsers"
                    :allow-empty="false"
                  />
                </div>

                <div class="filter-wrap">
                  <div @click="toggleFilterMenu" class="filter flex" :class="{ 'filter-menu-active': filterMenu }">
                    <p>Filter</p>
                    <FilterIcon class="filter-icon" />
                  </div>

                  <!-- Advanced Filter Menu -->
                  <div v-if="filterMenu && dataAvailable" class="filter-menu-wrap">
                    <div class="filter-menu">
                      <div class="vue-multiselect">
                        <span class="label">Department:</span>
                        <VueMultiselect
                          v-model="department"
                          :multiple="true"
                          :searchable="false"
                          :close-on-select="true"
                          :allow-empty="true"
                          :show-labels="false"
                          :options="filterMenuOptions.departments"
                        />
                      </div>
                      <div class="vue-multiselect">
                        <span class="label">Passed:</span>
                        <VueMultiselect
                          v-model="passedQuiz"
                          :searchable="false"
                          :close-on-select="true"
                          :allow-empty="true"
                          :show-labels="false"
                          :options="passedQuizOptions"
                        />
                      </div>
                    </div>
                    <div class="filter-actions">
                      <PorscheButton @click="getPaginatedUsers">
                        <Arrow class="arrow-icon icon" />
                        <span>Apply Filters</span>
                      </PorscheButton>
                      <PorscheButton @click="clearFilters" :light="true">
                        <Arrow class="arrow-icon icon" />
                        <span>Clear Filters</span>
                      </PorscheButton>
                    </div>
                  </div>
                </div>
              </div>

              <div class="user-results-data-wrap">
                <div class="user-results-data">
                  <div class="users-table">
                    <div class="labels-wrap">
                      <div class="labels">
                        <span>User ID</span>
                        <span>Last</span>
                        <span>First</span>
                        <span>Session</span>
                        <span>Status</span>
                        <span>Correct</span>
                        <span>Incorrect</span>
                        <span>Score</span>
                        <span>Passed</span>
                        <span>Actions</span>
                      </div>
                      <hr />
                    </div>
                    <div class="users-wrap">
                      <Loading :type="'section'" v-if="paginatedDataLoading" />
                      <!-- Check to see we have users that took the quiz  -->
                      <div v-if="paginatedUsers.length > 0 && !paginatedDataLoading && !quizPaginatedError">
                        <div v-for="(user, index) in paginatedUsers" :key="index">
                          <div class="user">
                            <span>{{ user.username ? user.username : '-' }}</span>
                            <span>{{ user.lastName ? user.lastName : '-' }}</span>
                            <span>{{ user.firstName ? user.firstName : '-' }}</span>
                            <span>{{ user.session ? user.session : '-' }}</span>
                            <span>{{ user.status ? user.status : 'Completed' }}</span>
                            <span>{{ user.correct ? user.correct : '-' }}</span>
                            <span>{{ user.inCorrect ? user.inCorrect : '-' }}</span>
                            <span>{{ user.score * 100 ? Math.round(user.score * 100) : '-' }}%</span>
                            <span>{{ user.passed ? 'Yes' : 'No' }}</span>
                            <div class="actions">
                              <div class="action">
                                <DownloadIcon
                                  @click="exportCSV(`quiz/${quizResult.id}/result/${user.userId}/csv`)"
                                  class="download-icon"
                                />
                                <ToolTip :right="true">Download User Responses</ToolTip>
                              </div>
                              <div class="action">
                                <ResetIcon @click="promptRetakeQuiz(user.userId)" class="reset-icon" />
                                <ToolTip :right="true">Allow Retakes</ToolTip>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </div>
                        <div class="pagination">
                          <Pagination :pageCount="pageCount" @page-change="pageChange" :currentPage="activePage" />
                        </div>
                      </div>
                      <!-- No users to show && no error -->
                      <h2
                        v-if="paginatedUsers.length === 0 && !quizPaginatedError && !paginatedDataLoading"
                        class="header-error"
                      >
                        No users have completed the quiz or no users match your filtered criteria.
                      </h2>
                      <!-- Error getting users from API -->
                      <h2 v-if="quizPaginatedError && !paginatedDataLoading" class="header-error">
                        Sorry, something went wrong, please try again
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Error on getting quiz results -->
          <div v-if="quizResultError && !quizResultsLoading">
            <h2 class="header-error">Something went wrong, please try again.</h2>
          </div>
        </div>
      </div>
      <!-- No quizzes in the workbook && but no errors -->
      <h2 class="header-error" v-if="(!quizzesError || !quizResultError) && quizResultOptions.length === 0">
        There are no quizzes associated with this workbook.
      </h2>
      <!-- Both components have an error after loading -->
      <h2 v-if="quizzesError && quizResultError" class="header-error">
        Sorry, something went wrong. please try again.
      </h2>
    </div>
  </div>
</template>

<script>
import IconSearch from '@/assets/images/Header/search.svg';
import IconCancel from '@/assets/images/Header/cancel.svg';
import FilterIcon from '@/assets/images/Icons/filter.svg';
import Arrow from '@/assets/images/Icons/arrow_right_white.svg';
import ResetIcon from '@/assets/images/Icons/reset.svg';
import DownloadIcon from '@/assets/images/Icons/download.svg';
import PorscheButton from '@/components/PorscheButton.vue';
import Modal from '@/components/Modal.vue';
import VueMultiselect from '@/components/VueMultiselect.vue';
import Pagination from '@/components/Pagination.vue';
import Loading from '@/components/Loading.vue';
import ToolTip from '@/components/ToolTip.vue';
import ExportCSV from '@/utilities/ExportCSV';
import ApiService from '@/utilities/ApiService';

export default {
  name: 'workbook-quiz-results',
  components: {
    VueMultiselect,
    Arrow,
    ResetIcon,
    DownloadIcon,
    Pagination,
    PorscheButton,
    Loading,
    Modal,
    IconSearch,
    IconCancel,
    FilterIcon,
    ToolTip,
  },
  props: {},
  data() {
    return {
      initialLoad: null,
      dataAvailable: null,
      paginatedDataLoading: null,
      quizResultsLoading: true,
      success: null,
      error: null,
      quizzesError: null,
      quizResultError: null,
      quizPaginatedError: null,
      statusMsg: null,
      quizResult: null,
      quizResultOptions: null,
      quizTotalUsers: null,
      quizTotalResults: null,
      quizNotStarted: null,
      quizAvgScore: null,
      quizHighestScore: null,
      quizLowestScore: null,
      quizPassed: null,
      quizFailed: null,
      displayCount: 8,
      displayCountOptions: [8, 16, 24],
      offset: 0,
      filterMenu: null,
      filterMenuOptions: [],
      department: null,
      passedQuiz: null,
      passedQuizOptions: ['Yes', 'No'],
      session: null,
      paginatedUsers: [],
      userPages: {},
      activePage: 1,
      pageCount: 0,
      promptRetakeQuizModal: null,
      promptRetakeQuizModalContent: null,
      resetUserModal: null,
      resetUserModalContent: null,
      searchQuery: '',
    };
  },
  async created() {
    await this.getInitialData();
  },
  computed: {},
  watch: {},
  methods: {
    async getInitialData() {
      try {
        // get quizzes
        await this.getAllQuizzes();

        if (this.quizResultOptions.length > 0) {
          // gets quiz results for first quiz in the results array
          await this.getQuizResults();
        }
      } catch (err) {
        console.warn(err);
      }
      // set loading variables to true
      this.dataAvailable = true;
      this.initialLoad = true;
    },

    async getAllQuizzes() {
      try {
        const quizzes = await ApiService.get(
          `${process.env.VUE_APP_API_URL}/quiz/workbook/${this.$route.params.workbookId}`,
        );
        this.quizResultOptions = quizzes.data.data;
        // set quiz result to first quiz.
        this.quizResult = this.quizResultOptions[0];
      } catch (err) {
        this.quizzesError = true;
        throw new Error(err);
      }
    },

    async getQuizResults() {
      try {
        // reset on users table
        this.paginatedUsers = [];
        this.userPages = {};

        // reset error
        this.quizResultError = null;

        // start loading
        this.quizResultsLoading = true;

        const quizResults = await ApiService.post(`${process.env.VUE_APP_API_URL}/quiz/result/${this.quizResult.id}`, {
          limit: this.displayCount,
          offset: this.offset,
          search: this.searchQuery,
          sessions: [],
        });

        const { data } = quizResults.data;

        // set overall data
        this.quizTotalUsers = data.totalUsers;
        this.quizTotalResults = data.totalResults;
        this.quizNotStarted = data.notStarted;
        this.quizAvgScore = data.avgScore;
        this.quizHighestScore = data.highestScore;
        this.quizLowestScore = data.lowestScore;
        this.quizPassed = data.passed;
        this.quizFailed = data.failed;

        data.dropdown.workbookSessions.forEach((option) => {
          option.fullTitle = `${option.palmsId} - ${option.title}`;
        });

        data.dropdown.workbookSessions.forEach((option) => {
          option.fullTitle = `${option.title}, ${new Date(option.startDate).toLocaleDateString('en-us')} - ${new Date(
            option.endDate,
          ).toLocaleDateString('en-us')}, ${option.location ? option.location : ''}`;
        });

        this.filterMenuOptions = data.dropdown;

        // set user results
        if (data.quizResults.length > 0) {
          this.userPages[this.activePage] = data.quizResults;
          this.paginatedUsers = this.userPages[this.activePage];
          this.pageCount = Math.ceil(data.totalResults / this.displayCount);
        }

        // stop loading
        this.quizResultsLoading = null;
      } catch (err) {
        this.quizResultsLoading = null;
        this.quizResultError = true;
        throw new Error(err);
      }
    },

    async getPaginatedUsers() {
      try {
        //
        this.quizPaginatedError = null;
        this.filterMenu = null;

        // init paginated loading
        this.paginatedDataLoading = true;

        // Setting Filter Options
        let passedQuiz = null;
        const sessions = [];
        // API has to return null for showing all quizzes
        if (this.passedQuiz) {
          passedQuiz = this.passedQuiz === 'Yes';
        }

        if (this.session) {
          sessions.push(this.session.palmsId);
        }

        const quizResults = await ApiService.post(`${process.env.VUE_APP_API_URL}/quiz/result/${this.quizResult.id}`, {
          limit: this.displayCount,
          offset: this.offset,
          search: this.searchQuery,
          sessions,
          passed: passedQuiz,
          departments: this.department,
        });
        const { data } = quizResults.data;

        this.quizTotalUsers = data.totalUsers;
        this.quizTotalResults = data.totalResults;
        this.quizNotStarted = data.notStarted;
        this.quizAvgScore = data.avgScore;
        this.quizHighestScore = data.highestScore;
        this.quizLowestScore = data.lowestScore;
        this.quizPassed = data.passed;
        this.quizFailed = data.failed;

        // set user results
        if (data.quizResults.length > 0) {
          this.userPages[this.activePage] = data.quizResults;
          this.paginatedUsers = this.userPages[this.activePage];
          this.pageCount = Math.ceil(data.totalResults / this.displayCount);
        } else {
          this.userPages = {};
          this.paginatedUsers = [];
        }

        // stop paginated loading
        this.paginatedDataLoading = false;
      } catch (err) {
        this.quizPaginatedError = true;
        throw new Error(err);
      }
    },

    clearFilters() {
      this.department = null;
      this.passedQuiz = null;
      this.getPaginatedUsers();
    },

    // ref utility function for exporting csv
    async exportCSV(path) {
      ExportCSV(path);
    },

    promptRetakeQuiz(userId) {
      if (userId) {
        this.promptRetakeQuizModal = true;
        this.promptRetakeQuizModalContent = {
          title: 'Are you sure you wish to allow a retake?',
          message: 'This action cannot be undone.',
          userId,
        };
        return;
      }
      this.promptRetakeQuizModal = null;
    },

    async resetUserQuiz() {
      try {
        this.promptRetakeQuizModal = null;

        await ApiService.post(`${process.env.VUE_APP_API_URL}/quiz/retake`, {
          userId: this.promptRetakeQuizModalContent.userId,
          quizId: this.quizResult.id,
        });

        // set modal popup content
        this.resetUserModalContent = {
          title: 'Reset user quiz',
          message: 'User has been reset to retake this quiz.',
        };
        this.resetUserModal = true;
      } catch (err) {
        console.warn(err);
      }
    },

    async closeUserReset() {
      this.resetUserModal = null;

      // re-get data now that user will no longer be part of data within user table
      this.userPages = {};
      this.paginatedUsers = [];
      await this.getPaginatedUsers();
    },

    toggleFilterMenu() {
      this.filterMenu = !this.filterMenu;
    },

    async pageChange(page) {
      if (this.userPages[page]) {
        this.paginatedUsers = this.userPages[page];
        this.activePage = page;
        return;
      }

      // Get new page
      this.offset = (page - 1) * this.displayCount;
      this.activePage = page;
      await this.getPaginatedUsers();
      this.paginatedUsers = this.userPages[page];
    },

    clearSearch() {
      this.searchQuery = '';
      this.getPaginatedUsers();
    },
  },
};
</script>

<style lang="scss" scoped>
.quiz-results-wrap {
  position: relative;

  .quiz-results {
    .result-header {
      .result-options {
        display: flex;
        align-items: center;

        .session-type::v-deep {
          width: 400px;
          margin-left: 16px;
          .label-wrap {
            position: absolute;
            top: 13px;
            left: 12px;
            z-index: 99;
            color: #000;
          }

          .multiselect__tags {
            padding: 12px 30px 12px 70px !important;

            .multiselect__single {
              padding: 0;
              margin-bottom: 0;
              overflow: scroll;
              -ms-overflow-style: none;
              scrollbar-width: none;
              white-space: nowrap;

              &::-webkit-scrollbar {
                display: none;
              }
            }
          }
        }
      }
    }
    .quiz-content-wrap {
      position: relative;
    }
  }
}
</style>
