import axios from 'axios';

const ApiService = {
  init() {
    axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 401) {
          localStorage.removeItem('jwtToken');
          window.location = `${process.env.VUE_APP_API_URL}/saml/auth`;
        }
        throw error;
      },
    );
    this.setToken();
  },
  setToken() {
    const token = localStorage.getItem('jwtToken');
    if (token) axios.defaults.headers.common.Authorization = token;
    else delete axios.defaults.headers.common.Authorization;
  },
  query(resource, params) {
    return axios.get(resource, params);
  },
  get(endpoint, params, config) {
    return axios.get(endpoint, { ...config, params });
  },
  post(resource, params, queryParams) {
    return axios.post(`${resource}`, params, queryParams);
  },
  update(resource, slug, params) {
    return axios.put(`${resource}/${slug}`, params);
  },
  put(resource, params) {
    return axios.put(`${resource}`, params);
  },
  patch(resource, params) {
    return axios.patch(`${resource}`, params);
  },
  delete(resource, data) {
    return axios.delete(resource, { data });
  },
  all(resources) {
    return axios.all(resources);
  },
};

export default ApiService;
