<template>
  <div class="survey-results-wrap">
    <div @click="closeSlidePanel" ref="slidePanelWrap" v-if="slidePanel" class="slide-panel-wrap">
      <div class="slide-panel">
        <div @click="toggleSlidePanel" class="close-report">
          <ArrowRed class="icon arrow-red" />
          <span>Back to Survey Results</span>
        </div>
        <h2>Question {{ activeQuestion.questionNum }} Responses</h2>
        <p class="question-label">{{ activeQuestion.questionNum }}. {{ activeQuestion.question.question }}</p>
        <div class="responses">
          <div class="response" v-for="(res, index) in activeQuestion.question.userQuestionResponse" :key="index">
            <p>{{ res.text }}</p>
            <hr v-if="index + 1 !== activeQuestion.question.userQuestionResponse.length" />
          </div>
        </div>
        <PorscheButton
          @click="exportCSV(`survey/result/${surveyResult.id}/question/${activeQuestion.question.id}/csv`)"
        >
          <Arrow class="arrow-icon icon" />
          <span>Export CSV</span>
        </PorscheButton>
      </div>
    </div>
    <!-- Loading animation for view on initial load -->
    <Loading v-if="!dataAvailable && !initialLoad" :type="'section'" />
    <div v-if="dataAvailable && initialLoad" class="survey-results">
      <!-- Check for errors on both API calls && to see if we have survey to choose from -->
      <div v-if="(!surveysError || !surveyResultError) && surveyResultOptions.length > 0">
        <!--Survey result dropdown  -->
        <div v-if="!surveysError" class="result-header">
          <h1>Results for</h1>
          <div class="vue-multiselect">
            <VueMultiselect
              v-model="surveyResult"
              label="title"
              track-by="id"
              :searchable="false"
              :close-on-select="true"
              :show-labels="false"
              :allow-empty="false"
              :options="surveyResultOptions"
              @input="getSurveyResults"
            />
          </div>
          <PorscheButton @click="exportCSV(`survey/result/${surveyResult.id}/csv`)">
            <Arrow class="arrow-icon icon" />
            <span>Export CSV</span>
          </PorscheButton>
        </div>

        <h2 v-if="surveysError" class="header-error">Something went wrong, please try again.</h2>

        <div class="survey-content-wrap">
          <Loading v-if="surveyResultsLoading" :type="'section'" />
          <!-- Check for errors on Survey Result Content API -->
          <div v-if="!surveyResultError && !surveyResultsLoading" class="survey-content">
            <div class="results">
              <div class="row">
                <div class="stat">
                  <span class="label">Total Users</span>
                  <span class="data">{{ surveyTotalUsers }}</span>
                </div>
                <div class="stat">
                  <span class="label">Total Results</span>
                  <span class="data">{{ surveyTotalResults }}</span>
                </div>
                <div class="stat">
                  <span class="label">Not Started</span>
                  <span class="data">{{ surveyNotStarted }}</span>
                </div>
                <div class="stat">
                  <span class="label">Completed</span>
                  <span class="data">{{ surveyTotalResults }}</span>
                </div>
              </div>
            </div>
            <div class="survey-questions">
              <div v-for="(question, index) in surveyQuestions" :key="index" class="question">
                <!-- Yes/No & Slider -->
                <template v-if="surveyQuestionTypes['Yes/No'].id === question.questionTypeId">
                  <span class="question-label">{{ index + 1 }}. {{ question.question }}<span>*</span></span>
                  <div class="question-results">
                    <div class="result" v-for="response in question.surveyQuestionOption" :key="response.id">
                      <span class="result-label">{{ response.text }}</span>
                      <span
                        class="result-bar"
                        :style="`width: ${
                          (response.userSurveyQuestionOptionResponseCount / surveyTotalResults) * 100
                        }%`"
                      >
                      </span>
                      <span>{{ response.userSurveyQuestionOptionResponseCount }}</span>
                    </div>
                  </div>
                </template>

                <!-- Checkboxes & Dropdown  -->
                <template
                  v-if="
                    surveyQuestionTypes['Checkboxes'].id === question.questionTypeId ||
                    surveyQuestionTypes['Dropdown Selection'].id === question.questionTypeId ||
                    surveyQuestionTypes['Multiple Choice'].id === question.questionTypeId
                  "
                >
                  <span class="question-label">{{ index + 1 }}. {{ question.question }}<span>*</span></span>
                  <div class="question-results multiple-rows">
                    <div class="result" v-for="(response, index) in question.surveyQuestionOption" :key="response.id">
                      <span>
                        <span v-if="index === 0">A.</span>
                        <span v-if="index === 1">B.</span>
                        <span v-if="index === 2">C.</span>
                        <span v-if="index === 3">D.</span>
                        <span v-if="index === 4">E.</span>
                        <span v-if="index === 5">F.</span>
                        {{ response.text }}
                      </span>
                      <div class="result-response">
                        <span
                          class="result-bar"
                          :style="`width: ${
                            (response.userSurveyQuestionOptionResponseCount / surveyTotalResults) * 100
                          }%`"
                        >
                        </span>
                        <span>{{ response.userSurveyQuestionOptionResponseCount }}</span>
                      </div>
                    </div>
                  </div>
                </template>

                <!-- Single & Multiline  -->
                <template
                  v-if="
                    surveyQuestionTypes['Single-line response'].id === question.questionTypeId ||
                    surveyQuestionTypes['Multi-line response'].id === question.questionTypeId
                  "
                >
                  <span class="question-label">{{ index + 1 }}. {{ question.question }}<span>*</span></span>
                  <div class="question-results text-response">
                    <p>{{ question.userQuestionResponseCount }}</p>
                    <p>Total Responses</p>
                    <div class="result">
                      <PorscheButton @click="toggleSlidePanel(question, index)">
                        <Arrow class="arrow-icon icon" />
                        <span>More details</span>
                      </PorscheButton>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="user-results">
              <h1>Results by User</h1>
              <!-- Search -->
              <div class="search">
                <IconSearch class="svg-icon" />
                <input
                  type="text"
                  placeholder="Search users..."
                  v-model="searchQuery"
                  @keypress.enter="getPaginatedUsers"
                />
                <IconCancel v-show="searchQuery" @click="clearSearch" class="svg-icon cancel-btn" />
              </div>
              <div class="filter-options">
                <div class="vue-multiselect display-count">
                  <span class="label">Show:</span>
                  <VueMultiselect
                    v-model="displayCount"
                    :searchable="false"
                    :close-on-select="true"
                    :show-labels="false"
                    :options="displayCountOptions"
                    @input="getPaginatedUsers"
                  />
                </div>
                <!-- Removed untill post launch -->
                <!-- <div @click="toggleFilterMenu" class="filter flex" :class="{ 'filter-menu-active': filterMenu }">
                <p>Filter</p>
                <FilterIcon class="filter-icon" />
              </div> -->
                <!-- Advanced Filter Menu -->
                <!-- <div v-show="filterMenu" class="filter-menu-wrap">
                <div class="filter-menu">
                  <div class="column">
                    <div class="sessions-filter">
                      <label>Status</label>
                      <div class="checkbox">
                        <input type="checkbox" v-model="notStarted" />
                        <span>Not Started</span>
                        <Check v-show="notStarted" class="check-icon" />
                      </div>
                      <div class="checkbox">
                        <input type="checkbox" v-model="inProgress" />
                        <span>In Progress</span>
                        <Check v-show="inProgress" class="check-icon" />
                      </div>
                      <div class="checkbox">
                        <input type="checkbox" v-model="completed" />
                        <span>Completed</span>
                        <Check v-show="completed" class="check-icon" />
                      </div>
                    </div>
                  </div>
                  <div class="column">
                    <div class="sessions-filter">
                      <label>Passed</label>
                      <div class="checkbox">
                        <input type="checkbox" v-model="passed" />
                        <span>Yes</span>
                        <Check v-show="passed" class="check-icon" />
                      </div>
                      <div class="checkbox">
                        <input type="checkbox" v-model="failed" />
                        <span>No</span>
                        <Check v-show="failed" class="check-icon" />
                      </div>
                    </div>
                  </div>
                  <div class="column">
                    <div class="sessions-filter">
                      <label>Sessions to Show</label>
                      <div class="checkbox">
                        <input type="checkbox" v-model="sessionOptionOne" />
                        <span>P-EV Taycan-Session01-022121</span>
                        <Check v-show="sessionOptionOne" class="check-icon" />
                      </div>
                      <div class="checkbox">
                        <input type="checkbox" v-model="sessionOptionTwo" />
                        <span>P-EV Taycan-Session01-022121</span>
                        <Check v-show="sessionOptionTwo" class="check-icon" />
                      </div>
                      <div class="checkbox">
                        <input type="checkbox" v-model="sessionOptionThree" />
                        <span>P-EV Taycan-Session01-022121</span>
                        <Check v-show="sessionOptionThree" class="check-icon" />
                      </div>
                      <div class="checkbox">
                        <input type="checkbox" v-model="sessionOptionFour" />
                        <span>P-EV Taycan-Session01-022121</span>
                        <Check v-show="sessionOptionFour" class="check-icon" />
                      </div>
                      <div class="checkbox">
                        <input type="checkbox" v-model="sessionOptionFive" />
                        <span>P-EV Taycan-Session01-022121</span>
                        <Check v-show="sessionOptionFive" class="check-icon" />
                      </div>
                      <div class="checkbox">
                        <input type="checkbox" v-model="sessionOptionSix" />
                        <span>P-EV Taycan-Session01-022121</span>
                        <Check v-show="sessionOptionSix" class="check-icon" />
                      </div>
                    </div>
                  </div>
                </div>

                <PorscheButton>
                  <Arrow class="arrow-icon icon" />
                  <span>Apply filters</span>
                </PorscheButton>
              </div> -->
              </div>
              <div class="user-results-data-wrap">
                <div class="user-results-data">
                  <div class="users-table">
                    <div class="labels-wrap">
                      <div class="labels">
                        <span>User ID</span>
                        <span>Last</span>
                        <span>First</span>
                        <span>Session</span>
                        <span>Status</span>
                        <span>Actions</span>
                      </div>
                      <hr />
                    </div>
                    <div class="users-wrap">
                      <Loading :type="'section'" v-if="paginatedDataLoading" />
                      <div v-if="paginatedUsers.length > 0 && !paginatedDataLoading">
                        <div v-for="(user, index) in paginatedUsers" :key="index">
                          <div class="user">
                            <span>{{ user.userName ? user.userName : '-' }}</span>
                            <span>{{ user.lastName ? user.lastName : '-' }}</span>
                            <span>{{ user.firstName ? user.firstName : '-' }}</span>
                            <span>{{ user.palmsSessionId ? user.palmsSessionId : '-' }}</span>
                            <span>{{ user.status ? user.status : 'Completed' }}</span>
                            <div class="actions">
                              <div class="action">
                                <DownloadIcon
                                  @click="exportCSV(`survey/result/${surveyResult.id}/users/${user.userId}/csv`)"
                                  class="download-icon"
                                />
                                <ToolTip :right="true">Download User Responses</ToolTip>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </div>
                        <div class="pagination">
                          <Pagination :pageCount="pageCount" @page-change="pageChange" :currentPage="activePage" />
                        </div>
                      </div>
                      <h2
                        v-if="paginatedUsers.length === 0 && !surveyPaginatedError && !paginatedDataLoading"
                        class="header-error"
                      >
                        No users have completed the survey.
                      </h2>
                      <h2
                        v-if="paginatedUsers.length === 0 && surveyPaginatedError && !paginatedDataLoading"
                        class="header-error"
                      >
                        Sorry, something went wrong, please try again.
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Error on getting survey results -->
          <div v-if="surveyResultError && !surveyResultsLoading">
            <h2 class="header-error">Something went wrong, please try again.</h2>
          </div>
        </div>
      </div>
      <!-- No surveys in the workbook && but no errors -->
      <h2 class="header-error" v-if="!surveysError && !surveyResultError && surveyResultOptions.length === 0">
        There are no surveys associated with this workbook.
      </h2>
      <!-- Both components have an error after loading -->
      <h2 v-if="surveysError && surveyResultError" class="header-error">
        Sorry, something went wrong. please try again.
      </h2>
    </div>
  </div>
</template>

<script>
import IconSearch from '@/assets/images/Header/search.svg';
import IconCancel from '@/assets/images/Header/cancel.svg';
import Arrow from '@/assets/images/Icons/arrow_right_white.svg';
import ArrowRed from '@/assets/images/Icons/arrow_right_red.svg';
import DownloadIcon from '@/assets/images/Icons/download.svg';
import PorscheButton from '@/components/PorscheButton.vue';
import Loading from '@/components/Loading.vue';
import ToolTip from '@/components/ToolTip.vue';
import VueMultiselect from '@/components/VueMultiselect.vue';
import Pagination from '@/components/Pagination.vue';
import ExportCSV from '@/utilities/ExportCSV';
import ApiService from '@/utilities/ApiService';
// import FilterIcon from '../assets/images/Icons/filter.svg';
// import Check from '../assets/images/Icons/check.svg';

export default {
  name: 'surveyResults',
  components: {
    VueMultiselect,
    Arrow,
    DownloadIcon,
    Pagination,
    PorscheButton,
    ArrowRed,
    Loading,
    IconSearch,
    IconCancel,
    ToolTip,
    // Check,
    // FilterIcon,
  },
  props: {},
  data() {
    return {
      initialLoad: null,
      dataAvailable: null,
      paginatedDataLoading: null,
      surveyResultsLoading: true,
      surveysError: null,
      surveyResultError: null,
      surveyPaginatedError: null,
      surveyTotalUsers: null,
      surveyTotalResults: null,
      surveyNotStarted: null,
      surveyCompleted: null,
      slidePanel: null,
      activeQuestion: null,
      surveyResult: null,
      surveyResultOptions: [],
      displayCount: 8,
      displayCountOptions: [8, 16, 24],
      filterMenu: null,
      sessionOptionOne: null,
      sessionOptionTwo: null,
      sessionOptionThree: null,
      sessionOptionFour: null,
      sessionOptionFive: null,
      sessionOptionSix: null,
      notStarted: null,
      inProgress: null,
      completed: null,
      passed: null,
      failed: null,
      surveyQuestionTypes: {},
      surveyQuestions: null,
      paginatedUsers: [],
      userPages: {},
      activePage: 1,
      offset: 0,
      pageCount: 0,
      searchQuery: '',
    };
  },
  async created() {
    await this.getInitialData();
  },
  computed: {},
  watch: {},
  methods: {
    async getInitialData() {
      try {
        // get quizzes
        await this.getAllSurveys();

        if (this.surveyResultOptions.length > 0) {
          // gets quiz results for first quiz in the results array
          await this.getSurveyResults();
        }

        // set loading variables to true
        this.dataAvailable = true;
        this.initialLoad = true;
      } catch (err) {
        this.dataAvailable = true;
        this.initialLoad = true;
        console.warn(err);
      }
    },

    async getAllSurveys() {
      try {
        const surveys = await ApiService.get(
          `${process.env.VUE_APP_API_URL}/survey/workbook/${this.$route.params.workbookId}`,
        );

        this.surveyResultOptions = surveys.data.data;
        // set survey result to first survey.
        this.surveyResult = this.surveyResultOptions[0];
      } catch (err) {
        this.surveysError = true;
        throw new Error(err);
      }
    },

    async getSurveyResults() {
      try {
        // reset on users table
        this.paginatedUsers = [];
        this.userPages = {};

        // reset error
        this.surveyResultError = null;

        // start loading
        this.surveyResultsLoading = true;

        const allQuestionTypes = await ApiService.get(`${process.env.VUE_APP_API_URL}/question-type/`);
        const surveyResults = await ApiService.get(
          `${process.env.VUE_APP_API_URL}/survey/result/${this.surveyResult.id}`,
        );
        const userResults = await ApiService.post(
          `${process.env.VUE_APP_API_URL}/survey/result/${this.surveyResult.id}/users`,
          {
            limit: this.displayCount,
            offset: this.offset,
            search: this.searchQuery,
          },
        );

        // filter out survey question types, and then create object key res
        const filteredSurveyTypes = allQuestionTypes.data.data.filter((type) => type.isSurveyOption === true);
        filteredSurveyTypes.forEach((type) => {
          this.surveyQuestionTypes[type.type] = type;
        });

        // set data
        const surveyData = surveyResults.data.data;
        const userData = userResults.data.data;

        // set survey totals
        this.surveyTotalUsers = surveyData.totalUsers;
        this.surveyTotalResults = surveyData.totalResults;
        this.surveyNotStarted = surveyData.notStarted;
        this.surveyCompleted = surveyData.totalResults;

        // set survey questions
        this.surveyQuestions = surveyData.surveyQuestion;

        // set user results
        if (userData.users.length > 0) {
          this.userPages[this.activePage] = userData.users;
          this.paginatedUsers = this.userPages[this.activePage];
          this.pageCount = Math.ceil(userData.count / this.displayCount);
        }

        // stop loading
        this.surveyResultsLoading = null;
      } catch (err) {
        this.surveyResultsLoading = null;
        this.surveyResultError = true;
        throw new Error(err);
      }
    },

    async getPaginatedUsers() {
      try {
        //
        this.surveyPaginatedError = null;

        // init paginated loading
        this.paginatedDataLoading = true;

        const userResults = await ApiService.post(
          `${process.env.VUE_APP_API_URL}/survey/result/${this.surveyResult.id}/users`,
          {
            limit: this.displayCount,
            offset: this.offset,
            search: this.searchQuery,
          },
        );

        const { data } = userResults;

        // set user results
        if (data.length > 0) {
          this.userPages[this.activePage] = data;
          this.paginatedUsers = this.userPages[this.activePage];
          this.pageCount = Math.ceil(data.count / this.displayCount);
        }

        // stop paginated loading
        this.paginatedDataLoading = false;
      } catch (err) {
        this.paginatedDataLoading = false;
        this.surveyPaginatedError = true;
        throw new Error(err);
      }
    },

    async pageChange(page) {
      if (this.userPages[page]) {
        this.paginatedUsers = this.userPages[page];
        this.activePage = page;
        return;
      }

      // Get new page
      this.offset = (page - 1) * this.displayCount;
      this.activePage = page;
      await this.getPaginatedUsers();
      this.paginatedUsers = this.userPages[page];
    },

    // ref utility function for exporting csv
    async exportCSV(path) {
      ExportCSV(path);
    },

    toggleFilterMenu() {
      this.filterMenu = !this.filterMenu;
    },

    toggleSlidePanel(question, index) {
      if (!this.slidePanel) {
        this.activeQuestion = {
          question,
          questionNum: index + 1,
        };
      }
      this.slidePanel = !this.slidePanel;
    },

    closeSlidePanel(e) {
      if (e.target === this.$refs.slidePanelWrap) {
        this.slidePanel = false;
      }
    },

    clearSearch() {
      this.searchQuery = '';
      this.getPaginatedUsers();
    },
  },
};
</script>

<style lang="scss" scoped>
.survey-results-wrap {
  position: relative;

  // Side Panel
  .slide-panel-wrap {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
    width: 100vw;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.6);

    .slide-panel {
      position: fixed;
      right: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 550px;
      height: 100%;
      min-height: 100vh;
      padding: 36px 36px 76px 36px;
      overflow: scroll;
      background-color: #fff;

      .close-report {
        display: flex;
        align-items: center;
        margin-bottom: 36px;
        cursor: pointer;

        .arrow-red {
          width: 6px;
          height: 13px;
          margin-right: 6px;
          transform: rotate(180deg);
        }
      }

      h2 {
        margin-bottom: 16px;
        font-size: 24px;
        font-weight: 500;
      }

      .question-label {
        margin-bottom: 4px;
      }

      .responses {
        max-height: 500px;
        padding: 18px;
        margin-bottom: 20px;
        overflow: scroll;
        border: 1px solid $grey;

        .response {
          hr {
            margin: 6px 0;
          }
        }
      }

      .porsche-button {
        align-self: flex-start;
      }
    }
  }

  .survey-results {
    .survey-content-wrap {
      position: relative;
    }

    .survey-questions {
      margin-top: 16px;
      .question {
        padding: 24px;
        margin-bottom: 16px;
        border: 1px solid #c9cacb;

        .question-label {
          span {
            color: #d5001c;
          }
        }

        .question-results {
          padding-left: 20px;
          margin-top: 16px;

          .result {
            display: flex;
            gap: 8px;
            align-items: center;
            margin-bottom: 8px;

            .result-label {
              min-width: 30px;
            }

            .result-bar {
              height: 12px;
              background-color: #d5001c;
            }
          }
        }

        .text-response {
          p:nth-child(1) {
            font-size: 24px;
          }
          p:nth-child(2) {
            margin-bottom: 16px;
            font-weight: 500;
          }
        }

        .multiple-rows {
          .result {
            flex-direction: column;
            align-items: flex-start;
            .result-response {
              display: flex;
              gap: 8px;
              align-items: center;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
