<template>
  <header class="navigation">
    <nav>
      <div class="container nav-top">
        <div v-if="searchNavigation" class="search-navigation">
          <search class="svg-icon" />
          <input
            autofocus
            type="text"
            placeholder="Search something..."
            v-model="searchQuery"
            @keydown.enter="searchPlatform"
          />
          <cancel @click="closeSearch" class="svg-icon" />
        </div>
        <div v-else class="content">
          <h2 class="pointer" @click="closeWindow">Exit</h2>

          <img src="@/assets/images/Logo/logo_porsche_crest@2x.png" alt="Porsche Logo" />

          <ul class="user-settings">
            <li class="icon link">
              <profile class="svg-icon" />
              <span v-if="!isLaptop">{{ this.username }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="line" :class="{ 'search-active': searchActive }"></div>
      <div v-if="!searchActive" class="container nav-bottom">
        <ul class="navigation-links">
          <router-link class="nav-link" :to="{ name: 'workbooks' }">Workbooks</router-link>
          <router-link v-if="admin || designer" class="nav-link" :to="{ name: 'reports' }">Reports</router-link>
          <router-link v-if="admin" class="nav-link" :to="{ name: 'access-code-reports' }">Access Codes</router-link>
          <router-link v-if="admin" class="nav-link" :to="{ name: 'admin-users' }">Users</router-link>
          <router-link v-if="admin" class="nav-link" :to="{ name: 'audit-trail' }">Audit Trail</router-link>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Logout from '@/utilities/Logout';
import search from '@/assets/images/Header/search.svg';
import profile from '@/assets/images/Header/profile.svg';
import cancel from '@/assets/images/Header/cancel.svg';

const { mapMutations, mapState, mapActions } = createNamespacedHelpers('userInformation');

export default {
  name: 'Navigation',
  components: {
    search,
    profile,
    cancel,
  },
  props: {},
  data() {
    return {
      search: null,
    };
  },
  created() {},
  computed: {
    isLaptop() {
      return this.$mq === 'tabletLarge' || this.$mq === 'tablet' || this.$mq === 'mobile';
    },
    ...mapState([
      'searchNavigation',
      'admin',
      'designer',
      'participant',
      'username',
      'userId',
      'searchQuery',
      'searchActive',
    ]),

    searchQuery: {
      get() {
        return this.$store.state.userInformation.searchQuery;
      },
      set(payload) {
        this.UPDATE_SEARCH_QUERY(payload);
      },
    },
  },
  watch: {},
  methods: {
    ...mapMutations(['OPEN_SEARCH_NAVIGATION', 'UPDATE_SEARCH_QUERY', 'CLOSE_SEARCH']),
    ...mapActions(['SEARCH_PLATFORM']),

    openSearch() {
      this.OPEN_SEARCH_NAVIGATION();
    },

    closeSearch() {
      this.CLOSE_SEARCH();
    },
    closeWindow() {
      window.close();
    },

    searchPlatform() {
      if (this.searchQuery !== '' && this.searchQuery) {
        this.SEARCH_PLATFORM();
      }
    },

    logoutUser() {
      try {
        // takes userId as a param, imported from store.
        Logout(this.userId);
      } catch (error) {
        console.warn(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// Nav defaults
@import '../assets/scss/navigation.scss';

.navigation {
  position: relative;
  padding: 15px 0 20px 0;

  nav {
    position: relative;
    display: flex;
    flex-direction: column;

    .svg-icon {
      width: 17px;
    }

    .nav-top {
      .search-navigation {
        min-height: 62px;

        input {
          height: 40px;
        }
      }
    }

    .line {
      bottom: 43px;
    }

    .search-active {
      position: relative;
      bottom: initial;
    }

    .nav-bottom {
      .navigation-links {
        z-index: 101;
        margin-top: 16px;

        .nav-link {
          margin-right: 20px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
.pointer {
  cursor: pointer;
}
</style>
