<template>
  <transition name="alert">
    <span class="status-msg error" :class="{ inline: type === 'inline', block: type === 'block' }" v-show="alertError">
      <Cancel class="cancel-icon" />
      <slot name="alert-message" />
    </span>
  </transition>
</template>

<script>
import Cancel from '@/assets/images/Icons/cancel.svg';

export default {
  name: 'alert-error',
  components: { Cancel },
  props: { alertError: Boolean, type: String },
  data() {
    return {};
  },
  created() {},
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.alert-enter-active {
  transition: 0.3s ease all;
}

.alert-enter {
  opacity: 0;
}

.status-msg {
  display: flex;
  align-items: center;
  margin-left: 0;
  font-size: 16px;

  .cancel-icon {
    width: auto;
    height: 24px;
    margin-right: 6px;
    path {
      fill: $primary;
    }
  }
}

.error {
  color: $primary;
}

.inline {
  margin-left: 24px;
}

.block {
  margin-bottom: 16px;
}
</style>
