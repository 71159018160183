import ApiService from '@/utilities/ApiService';
import { uid } from 'uid';

export default async (file) => {
  const formData = new FormData();
  formData.append('template', file);
  const parse = await ApiService.post(`${process.env.VUE_APP_API_URL}/survey/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  parse.data.data.questions.forEach((surveyQuestion) => {
    surveyQuestion.id = uid();
    if (surveyQuestion.questionType.toLowerCase() !== 'yes/no') {
      surveyQuestion.options.forEach((option) => {
        option.id = uid();
        option.isAnswer = false;
      });
    } else {
      surveyQuestion.options = [];
      surveyQuestion.options.push(
        {
          id: uid(),
          text: 'Yes',
          isAnswer: false,
        },
        {
          id: uid(),
          text: 'No',
          isAnswer: false,
        },
      );
    }
  });

  return parse;
};
